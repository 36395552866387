import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import $ from "jquery";

// reactstrap components
import { Col, Row } from "reactstrap";
import { useWindow } from "hooks/UseWindow";
import FeaturedEventCard from "components/Event/FeaturedEventCard";
import styles from "../../views/pages/learner/event/Event.module.scss";

function FeaturedEvents({ events }) {
	const [currentDisplayEvent, setCurrentDisplayEvent] = useState(1);
	let totalEvents = events.length;
	const slider = React.useRef(null);
	const { width } = useWindow();

	var slider_settings = {
		dots: false,
		infinite: true,
		autoplay: false,
		speed: 1000,
		autoplaySpeed: 10000,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],

		beforeChange: (oldIndex, newIndex) => {
			setCurrentDisplayEvent(newIndex + 1);
			if (newIndex == 0) {
				// $(".upcoming-arrow .arrow-left").addClass("disable");
				$(".upcoming-arrow .arrow-right").removeClass("disable");
			} else if (newIndex == totalEvents - 1) {
				// $(".upcoming-arrow .arrow-right").addClass("disable");
				$(".upcoming-arrow .arrow-left").removeClass("disable");
			} else {
				$(".upcoming-arrow .arrow-left,.upcoming-arrow .arrow-right").removeClass("disable");
			}
		},
	};
	
	return (
		<div className={"fadeInDiv"}>
			<Row className='align-items-center justify-content-between' style={{ paddingRight: "10px", paddingLeft: "10px" }}>
				<Col className=''>
					<span className='mb-0 fs24 font_2 text-fca-darks'>
						{"You Won’t Want to Miss This"}
					</span>
				</Col>
				{Object.keys(events).length !== 0 && width <= 991 && (
					<Col xl='auto' md='auto' xs='auto'>
						<div className='arrow-wrap flex upcoming-arrow'>
							<div
								className='arrow arrow-left'
								onClick={() => slider?.current?.slickPrev()}
							>
								<i className='fa fa-angle-left' aria-hidden='true'></i>
							</div>
							<div
								className={"arrow arrow-right ml-2"}
								onClick={() => slider?.current?.slickNext()}
							>
								<i className='fa fa-angle-right' aria-hidden='true'></i>
							</div>
						</div>
					</Col>
				)}
			</Row>

			<div className={"w-100 pb-2 pt-0"}>
				{Object.keys(events).length !== 0 ? (
					<div className={"featured_event_slider"}>
						<Slider ref={slider} className={`${width > 991 ? "adaptive-slider" : "adaptive-mobile-slider"}`} arrows={width > 991 ? true : false} {...slider_settings} >
							{events.map((event, i) => (
								<FeaturedEventCard key={i} event={event} />
							))}
							{width <= 991 && width > 575 && events.length === 1 &&
								<></>
							}
						</Slider>
					</div>
				) : (
					<div className='pt-4'>
						<p>Do not have any Event Yet!</p>
					</div>
				)}
			</div>
		</div>
	);
}

export default FeaturedEvents;
