const { useState, useEffect } = require("react");

export const useScrollDirection = () => {
	// storing this to get the scroll direction
	const [lastScrollTop, setLastScrollTop] = useState(0);
	// the offset of the document.body
	const [bodyOffset, setBodyOffset] = useState(
		document.body.getBoundingClientRect()
	);
	// the vertical direction
	const [scrollY, setScrollY] = useState(window.scrollY);
	// the horizontal direction
	const [scrollX, setScrollX] = useState(window.scrollX);
	// scroll direction would be either up or down
	const [scrollDirection, setScrollDirection] = useState("");
 
	const listener = e => {
		setBodyOffset(document.body.getBoundingClientRect());
		setScrollY(window.scrollY);
		setScrollX(window.scrollX);
		if (lastScrollTop <= 0 && window.scrollY <= 0) {
			setScrollDirection("down");
		} else {
			setScrollDirection(lastScrollTop > window.scrollY ? "down" : "up");
		}
		setLastScrollTop(window.scrollY);
	};
 
	useEffect(() => {
		window.addEventListener("scroll", listener);
		return () => {
			window.removeEventListener("scroll", listener);
		};
	});
	return {
		scrollY,
		scrollX,
		scrollDirection
	};
};