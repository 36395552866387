/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "../../../../assets/scss/custom/react-tabs.scss";
import DailyNews from "views/pages/learner/news/DailyNews.js";
import Teaser from "views/pages/learner/news/Teaser.js";

// core components
import { useDispatch, useSelector } from "react-redux";
import { setCurrentNewsIndex } from "actions/news";
import EventHome from "../event/EventHome";


function NewsHomeScreen() {
	const [tabIndex, setTabIndex] = useState(0);
	const newsState = useSelector((state) => state.news);
	const dispatch = useDispatch();
	
	useEffect(() => {
		if (newsState.currentTabIndex !== tabIndex) {
			setTabIndex(newsState.currentTabIndex);
		}
	}, [newsState.currentTabIndex]);

	return (
		<div className="fadeInDiv event_dashboard_container" style={{ maxWidth: 1080}}>
			{/* <Tabs
				selectedIndex={tabIndex}
				onSelect={(index) => {
					dispatch(setCurrentNewsIndex(index));
					setTabIndex(index);
				}}
			>
				<TabList>
					<Tab>{"Events"}</Tab>
					<Tab>{"Daily News"}</Tab>
					<Tab>{"Teasers"}</Tab>
					<Tab>{"Notifications"}</Tab>
				</TabList>
				<TabPanel>
					<EventHome />
				</TabPanel>
				<TabPanel>
					<DailyNews />
				</TabPanel>
				<TabPanel>
					<Teaser onFocus={() => null} />
				</TabPanel>
				<TabPanel>
					<Notifications/>
				</TabPanel>
			</Tabs> */}
			<EventHome />

		</div>
	);
}

export default NewsHomeScreen;
