
import { useScrollDirection } from "hooks/UseScrollDirection";
import React from "react";
import { Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown } from "reactstrap";
import { ReactComponent as UserIcon } from "assets/img/Home/Header/icon_acc.svg";
import { ReactComponent as BellIcon } from "assets/img/Home/Header/icon_bell.svg";
import { PropTypes } from "prop-types";
import classnames from "classnames";
import { useLocation, NavLink as NavLinkRRD, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useWindow } from "hooks/UseWindow";



const HomeNavbar = ({ logo, routes }) => {
	const [state, setState] = React.useState({});
	const [isOpen, setIsOpen] = React.useState(false);
	const [isShowHeader, setIsShowHeader] = React.useState(true);
	const  { scrollY, scrollX, scrollDirection }  = useScrollDirection();
	const [showNotification, setShowNotification] = React.useState(false);

	const { user: currentUser } = useSelector((state) => state.auth);
	const { width } = useWindow();
	const location = useLocation();

	React.useEffect(() => {
		setIsShowHeader(true);
	}, [location]);

	React.useEffect(() => {
		if (scrollDirection === "down") {
			// show header
			setIsShowHeader(true);
		} else if (scrollDirection === "up") {
			setIsShowHeader(false);
		} else if (scrollDirection === "") {
			setIsShowHeader(true);
		}
	}, [scrollDirection]);

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	const toggleNotification = () => {
		setShowNotification(!showNotification);
	};
	

	const activeRoute = (routeName) => {
		return location.pathname.indexOf(routeName) > -1 ? "active" : "";
	};
	
	const getCollapseInitialState = (routes) => {
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
				return true;
			} else if (location.pathname.indexOf(routes[i].path) !== -1) {
				return true;
			}
		}
		return false;
	};

	const createLinks = (routes) => {
		return routes.map((prop, key) => {
			if (prop.redirect) {
				return null;
			}
			if (prop.collapse) {
				var st = {};
				st[prop["state"]] = !state[prop.state];
				return (
					<NavItem key={key}>
						<NavLink
							href="#pablo"
							data-toggle="collapse"
							aria-expanded={state[prop.state]}
							className={classnames({
								active: getCollapseInitialState(prop.views),
							})}
							onClick={(e) => {
								e.preventDefault();
								setState(st);
							}}
						>
							{prop.icon ? (
								<>
									<i className={prop.icon} />
									<span className="nav-link-text">{prop.name}</span>
								</>
							) : prop.miniName ? (
								<>
									<span className="sidenav-mini-icon"> {prop.miniName} </span>
									<span className="sidenav-normal"> {prop.name} </span>
								</>
							) : null}
						</NavLink>
						<Collapse isOpen={state[prop.state]}>
							<Nav className="nav-sm flex-column">
								{createLinks(prop.views)}
							</Nav>
						</Collapse>
					</NavItem>
				);
			}
			return (
				<NavItem className={activeRoute(prop.layout + prop.path)} key={key}>
					<NavLink
						to={prop.layout + prop.path}
						activeclassname=""
						onClick={toggle}
						tag={NavLinkRRD}
						style={{
							color: activeRoute(prop.layout + prop.path) === "active" ? "rgb(55,132,196)" : "#101820",
							transition: "all .5s ease",
							WebkitTransition: "all .5s ease",
							MozTransition: "all .5s ease"
						}}
					>
						{prop.name}
						{/* {activeRoute(prop.layout + prop.path) === "active" &&
							<div className="nav-active-bar">
							</div>
						} */}
					</NavLink>
				</NavItem>
			);
		});
	};
	
	return (
		<div className={`main-header-container ${isShowHeader ? "active" : "hidden"}`}>
			<Navbar color="white" light expand="lg" className="shadow p-0 main-header-nav" style={{  }}>
				<div className="nav-container navbar-expand-lg">
					{logo ? (
						<NavbarBrand href="/">
							<img
								alt={logo.imgAlt}
								className="navbar-logo"
								src={logo.imgSrc}
								href="/"
							/>
						</NavbarBrand>
					) : null}
					<NavbarToggler onClick={toggle} />
					<Collapse isOpen={width <= 992 ? isOpen : false} navbar className="nav-collapse-container" >
						<Nav navbar>
							{createLinks(routes)}
							{!currentUser &&
								<NavItem className="nav-collapse-navlink-login">
									<NavLink href="/login" className="px-0"><UserIcon height={20} width={20} /></NavLink>
									<NavLink href="/login"  className="pl-2 pr-0">Login</NavLink>
								</NavItem>
							}
							{currentUser &&
								<NavItem className="nav-collapse-navlink-login">
									<NavLink href={"/notification"} className="px-0 pr-4"><BellIcon height={22} width={22} /></NavLink>
									{/* <NavLink href={"/notification"} className="px-0 pr-4 notification-number-container">
										<div className="notification-number-container-button">
											<span className="notification-number-text">10</span>
										</div>
									</NavLink> */}
									{/* <div className={`fade-in-out-div ${showNotification ? "show" : ""}`}>
										Fading Div
									</div> */}
									{/* <div className="fade-in-out-div" style={
										{
											opacity: showNotification ? 1 : 0,
											transition: "opacity 0.2s ease-in-out",
											transform: translateY("-20px"),
  											transition:" opacity 0.5s ease-in-out transform 0.5s ease-in-out",
											backgroundColor: "blue",
											padding: "10px",
										}
									}>Fading Div</div> */}
									<NavLink href="/profile" className="px-0"><UserIcon height={22} width={22} /></NavLink>
									<NavLink href="/profile"  className="pl-2 pr-0">Profile</NavLink>
								</NavItem>
							}
						</Nav>
					</Collapse>
				</div>
				
			</Navbar>
		</div>
	);
};

HomeNavbar.defaultProps = {
	routes: [{}],
	toggleSidenav: () => {},
	sidenavOpen: false,
	rtlActive: false,
};

HomeNavbar.propTypes = {
	// function used to make sidenav mini or normal
	toggleSidenav: PropTypes.func,
	// prop to know if the sidenav is mini or normal
	sidenavOpen: PropTypes.bool,
	// links that will be displayed inside the component
	routes: PropTypes.arrayOf(PropTypes.object),
	// logo
	logo: PropTypes.shape({
		// innerLink is for links that will direct the user within the app
		// it will be rendered as <Link to="...">...</Link> tag
		innerLink: PropTypes.string,
		// outterLink is for links that will direct the user outside the app
		// it will be rendered as simple <a href="...">...</a> tag
		outterLink: PropTypes.string,
		// the image src of the logo
		imgSrc: PropTypes.string.isRequired,
		// the alt for the img
		imgAlt: PropTypes.string.isRequired,
	}),
	// rtl active, this will make the sidebar to stay on the right side
	rtlActive: PropTypes.bool,
};


export default HomeNavbar;