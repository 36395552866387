import { PrintDate } from "common_function/function";
import { PrintCourseDate } from "common_function/function";
import { formatCSession } from "common_function/function";
import React, { createRef, useEffect, useState } from "react";
import { Row, Col, FormGroup, Button, TabContent, TabPane, NavItem, Nav } from "reactstrap";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interaction from "@fullcalendar/interaction";

import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { getAllDateArray } from "common_function/function";
import moment from "moment";
import { NavLink, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import $ from "jquery";

function CourseTrainingLocation({
	course_training_location,
	selectedLocation,
	setSelectedLocation,
	selectedCourseRunID,
	setSelectedCourseRunID,
}) {
	// const [selectedLocation, setSelectedLocation] = useState("");
	const [courseRunArray, setCourseRunArray] = useState([]);
	const [calendarCourseRunArray, setCalendarCourseRunArray] = useState([]);
	const [selectedCourseRun, setSelectedCourseRun] = useState([]);

	const navigate = useNavigate();

	// const [activeTab, setActiveTab] = useState(0);
	const sliderRefs = useRefArray(courseRunArray.length); // Create an array of refs
	const contentSlickSettingsArrays = contentSlickSettingsArray(courseRunArray.length);

	function useRefArray(length) {
		return Array.from({ length }, () => createRef());
	}

	useEffect(() => {
		if (course_training_location && course_training_location.length > 0) {
			let course_run_array = [];
			course_training_location.map((location, i) => {
				let courseRunByMonth = [];

				if (location.courserun.length > 0) {
					// location.courserun.map((courserun, j) => {
					Object.keys(location.courserun).map((j) => {
						if (location.courserun[j] && !location.courserun[j].is_full) {
							const singleCourserun = location.courserun[j];
							// console.log(singleCourserun);
							let courserun_data = {
								id: singleCourserun.courserun_id,
								title: PrintCourseDate(
									singleCourserun.courserun_course_from,
									singleCourserun.courserun_course_to,
								),
								dayOfWeek: moment(singleCourserun.courserun_course_from).format("ddd"),
								day: moment(singleCourserun.courserun_course_from).format("DD"),
								dayOfWeekRange:
									moment(singleCourserun.courserun_course_from).format("dddd") +
									" - " +
									moment(singleCourserun.courserun_course_to).format("dddd"),
								dateRange:
									moment(singleCourserun.courserun_course_from).format("DD") +
									" " +
									moment(singleCourserun.courserun_course_from).format("MMMM") +
									" - " +
									moment(singleCourserun.courserun_course_to).format("DD") +
									" " +
									moment(singleCourserun.courserun_course_to).format("MMMM"),
								start: new Date(singleCourserun.courserun_course_from),
								end: new Date(singleCourserun.courserun_course_to),
								className: singleCourserun.is_limited ? "bg-fca-red" : "bg-fca-green",
								isFull: singleCourserun.is_full,
								isLimited: singleCourserun.is_limited,
								cSession: formatCSession(singleCourserun.csessions),
								trainer: singleCourserun.trainer,
							};
							let month = moment(singleCourserun.courserun_course_from).format("MM");
							if (courseRunByMonth[month]) {
								courseRunByMonth[month].courserun.push(courserun_data);
							} else {
								courseRunByMonth[month] = {
									title:
										moment(singleCourserun.courserun_course_from).format("MMMM") +
										" " +
										moment(singleCourserun.courserun_course_from).format("YYYY"),
									courserun: [courserun_data],
								};
							}
							// allCourseRun[j] = courserun_data;
							// let all_dates = getAllDateArray(
							// 	singleCourserun.courserun_course_from,
							// 	singleCourserun.courserun_course_to,
							// );

							// all_dates.forEach((date, index) => {
							// 	if (!calendar_course_run_array[date]) {
							// 		calendar_course_run_array[date] = [];
							// 	} else {
							// 		calendar_overlay_array.push(date);
							// 	}
							// 	calendar_course_run_array[date].push(courserun_data);

							// 	if (singleCourserun.is_limited) {
							// 		if (index === 0) {
							// 			calendar_modifier_array["limit_start"].push(new Date(date));
							// 		} else if (index === all_dates.length - 1) {
							// 			calendar_modifier_array["limit_end"].push(new Date(date));
							// 		} else {
							// 			calendar_modifier_array["limit_middle"].push(new Date(date));
							// 		}
							// 	} else {
							// 		if (index === 0) {
							// 			calendar_modifier_array["normal_start"].push(new Date(date));
							// 		} else if (index === all_dates.length - 1) {
							// 			calendar_modifier_array["normal_end"].push(new Date(date));
							// 		} else {
							// 			calendar_modifier_array["normal_middle"].push(new Date(date));
							// 		}
							// 	}
							// });
						}
					});
				}
				course_run_array[i] = courseRunByMonth;
				// calendar_array[i] = {
				// 	calendar_course_run_array: calendar_course_run_array,
				// 	calendar_modifier_array: calendar_modifier_array,
				// };
			});
			setCourseRunArray(course_run_array);
			// console.log(course_run_array);
		}
	}, [course_training_location]);

	const handleLocationChange = (index) => {
		// console.log(courseRunArray);
		// setActiveTab(index);
		setSelectedLocation(index);
		setSelectedCourseRun([]);
		$(".loc-" + index + "-title").html($(".month-title-clone-" + index + "-0").html());
		// createCalendar(courseRunArray[event.target.value]);
	};

	const handleSelectCourseRun = (courseRun) => {
		setSelectedCourseRun(courseRun);
		setSelectedCourseRunID(courseRun.id);
	};

	var headerSlickSettings = {
		dots: false,
		infinite: false,
		arrows: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
		],
		// beforeChange: (oldIndex, newIndex) => {

		// 	// setSelectedLocation(newIndex);
		// }
	};

	function contentSlickSettingsArray(length) {
		let settingsArray = [];
		for (let i = 0; i < length; i++) {
			settingsArray[i] = {
				dots: false,
				infinite: false,
				arrows: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				beforeChange: (oldIndex, newIndex) => {
					// setCurrentDisplayCourse(newIndex + 1);
					// console.log(newIndex);
					// console.log(Object.keys(courseRunArray[i]).length - 1);
					$(".loc-" + i + "-title").html($(".month-title-clone-" + i + "-" + newIndex).html());
					if (newIndex == 0) {
						$(".loc-" + i + "-arrow.arrow-left").addClass("disable");
						$(".loc-" + i + "-arrow.arrow-right").removeClass("disable");
					} else if (newIndex == Object.keys(courseRunArray[i]).length - 1) {
						$(".loc-" + i + "-arrow.arrow-right").addClass("disable");
						$(".loc-" + i + "-arrow.arrow-left").removeClass("disable");
					} else {
						$(".loc-" + i + "-arrow.arrow-left, .loc-" + i + "-arrow.arrow-right").removeClass(
							"disable",
						);
					}
				},
			};
		}
		return settingsArray;
	}

	return (
		<div className='course-training-location-container'>
			<div className='header-wrap pb-3 pb-md-5'>
				<h5 className='pb-md-4 text-sm-left text-center'>Step 1 (Select Training Center)</h5>
				<Nav tabs className='border-0'>
					<Slider className='w-100 sameheight-slider' {...headerSlickSettings}>
						{course_training_location.map((location, index) => (
							<NavItem key={index} className='tab-wrap h-100'>
								<Row
									className={(selectedLocation == index ? "active" : "") + " tab-item h-100"}
									onClick={() => handleLocationChange(index)}>
									<Col className='p-0' md='4' xs='4' xl='4'>
										<img
											src={location.traininglocation_image_url}
											alt={location.traininglocation_title}
										/>
									</Col>
									<Col className='pr-0' md='8' xs='8' xl='8'>
										<div className='content p-2'>
											<div className='title'>
												<b>{location.traininglocation_title}</b>
											</div>
											<div
												className='address'
												dangerouslySetInnerHTML={{
													__html: location.traininglocation_address,
												}}></div>
										</div>
									</Col>
								</Row>
							</NavItem>
						))}
					</Slider>
				</Nav>
			</div>
			<div className='content-wrap'>
				<TabContent activeTab={Number(selectedLocation)}>
					{courseRunArray.map((courseRun, i) => (
						<TabPane key={i} tabId={i}>
							<div className='tab-content-wrap'>
								<Row className='align-items-center justify-content-between mb-4'>
									<Col xl='auto' md='auto' xs='12'>
										<h5 className=' text-sm-left text-center'>Step 2 (Select Date)</h5>
									</Col>
									<Col xl='auto' md='auto' xs='12'>
										<div className='arrow-wrap flex completed-arrow align-items-center justify-content-center'>
											<div
												// className='arrow arrow-left disable'
												className={`loc-${i}-arrow arrow arrow-left disable`}
												onClick={() => sliderRefs[i]?.current?.slickPrev()}>
												<i className='fa fa-angle-left' aria-hidden='true'></i>
											</div>
											<div className={`month-title loc-${i}-title`}>
												{courseRun[Object.keys(courseRun)[0]].title}
											</div>
											<div
												className={`loc-${i}-arrow arrow arrow-right ml-2`}
												// className={"arrow arrow-right ml-2"}
												onClick={() => sliderRefs[i]?.current?.slickNext()}>
												<i className='fa fa-angle-right' aria-hidden='true'></i>
											</div>
										</div>
									</Col>
								</Row>
								<Slider ref={sliderRefs[i]} {...contentSlickSettingsArrays[i]}>
									{Object.keys(courseRun).map((month, j) => (
										<div className='course-run-item-wrap' key={j}>
											<div className={`month-title-clone-${i}-${j} d-none`}>
												{courseRun[month].title}
											</div>
											{courseRun[month].courserun.map((courserun, k) => (
												<div
													key={k}
													onClick={() => handleSelectCourseRun(courserun)}
													className={
														(selectedCourseRunID == courserun.id ? " active" : "") +
														(courserun.isLimited ? " is_limited" : "") +
														" course-run-card"
													}>
													{courserun.isLimited && (
														<span className='course-sticker limited-course-sticker'>
															{"Limited Seats!"}
														</span>
													)}
													<Row className='justify-content-between'>
														<Col className='' xl='1' md='1' xs='3'>
															<div className='date-display'>
																<div className='date-display-inner'>
																	<div className='day-of-week'>{courserun.dayOfWeek}</div>
																	<div className='day'>{courserun.day}</div>
																</div>
															</div>
														</Col>
														<Col
															className='right-border hide-right-border-540'
															xl='4'
															md='4'
															xs='9'>
															<div className='content'>
																<div className='title'>Day & Date</div>
																<div className='day-range'>{courserun.dayRange}</div>
																<div className='date-range'>{courserun.dateRange}</div>
															</div>
														</Col>
														<Col className='right-border top-border-540' xl='4' md='4' xs='6'>
															<div className='content'>
																<div className='title'>Time</div>
																<div className='time-range'>
																	{courserun.cSession &&
																		courserun.cSession.map((session, i) => (
																			<div key={i}>{session}</div>
																		))}
																</div>
															</div>
														</Col>
														<Col className='top-border-540' xl='3' md='3' xs='6'>
															<div className='content'>
																<div className='title'>Trainer</div>
																<div className='trainer'>
																	{courserun.trainer
																		? courserun.trainer.trainer_name
																		: "To Be Confirmed"}
																</div>
															</div>
														</Col>
													</Row>
												</div>
											))}
										</div>
									))}
								</Slider>
							</div>
						</TabPane>
					))}
				</TabContent>
			</div>
		</div>
	);
}

export default CourseTrainingLocation;
