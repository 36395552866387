import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
import authHeader from "./auth-header";

const getAvatars = () => {
	return axios
		.get(
			API_URL +
			"/api/mobile/avatar/get_avatars",
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};

const submitAvatar = (avatar_occupation_id, avatar_background_id) => {
	let request_data = {
		avatar_occupation_id: avatar_occupation_id,
		avatar_background_id: avatar_background_id,
	};
	return axios
		.post(API_URL + "/api/mobile/avatar/submit_avatar", request_data,{ headers: authHeader() },)
		.then((response) => {
			return response.data;
		});
};

const getLearnerAvatars = () => {
	return axios
		.get(
			API_URL +
			"/api/mobile/avatar/get_learner_avatars",
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};



export default {
	getAvatars,
	submitAvatar,
	getLearnerAvatars,
};
