/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
// reactstrap components
import { CardBody, CardFooter, CardHeader, Col, Row} from "reactstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import {
	Card,
} from "reactstrap";
import { toast } from "react-toastify";
import newsApi from "services/news-api";
import { getErrorMessage } from "utilities/error";
import moment from "moment";
import "../../../../assets/scss/custom/_inner-html.scss";
import LikeButton from "components/LearnerPortal/News/LikeButton";
import CommentButton from "components/LearnerPortal/News/CommentButton";
import ShareButton from "components/LearnerPortal/News/ShareButton";
import CommentArea from "components/LearnerPortal/CommentArea";
import VideoPlayer from "components/LearnerPortal/News/VideoPlayer";

const WEB_URL = process.env.REACT_APP_WEB_URL;

function ArticleReply() {
	const params = useParams();
	let { state } = useLocation();
	let comment_id = state.comment_id || null;
	const [article, setArticle] = React.useState(null);
	const [isButtonLoading, setIsButtonLoading] = React.useState(false);
	const [isError, setIsError] = React.useState(false);
	const [isComment, setIsComment] = React.useState(null);
	const [isReachEnd, setIsReachEnd] = React.useState(false);

	useEffect(() => {
		getDailyNewsDetails();
	}, []);

	const getDailyNewsDetails = () => {
		setIsButtonLoading(true);
		setIsError(false);
		newsApi.getArticleDetails(params.id).then(
			(response) => {
				setIsButtonLoading(false);
				let newarticledetails = response.data;
				let newDescHtml = newarticledetails.article_content;
				newDescHtml = newDescHtml.split("<oembed").join("<iframe");
				newDescHtml = newDescHtml.split("</oembed>").join("</iframe>");
				newDescHtml = newDescHtml.split("url=").join("src=");
				newarticledetails.article_content = newDescHtml;
				setArticle(newarticledetails);
				setIsError(false);
			},
			(error) => {
				toast.error(getErrorMessage(error));
				setIsButtonLoading(false);
				setArticle(null);
				setIsError(true);
			},
		);
	};

	// We need to listen to scroll events on the window so we
	// manage an event listener ourselves.
	React.useEffect(() => {
		const isBottom = (el) => {
			return el.getBoundingClientRect().bottom <= window.innerHeight;
		};


		const handleScroll = e => {
			const wrappedElement = document.getElementById("DailyNewsContainer");
			if (isBottom(wrappedElement)) {
				setIsReachEnd(true);
			} else {
				setIsReachEnd(false);
			}
		};
		document.addEventListener("scroll", handleScroll, { passive: true });
		return () => {
		// This cleans up the event handler when the component unmounts.
			document.removeEventListener("scroll", handleScroll);
		};
	}, []);


	

	return (
		<div className='' id={"DailyNewsContainer"}>
			<div className='title-container'>
				<h2>{"Article"}</h2>
			</div>
			{isButtonLoading && (
				<div className='justify-content-center mt-4 w-100 flex'>
					<span className='spinner-border spinner-border-sm'></span>
				</div>
			)}
			{!isButtonLoading && isError && (
				<div onClick={() => getDailyNewsDetails()}>
					<Card className='mt-4'>
						<CardBody className='flex justify-content-center'>
							<div className='btn btn-gradient'>
								<span>Reload</span>
							</div>
						</CardBody>
					</Card>
				</div>
			)}
			{!isButtonLoading && !isError && article && (
				<div>
					<Card className='mt-4'>
						<CardHeader className='border-bottom-0'>
							<Row className='align-items-center'>
								{article.author.author_image_url && (
									<Col className='col-auto pr-0'>
										<img alt='{article.author}' src={article.author.author_image_url} className="article-details-author-image"/>
									</Col>
								)}
								{!article.author.author_image_url &&
								article.author.author_name && (
									<Col className='col-auto pr-0'>
										<div className="article-details-author-initial-div">
											<b className="article-details-author-initial-text">
												{article.author.author_name[0]}
											</b>
										</div>
									</Col>
								)}
								<div className='col'>
									<p className='m-0 article-details-author-name'>{article.author.author_name}</p>
									<p className='article-details-author-date'>
										{`${moment(
											article.article_published,
										).format("MMMM DD YYYY, hh:ssA")}`}
									</p>
								</div>
							</Row>
							<p className='article-title mt-3'>{article.article_title}</p>
						</CardHeader>
						<CardBody className='p-0'>
							{article && article.article_video_url && (
								<VideoPlayer url={article.article_video_url} thumbnail={article.article_image_url} endThumbnail={article.article_image_url} />
							)}
							{article && !article.article_video_url && (
								<img
									className='article-details-image'
									alt={article.article_title}
									src={article.article_image_url ? article.article_image_url : require("assets/img/FALCOMM_LOGO.png")}
									onError={({ currentTarget }) => {
										currentTarget.onerror = null; // prevents looping
										currentTarget.src= require("assets/img/FALCOMM_LOGO.png");
									}}
								/>
							)}
							<div className="article-content">
								<div className="inner-html" dangerouslySetInnerHTML={{ __html: article.article_content }}></div>
							</div>
							{article && article.categories && article.categories.length > 0 &&
								<div className="article-category-container">
									{article && article.categories && article.categories.map((category, i) => {
										return (
											<span
												key={i}
												style={{
													backgroundColor: category.category_color,
												}}
												className='tag m-2 text-white'
											>
												{category.category_title}
											</span>
										);
									})}
								</div>
							}
							
							<Row>
								<Col md='12' xl='12' className='mt-1'>
									<div className='flex mb-4 align-items-center mr-4'>
										<Link
											to='/news'
											className='article-comment-submit-btn article-comment-btn-default ml-auto'>
											{"Back"}
										</Link>
									</div>
								</Col>
							</Row>
						</CardBody>
						<CardFooter className="p-2" style={{ borderTopWidth: 1, borderTopColor: "rgba(0, 0, 0, 0.1)" }}>
							<div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", overflow: "hidden"}}>
								<div className="like-main-button-container">
									<LikeButton
										isLike={article.liked === 1}
										totalLike={article.likes_count}
										article_id={article.article_id.toString()}
									/>
								</div>
								<div className="comment-main-button-container">
									<CommentButton  onPress={() => {
										setIsComment(Math.random());
									}}
									totalComment={article.comments_count}/>
								</div>
								<div className="share-main-button-container">
									<ShareButton
										article_id={article.article_id}
										totalShare={article.shares_count}
										title={article.article_title}
										url={`${WEB_URL}/news/article/${article.article_id}`}
										desc={""}
									/>
								</div>
							</div>
						</CardFooter>
					</Card>
					<Card className='mt-4'>
						<CardBody className='p-3 pt-4'>
							<CommentArea
								isComment={isComment}
								isReachEnd={isReachEnd}
								article={article}
								onReplyPress={() => null}
								comment_id={comment_id}
							/>
						</CardBody>
					</Card>
				</div>
			)}
		</div>
	);
}

export default ArticleReply;
