
import React, { useEffect, useRef, useState } from "react";
import { Button, Container } from "reactstrap";
import Slider from "react-slick";
import girl1Image from "assets/img/Login/Girl1/gal01.png";
import CongraCreateAccountImage from "assets/img/Checkout/congra-create-account.png";
import { ReactComponent as GuySVG } from "assets/img/Checkout/Success/icon_guy.svg";
import { ReactComponent as TargetSVG } from "assets/img/Checkout/Success/success_target.svg";
import { ReactComponent as ArrowSVG } from "assets/img/Checkout/Success/success_arrow.svg";
import { ReactComponent as CertSVG } from "assets/img/Checkout/Success/icon_cert.svg";
import { ReactComponent as StarSVG } from "assets/img/Checkout/Success/success_star.svg";

import Lottie from "react-lottie";
import * as fireAnimation from "assets/img/Checkout/AccountCreation/fire1.json";
import * as fireAnimation2 from "assets/img/Checkout/AccountCreation/fire2.json";

import { debounce } from "lodash";
import styles from "../CheckoutSuccessPage.module.scss";
import confetti from "canvas-confetti";


const CheckoutSuccessFloating = () => {
	const rootRef = useRef();
	const defaultOptions = {
		loop: true,
		autoplay: true, 
		animationData: fireAnimation2,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice"
		}
	};

	useEffect(() => { 
		if(!rootRef.current) return;
 
		// calculate the origin based on the center of image 
		// image might be offset
		const boundingBox = rootRef.current.getBoundingClientRect();
		const origin = {
			x : (boundingBox.left + boundingBox.width/2) /window.innerWidth,
			y : ((boundingBox.top + boundingBox.height/2) / window.innerHeight) + 0.1
		};
			
		confetti({
			particleCount: 100,
			spread: 70,
			origin: origin
		});
	},[]);

	return (
		<div className={`${styles.success_account_container}`} ref={ref=>rootRef.current = ref}>
			<div className={`${styles.vector_animate_ele} ${styles.vector_animate_wrap}`}>
				<div className={`${styles.item_1} ${styles.background_container}`}>
				    <div className={`${styles.backgroundCloud}`}></div>
				</div>
			 	<div className={`${styles.item_2} `}><GuySVG className={`${styles.banner_1_item_image}`} /></div>
			 	<div className={`${styles.item_3} `}><TargetSVG className={`${styles.banner_1_item_image}`} /></div>
			 	<div className={`${styles.item_4} ${styles.arrow_animation}`}><ArrowSVG className={`${styles.banner_1_item_image}`} /></div>
			 	<div className={`${styles.item_5} ${styles.rotate_2}`}><CertSVG className={`${styles.banner_1_item_image}`} /></div>
			 	<div className={`${styles.item_6} ${styles.scale_animation_2} ${styles.rotate_1}`}><StarSVG className={`${styles.banner_1_item_image}`} /></div>
			 	<div className={`${styles.item_7} ${styles.scale_animation_3} ${styles.rotate_1}`}><StarSVG className={`${styles.banner_1_item_image}`} /></div>
			</div>
		</div>
	);
};
export default CheckoutSuccessFloating;