import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// reactstrap components
import { Button, Card, CardBody, FormGroup, Form, Container, Row, Col, InputGroupAddon, InputGroupText, InputGroup } from "reactstrap";
import styles from "./AvatarPage.module.scss";
import { ReactComponent as FemaleIcon } from "assets/img/Avatar/female.svg";
import { ReactComponent as MaleIcon } from "assets/img/Avatar/male.svg";
import { ReactComponent as FemaleGreyIcon } from "assets/img/Avatar/female-grey.svg";
import { ReactComponent as MaleGreyIcon } from "assets/img/Avatar/male-grey.svg";

import occupationExampleImage from "assets/img/Avatar/ava_manager.png";
import backgroundExampleImage from "assets/img/Avatar/bg01.png";
import AvatarOccupationCarousel from "views/pages/learner/avatar/components/AvatarOccupationCarousel";
import avatarApi from "services/avatar-api";
import { toast } from "react-toastify";
import { getErrorMessage } from "utilities/error";
import AvatarBackgroundCarousel from "views/pages/learner/avatar/components/AvatarBackgroundCarousel";
import { useDebounceFn } from "ahooks";

import backgroundLeftImage from "assets/img/Avatar/bgL.png";
import backgroundRightImage from "assets/img/Avatar/bgR.png";
import backgroundMidImage from "assets/img/Avatar/bgM.png";
import AvatarOccupationListing from "views/pages/learner/avatar/components/AvatarOccupationListing";
import AvatarBackgroundListing from "views/pages/learner/avatar/components/AvatarBackgroundListing";
import { useWindow } from "hooks/UseWindow";

function AvatarScreen() {
	const [currentGender, setCurrentGender] = React.useState("Male");
	const [currentAvatarBackground, setCurrentAvatarBackground] = React.useState(null);
  	const [currentAvatarOccupation, setCurrentAvatarOccupation] = React.useState(null);
	const [avatarOccupationMale, setAvatarOccupationMale] = React.useState(null);
	const [avatarOccupationFemale, setAvatarOccupationFemale] = React.useState(null);
	const [avatarBackgroundMale, setAvatarBackgroundMale] = React.useState(null);
	const [avatarBackgroundFemale, setAvatarBackgroundFemale] = React.useState(null);
	const [getAvatarsLoading, setGetAvatarsLoading] = useState(true);
	const [getAvatarsError, setGetAvatarsError] = useState(false);
	const [submitAvatarLoading, setSubmitAvatarsLoading] = useState(false);
	// animation
	const [occupationFade, setOccupationFade] = useState(true);
	const [occupationSource, setOccupationSource] = useState(null);
	const [backgroundFade, setBackgroundFade] = useState(true);
	const [backgroundSource, setBackgroundSource] = useState(null);

	const { user: userData } = useSelector((state) => state.auth);
	const currentUser = userData.user;
	const { width } = useWindow();

	let navigate = useNavigate();

	useEffect(() => {
		fetchAvatars();
	}, []);

	useEffect(() => {
		if (currentAvatarOccupation) {
			setOccupationFade(false);
			setTimeout(() => {
				setOccupationSource(currentAvatarOccupation.avatar_occupation_full_image);
				setOccupationFade(true);
			}, 300); // Duration of fade-out transition
		}
	}, [currentAvatarOccupation]);

	useEffect(() => {
		if (currentAvatarBackground) {
			setBackgroundFade(false);
			setTimeout(() => {
				setBackgroundSource(currentAvatarBackground.avatar_background_full_image);
				setBackgroundFade(true);
			}, 300); // Duration of fade-out transition
		}
	}, [currentAvatarBackground]);

	const fetchAvatars =  async () => {
		setGetAvatarsLoading(true);
		avatarApi.getAvatars().then(
			(response) => {
				if (response.data) {
					if (
					  response.data.avatar_occupation_male &&
					  response.data.avatar_occupation_male.length > 0
					) {
					  setAvatarOccupationMale(response.data.avatar_occupation_male);
					}
					if (
					  response.data.avatar_occupation_female &&
					  response.data.avatar_occupation_female.length > 0
					) {
					  	setAvatarOccupationFemale(
							response.data.avatar_occupation_female,
					  	);
					}
					if (
					  response.data.avatar_background_male &&
					  response.data.avatar_background_male.length > 0
					) {
					  	setAvatarBackgroundMale(response.data.avatar_background_male);
					}
					if (
					  response.data.avatar_background_female &&
					  response.data.avatar_background_female.length > 0
					) {
						setAvatarBackgroundFemale(
							response.data.avatar_background_female,
						);
					}
			  
					// default avatar
					if (response.data.current_avatar) {
					  // learner's avatar
						const avatarOccupation = {
							id: response.data.current_avatar.avatar_occupation_id,
							avatar_occupation_gender:
							response.data.current_avatar.avatar_occupation_gender,
							avatar_occupation_name:
							response.data.current_avatar.avatar_occupation_name,
							avatar_occupation_description:
							response.data.current_avatar.avatar_occupation_description,
							avatar_occupation_full_image:
							response.data.current_avatar.avatar_occupation_full_image,
							avatar_occupation_selection_image:
							response.data.current_avatar
								.avatar_occupation_selection_image,
						};
						const avatarBackground = {
							id: response.data.current_avatar.avatar_background_id,
							avatar_background_gender:
							response.data.current_avatar.avatar_background_gender,
							avatar_background_full_image:
							response.data.current_avatar.avatar_background_full_image,
							avatar_background_selection_image:
							response.data.current_avatar
								.avatar_background_selection_image,
						};
						setCurrentAvatarOccupation(avatarOccupation);
						setCurrentAvatarBackground(avatarBackground);
					} else {
					  	if (response.data.avatar_occupation_male.length > 0) {
							setCurrentAvatarOccupation(
								response.data.avatar_occupation_male[0],
							);
					  	} else if (response.data.avatar_occupation_female.length > 0) {
							setCurrentAvatarOccupation(
								response.data.avatar_occupation_female[0],
							);
						}
			  
						if (response.data.avatar_background_male.length > 0) {
							setCurrentAvatarBackground(
								response.data.avatar_background_male[0],
							);
						} else if (response.data.avatar_background_female.length > 0) {
							setCurrentAvatarOccupation(
								response.data.avatar_background_female[0],
							);
						}
					}
				}
				setGetAvatarsError(false);
				setGetAvatarsLoading(false);
			},
			(error) => {
				toast.error(getErrorMessage(error));
				setGetAvatarsError(true);
				setGetAvatarsLoading(false);
			},
		);
	};

	const handleSubmitAvatar = useDebounceFn(
		(avatar_occupation_id, avatar_background_id) => {
			setSubmitAvatarsLoading(true);
			avatarApi.submitAvatar(avatar_occupation_id, avatar_background_id).then(
				(response) => {
					setSubmitAvatarsLoading(false);
					navigate(-1);
					toast.success("Avatar Changed Successfully!");
				},
				(error) => {
					setSubmitAvatarsLoading(false);
					toast.error(getErrorMessage(error));
				},
			);
		},
		{ leading: true, trailing: false, wait: 1000 },
	).run;

	return (
		<div className={`fadeInDiv ${styles.avatar_main_container}`}>
			<div className={`${styles.background_image_main_container}`}>
				<div className={`${styles.background_image_left_container}`}>
					<img
						className={styles.avatar_background_left_image}
						alt={"avatar_background_left"}
						src={backgroundLeftImage}
					/>
				</div>
				<div className={`${styles.background_image_mid_container}`}>
					<img
						className={styles.avatar_background_left_image}
						alt={"avatar_background_left"}
						src={backgroundMidImage}
					/>
				</div>
				<div className={`${styles.background_image_right_container}`}>
					<img
						className={styles.avatar_background_left_image}
						alt={"avatar_background_left"}
						src={backgroundRightImage}
					/>
				</div>
			</div>
			<div className='title-container'>
				<h2>Avatar Creation</h2>
			</div>
			<Card className={`${styles.avatar_card_container} border-0 mt-2`}>
				<CardBody className='p-0'>
					{getAvatarsLoading &&
						<div className='justify-content-center mt-4 w-100 flex'>
							<span className='spinner-border spinner-border-sm'></span>
						</div>
					}
					{!getAvatarsLoading && !getAvatarsError &&
						<>
							<div className={`${styles.gender_main_button_container}`}>
								<div
									className={`
										${styles.gender_button_container}
										${currentGender == "Male" ? styles.selected : null}
										mr-1
										cursor-pointer
									`} onClick={() => setCurrentGender("Male")}>
									<div className='mr-2'>
										{currentGender == "Male" && (
											<MaleIcon height={20} width={20} />
										)}
										{currentGender != "Male" && (
											<MaleGreyIcon height={20} width={20} />
										)}
									</div>
									<span
										className={`${styles.gender_button_text} ${currentGender == "Male" ? styles.selected : ""}`}
									>Male</span>
								</div>
								<div
									className={`
										${styles.gender_button_container}
										${currentGender == "Female" ? styles.selected : null}
										ml-1
										cursor-pointer
									`} onClick={() => setCurrentGender("Female")}>
									<div className='mr-2'>
										{currentGender == "Female" && (
											<FemaleIcon height={20} width={20} />
										)}
										{currentGender != "Female" && (
											<FemaleGreyIcon height={20} width={20} />
										)}
									</div>
									<span
										className={`${styles.gender_button_text} ${currentGender == "Female" ? styles.selected : ""}`}
									>Female</span>
								</div>
							</div>
							<div className={`${styles.selection_main_container}`}>
								{width > 767 &&
									<div key={"occupation-list-male"} className={`${styles.selection_occupation_container}`}>
										<span className={`${styles.avatar_listing_selection_title_text}`}>Select an Occupation</span>
										<AvatarOccupationListing data={currentGender === "Male" ? avatarOccupationMale : avatarOccupationFemale} currentAvatar={currentAvatarOccupation} onSelectAvatar={(item) => setCurrentAvatarOccupation(item)} />
									</div>
								}
								<div className={`${styles.display_main_container}`}>
									<img
										className={`${styles.display_background_image} ${backgroundFade ? styles.fade_in : styles.fade_out}`}
										alt={"display_background"}
										src={
											backgroundSource
												? backgroundSource
												: null
										}
									/>
									<img
										className={`${styles.display_occupation_image} ${occupationFade ? styles.fade_in : styles.fade_out}`}
										alt={"display_occupation"}
										src={
											occupationSource
												? occupationSource
												: null
										}
									/>
								</div>
								{width > 767 &&
									<div key={"background-list-male"} className={`${styles.selection_background_container}`}>
										<span className={`${styles.avatar_listing_selection_title_text}`}>Select a Theme</span>
										<AvatarBackgroundListing data={currentGender === "Male" ? avatarBackgroundMale : avatarBackgroundFemale} currentAvatar={currentAvatarBackground} onSelectAvatar={(item) => setCurrentAvatarBackground(item)} />
									</div>
								}
							</div>
							
							{currentAvatarOccupation && (
								<div className={`${styles.display_occupation_details_container}`}>
									<span className={`${styles.display_occupation_description_text}`}>
										{currentAvatarOccupation.avatar_occupation_description ?? ""}
									</span>
									<span className={`${styles.display_occupation_title_text}`}>
										{currentAvatarOccupation.avatar_occupation_name ?? ""}
									</span>
								</div>
							)}
							
							{width <= 767 && currentGender === "Male" &&
								avatarOccupationMale &&
								avatarOccupationMale?.length > 0 && (
								<div key="occupation-male" className={`${styles.avatar_selection_container}`}>
									<span className={`${styles.avatar_selection_title_text}`}>Select an Occupation</span>
									<AvatarOccupationCarousel data={avatarOccupationMale} onSelectAvatar={(item) => setCurrentAvatarOccupation(item)} />
								</div>
							)}
							{width <= 767 && currentGender === "Female" &&
								avatarOccupationFemale &&
								avatarOccupationFemale?.length > 0 && (
								<div key="occupation-female" className={`${styles.avatar_selection_container}`}>
									<span className={`${styles.avatar_selection_title_text}`}>Select an Occupation</span>
									<AvatarOccupationCarousel data={avatarOccupationFemale} onSelectAvatar={(item) => setCurrentAvatarOccupation(item)} />
								</div>
							)}
							{width <= 767 && currentGender === "Male" &&
								avatarBackgroundMale &&
								avatarBackgroundMale?.length > 0 && (
								<div key="background-male" className={`${styles.avatar_selection_container}`}>
									<span className={`${styles.avatar_selection_title_text}`}>Select a Theme</span>
									<AvatarBackgroundCarousel data={avatarBackgroundMale} onSelectAvatar={(item) => setCurrentAvatarBackground(item)} />
								</div>
							)}
							{width <= 767 && currentGender === "Female" &&
								avatarBackgroundFemale &&
								avatarBackgroundFemale?.length > 0 && (
								<div key="background-female" className={`${styles.avatar_selection_container}`}>
									<span className={`${styles.avatar_selection_title_text}`}>Select a Theme</span>
									<AvatarBackgroundCarousel data={avatarBackgroundFemale} onSelectAvatar={(item) => setCurrentAvatarBackground(item)} />
								</div>
							)}
						
							<div className='btn-container flex-wrap mt-4 mb-4 d-flex align-items-center justify-content-center'>
								<Button
									className='btn-gradient p-3 pl-6 pr-6'
									disabled={submitAvatarLoading}
									onClick={() => handleSubmitAvatar(
										currentAvatarOccupation && currentAvatarOccupation.id
											? currentAvatarOccupation.id.toString()
											: null,
										currentAvatarBackground && currentAvatarBackground.id
											? currentAvatarBackground.id.toString()
											: null,
									)}
									type='button'
								>
									{submitAvatarLoading && <span className='spinner-border spinner-border-sm'></span>}
									<span>Confirm Avatar</span>
								</Button>
							</div>
						</>
					}
					{!getAvatarsLoading && getAvatarsError && (
						<div className='justify-content-center mt-4 w-100 flex'>
							<p className='text-center flex'>
								<b>{"Avatar Creation failed to load, Please Refresh the page or try again later!"}</b>
							</p>
						</div>
					)}
				</CardBody>
			</Card>
		</div>
	);
}

export default AvatarScreen;
