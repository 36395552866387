import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import  styles  from "./ReferralDashboard.module.scss";
import { ReactComponent as LinkSVG } from "assets/img/Referral/icon_link.svg";
import { ReactComponent as ArrowSVG } from "assets/img/Referral/arrow.svg";
import { ReactComponent as RedeemSVG } from "assets/img/Referral/redeem.svg";
import { ReactComponent as ReferralSVG } from "assets/img/Referral/referral.svg";
import { ReactComponent as VoucherSVG } from "assets/img/Referral/voucher.svg";
import { ReactComponent as DownloadSVG } from "assets/img/Referral/DL.svg";

import { Button, Card, CardBody, CardHeader, Col, Container, Row, Table } from "reactstrap";
import ReferralBannerFloating from "views/pages/learner/referral/components/ReferralBannerFloating";
import { toast } from "react-toastify";
import ReferralContactUsModal from "./components/ReferralContactUsModal";
import ReferralRedeemModal from "views/pages/learner/referral/components/ReferralRedeemModal";
import ReactPaginate from "react-paginate";
import referralApi from "services/referral-api.js";
import { getErrorMessage } from "utilities/error";
import { useLocation } from "react-router-dom";
import { useSessionStorageState } from "ahooks";
import classNames from "classnames";

const pageSize = 10; // Number of items per page

function ReferralDashboard() {
	const location = useLocation();

	// const [isLoading, setIsLoading] = useState(false);
	const [openModal, setOpenModal] = React.useState(0);
	const [openRedeemModal, setOpenRedeemModal] = React.useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = React.useState(true);
	// databox
	const [totalReferrers, setTotalReferrers] = useState(0);
	const [totalUpcomingVoucherAmount, setTotalUpcomingVoucherAmount] = useState(0);
	const [totalPaidVoucherAmount, setTotalPaidVoucherAmount] = useState(0);
	const [referreeList, setReferreeList] = useState(null);
	const [readyRedeemList, setReadyRedeemList] = useState(null);
    
	const dispatch = useDispatch();
	const { user: userData } = useSelector((state) => state.auth);

	React.useEffect(() => {
		getDashboard();
	}, []);

	const getDashboard = () => {
		setIsLoading(true);
		setIsError(false);
		referralApi.getReferralDashboard().then(
			(response) => {
				setTotalReferrers(response.total_referrers);
				setTotalUpcomingVoucherAmount(response.total_upcoming_voucher_amount);
				setTotalPaidVoucherAmount(response.total_paid_voucher_amount);
				setReferreeList(response.referee_list);
				setReadyRedeemList(response.ready_redeem_list);
                
				setIsLoading(false);
				setIsError(false);
			},
			(error) => {
				toast.error(getErrorMessage(error));
				setIsLoading(false);
				setIsError(true);
			},
		);
	};

	// copy referral link to clipboard
	const handleCopy = () => {
		// Create a textarea element to assist with copying
		const textArea = document.createElement("textarea");
		textArea.value = userData.user.referral_link;
    
		// Append the textarea to the document
		document.body.appendChild(textArea);
    
		// Select the text within the textarea
		textArea.select();
		document.execCommand("copy");
    
		// Remove the textarea from the document
		document.body.removeChild(textArea);
    
		// You can add additional logic here, e.g., show a success message
		toast.success(`Copied: ${userData.user.referral_link}`);
	};

	const handleOpenModal = () => {
		setOpenModal(true);
	};
	const handleSuccess = () => {
		setOpenModal(false);
		getDashboard();
	};
	const handleClose = () => {
		setOpenModal(false);
	};

	const handleOpenRedeemModal = () => {
		setOpenRedeemModal(true);
	};
	const handleRedeemSuccess = () => {
		setOpenRedeemModal(false);
		getDashboard();
	};
	const handleRedeemClose = () => {
		setOpenRedeemModal(false);
	};

	const handlePageChange = ({ selected }) => {
		setCurrentPage(selected);
	};


	const paginatedData = referreeList ? referreeList.slice(currentPage * pageSize, (currentPage + 1) * pageSize) : [];
	console.log(readyRedeemList);

	const [cachedTSCCC, setCachedTSCCC] =useSessionStorageState("ts_ccc", {defaultValue:null}); 
	const param_ts_ccc = new URLSearchParams(location.search).get("ts_ccc",);
	useEffect(() => {
		if(param_ts_ccc){ 
			setCachedTSCCC(param_ts_ccc); 
			setOpenModal(true);
		}
		if(cachedTSCCC){
			setOpenModal(true);
		}
	},[]);

	const ts_ccc = param_ts_ccc || cachedTSCCC;

	return (
		<div className={`${styles.referral_dashboard_container} fadeInDiv`}>
			{/* <div className={styles.referral_banner_container}>
				<div className={styles.referral_banner_image_container}>
					<ReferralBannerFloating id={"h8aj"} />
				</div>
				<div className={styles.referral_banner_text_container}>
					<p className={styles.referral_banner_title}>
						{"Call on your "}
						<span className={styles.referral_banner_title_green}>{"Friends,"}</span>
						{" and get "}
						<span className={styles.referral_banner_title_green}>{"Shopping Vouchers"}</span>
						{" when your friends sign up!"}
					</p>
					<p className={styles.referral_banner_desc}>
						{"Refer a friend who is interested in our courses and receive a "}
						<span className={styles.referral_banner_desc_green}>{"$80 Shopping Voucher!"}</span>
					</p>
					<div className={styles.refferal_link_button_container}>
						<Button className='btn-gradient' onClick={handleCopy}>
							<LinkSVG />
							<span className="fs14">{"Copy Referral Link"}</span>
						</Button>
						<Button className='btn-gradient' onClick={handleOpenModal}>
							<ArrowSVG />
							<span className="fs14">{"Refer Now"}</span>
						</Button>
					</div>
				</div>
			</div> */}
			{isLoading &&
                <div className='justify-content-center align-items-center w-100 flex p-4'>
                	<span className='spinner-border spinner-border-sm'></span>
                </div>
			}
			{!isLoading && isError && (
				<div onClick={() => getDashboard()}>
					<Card className='mt-4'>
						<CardBody className='flex justify-content-center'>
							<div className='btn btn-gradient'>
								<span>{"Reload Dashboard"}</span>
							</div>
						</CardBody>
					</Card>
				</div>
			)}
			{!isLoading && !isError &&
                <div className={styles.referral_databox_container}>
                	<div className={styles.referral_databox_title_container}>
                		<p className={styles.referral_databox_title}>{"Learner Referral"}</p>
                		{/* <div className={styles.outline_green_button} onClick={handleOpenModal}>
                			<ArrowSVG />
                			<span className="fs14">Refer</span>
                			<i className='fa fa-angle-right' aria-hidden></i>
                		</div> */}
                	</div>
                	<Row className={styles.databox_row}>
                		<Col xl={4} lg={4} md={6} sm={6} xs={12} className={styles.databox_col}>
                			<div className={styles.referral_databox_referral_container}>
                				<div>
                					<p className={styles.databox_referral_number}>{totalReferrers}</p>
                					<p className={styles.databox_desc}>{"Number of Referrers"}</p>
                				</div>
                				<ReferralSVG />
                			</div>
                		</Col>
                		<Col xl={4} lg={4} md={6} sm={6} xs={12} className={styles.databox_col}>
                			<div className={styles.referral_databox_voucher_container}>
                				<div>
                					<p className={styles.databox_voucher_number}>{`$${totalUpcomingVoucherAmount}`}</p>
                					<p className={styles.databox_desc}>{"Voucher Ready for Redemption"}</p>
                				</div>
                				<VoucherSVG />
                			</div>
                		</Col>
                		<Col xl={4} lg={4} md={6} sm={6} xs={12} className={styles.databox_col}>
                			<div className={styles.referral_databox_redeemed_container}>
                				<div>
                					<p className={styles.databox_redeemed_number}>{`$${totalPaidVoucherAmount}`}</p>
                					<p className={styles.databox_desc}>{"Voucher Redeemed"}</p>
                				</div>
                				<RedeemSVG />
                			</div>
                		</Col>
                	</Row>
                </div>
			}
			{!isLoading && !isError &&
                <div className={styles.referral_datatable_container}>
                	<div className={styles.referral_datatable_title_container}>
                		<p className={styles.referral_datatable_title}>{"List of Referrees"}</p>
                		<div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                			<div className={`${styles.outline_green_button} mr-2`} onClick={handleOpenRedeemModal}>
                				<span className="fs14">Redeem Voucher</span>
                				<i className='fa fa-angle-right' aria-hidden></i>
                			</div>
                			{/* <div className={styles.outline_green_button}>
                				<DownloadSVG />
                				<span className="fs14">Download Excel</span>
                			    <i className='fa fa-angle-right' aria-hidden></i>
                		    </div> */}
                		</div>
                		
                	</div>
                	<div className={styles.referral_datatable_table_container}>
                		{/* <div className='justify-content-center align-items-center w-100 flex p-4'>
                        <span className="mr-2">Loading Table</span>
                        <span className='spinner-border spinner-border-sm'>
                        </span>
                    </div>
                    <div className='justify-content-center align-items-center w-100 flex p-4'>
                        <span className="mr-2">Empty Table</span>
                    </div> */}
                		{!referreeList || (referreeList && referreeList.length <= 0) &&
                            <div className='justify-content-center align-items-center w-100 flex p-4'>
                            	<span className="mr-2">Empty Table</span>
                            </div>
                		}
                		{referreeList && referreeList.length > 0 &&
                            <>
                            	<Table hover responsive className={styles.referral_datatable_table}>
                            		<thead className={styles.datatable_table_header}>
                            			<tr>
                            				<th className={styles.datatable_header_th}>{"Type"}</th>
                            				<th className={styles.datatable_header_th}>{"Name (lead)"}</th>
                            				<th className={styles.datatable_header_th}>{"Contact No. (lead)"}</th>
                            				<th className={styles.datatable_header_th}>{"Email (lead)"}</th>
                            				<th className={styles.datatable_header_th}>{"Leaner Info"}</th>
                            				<th className={styles.datatable_header_th}>{"Qualified For Voucher"}</th>
                            				<th className={styles.datatable_header_th}>{"Lead Status"}</th>
                            				<th className={styles.datatable_header_th}>{"Remarks"}</th>
                            				<th className={styles.datatable_header_th}>{"Redeem Status"}</th>
                            			</tr>
                            		</thead>
                            
                            		<tbody className={styles.datatable_table_body}>
                            			{paginatedData.map((item, index) => (
                            				<tr key={index}>
                            					<td>
                            						{item.type === "from_order" ?
                            							"Order"
                            							: 
                            							item.lead_group === "SOLAR" ?
                            								"Corporate Lead"
                            								:
                            								"Individual Lead"
                            						}
                            					</td>
                            					<td>{item.display_name}</td>
                            					<td>{item.display_phone}</td>
                            					<td>{item.display_email}</td>
                            					<td>
                            						{item.from_learnermaster_name}<br/>
                            						{
                            							item.is_alumni !== null  && item.is_alumni !== undefined
                            							  ? item.is_alumni 
                            								? <div className={classNames([styles.table_status_container_orange, styles.alumni_status])}>
                            									<span className={styles.table_status_text_orange}>
																										Alumni
                            									</span>
                            								</div> 
                            								: <div className={classNames([styles.table_status_container, styles.alumni_status]) }>
                            									<span className={styles.table_status_text}>
																										Non-Alumni
                            									</span>
                            								</div>
                            							:null
                            						}
                            					</td>
                            					<td>
                            						{
                            							item.unqualified ? 
                            							<div className={styles.table_status_container_red}>
                            										<span className={styles.table_status_text_red}>Unqualified</span>
                            									</div>
                            							: item.quality 
                            									? <div className={styles.table_status_container_orange}>
                            										<span className={styles.table_status_text_orange}>
																									Qualified
                            										</span>
                            									</div> 
                            									:<div className={styles.table_status_container_grey}>
                            										<span className={styles.table_status_text_orange_grey}>Pending</span>
                            									</div>
                            						}
                            					</td>
                            					<td>
                            						{item.display_lead_status}
                            					</td>
                            					<td>{item.display_remarks ? item.display_remarks : "-"}</td>
                            					<td>
                            						{item.pv_payment_status && item.pv_payment_status === "Paid" &&
                                                        <div className={styles.table_status_container}>
                                                        	<span className={styles.table_status_text}>Paid</span>
                                                        </div>
                            						}
                            						{item.pv_payment_status && item.pv_payment_status === "Rejected" &&
                                                        <div className={styles.table_status_container_red}>
                                                        	<span className={styles.table_status_text_red}>Rejected</span>
                                                        </div>
                            						}
                            						{item.pv_payment_status && item.pv_payment_status === "Submitted" &&
                                                        <div className={styles.table_status_container_orange}>
                                                        	<span className={styles.table_status_text_orange}>Submitted</span>
                                                        </div>
                            						}
                            						{!item.pv_payment_status &&
                                                        <div className={styles.table_status_container_grey}>
                                                        	<span className={styles.table_status_text_orange_grey}>-</span>
                                                        </div>
                            						}
                            					</td>
                            					{/* 
																			<td>
                            						<div className={styles.table_redeem_container}>
                            							<Button  className={`btn btn-outline-black ${styles.table_redeem_button}`} onClick={handleOpenRedeemModal}>
                            								<span>{"Select"}</span>
                            							</Button>
                            						</div>
                            					</td> 
																			*/}
                            				</tr>
                            			))}
                            		</tbody>
                            	</Table>
                            	<ReactPaginate
                            		pageCount={Math.ceil(referreeList.length / pageSize)}
                            		pageRangeDisplayed={3}
                            		marginPagesDisplayed={1}
                            		onPageChange={handlePageChange}
                            		containerClassName="pagination"
                            		activeClassName="active"
                            		previousLabel={
                            			<i className='fa fa-angle-left' aria-hidden='true'></i>
                            		}
                            		nextLabel={
                            			<i className='fa fa-angle-right' aria-hidden='true'></i>
                            		}
                            	/>
                            </>
                		}
                		
                	</div>
                
                </div>
			}
			
			<ReferralContactUsModal 
				ts_ccc={ts_ccc}
				show={openModal} 
				onClose={handleClose} 
				onSuccess={handleSuccess}>
			</ReferralContactUsModal>
			<ReferralRedeemModal 
				show={openRedeemModal} 
				onClose={handleRedeemClose} 
				onSuccess={handleRedeemSuccess} 
				readyRedeemList={readyRedeemList}>
			</ReferralRedeemModal>
		</div>
	);
}

export default ReferralDashboard;
