
import React, { useEffect, useRef, useState } from "react";
import { Button, Container } from "reactstrap";
import Slider from "react-slick";
import girl1Image from "assets/img/Login/Girl1/gal01.png";
import { ReactComponent as Banner1_1 } from "assets/img/Login/Girl1/ico_gal01_smily.svg";
import { ReactComponent as Banner1_2 } from "assets/img/Login/Girl1/icon_gal01_bubble.svg";
import { ReactComponent as Banner1_3 } from "assets/img/Login/Girl1/icon_gal01_good.svg";
import { ReactComponent as Banner1_4 } from "assets/img/Login/Girl1/icon_gal01_heart.svg";
import { ReactComponent as Banner1_5 } from "assets/img/Login/Girl1/icon_gal01_media.svg";
import { ReactComponent as Banner1_6 } from "assets/img/Login/Girl1/icon_gal01_play.svg";

import { debounce } from "lodash";
import styles from "assets/scss/module/auth/login.module.scss";


const LoginGirl1Floating = () => {
	// dynamic height of image and floating image
	const [targetHeight, setTargetHeight] = useState(0);
	const [targetWidth, setTargetWidth] = useState(0);
	const [imageLoaded, setImageLoaded] = useState(false);
	
	const handleResize = debounce(() => {
		const targetDiv = document.getElementById("girl-image1");
		if (targetDiv) {
			setTargetHeight(targetDiv.clientHeight);
			setTargetWidth(targetDiv.clientWidth);
		}
	}, 500);

	useEffect(() => {
		handleResize(); // Initial calculation
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		handleResize(); // Initial calculation
	}, [imageLoaded]);

	return (
		<div className={`${styles.girl1_container}`}>
			<img id={"girl-image1"} className={styles.girl1_image} alt='girl1' src={girl1Image} onLoad={() => setImageLoaded(true)} />
			<div className={`${styles.vector_animate_ele} ${styles.vector_animate_wrap}`} style={{ height: `${targetHeight}px`, width: `${targetWidth}px` }}>
				<div className={`${styles.item_1} ${styles.moving_vertical_1}`}><Banner1_1 className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_2} ${styles.moving_vertical_3}`}><Banner1_2 className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_3} ${styles.moving_vertical_2}`}><Banner1_3 className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_4} ${styles.moving_vertical_2}`}><Banner1_4 className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_5} ${styles.moving_vertical_3}`}><Banner1_5 className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_6} ${styles.moving_vertical_3}`}><Banner1_6 className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_7} ${styles.rotate_1}`}><Banner1_1 className={`${styles.banner_1_item_image}`} /></div>
			</div>
		</div>
		// <div className="flex-col-50 posrel gutter image-col flex align-items-end banner-image-container">
		// 	<img id={"home-banner-image1"} className="home-banner-image" src={require("assets/img/Home/Banner/Banner1/main.png")} alt="Advance your career with in-demand skills, upskill with WSQ SkillsFuture courses in Singapore" />
		// 	<div className="vector-animate-ele vector-animate-wrap" style={{ height: `${targetHeight}px`, width: `${targetWidth}px` }}>
		// 		<div className="fb item-2 moving-vertical-3"><Banner1_2 className="banner-1-item-image" /></div>
		// 		<div className="fb item-3 moving-vertical-2"><Banner1_3 className="banner-1-item-image" /></div>
		// 		<div className="fb item-4 moving-vertical-3"><Banner1_4 className="banner-1-item-image" /></div>
		// 		<div className="fb item-5 moving-vertical-2"><Banner1_5 className="banner-1-item-image" /></div>
		// 		<div className="fb item-6 moving-vertical-1"><Banner1_5 className="banner-1-item-image" /></div>
		// 		<div className="fb item-7 moving-vertical-2"><Banner1_5 className="banner-1-item-image" /></div>
		// 	</div>
		// </div>
	);
};
export default LoginGirl1Floating;