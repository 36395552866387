
import React, { useEffect, useState } from "react";

// reactstrap components

import { Link } from "react-router-dom";
import {
	Card,
} from "reactstrap";
import { dateFormatting } from "utilities/date";
import "../../../assets/scss/custom/notification-card.scss";

function CommentButton({
	totalComment,
	onPress,
	style,
	title,
}) {
	const [comments, setComments] = useState(totalComment);
	useEffect(() => {
		if (totalComment !== comments) {
			setComments(totalComment);
		}
	}, [totalComment]);

	return (
		<button
			className={"comment-button"}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				onPress();
			}}
		>
			<div className="comment-button-container">
				<i className='fa fa-comment comment-button-icon' aria-hidden='true'></i>
				<p className="comment-button-title">{"Comment"}</p>
				<p className="comment-button-number">{comments}</p>
			</div>

		</button>
	);
}

export default React.memo(CommentButton);

