import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import * as yup from "yup";
import { yupHelper } from "utilities/yupHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Modal,
	Row,
	Col,
	Spinner,
} from "reactstrap";

import { verifyOtp, resendOtp, resetUser } from "../../actions/auth";
import { clearMessage } from "actions/message";
import AlertDialog from "components/AlertDialog";
import { removeLogGTMUser } from "utilities/googleTagManager";
import authApi from "services/auth-api";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { InputLabel } from "@material-ui/core";
import { getErrorMessage } from "utilities/error";

const schema = yup.object({
	otp: yupHelper.otp(),
});

const changeMobileSchema = yup.object({
	mobile: yupHelper.mobile(),
});

function OTPModal() {
	const { user: currentUser, isLoggedIn } = useSelector((state) => state.auth);
	const [modalChange, setModalChange] = React.useState(true);
	const [loading, setLoading] = useState(false);
	const [resendLoading, setResendLoading] = useState(false);
	const [seconds, setSeconds] = useState(30);
	const { message } = useSelector((state) => state.message);
	const [isDialogOpen, setIsDialogOpen] = React.useState(false);
	const dispatch = useDispatch();
	const [
		chagingMobileNumber,
		setChagingMobileNumber,
	 ] = useState(false);
	let navigate = useNavigate();
	const [tempMobileNumber,setTempMobileNumber] = useState(null);

	// const verification_method = currentUser.user.learnermaster_verification_channel ? currentUser.user.learnermaster_verification_channel  : "email"; 
	const verification_method = "mobile";

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		if (seconds !== 0) {
			const interval = setInterval(() => {
				setSeconds(seconds - 1);
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [seconds]);

	const handleVerifyOTP = (data) => {
		setLoading(true);
		dispatch(verifyOtp(data.otp))
			.then(() => {
				const redirectURL = localStorage.getItem("upskilling100_register_return_url");
				if (redirectURL) {
					localStorage.removeItem("upskilling100_register_return_url");
					window.location.href= redirectURL;
				}
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const handleResendOTP = () => {
		setResendLoading(true);
		setSeconds(30);
		if(verification_method === "email"){
			dispatch(resendOtp())
				.then(() => {
					setResendLoading(false);
				})
				.catch(() => {
					setResendLoading(false);
				});
		}else{
			authApi.resendMobileOtp({
				change_mobile: tempMobileNumber
			}).then(() => { 
				toast.success("OTP sent successfully");
				setResendLoading(false);
			}).catch((error) => {
				toast.error( getErrorMessage(error));
				setResendLoading(false);
			});
		}
	};

	const changeMobileForm = useForm({
		resolver: yupResolver(changeMobileSchema),
	});

	const changeMobileMutation = 	useMutation(({mobile}) => {
		return authApi.resendMobileOtp({
			change_mobile: mobile
		}).then(() => { 
			setTempMobileNumber(mobile);
			setChagingMobileNumber(false);
			toast.success("OTP sent successfully");
			setSeconds(30);
		}).catch((error) => {
			toast.error( getErrorMessage(error));

		});
	});


	const cancelLogin = () => {
		removeLogGTMUser();
		dispatch(resetUser());
		localStorage.removeItem("user");
		navigate("/login");
	};

	useEffect(() => {
		dispatch(clearMessage());
	}, []);

	if (!isLoggedIn) {
		return <Navigate to='/login' />;
	} else {
		if (currentUser.user.email_verified_at) {
			return <Navigate to='/' />;
		}
	}

	return (
		<>
			<Modal
				className='modal-dialog-centered'
				isOpen={modalChange}
				// toggle={() => setModalChange(false)}
			>
				<div>
					<button
						aria-label='Close'
						className='close pr-3 pt-3'
						data-dismiss='modal'
						type='button'
						onClick={() => setIsDialogOpen(true)}
					>
						<i className='fa fa-times' aria-hidden='true'></i>
					</button>
				</div>

				{
					verification_method  === "email"
						?  <div className='modal-header pt-5'>
							<div className='modal-header-wrap text-center'>
								<h3><span style={{fontWeight: "bold"}}>Email</span> Verification</h3>
								<p >Enter the OTP verification code that sent to</p>
								<p>
									<u className='text-lowercase'>{currentUser.user.learnermaster_email}</u>
								</p>
							</div>
						</div>
						: <div className='modal-header pt-5'>
							<div className='modal-header-wrap text-center'>
								<h3><span style={{fontWeight: "bold"}}>Mobile </span> Verification</h3>
								<p >Enter the OTP verification code that sent to</p>
								<p>
									{
										!chagingMobileNumber 
											?<div 
												className="d-flex justify-content-center" 
												style={{
													cursor: "pointer"
												}}
												onClick={() => setChagingMobileNumber(true)}>
												<u className='text-lowercase'>{tempMobileNumber ? tempMobileNumber:currentUser.user.learnermaster_mobile}</u>
												<div>
													<i className="fa fa-pencil" ></i>
												</div>
											</div>
											: <form onSubmit={changeMobileForm.handleSubmit(changeMobileMutation.mutate)}>
												<input
													className={"form-control form-control-sm text-center" + (errors.mobile ? "is-invalid" : "")}
													placeholder='Mobile Number'
													type='text'
													{...changeMobileForm.register("mobile")}
												/>
												<InputLabel className="text-danger">{changeMobileForm.formState.errors.mobile?.message}</InputLabel>
												<div className="d-flex justify-content-center mt-2">
													<button 
														type="submit"
														disabled={changeMobileMutation.isLoading}
														className="btn  btn-primary btn-sm" 
														style={{ padding: "0.25rem 0.5rem"}}
													>
														{
															changeMobileMutation.isLoading 
																? <Spinner size='sm'/> 
																: "Save Changes"
														}
													</button>
													<button 
														onClick={() => { setChagingMobileNumber(false); }}
														className="btn  btn-warning btn-sm"
														style={{ padding: "0.25rem 0.5rem" }}
													>
														Cancel
													</button>

												</div>
											</form>
									}
								</p>
							</div>
						</div>
				}
				
				<div className='modal-body'>
					<Form role='form' onSubmit={handleSubmit(handleVerifyOTP)}>
						<FormGroup>
							<input
								className={"form-control " + (errors.otp ? "is-invalid" : "")}
								defaultValue=''
								placeholder='OTP Verification Code'
								type='text'
								{...register("otp")}
							/>
							<div className='mt-2 invalid-feedback text-center'>{errors.otp?.message}</div>
							<div className='mt-2 text-center'>
                Do not receive OTP Code?{" "}
								{seconds !== 0 && <span className='text-blue bold'>{seconds}</span>}
								{seconds === 0 && (
									chagingMobileNumber 
										?  null
										: <span onClick={handleResendOTP} role='button' className='text-blue bold'>
                    Resend
										</span>
								)}
							</div>
						</FormGroup>
						<div className='modal-footer justify-content-center mb-4'>
							<Button className='mt-4' color='success' type='submit' disabled={loading}>
								{loading && <span className='spinner-border spinner-border-sm'></span>}
								<span>Verify</span>
							</Button>
						</div>

						{message && (
							<div className='form-group'>
								<div
									className={classnames(
										"alert",
										message === "OTP Sent Success" ? "alert-success" : "alert-danger",
									)}
									role='alert'
								>
									{message}
								</div>
							</div>
						)}
					</Form>
				</div>
				<AlertDialog isOpen={isDialogOpen} title={"Cancel Login"} description={"Are you sure to cancel your login!"} onCancel={() => setIsDialogOpen(false)} onAgree={() => cancelLogin()} />

			</Modal>
		</>
	);
}

export default OTPModal;
