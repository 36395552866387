
import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import styles from "../Profile.module.scss";
import { ReactComponent as CertIcon } from "assets/img/Profile/Bundle/cert.svg";
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import BadgeInactiveImage from "assets/img/Profile/Bundle/icon_cssA.png";
import BadgeActiveImage from "assets/img/Profile/Bundle/icon_cssB.png";

const BundleBadge = ({ totalSteps = 0, currentSteps = 0, color = "#0085ca", badge_active_image = null, badge_inactive_image = null }) => {
	return (
		<div className={`${styles.profile_bundle_badge_container}`}>
			<CircularProgressbarWithChildren
				value={currentSteps}
				maxValue={totalSteps}
				text={"1"}
				styles={buildStyles({
					strokeLinecap: "butt",
					pathColor: color,
					trailColor: "#eee",
					height: 100,
					width: 100,
				})}
				strokeWidth={5}
			>
				<img
					className={`${styles.profile_bundle_badge_image_container}`}
					src={
						totalSteps == currentSteps ?
							badge_active_image ? badge_active_image : BadgeActiveImage
							:
							badge_inactive_image ? badge_inactive_image : BadgeInactiveImage
					}
					alt="doge"
				/>
				<div className={`${styles.profile_bundle_badge_number_container}`} style={{ backgroundColor: color ? color : "#0085ca" }}>
					<span className={`${styles.profile_bundle_badge_number_text}`}>{currentSteps}</span>
				</div>
			</CircularProgressbarWithChildren>
		</div>
	);
};
export default BundleBadge;