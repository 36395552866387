import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import $ from "jquery";

// reactstrap components
import { Col, Row } from "reactstrap";
import UpcomingCoursesCard from "./UpcomingCoursesCard";
import { useWindow } from "hooks/UseWindow";
import CompletedCoursesCard from "components/LearnerPortal/CompletedCoursesCard";

function CompletedCourses({ courses }) {
	const [currentDisplayCourse, setCurrentDisplayCourse] = useState(1);
	let totalCourses = courses.length;
	const slider = React.useRef(null);
	const { width } = useWindow();

	var slider_settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 4,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 980,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],

		beforeChange: (oldIndex, newIndex) => {
			setCurrentDisplayCourse(newIndex + 1);
			if(newIndex == 0){
				$(".completed-arrow .arrow-left").addClass("disable");
				$(".completed-arrow .arrow-right").removeClass("disable");
			}else if(newIndex == totalCourses -1){
				$(".completed-arrow .arrow-right").addClass("disable");
				$(".completed-arrow .arrow-left").removeClass("disable");
			}else{
				$(".completed-arrow .arrow-left,.completed-arrow .arrow-right").removeClass("disable");

			}
		},
	};
	return (
		<div className={"fadeInDiv"}>
			<Row className='align-items-center justify-content-between mt-3'>
				<Col className=''>
					<span className='mb-0 fs24 font_2 text-fca-dark'>
						{"Completed Courses"}
						<span className='mb-0 fs18 font_2 text-fca-dark'>
							{` (${currentDisplayCourse} / ${totalCourses})`}
						</span>
					</span>
				</Col>
				{Object.keys(courses).length >1 && width <= 575 && (
					<Col xl='auto' md='auto' xs='auto'>
						<div className='arrow-wrap flex completed-arrow'>
							<div className='arrow arrow-left disable' onClick={() => slider?.current?.slickPrev()}>
								<i className='fa fa-angle-left' aria-hidden='true'></i>
							</div>
							<div className={"arrow arrow-right ml-2" + (totalCourses == 1 ? " disable" : "") } onClick={() => slider?.current?.slickNext()}>
								<i className='fa fa-angle-right' aria-hidden='true'></i>
							</div>
						</div>
					</Col>
				)}
			</Row>

			<div className='w-100 upcoming_course'>
				<Slider ref={slider} className={`${width > 575 ? "adaptive-slider" : "adaptive-mobile-slider"}`} arrows={width > 575 ? true : false} {...slider_settings} >
					{courses.map((course, i) => (
						<CompletedCoursesCard key={i} course={course} status='completed' />
					))}
				</Slider>
			</div>
		</div>
	);
}

export default CompletedCourses;