import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import Slider from "react-slick";

import {
	Col,
	Container,
	Row,
	Card,
	CardBody,
	CardTitle,
	CardSubtitle,
	Badge,
	Spinner,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import generalApi from "services/general-api";
import styles from "./bundleinfo.module.scss";
import CourseBannerPlaceholder from "assets/img/Course/course-banner-placeholder.png";
import  defaultImage  from "assets/img/default_img.jpg";
import CourseCard from "views/pages/home/BundleInfo/CourseCard";

const CATEGORY_OPTIONS = [
	{
		category_title: "All",
		category_id: "13,14,15",
	},
	{
		category_id: 13,
		category_title: "Digital Tech Skills",
	},
	{
		category_id: 14,
		category_title: "Critical Soft Skills",
	},
	{
		category_id: 15,
		category_title: "Creative Content Advertising",
	},
];

var slickSettings = {
	dots: true,
	infinite: false,
	arrows: false,
	slidesToShow: 4,
	slidesToScroll: 4,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
			},
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
};

function BundleInfo() {
	const [selectedCategory, setSelectedCategory] = React.useState(CATEGORY_OPTIONS[0]);
	const params = useParams();
	const query = useQuery(
		["bundle-info", params.id],
		() => {
			return generalApi.getBundleDetailV2(params.id);
		},
		{
			retry: 0,
		},
	);

	const interestedCourseQuery = useQuery(
		["interested-courses", selectedCategory.category_id],
		() => {
			return generalApi.getCourses(selectedCategory.category_id);
		},
		{
			retry: 0,
		},
	);

	if (query.isLoading && !query.data) {
		return (
			<div className="d-flex justify-content-center mt-4">
				<Spinner />
			</div>
		);
	}

	if ((!query.isLoading && !query.data) || query.error) {
		return <div className={`d-flex justify-content-center mt-4 ${styles.bundle_empty_title}`}>The Bundle is not available</div>;
	}
	return (
		<div className={styles.container}>
			<div className={styles.banner_background_container} style={{ backgroundImage: `url("${query.data.bundle_banner_background_image }")`}}>
				<Row className={styles.banner_container}>
					<Col md='6'>
						<div className={styles.banner_content}>
							<p className={styles.bundle_title}>{query.data.bundle_banner_title}<span className={styles.bundle_title_blue}>{` ${query.data.bundle_name}`}</span></p>
							<p className={styles.bundle_description}>{query.data.bundle_banner_description}</p>
						</div>
					</Col>
					<Col md='6'>
						<img className={styles.banner_image} src={query.data.bundle_banner_image ? query.data.bundle_banner_image : defaultImage} />
					</Col>
				</Row>
			</div>
			

			<p className={styles.slider_title}>Courses Available</p>
			<Slider {...slickSettings} className={styles.course_slick}>
				{query.data.courses.map((course) => {
					return (
						<Link
							key={course.course_id}
							to={`/course/course-info/${course.course_slug || course.course_id}`}>
							<CourseCard key={course.course_id} course={course} />
						</Link>
					);
				})}
			</Slider>

			<p className={`${styles.slider_title} mt-5`}>Course You May Be Interested</p>
			<div className={styles.category_selections}>
				{CATEGORY_OPTIONS.map((option) => {
					return (
						<div
							key={option.category_title}
							onClick={() => {
								setSelectedCategory(option);
							}}
							className={
								styles.category_option +
								" " +
								(option.category_id === selectedCategory.category_id ? styles.selected : "")
							}>
							{option.category_title}
						</div>
					);
				})}
			</div>
			{/* <Row className={styles.course_available_row}> */}
			{interestedCourseQuery.isLoading ? (
				<div className="d-flex justify-content-center">
					<Spinner />
				</div>
			) : interestedCourseQuery.data ? (
				<Slider {...slickSettings}>
					{!interestedCourseQuery.data || !interestedCourseQuery.data.length ? (
						<div className={styles.interested_course_placeholder}>No Course Available</div>
					) : null}
					{interestedCourseQuery.data.map((course) => {
						return (
							<Link
								key={course.course_id}
								to={`/course/course-info/${course.course_slug || course.course_id}`}>
								<CourseCard key={course.course_id} course={course} />
							</Link>
						);
					})}
				</Slider>
			) : null}

			{/* </Row> */}
		</div>
	);
}

export default BundleInfo;
