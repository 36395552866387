
import React, { useEffect, useState } from "react";
import earthImage from "assets/img/Events/earth@4x.png";
import { debounce } from "lodash";
import styles from "../Profile.module.scss";


const EarthAnimation = () => {
	// dynamic height of image and floating image
	const [targetHeight, setTargetHeight] = useState(0);
	const [targetWidth, setTargetWidth] = useState(0);
	const [imageLoaded, setImageLoaded] = useState(false);
	
	const handleResize = debounce(() => {
		const targetDiv = document.getElementById("earth-image");
		if (targetDiv) {
			setTargetHeight(targetDiv.clientHeight);
			setTargetWidth(targetDiv.clientWidth);
		}
	}, 500);

	useEffect(() => {
		handleResize(); // Initial calculation
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		handleResize(); // Initial calculation
	}, [imageLoaded]);

	return (
		<div className={`${styles.earth_container}`}>
			<img id={"earth-image"} className={`${styles.earth_image} ${styles.rotate_1}`} alt='earth' src={earthImage} onLoad={() => setImageLoaded(true)} />
		</div>
	);
};
export default EarthAnimation;