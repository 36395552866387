import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getReferralDashboard = () => {
	return axios
		.get(
			API_URL +
			"/api/mobile/referral/dashboard",
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data.data;
		});
};

const redeemVoucher = (selectedOptions, signature) => {
	return axios
		.post(
			API_URL + "/api/mobile/referral/redeemVoucher",
			{ selectedOptions: selectedOptions, voucher_redeem_signature: signature },
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data.data;
		});
};

export default {
	getReferralDashboard,
	redeemVoucher,
};
