
import { articleLike } from "actions/news";
import { useDebounceFn } from "ahooks";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// reactstrap components
import { toast } from "react-toastify";
import { getErrorMessage } from "utilities/error";
import { intToString } from "utilities/number";
import "../../../assets/scss/custom/notification-card.scss";

function LikeButton({isLike, totalLike, article_id}) {
	const [likes, setLikes] = useState(totalLike);
	const [liked, setLiked] = useState(isLike);
	const dispatch = useDispatch();
	const { user: currentUser } = useSelector((state) => state.auth);
	let navigate = useNavigate();

	useEffect(() => {
		if (totalLike !== likes) {
			setLikes(totalLike);
		}
		if (isLike !== liked) {
			setLiked(isLike);
		}
	}, [isLike, totalLike]);

	const likeArticle =  async (article_id, liked) => {
		try {
			let response = await dispatch(articleLike(article_id, liked));
		
		} catch (e) {
			let error_message = getErrorMessage(e);
			toast.error(error_message);
		}
	};

	const handleLikePress = useDebounceFn(
		(article_id) => {
			if (liked === true) {
				setLikes(likes - 1);
				setLiked(false);
			} else {
				setLikes(likes + 1);
				setLiked(true);
			}
			likeArticle(article_id, liked);
		},
		{ leading: true, trailing: false, wait: 1000 },
	).run; 

	return (
		<button
			className={"like-button"}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				if (currentUser) {
					handleLikePress(article_id);
				} else {
					navigate("/login");
				}
			}}
		>
			<div className="like-button-container">
				<i className={`fa fa-thumbs-up like-button-icon ${liked ? "like-button-icon-liked" : ""}`}aria-hidden='true'></i>
				<p className="like-button-title">{"Like"}</p>
				<p className="like-button-number">{intToString(Number(likes))}</p>
			</div>
		</button>
	);
}

export default React.memo(LikeButton);

