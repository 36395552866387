import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button, Col, Modal, Card, CardBody, CardHeader, Row } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import generalApi from "services/general-api";
import moment from "moment";
import { getErrorMessage } from "utilities/error";

function ModalConfirm() {
	const [loading, setLoading] = useState(false);
	const [modalChange, setModalChange] = useState(true);
	const dispatch = useDispatch();
	const [message, setmessage] = useState("");

	const courseRegisterData = useSelector((state) => state.courseRegisterSlice);

	const registerFormData = courseRegisterData.registerForm;
	const courseData = courseRegisterData.course;
	const courserunData = courseRegisterData.courserun;
	let navigate = useNavigate();

	useEffect(() => {
		if (
			Object.keys(courseRegisterData.course).length === 0 ||
      Object.keys(courseRegisterData.courserun).length === 0
		) {
			navigate("/course");
		}
	}, []);

	const handleRegisterCourse = () => {
		let requestData = {
			learner_nric: registerFormData.nric,
			learner_name: registerFormData.name,
			learner_dob: registerFormData.dob,
			learner_nationality: registerFormData.nationality,
			learner_email: registerFormData.email,
			learner_mobile: registerFormData.mobileno,
			learner_qualificationlevel: registerFormData.education,
			learner_designation: registerFormData.designation,
			learner_laptoploan: registerFormData.laptopLoan === "Yes" ? true : false,
			registrationform_sme: registerFormData.registerCompanySme,
			registrationform_courserun: courserunData.courserun_id,
			registrationform_sponsoredbycompany_yes_no: registerFormData.sponsoredCompany,
			registrationform_billing_email: registerFormData.registerCompanyEmail,
			registrationform_company_name: registerFormData.registerCompanyName,
			registrationform_uen: registerFormData.registerCompanyUenNo,
			registrationform_company_contact_person: registerFormData.registerCompanyContactPerson,
			registrationform_company_contact_email: registerFormData.registerCompanyEmail,
			registrationform_company_contact_mobile: registerFormData.registerCompanyContactNo,
			registrationform_company_address: registerFormData.registerCompanyBillingAddress,
			registrationform_company_postal: registerFormData.registerCompanyPostalCode,
			registrationform_company_country: registerFormData.registerCompanyCountry,
			registrationform_signature: registerFormData.signature,
		};
		
		generalApi.registerCourse(requestData).then(
			(response) => {
				navigate("/course/success");
			},
			(error) => {
				setLoading(false);
				setmessage(getErrorMessage(error));
			},
		);

	};

	return (
		<>
			<Modal
				className='modal-dialog-centered registration_modal'
				isOpen={modalChange}
			>
				{Object.keys(courseRegisterData.course).length !== 0 &&
          Object.keys(courseRegisterData.courserun).length !== 0 && (
					<div className='modal-body'>
						<h4>Registration Details</h4>
						<Card>
							<CardHeader>
								<h5 className='h3 mb-0'>Course Information</h5>
							</CardHeader>
							<CardBody>
								<div className='info-wrap'>
									<div className='title'>
										<b>Course Registration Date</b>
									</div>
									<div className=''>{moment().format("DD MMM YYYY")}</div>
								</div>
								<div className='info-wrap'>
									<div className='title'>
										<b>Mode of Training</b>
									</div>
									<div className=''>{courserunData.courserun_modeoftraining}</div>
								</div>
								<div className='info-wrap'>
									<div className='title'>
										<b>Course Title</b>
									</div>
									<div className=''>{courseData.course_title}</div>
								</div>
								<div className='info-wrap'>
									<div className='title'>
										<b>Course Date</b>
									</div>
									<div className=''>
										{moment(courserunData.courserun_course_from).format("DD MMM YYYY")} -{" "}
										{moment(courserunData.courserun_course_to).format("DD MMM YYYY")}
									</div>
								</div>
								<div className='info-wrap'>
									<div className='title'>
										<b>Course Location</b>
									</div>
									<div className=''>{courserunData.venue.venue_address}</div>
								</div>
								<div className='info-wrap'>
									<div className='title'>
										<b>Course Sessions</b>
									</div>
									<div className='mt-2 mb-2'>
										<Row className="mx-1">
											<Col style={{ borderWidth: 1, borderStyle: "solid", borderColor: "rgb(204,204,204)", padding: 5 }}>
												<span>Date</span>
											</Col>
											<Col style={{ borderWidth: 1, borderStyle: "solid", borderColor: "rgb(204,204,204)", padding: 5 }}>
												<span>Time</span>
											</Col>
										</Row>
										{courserunData.csessions.map((session, i) => (
											<Row key={i} className="mx-1">
												<Col style={{ borderWidth: 1, borderStyle: "solid", borderColor: "rgb(204,204,204)", padding: 5 }}>
													<span>{moment(session.csession_start_date).format("DD MMM YYYY, ddd")} -{" "}
														{moment(session.csession_end_date).format("DD MMM YYYY, ddd")}</span>
												</Col>
												<Col style={{ borderWidth: 1, borderStyle: "solid", borderColor: "rgb(204,204,204)", padding: 5 }}>
													<span>{session.csession_start_time + " - " + session.csession_end_time}</span>
												</Col>
											</Row>
											
										))}
									</div>
								</div>
								<div className='info-wrap'>
									<div className='title'>
										<b>Course Price</b>
									</div>
									<div className=''>${registerFormData.coursePrice}</div>
								</div>
							</CardBody>
						</Card>

						<Card className='mt-2'>
							<CardHeader>
								<h5 className='h3 mb-0'>Learner Information</h5>
							</CardHeader>
							<CardBody>
								<div className='info-wrap'>
									<div className='title'>
										<b>Full Name (As Shown in NRIC)</b>
									</div>
									<div className=''>{registerFormData.name}</div>
								</div>
								<div className='info-wrap'>
									<div className='title'>
										<b>NRIC or ID No.</b>
									</div>
									<div className=''>{registerFormData.nric}</div>
								</div>
								<div className='info-wrap'>
									<div className='title'>
										<b>Email Address</b>
									</div>
									<div className=''>{registerFormData.email}</div>
								</div>
								<div className='info-wrap'>
									<div className='title'>
										<b>Mobile No.</b>
									</div>
									<div className=''>{registerFormData.mobileno}</div>
								</div>
								<div className='info-wrap'>
									<div className='title'>
										<b>Date of Birth</b>
									</div>
									<div className=''>{moment(registerFormData.dob).format("DD-MM-YYYY")}</div>
								</div>
								<div className='info-wrap'>
									<div className='title'>
										<b>Education Qualification</b>
									</div>
									<div className=''>{registerFormData.education}</div>
								</div>
								<div className='info-wrap'>
									<div className='title'>
										<b>Designation</b>
									</div>
									<div className=''>{registerFormData.designation}</div>
								</div>
								<div className='info-wrap'>
									<div className='title'>
										<b>Laptop Loan</b>
									</div>
									<div className=''>{registerFormData.laptopLoan}</div>
								</div>
								<div className='info-wrap'>
									<div className='title'>
										<b>Sponsored by Company</b>
									</div>
									<div className=''>{registerFormData.sponsoredCompany}</div>
								</div>
							</CardBody>
						</Card>
						{registerFormData.sponsoredCompany === "Yes" && (
							<Card className='mt-2'>
								<CardHeader>
									<h5 className='h3 mb-0'>Company Information</h5>
								</CardHeader>
								<CardBody>
									<div className='info-wrap'>
										<div className='title'>
											<b>Company Name</b>
										</div>
										<div className=''>{registerFormData.registerCompanyName}</div>
									</div>
									<div className='info-wrap'>
										<div className='title'>
											<b>Company UEN No.</b>
										</div>
										<div className=''>{registerFormData.registerCompanyUenNo}</div>
									</div>
									<div className='info-wrap'>
										<div className='title'>
											<b>Company Contact Person's Name</b>
										</div>
										<div className=''>{registerFormData.registerCompanyContactPerson}</div>
									</div>
									<div className='info-wrap'>
										<div className='title'>
											<b>Company Contact Person's Email</b>
										</div>
										<div className=''>{registerFormData.registerCompanyContactNo}</div>
									</div>
									<div className='info-wrap'>
										<div className='title'>
											<b>Address</b>
										</div>
										<div className=''>{registerFormData.registerCompanyBillingAddress}</div>
									</div>
								</CardBody>
							</Card>
						)}

						{message && (
							<Col xl='12'>
								<div className='form-group'>
									<div className='alert alert-danger' role='alert'>
										{message}
									</div>
								</div>
							</Col>
						)}
					</div>
				)}

				<div className='modal-footer justify-content-between'>
					<Link to='/course' className='btn btn-default'>
            Close
					</Link>
					<Link to={"/course/payment"} className='btn btn-primary mr-auto'>
            Back
					</Link>
					<Button color='success' type='button' onClick={handleRegisterCourse}>
            Proceed To Endorse
					</Button>
				</div>
			</Modal>
		</>
	);
}

export default ModalConfirm;
