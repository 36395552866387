const { useState, useEffect } = require("react");

export const useWindow = () => {
	const [screenSize, getDimension] = useState({
		dynamicWidth: window.innerWidth,
		dynamicHeight: window.innerHeight
	});
	const setDimension = () => {
		getDimension({
			dynamicWidth: window.innerWidth,
			dynamicHeight: window.innerHeight
		});
	};
      
	useEffect(() => {
		window.addEventListener("resize", setDimension);
        
		return(() => {
			window.removeEventListener("resize", setDimension);
		});
	}, [screenSize]);
      
	return { height: screenSize.dynamicHeight, width: screenSize.dynamicWidth};
};