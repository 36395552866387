import React from "react";

function RatingDisplay({ rating }) {
	const maxRating = 5;

	const solidStar = (i) => {
		return <i className='fas fa-star' key={i}></i>;
	};

	const regularStar = (i) => {
		return <i className='far fa-star' key={i}></i>;
	};

	const renderStars = () => {
		const stars = [];
		for (let i = 1; i <= maxRating; i++) {
			const starIcon = i <= rating ? solidStar(i) : regularStar(i);
			stars.push(starIcon);
		}
		return stars;
	};

	return <div className='rating'>{renderStars()}</div>;
}

export default RatingDisplay;
