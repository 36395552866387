import {
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
	SET_MESSAGE,
	OTP_SUCCESS,
	OTP_FAIL,
	RESEND_OTP_SUCCESS,
	RESEND_OTP_FAIL,
	UPDATE_PROFILE_SUCCESS,
	UPDATE_PROFILE_FAIL,
	GET_USER_SUCCESS,
	GET_USER_FAIL,
	CLEAR_MESSAGE,
	HYPERADMIN_RESEND_OTP_SUCCESS,
	HYPERADMIN_RESEND_OTP_FAIL,
	HYPERADMIN_OTP_SUCCESS,
	HYPERADMIN_OTP_FAIL,
	HYPERADMIN_SEARCH_USER_SUCCESS,
	HYPERADMIN_SEARCH_USER_FAIL,
	HYPERADMIN_LOGIN_SUCCESS,
	HYPERADMIN_LOGIN_FAIL,
	RESET_EMAIL_SUCCESS,
	RESET_EMAIL_FAIL,
	RESET_USER,
	GET_PROFILE_BUNDLE_SUCCESS,
	GET_PROFILE_BUNDLE_FAIL,
} from "./types";

import AuthService from "../services/auth-api";
import { getErrorMessage } from "utilities/error";
import { clearCheckout, clearCheckoutAnalyticsData } from "reducers/courseRegisterSlice";

export const register = (data) => {
	return (dispatch) => {
		return AuthService.register(data).then(
			(response) => {
				dispatch({
					type: REGISTER_SUCCESS,
					payload: { user: response },
				});

				dispatch({
					type: SET_MESSAGE,
					payload: response.data.message,
				});

				return Promise.resolve();
			},
			(error) => {
				dispatch({
					type: REGISTER_FAIL,
				});
				dispatch({
					type: SET_MESSAGE,
					payload: getErrorMessage(error),
				});

				return Promise.reject();
			},
		);
	};
};

export const login = (
	username, 
	password,
	accountType,
) => (dispatch) => {
	return AuthService.login(username, password,accountType).then(
		(data) => {
			if (!data.isHyperAdminLogin) {
				dispatch({
					type: LOGIN_SUCCESS,
					payload: { user: data },
				});
			}
			return Promise.resolve(data);
		},
		(error) => {
			dispatch({
				type: LOGIN_FAIL,
			});

			dispatch({
				type: SET_MESSAGE,
				payload: getErrorMessage(error),
			});

			return Promise.reject(error);
		},
	);
};

export const switchAccountType = (
	accountType,
) => (dispatch) => {
	return AuthService.switchAccountType(accountType).then(
		(data) => {
			dispatch({
				type: LOGIN_SUCCESS,
				payload: { user: data.data },
			});

		},
		(error) => {
			return Promise.reject(error);
		},
	);
};


export const getUser = () => (dispatch) => {
	return AuthService.getUser().then(
		(data) => {
			dispatch({
				type: GET_USER_SUCCESS,
				payload: { user: data },
			});

			return Promise.resolve();
		},
		(error) => {
			dispatch({
				type: GET_USER_FAIL,
			});

			dispatch({
				type: SET_MESSAGE,
				payload: getErrorMessage(error),
			});

			return Promise.reject(error);
		},
	);
};


export const getProfileBundle = () => (dispatch) => {
	return AuthService.getProfileBundle().then(
		(data) => {
			dispatch({
				type: GET_PROFILE_BUNDLE_SUCCESS,
				payload: {
					profileBundle: data.profileBundle,
					is_completed_one_course: data.is_completed_one_course,
					almost_completed_bundle_course_left: data.almost_completed_bundle_course_left,
					almost_completed_bundle_title: data.almost_completed_bundle_title,
					total_badge: data.total_badge,
					total_completed_course: data.total_completed_course,
					total_upcoming_course: data.total_upcoming_course,
				},
			});

			return Promise.resolve();
		},
		(error) => {
			dispatch({
				type: GET_PROFILE_BUNDLE_FAIL,
			});

			dispatch({
				type: SET_MESSAGE,
				payload: getErrorMessage(error),
			});

			return Promise.reject(error);
		},
	);
};

export const verifyOtp = (otp) => (dispatch) => {
	return AuthService.verifyOtp(otp).then(
		(data) => {
			dispatch({
				type: OTP_SUCCESS,
			});

			return Promise.resolve();
		},
		(error) => {
			dispatch({
				type: OTP_FAIL,
			});

			dispatch({
				type: SET_MESSAGE,
				payload: getErrorMessage(error),
			});

			return Promise.reject();
		},
	);
};

export const resendOtp = () => (dispatch) => {
	return AuthService.resendOtp().then(
		() => {
			dispatch({
				type: RESEND_OTP_SUCCESS,
			});

			dispatch({
				type: SET_MESSAGE,
				payload: "OTP Sent Success",
			});

			return Promise.resolve();
		},
		(error) => {
			dispatch({
				type: RESEND_OTP_FAIL,
			});

			dispatch({
				type: SET_MESSAGE,
				payload: getErrorMessage(error),
			});

			return Promise.reject();
		},
	);
};

export const updateProfile = (data) => {
	return (dispatch) => {
		return AuthService.updateProfile(data).then(
			(response) => {
				dispatch({
					type: UPDATE_PROFILE_SUCCESS,
					payload: { user: response },
				});

				return Promise.resolve();
			},
			(error) => {
				dispatch({
					type: UPDATE_PROFILE_FAIL,
				});

				dispatch({
					type: SET_MESSAGE,
					payload: getErrorMessage(error),
				});

				return Promise.reject();
			},
		);
	};
};

export const logout = (isHyperAdminLogin, user) => (dispatch) => {
	if (isHyperAdminLogin) {
		AuthService.hyperAdminLogout(user.user.learnermaster_id);
		dispatch({
			type: CLEAR_MESSAGE,
		});
		dispatch({
			type: LOGOUT,
		});
		dispatch(clearCheckout());
		dispatch(clearCheckoutAnalyticsData());
	} else {
		AuthService.logout();
		dispatch({
			type: CLEAR_MESSAGE,
		});
		dispatch(clearCheckout());
		dispatch(clearCheckoutAnalyticsData());
		dispatch({
			type: LOGOUT,
		});
		sessionStorage.removeItem("upskilling100");
	}
};

export const resetEmail = (data) => {
	return (dispatch) => {
		return AuthService.resetEmail(data).then(
			(response) => {
				
				dispatch({
					type: RESET_EMAIL_SUCCESS,
					payload: { user: response.data },
				});

				return Promise.resolve();
			},
			(error) => {
				dispatch({
					type: RESET_EMAIL_FAIL,
				});

				dispatch({
					type: SET_MESSAGE,
					payload: getErrorMessage(error),
				});

				return Promise.reject(error);
			},
		);
	};
};

export const resetUser = () => (dispatch) => {
	dispatch({
		type: CLEAR_MESSAGE,
	});
	dispatch({
		type: RESET_USER,
	});
};


// hyper admin
export const hyperAdminResendOtp = () => (dispatch) => {
	return AuthService.hyperAdminResendOtp().then(
		() => {
			dispatch({
				type: HYPERADMIN_RESEND_OTP_SUCCESS,
			});

			dispatch({
				type: SET_MESSAGE,
				payload: "OTP Sent Success",
			});

			return Promise.resolve();
		},
		(error) => {
			dispatch({
				type: HYPERADMIN_RESEND_OTP_FAIL,
			});

			dispatch({
				type: SET_MESSAGE,
				payload: getErrorMessage(error),
			});

			return Promise.reject();
		},
	);
};

export const hyperAdminVerifyOtp = (otp) => (dispatch) => {
	return AuthService.hyperAdminVerifyOtp(otp).then(
		(data) => {
			dispatch({
				type: HYPERADMIN_OTP_SUCCESS,
			});

			return Promise.resolve();
		},
		(error) => {
			dispatch({
				type: HYPERADMIN_OTP_FAIL,
			});

			dispatch({
				type: SET_MESSAGE,
				payload: getErrorMessage(error),
			});

			return Promise.reject();
		},
	);
};

export const hyperAdminSearchUser = (email) => (dispatch) => {
	return AuthService.hyperAdminSearchUser(email).then(
		(data) => {
			dispatch({
				type: HYPERADMIN_SEARCH_USER_SUCCESS,
			});

			return Promise.resolve(data.data.users);
		},
		(error) => {
			dispatch({
				type: HYPERADMIN_SEARCH_USER_FAIL,
			});

			dispatch({
				type: SET_MESSAGE,
				payload: getErrorMessage(error),
			});

			return Promise.reject();
		},
	);
};

export const hyperAdminLogin = (selectedId) => (dispatch) => {
	return AuthService.hyperAdminLogin(selectedId).then(
		(data) => {
			dispatch({
				type: HYPERADMIN_LOGIN_SUCCESS,
				payload: { user: data },
			});
			return Promise.resolve(data);
		},
		(error) => {
			dispatch({
				type: HYPERADMIN_LOGIN_FAIL,
			});

			dispatch({
				type: SET_MESSAGE,
				payload: getErrorMessage(error),
			});

			return Promise.reject();
		},
	);
};


