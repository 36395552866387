
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, InputGroup, Row } from "reactstrap";
import  styles  from "./HomeFooter.module.scss";
import { getNavRoutes } from "side_menu";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as FacebookSVG } from "assets/img/Home/Footer/icon_fb.svg";
import { ReactComponent as InstagramSVG } from "assets/img/Home/Footer/icon_ig.svg";
import { ReactComponent as LinkedInSVG } from "assets/img/Home/Footer/icon_ln.svg";
import { ReactComponent as TikTokSVG } from "assets/img/Home/Footer/icon_tt.svg";
import { ReactComponent as YoutubeSVG } from "assets/img/Home/Footer/icon_yt.svg";
import { ReactComponent as FCASVG } from "assets/img/Home/Footer/logo_footer.svg";
import { ReactComponent as EnquiryFloater } from "assets/img/Home/Footer/enquire.svg";
import { ReactComponent as EnquiryFloaterMob } from "assets/img/Home/Footer/enquire_mob.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupHelper } from "utilities/yupHelpers";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { getErrorMessage } from "utilities/error";
import { toast } from "react-toastify";
import { useDebounceFn } from "ahooks";
import generalApi from "services/general-api";
import ContactUs2 from "../../views/pages/learner/course/ContactUs2";

const schema = yup.object({
	email: yupHelper.email(),
});

const HomeFooter = () => {
	const [openModal, setOpenModal] = React.useState(0);
	const { user: currentUser } = useSelector((state) => state.auth);
	const [loading, setLoading] = useState(false);
	const routes = getNavRoutes();
	let navigate = useNavigate();
	const MySwal = withReactContent(Swal);
	const dispatch = useDispatch();
	const location = useLocation();
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();

	const handleOpenModal = () => {
		setOpenModal(true);
	};
	const handleSuccess = () => {
		setOpenModal(false);
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});


	const handleSubscribeEmail = useDebounceFn(
		(data, event) => {
			event.preventDefault();
			setLoading(true);
			generalApi.subscribeNewsletter(data.email).then(
				(response) => {
					setLoading(false);
					toast.success("Successfully subscribe to our newsletter!");
					event.target.reset();
				},
				(error) => {
					console.log(error);
					setLoading(false);
					toast.error(getErrorMessage(error));
				},
			);
		},
		{ leading: true, trailing: false, wait: 1000 },
	).run;

	return (
		<React.Fragment>
			<div>
				<div className={`${styles.footer_container}`}>
					<div className={styles.logo_container}>
						{/* <img
							alt={"fca-logo"}
							className={styles.footer_logo}
							src={require("assets/img/Home/Footer/logo_footer.svg")}
							href="/"
						/> */}
						<FCASVG className={styles.footer_logo} />

						<div className={styles.logo_title_container}>
							<span className={styles.logo_title}>FirstCom Academy Pte Ltd</span>
							<span className={styles.logo_title}>UEN 201734671R</span>
						</div>
					</div>
					<div className={styles.content_container}>
						<div  className={styles.information_container}>
							<p className={styles.information_title}>Information</p>
							{routes.map((a, index) => (
								<Link to={a.path} key={index} className={styles.information_item}><p className={styles.information_item_text}>{a.name}</p></Link>
							))}
						</div>
						<div  className={styles.social_media_container}>
							<div>
								<p className={styles.social_media_title}>Social Media</p>
								<div>
									<ul className={styles.social_media_logo_ul}>
										<li className={styles.social_media_logo_li}>
											<a className={styles.social_media_item} href="https://www.facebook.com/firstcomacademy?_rdr" target={"_blank"} rel="noreferrer" >
												<div className={styles.social_media_logo_container}>
													<FacebookSVG className={styles.social_media_svg} />
												</div>
												<p className={styles.information_item_text}>{"Facebook"}</p>
											</a>
										</li>
										<li className={styles.social_media_logo_li}>
											<a className={styles.social_media_item} href="https://www.instagram.com/firstcomacademy/?hl=en" target={"_blank"} rel="noreferrer" >
												<div className={styles.social_media_logo_container}>
													<InstagramSVG className={styles.social_media_svg} />
												</div>
												<p className={styles.information_item_text}>{"Instagram"}</p>
											</a>
										</li>
										<li className={styles.social_media_logo_li}>
											<a className={styles.social_media_item} href="https://www.linkedin.com/company/firstcom-academy/" target={"_blank"} rel="noreferrer" >
												<div className={styles.social_media_logo_container}>
													<LinkedInSVG className={styles.social_media_svg} />
												</div>
												<p className={styles.information_item_text}>{"Linkedin"}</p>
											</a>
										</li>
										<li className={styles.social_media_logo_li}>
											<a className={styles.social_media_item} href="https://www.tiktok.com/@firstcomacademy" target={"_blank"} rel="noreferrer" >
												<div className={styles.social_media_logo_container}>
													<TikTokSVG className={styles.social_media_svg} />
												</div>
												<p className={styles.information_item_text}>{"Tiktok"}</p>
											</a>
										</li>
										<li className={styles.social_media_logo_li}>
											<a className={styles.social_media_item} href="https://www.youtube.com/@FirstComAcademy" target={"_blank"} rel="noreferrer" >
												<div className={styles.social_media_logo_container}>
													<YoutubeSVG className={styles.social_media_svg} />
												</div>
												<p className={styles.information_item_text}>{"Youtube"}</p>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div  className={styles.newsletter_container}>
						<p className={styles.newsletter_title}>Newsletter</p>
						<p className={styles.newsletter_desc}>Join our mailing list for updated news and promotions.</p>
						<Form role='form' onSubmit={handleSubmit(handleSubscribeEmail)}>
							<InputGroup className={"input-group-merge"}>
								<input
									className={`form-control ${styles.newsletter_input}`}
									id='input-email'
									placeholder='Enter Your Email'
									type='email'
									disabled={loading}
									{...register("email")}
								/>
							</InputGroup>
							<div className={`justify-content-center mb-4 ${styles.subscribe_button_container}`}>
								<Button className='mt-4 w-100 btn-gradient p-3' type='submit' disabled={loading}>
									{loading && <span className='spinner-border spinner-border-sm'></span>}
									<span>Subscribe Now</span>
								</Button>
							</div>
						</Form>
					</div>
				</div>
			</div>
			{/* {/^\/course\/course-info\//.test(location.pathname) == false ?
				<>
					<div className={styles.enquiry_floater} onClick={handleOpenModal}>
						<EnquiryFloater></EnquiryFloater>
					</div>
					<div className={styles.enquiry_floater_mob} onClick={handleOpenModal}>
						<EnquiryFloaterMob></EnquiryFloaterMob>
					</div>
				</>:null} */}
			<ContactUs2 show={openModal} onSuccess={handleSuccess}></ContactUs2>
			<footer className="border-top py-3" style={{ backgroundColor: "rgb(247, 247, 247)" }}>
				<Container className={`d-flex justify-content-center ${styles.footer_title}`}>
					{`${currentYear}`} &copy; FirstCom Academy . All Rights Reserved.
				</Container>
			</footer>
		</React.Fragment>
	);
};
export default HomeFooter;