
import React from "react";
import { ReactComponent as BackgroundSVG } from "assets/img/Course/reschedule_success/bg_cir.svg";
import { ReactComponent as MainSVG } from "assets/img/Course/reschedule_success/img_main.svg";
import { ReactComponent as StarSVG } from "assets/img/Course/reschedule_success/star.svg";
import { ReactComponent as TickSVG } from "assets/img/Course/reschedule_success/tick.svg";

import styles from "../successpage.module.scss";


const RescheduleSuccessFloating = () => {
	return (
		<div className={`${styles.success_reschedule_container}`}>
			<div className={`${styles.vector_animate_ele} ${styles.vector_animate_wrap}`}>
				<div className={`${styles.item_1} ${styles.scale_animation_1}`}><BackgroundSVG className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_2} `}><MainSVG className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_3} ${styles.moving_horizontal_1}`}><TickSVG className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_4} ${styles.scale_animation_2}`}><StarSVG className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_5} ${styles.scale_animation_3}`}><StarSVG className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_6} ${styles.scale_animation_4}`}><StarSVG className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_7} ${styles.scale_animation_3}`}><StarSVG className={`${styles.banner_1_item_image}`} /></div>
			</div>
		</div>
	);
};
export default RescheduleSuccessFloating;