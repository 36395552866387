import React, { useEffect, useState } from "react";
import { Form, Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
	Col,
	Row,
	Card,
	CardBody,
	FormGroup,
	Button,
	Nav,
	NavItem,
	TabContent,
	TabPane,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import generalApi from "services/general-api";
import moment from "moment";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { getErrorMessage } from "utilities/error";

// const schema = yup.object({
// 	// remarks: yup.string().required(),
// 	// remarks: yup.string().required(),
// 	// remarks: yup.string().required(),
// });

function ModalMealSelection() {
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");
	const [dateString, setDateString] = useState("");
	const [mealDetails, setMealDetails] = useState({});
	const [selectedMeals, setSelectedMeals] = React.useState([]);
	const [meals, setMeals] = React.useState(undefined);
	let navigate = useNavigate();

	const MySwal = withReactContent(Swal);
	const location = useLocation();
	const learner_id = location.state?.learner_id;
	const course = location.state?.course;
	const status = location.state?.status;

	// State for current active Tab
	const [currentActiveTab, setCurrentActiveTab] = useState(0);

	// Toggle active state for Tab
	const toggle = (tab) => {
		if (currentActiveTab !== tab) setCurrentActiveTab(tab);
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const getDate = (sessions) => {
		let sessionString = "";
		if (sessions && sessions.length > 0) {
			sessions.map((session) => {
				sessionString = sessionString.concat(
					`- ${moment(session.date, "YYYY-MM-DD").format("DD MMM YYYY")} (${moment(
						session.sessionFrom,
						"HH:mm:ss",
					).format("hh:mm a")} - ${moment(session.sessionto, "HH:mm:ss").format("hh:mm a")}) \n`,
				);
			});
		} else {
			return "-";
		}
		return sessionString;
	};
	
	useEffect(() => {
		generalApi.getMealDetails(learner_id).then(
			(response) => {
				setMeals(response.data.options || undefined);
				setMealDetails(response.data);
				setDateString(getDate(response.data.sessions));
				if (response.data && response.data.options.length > 0) {
					let selectedOptions = [];
					response.data.options.map((option) => {
						selectedOptions.push({
							date: option.date || "",
							food_id:
							option.Lunch && option.Lunch.selected
								? option.Lunch.selected.food_id.toString()
								: null,
							breakfast_food_id:
							option.Breakfast && option.Breakfast.selected
								? option.Breakfast.selected.food_id.toString()
								: null,
							isLunch: option.Lunch ? true : false,
							isBreakfast: option.Breakfast ? true : false,
							option_title:
							option.Lunch && option.Lunch.selected
								? option.Lunch.selected.food_type_option
								: null,
							alternative_display_name:
							option.Lunch && option.Lunch.alternative_display_name
								? option.Lunch.alternative_display_name
								: null,

							breakfast_option_title:
							option.Breakfast && option.Breakfast.selected
								? option.Breakfast.selected.food_type_option
								: null,
							breakfastfoodmenus:
							option.Breakfast && option.Breakfast.selected
								? option.Breakfast.selected.foodmenus
								: null,
							foodmenus:
							option.Lunch && option.Lunch.selected
								? option.Lunch.selected.foodmenus
								: null,
							option: option,
						});
					});
					setSelectedMeals(selectedOptions);
				}

			},
			(error) => {
				navigate("/course");
			},
		);
	}, []);

	const handleSaveMeal = (data) => {
		setLoading(true);
		var next_step = true;
		setMessage("");

		if (data.selections && data.selections.length > 0) {
			for (let i = 0; i < data.selections.length; i++) {
				if (mealDetails.options[i].Lunch !== null && data.selections[i] && data.selections[i].food_id == null) {
					next_step = false;
					setMessage("Lunch Selection for Day " + (i + 1) + " is required.");
					setCurrentActiveTab(i);
					setLoading(false);
					break;
				}
				if (mealDetails.options[i].Breakfast !== null && data.selections[i] && data.selections[i].breakfast_food_id == null) {
					next_step = false;
					setMessage("Breakfast Selection for Day " + (i + 1) + " is required.");
					setCurrentActiveTab(i);
					setLoading(false);
					break;
				}
			}

			if (next_step) {
				const request_selection = data.selections.filter((element) => {
					if (Object.keys(element).length !== 0) {
						return true;
					}
					return false;
				});
				generalApi
					.saveMeal(learner_id, request_selection, data.remarks)
					.then(
						(response) => {
							// setLoading(false);
							navigate("/course/meal-success-page", {
								state: { data: { course: course, selection: selectedMeals, remark: data.remarks } },
							});
							// MySwal.fire({
							// 	title: "Congratulations!",
							// 	html: <p>Meal Selection Update Successfully.</p>,
							// 	icon: "success",
							// 	// didOpen: () => {
							// 	// 	// `MySwal` is a subclass of `Swal` with all the same instance & static methods
							// 	// 	MySwal.showLoading();
							// 	// },
							// }).then(() => {
							// 	navigate("/course/course-details/", {
							// 		state: { data: course, status: status },
							// 	});
							// });
						},
						(error) => {
							setLoading(false);
							setMessage(getErrorMessage(error));

						},
					)
					.catch(() => {
						setLoading(false);
					});
			}
		}
	};

	const handlePopup = (displayMSG) => {
		MySwal.fire({
			title: "Unable to Proceed!",
			html: displayMSG,
			icon: "error",
			// icon: "warning",
			// didOpen: () => {
			// 	// `MySwal` is a subclass of `Swal` with all the same instance & static methods
			// 	MySwal.showLoading();
			// },
		});
	};

	const selectMeal = (dayIndex, mealIndex) => {
		let newSelectedMeals = [...selectedMeals];
		newSelectedMeals[dayIndex].food_id =
		(meals &&
		meals[currentActiveTab].Lunch.food_options[mealIndex].food_id.toString()) ||
		null;
		newSelectedMeals[dayIndex].option_title =
		(meals &&
		meals[currentActiveTab].Lunch.food_options[mealIndex].food_type_option) ||
		null;
		newSelectedMeals[dayIndex].foodmenus =
		(meals && meals[currentActiveTab].Lunch.food_options[mealIndex].foodmenus) ||
		null;

		setSelectedMeals(newSelectedMeals);
	};

	const selectBreakfastMeal = (dayIndex, mealIndex) => {
		let newSelectedMeals = [...selectedMeals];
		newSelectedMeals[dayIndex].breakfast_food_id =
		(meals && meals[currentActiveTab].Breakfast.food_options[mealIndex].food_id.toString()) || null;
		newSelectedMeals[dayIndex].breakfast_option_title =
		(meals && meals[currentActiveTab].Breakfast.food_options[mealIndex].food_type_option) || null;
		newSelectedMeals[dayIndex].breakfastfoodmenus =
		(meals && meals[currentActiveTab].Breakfast.food_options[mealIndex].foodmenus) || null;
	
		setSelectedMeals(newSelectedMeals);
	};

	return (
		<>
			<div className='reschedule_main_container'>
				<div className='title-container'>
					<span className="fs40 text-fca-dark font_2">Meal Selection</span>
				</div>
				<div>
					<Card className='mt-4'>
						<CardBody>
							<div>
								<p className="fs14 text-fca-dark">
									{"Please select your meal selection and indicate if you have any dietary requirements."}
								</p>
							</div>
							<hr></hr>
							<Form role='form' onSubmit={handleSubmit(handleSaveMeal)}>
								<div>
									<Row>
										<Col md='12' xl='6'>
											<FormGroup>
												<label className='fs14 text-fca-dark font-weight-600' htmlFor='input-courseName'>
													{"Course Name"}
												</label>
												<input
													className={"form-control "}
													defaultValue={mealDetails.course_title}
													disabled={true}
													id='input-courseName'
													type='text'
												/>
											</FormGroup>
										</Col>
										<Col md='12' xl='6'>
											<FormGroup>
												<label className='fs14 text-fca-dark font-weight-600' htmlFor='input-learnerName'>
													{"Learner Name"}
												</label>
												<input
													className={"form-control "}
													defaultValue={mealDetails.learner_name}
													disabled={true}
													id='input-learnerName'
													type='text'
												/>
											</FormGroup>
										</Col>
										<Col md='12' xl='12'>
											<FormGroup>
												<label className='fs14 text-fca-dark font-weight-600' htmlFor='input-courseDate'>
													{"Date"}
												</label>
												<textarea
													className={"form-control "}
													defaultValue={dateString}
													disabled={true}
													id='input-courseDate'
													type='text'
													rows='5'
												/>
											</FormGroup>
										</Col>
										<Col md='12' xl='12'>
											<FormGroup>
												<label className='fs14 text-fca-dark font-weight-600' htmlFor='input-remarks'>
													{"Additional Remarks"}
												</label>
												<textarea
													className={"form-control " + (errors.remarks ? "is-invalid" : "")}
													id='input-remarks'
													defaultValue={mealDetails.food_remark}
													placeholder='Eg. Allergic to seafood'
													rows='5'
													{...register("remarks")}
												/>
											</FormGroup>
										</Col>
									</Row>
								</div>

								{mealDetails && mealDetails.options && mealDetails.options.length > 0 && (
									<div>
										<Row className='align-items-center pb-5 pt-2 meal-selection-tab-row'>
											<Col className='w-20 flex-unset'>
												<label className='fs14 text-fca-dark font-weight-600'>{"Meal Selections *"}</label>
											</Col>
											<Col className='w-80 flex-unset'>
												<Nav tabs className='meal-selection-tab-nav-wrap'>
													{mealDetails.options.map((option, i) => (
														<NavItem key={i}>
															<span
																className={classNames({
																	active: currentActiveTab === i,
																})}
																onClick={() => {
																	toggle(i);
																}}
															>
																{`Day ${i + 1}`}
															</span>
														</NavItem>
													))}
												</Nav>
											</Col>
										</Row>
										<div className='meal-selection-tab-content-wrap'>
											{mealDetails.options.map((day, i) => (
												<div key={i}>
													<TabContent activeTab={currentActiveTab}>
														<TabPane tabId={i}>
															<div className='day-wrap mx--3'>
																<input
																	type='hidden'
																	defaultValue={day.date}
																	{...register(`selections.${i}.date`)}
																></input>
																{day && day.Breakfast && day.Breakfast.food_options && day.Breakfast.food_options.length > 0 && (
																	<div className='lunch-wrap pt-5'>
																	
																		<div className='title-wrap mx-2'>
																			<div className='title'>
																				{day.Breakfast.alternative_display_name ? `${day.Breakfast.alternative_display_name} Selection (Please Select One)`
																					: "Breakfast"}{" "}
																				{"Selection (Please Select One)"}
																			</div>
																		</div>
																		<div className='option-wrap flex pt-3'>
																			{day.Breakfast.food_options.map((food_option, j) => (
																				<label key={j} className='d-block mx-2 mb-2'>
																					<input
																						type='radio'
																						defaultChecked={
																							day.Breakfast.selected?.food_id === food_option.food_id
																								? true
																								: false
																						}
																						{...register(`selections.${i}.breakfast_food_id`)}
																						value={food_option.food_id}
																						onChange={() => selectBreakfastMeal(currentActiveTab, j)}
																					/>
																					<div className='option-box'>
																						<div className='option-title pb-2'>
																							<div className='title'>
																								{food_option.food_type_option}
																							</div>
																						</div>
																						<div className='option-content'>
																							<ul className='list-unstyled'>
																								{food_option.foodmenus.map((food, k) => (
																									<li key={k}>
																										<div className='food_name'>
																											{`${food.food_name_en} (${food.food_name_cn})`}
																										</div>
																										{food.food_allergern && (
																											<div className='food_allergern'>
																												{`Allergen: ${food.food_allergern}`}
																											</div>
																										)}
																										<div className='food_ingredient'>
																											{food.food_ingredient}
																										</div>
																									</li>
																								))}
																							</ul>
																						</div>
																					</div>
																				</label>
																			))}
																		</div>
																	</div>
																)}

																{day && day.Lunch && day.Lunch.food_options && day.Lunch.food_options.length > 0 && (
																	<div className='lunch-wrap pt-5'>
																		
																		<div className='title-wrap mx-2'>
																			<div className='title'>
																				{day.Lunch.alternative_display_name
																					? day.Lunch.alternative_display_name
																					: "Lunch"}{" "}
																				{"Selection (Please Select One)"}
																			</div>
																		</div>
																		<div className='option-wrap flex pt-3'>
																			{day.Lunch.food_options.map((food_option, j) => (
																				<label key={j} className='d-block mx-2 mb-2'>
																					<input
																						type='radio'
																						defaultChecked={
																							day.Lunch.selected?.food_id === food_option.food_id
																								? true
																								: false
																						}
																						{...register(`selections.${i}.food_id`)}
																						value={food_option.food_id}
																						onChange={() => selectMeal(currentActiveTab, j)}
																					/>
																					<div className='option-box'>
																						<div className='option-title pb-2'>
																							<div className='title'>
																								{food_option.food_type_option}
																							</div>
																						</div>
																						<div className='option-content'>
																							<ul className='list-unstyled'>
																								{food_option.foodmenus.map((food, k) => (
																									<li key={k}>
																										<div className='food_name'>
																											{`${food.food_name_en} (${food.food_name_cn})`}
																										</div>
																										{food.food_allergern && (
																											<div className='food_allergern'>
																												{`Allergen: ${food.food_allergern}`}
																											</div>
																										)}
																										<div className='food_ingredient'>
																											{food.food_ingredient}
																										</div>
																									</li>
																								))}
																							</ul>
																						</div>
																					</div>
																				</label>
																			))}
																		</div>
																	</div>
																)}

																{day && day["Afternoon Tea"] && day["Afternoon Tea"].food_options && day["Afternoon Tea"].food_options.length > 0 && (
																	<div className='tea-break-wrap mx-2 pt-5'>
																		<div className='flex title-wrap align-items-center'>
																			<div className='img-container'>
																				<img
																					className='img-fluid'
																					alt='...'
																					src={require("assets/img/Course/bus_meal/pm.png")}
																				/>
																			</div>
																			<div className='text-container'>
																				<h5>
																					{day["Afternoon Tea"].food_options[0].food_type_option}
																				</h5>
																			</div>
																		</div>
																		<div className='content'>
																			<div className='content-wrap'>
																				<ul className='list-unstyled'>
																					{day["Afternoon Tea"].food_options[0].foodmenus.map(
																						(food, i) => (
																							<li key={i}>
																								<div className='food_name'>
																									{`${food.food_name_en} (${food.food_name_cn})`}
																								</div>
																								{food.food_allergern && (
																									<div className='food_allergern'>
																										{`Allergen: ${food.food_allergern}`}
																									</div>
																								)}
																								<div className='food_ingredient'>
																									{food.food_ingredient}
																								</div>
																							</li>
																						),
																					)}
																				</ul>
																			</div>
																		</div>
																	</div>
																)}
															</div>
														</TabPane>
													</TabContent>
												</div>
											))}
										</div>

										<div className='notes pt-4'>
											{"*No egg, onion, garlic and mock meat for vegetarian selection."}
										</div>
									</div>
								)}

								<Row>
									{message && (
										<Col md='12' xl='12' className='mt-6'>
											<div
												className='alert alert-danger'
												role='alert'
												dangerouslySetInnerHTML={{ __html: message }}
											></div>
										</Col>
									)}
									<Col md='12' xl='12' className='mt-6'>
										<div className='flex mb-4'>
											<Link
												to='/course/course-details/'
												state={{ data: course, status: status }}
												className='btn btn-default ml-auto'
											>
												{"Back"}
											</Link>

											{!course.cannot_select_food_reason ? (
												<Button className='btn-gradient' type='submit' disabled={loading}>
													{loading && <span className='spinner-border spinner-border-sm'></span>}
													<span>{"Submit"}</span>
												</Button>
											) : (
												<Button
													className='btn-gradient opacity-6'
													onClick={() => handlePopup(course.cannot_select_food_reason)}
												>
													<span>{"Submit"}</span>
												</Button>
											)}
										</div>
									</Col>
								</Row>
							</Form>
						</CardBody>
					</Card>
				</div>
			</div>
		</>
	);
}

export default ModalMealSelection;
