import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./successpage.module.scss";

import { Modal, Card, CardBody, CardHeader, Row, Col, Container } from "reactstrap";

import { useSelector } from "react-redux";
import moment from "moment";

import { ReactComponent as SuccessIcon } from "assets/img/Course/registration/success.svg";
import SuccessCourseInfo from "components/LearnerPortal/SuccessCourseInfo";
import RescheduleSuccessFloating from "views/pages/learner/course/components/RescheduleSuccessFloating";

function SuccessPage() {
	const [loading, setLoading] = useState(false);
	const [modalChange, setModalChange] = useState(true);

	const courseRegisterData = useSelector((state) => state.courseRegisterSlice);

	const courseData = courseRegisterData.course;
	const courserunData = courseRegisterData.courserun;

	const location = useLocation();
	const data = location.state?.data;
	const course = data.course;
	let navigate = useNavigate();

	useEffect(() => {
		// 	if (
		// 		Object.keys(courseRegisterData.course).length === 0 ||
		//   Object.keys(courseRegisterData.courserun).length === 0
		// 	) {
		// 		navigate("/course");
		// 	}
	}, []);

	return (
		<>
			<Container className=''>
				{Object.keys(course).length !== 0 && (
					<div className=''>
						<div className={`${styles.successContainer}`}>
							<RescheduleSuccessFloating />
							
							{data.type === "reschedule" &&
								<>
									<span className={styles.success_title_text}>
										{"Congratulations! You have successfully rescheduled for the course below"}
									</span>
									<span className={styles.success_desc_text}>
										{"You will receive an email notification shortly. Our Student Services Executive will also be in contact with you prior to the class to assist you with the preparation."}
									</span>
								</>
							}
							{data.type === "withdrawal" &&
								<>
									<span className={styles.success_title_text}>
										{`We have received your ${data.type} request`}
									</span>
									
									<span className={styles.success_desc_text}>
										{"You will receive an email notification shortly. Our Student Services Executive will also be in contact with you soon"}
									</span>
								</>
							}
						</div>
						<Card className='mt-5' style={{ boxShadow: "none" }}>
							<CardBody>
								<SuccessCourseInfo
									course_title={course.course.course_title}
									course_logos={course.course.logos}
									trainer_name={course.trainer?.trainer_name}
									trainer_image_url={course.trainer?.trainer_image_url}
									course_duration={course.duration}
									course_categories={course.course.categories}
									course_ref={course.course.course_ref}
									courserun_modeoftraining={course.courserun_modeoftraining}
									venue_address={course.venue.venue_address}
									training_support_name={course.training_support?.user_name}
									training_support_mobile={course.training_support?.user_mobile}
									courserun_course_from={course.courserun_course_from}
									courserun_course_to={course.courserun_course_to}
								/>
								<hr className="my-3"/>
								<Row className='align-items-center'>
									<Col xl='auto' md='auto' xs='auto' className='p-2 ml-auto'>
										<div className='modal-footer justify-content-center p-0'>
											<Link to='/course' className='btn btn-gradient'>
												Back To Home Page
											</Link>
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</div>
				)}
			</Container>
		</>
	);
}

export default SuccessPage;
