import React, { useState } from "react";
import {
	Button,
	Row,
	Col,
	Modal,
} from "reactstrap";
import classNames from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
	GTMCheckoutUserLoginSuccess,
	logGTMUser,
} from "utilities/googleTagManager";
import { useCheckout } from "hooks/selectors/useCheckout";

import EmbeddedResetPasswordModal from "components/EmbeddedResetPasswordModal";
import HeroImage from "assets/img/Video/upskilling100/gifts-square.png";
import styles from "./LoginModal.module.scss";
import { LoginFormWithAccountType } from "components/LoginFormWithAccountType";
import { Link, useLocation } from "react-router-dom";


export function LoginModal(props) {
	const location = useLocation();
	const fullPath = `${location.pathname}${location.search ||""}`;

	// const [passwordType, setPasswordType] = useState("password");
	const lg = useMediaQuery("(min-width: 992px)");
	// const [showForgetPassword, setShowForgetPassword] = useState(false);
	const { checkoutAnalyticsData } = useCheckout();

	// const handleLogin = () => {};

	const handleLoginSucess = (data) => { 
		props.toggle();
		props.onSuccess(data);
		logGTMUser(
			data.user.learnermaster_id ? data.user.learnermaster_id.toString() : "",
			data.user.learnermaster_name ? data.user.learnermaster_name.toString() : "",
			data.user.learnermaster_dob ? data.user.learnermaster_dob : "",
			data.user.learnermaster_nationality ? data.user.learnermaster_nationality : "",
			data.user.learnermaster_gender ? data.user.learnermaster_gender : "",
		);
		GTMCheckoutUserLoginSuccess(checkoutAnalyticsData, data);
	};


	return (
		<>
			<Modal size='xl' className='modal-dialog-centered' {...props}>
				{/* <EmbeddedResetPasswordModal
					isOpen={showForgetPassword}
					onSuccess={(data) => {
						setTimeout(() => {
							toast.success("Password reset successfully 🎉");
						}, 500);
						GTMCheckoutUserForgetPasswordSuccess(checkoutAnalyticsData);
						setShowForgetPassword(false);
					}}
					toggle={() => {
						setShowForgetPassword(!showForgetPassword);
					}}
				/> */}
				<div
					style={{ position: "absolute", right: 10, top: 10, zIndex: 10000 }}
					onClick={props.toggle}>
					<i className='fa fa-times' aria-hidden='true'></i>
				</div>

				<Row className='mr-0 ml-0'>
					{lg && (
						<Col xs={12} lg={6} className='pl-0 pr-0'>
							<img src={HeroImage} className={styles.login_modal_img} />
						</Col>
					)}

					<Col xs={12} lg={6} className={classNames(styles.login_modal_col)}>
						<div style={{
							overflowX:"hidden",
						}}>
							<h2 className={styles.login_modal_header}>
								<span className={styles["hightlight-text"]}>Sign in </span> or <span className={styles["hightlight-text"]}>Create an account</span>  for us to contact you if you win the prize. 
								You can choose to fill in your details or register using your Singpass to
								vote for your favourite learner.
							</h2>

							<LoginFormWithAccountType 
								redirect_url={fullPath}
								onSuccess={handleLoginSucess}
							/>
							<div className='text-center'>
								<span>
									Don't have an account?
									<Link to='/register' className='text-blue bold ml-1' state={{redirect_url:fullPath}}>
										Create new account
									</Link>
								</span>
							</div>
						</div>
					</Col>
				</Row>
			</Modal>
		</>
	);
}
