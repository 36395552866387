import React, { useState, useEffect } from "react";
import {
	FormGroup,
	Label,
} from "reactstrap";
// import CreatableSelect from "react-select/creatable";
import Select, {components} from "react-select";
import generalApi from "services/general-api";
import { useCheckout } from "hooks/selectors/useCheckout";

const CourseConsultantCode = (props) => {
	const [ccCode, setccCode] = useState("");
	const [ccOptions, setccOptions] = useState([]);
	const { checkoutAnalyticsData } = useCheckout();

	const handleInputChange = (inputValue) => {
		if (inputValue.length >= 2) {
			generalApi
				.checkCCC({
					ccc: inputValue,
				})
				.then((cccObject) => {
					// Assuming the response from the API contains ccCode and textccCode
					const ccCodeFromApi = cccObject.ccCode;
					setccCode(ccCodeFromApi);
					// Set options based on API response
					const userOptions = cccObject.data.user_list.map((user) => ({
						label: user,
						value: user,
					}));
					setccOptions(userOptions);
				});
		} else {
			// Clear options when input length is less than 2 characters
			setccOptions([]);
		}
	};

	useEffect(() => {
		if (checkoutAnalyticsData && checkoutAnalyticsData.CCC) {
			setccCode(checkoutAnalyticsData.CCC);
		} else {
			setccCode("");
		}
	}, [checkoutAnalyticsData]);

	return (
		<FormGroup className='mb-0'>
			<Label>Course Consultant Code (Optional)</Label>
			<Select
				placeholder="Enter Code"
				isSearchable
				value={ccCode ? { label: ccCode, value: ccCode } : null}
				isClearable
				// ... other props
				onChange={(e) => {
					console.log(e);
					props.onChange(e ? e.value : null);
					setccCode(e ? e.value : null);
				}}
				formatCreateLabel={(inputValue) => inputValue}
				menuIsOpen={ccOptions.length > 0}
				components={{
					DropdownIndicator: null,
					ClearIndicator: components.ClearIndicator,
				}}
				styles={{
					singleValue: (base) => ({
						...base,
						padding: "2px 5px",
						borderRadius: 5,
						backgroundColor: "#efefef",
						display: "flex",
					}),
				}}
				onInputChange={handleInputChange}
				options={ccOptions}
			/>
		</FormGroup>
	);
};

export default CourseConsultantCode;
