import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ReactGA from "react-ga4";
import { logGTMUser, logGTMUserID } from "utilities/googleTagManager";
import TagManager from "react-gtm-module";

const Analytics = () => {
	const { user: currentUser } = useSelector((state) => state.auth);
	const tagManagerArgs = {
		gtmId: process.env.REACT_APP_GTM_ID,
	};


	useEffect(() => {
		// Initialize GTM
	
		TagManager.initialize(tagManagerArgs);

		if (currentUser && currentUser.user) {
			logGTMUser(
				currentUser.user.learnermaster_id ? currentUser.user.learnermaster_id.toString() : "",
				currentUser.user.learnermaster_name ? currentUser.user.learnermaster_name.toString() : "",
				currentUser.user.learnermaster_dob ? currentUser.user.learnermaster_dob : "",
				currentUser.user.learnermaster_nationality ? currentUser.user.learnermaster_nationality : "",
				currentUser.user.learnermaster_gender ? currentUser.user.learnermaster_gender : "",
			);
			// 	console.log("test", currentUser.user);
		
		}

		//Initialize GA4
		// if (!currentUser) {
		// 	ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID,{
		// 		gaOptions: {
		// 			debug_mode: true,
		// 		},
		// 		gtagOptions: {
		// 			debug_mode: true,
		// 		},
		// 	});
		// } else {
		// 	ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID, {
		// 		gaOptions: {
		// 			debug_mode: true,
		// 			userId: currentUser && currentUser.user ? currentUser.user.learnermaster_id : "",
		// 		},
		// 		gtagOptions: {
		// 			debug_mode: true,
		// 		},
		// 	});
		// }
	}, []);
	
};

export default Analytics;
