import React, { useState} from "react";
// reactstrap components
import { Button, CardBody, Col, Row, Form, FormGroup} from "reactstrap";
import { useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
	Card,
} from "reactstrap";
import { getErrorMessage } from "utilities/error";
import generalApi from "services/general-api";
import "../../../../../assets/scss/custom/_inner-html.scss";
import { useSelector } from "react-redux";

import * as yup from "yup";
import { yupHelper } from "utilities/yupHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import "./ReferralContactUsModal.css";
import { useCheckout } from "hooks/selectors/useCheckout";

const schema = yup.object({
	name: yupHelper.fullname().required(),
	email: yupHelper.emailOptional(),
	mobileno: yupHelper.mobile().required(),
	remarks: yup.string(),
	// consent: yup
	// 	.boolean()
	// 	.oneOf([true], "You must agree to the Privacy Policy")
	// 	.required("You must agree to the Privacy Policy"),
	referee_consent: yup
		.boolean()
		.oneOf([true], "You must agree to the referee consent.")
		.required("You must agree to the referee consent"),
	// learning_intention: yupHelper.learning_intention().required()
});

function IndividualForm(props) {
	const [isButtonLoading, setIsButtonLoading] = React.useState(false);
	const [isError, setIsError] = React.useState(false);
	const [isSuccess, setSuccess] = React.useState(false);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");
	const { user: userData } = useSelector((state) => state.auth);
	const MySwal = withReactContent(Swal);
	const currentUser = userData? userData.user :false;
	const { ts_ccc=null } = props;
	
	let navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});
	
	const handleContactUs = (data) => {
		setLoading(true);
		setMessage("");
		data.lead_type = "ALUMNI REFERRAL";
		data.lead_url = window.location.href;
		data.invite = currentUser.invite_code;
		data.ts_ccc = ts_ccc ;
		generalApi
			.ContactUsSubmitForm2(data)
			.then(
				(response) => {
					// setLoading(false);
					data.course_id = null;
					data.courserun_id = null;
					data.campaign_type = "ALUMNI REFERRAL";
					data.referral = null;
					data.invite = currentUser.invite_code;
					data.utm_campaign = null;
					data.utm_content = null;
					data.utm_medium = null;
					data.utm_source = null;
					data.utm_term = null;
					data.ccc = null;
					data.lead_id = response.data.id;

					generalApi
						.SubmitBigBangLeadConversionLog(data)
						.then(
							(response) => {
							},
							(error) => {
							},
						)
						.catch(() => {
						});

					MySwal.fire({
						title: "Thank you for your referral",
						html: <p>We will contact them soon!</p>,
						icon: "success",
					}).then(() => {
						props.onSuccess(true);
					});
				},
				(error) => {
					setLoading(false);
					setMessage(getErrorMessage(error));
				},
			)
			.catch(() => {
				setLoading(false);
			});
	};

	return (
		<div className="form_content" id={"EventContainer"}>
			{isButtonLoading && (
				<div className='justify-content-center mt-4 w-100 flex'>
					<span className='spinner-border spinner-border-sm'></span>
				</div>
			)}
			{!isButtonLoading && !isError && (
				<div className="individual-contact-form">
					<Card className='mt-4'>
						<CardBody className='p-0'>
							<Form role='form' id="individual-contact-form" onSubmit={handleSubmit(handleContactUs)}>
								<input
									type="hidden"
									name="form_type" 
									value="individual" 
									{...register("form_type")}
								/>
								<Row>
									<Col lg='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-fullname'>
												{"Name"}
											</label>
											<input
												className={"form-control " + (errors.name ? "is-invalid" : "")}
												id='input-fullname'
												placeholder='Enter Name'
												defaultValue={""}
												type='text'
												{...register("name")}
											/>
											<div className='invalid-feedback'>{errors.name?.message}</div>
										</FormGroup>
									</Col>
									<Col lg='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-mobileNo'>
												{"Contact No."}
											</label>
											<input
												className={"form-control " + (errors.mobileno ? "is-invalid" : "")}
												id='input-mobileNo'
												placeholder='Enter Contact Number'
												defaultValue={""}
												type='text'
												{...register("mobileno")}
											/>
											<div className='invalid-feedback'>{errors.mobileno?.message}</div>
										</FormGroup>
									</Col>

									<Col lg='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-email'>
												{"Email"} <span>({"optional"})</span>
											</label>
											<input
												className={"form-control " + (errors.email ? "is-invalid" : "")}
												id='input-email'
												placeholder='Enter Email address'
												defaultValue={""}
												type='email'
												{...register("email")}
											/>
											<div className='invalid-feedback'>{errors.email?.message}</div>
										</FormGroup>
									</Col>
									{/* <Col lg='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-learning-intention'>
												{"Learning Intention"}
											</label>
											<select
												className={"form-control " + (errors.learning_intention ? "is-invalid" : "")}
												id='input-learning-intention'
												{...register("learning_intention", { required: true })}
											>
												<option value="">-- Select your learning intention --</option>
												<option value="For general knowledge">For general knowledge</option>
												<option value="To increase my business revenue">To increase my business revenue</option>
												<option value="To start my own business / looking for a side hustle">To start my own business / looking for a side hustle</option>
												<option value="To upskill and increase career progression">To upskill and increase career progression</option>
												<option value="Planning to make a career switch">Planning to make a career switch</option>
											</select>
											<div className='invalid-feedback'>{errors.learning_intention?.message}</div>
										</FormGroup>
									</Col> */}

									<Col md='12' xl='12'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-remarks'>
												{"Remarks"} <span>({"optional"})</span>
											</label>
											<textarea
												className={"form-control " + (errors.remarks ? "is-invalid" : "")}
												id='input-remarks'
												placeholder='Remarks'
												rows='5'
												{...register("remarks")}
											/>
											<div className='invalid-feedback'>{errors.remarks?.message}</div>
										</FormGroup>
									</Col>

									<Col md='12' xl='12'>
										<p>
											<h5>Term & Conditions*</h5>
											<ul>
												<li> Every unique Referee that is referred shall entitle the Referral for the referral reward only once.<br/> </li>
												<li> Processing of referral gift may take up to one month upon the successful completion of all courses that each Referee has signed up with us. <br/> </li>
												<li> Rescheduling of course dates by the Referees may occur, and you may contact help@fca.edu.sg to enquire on the course completion status of your Referees.<br/> </li>
												<li> The Referrer is responsible for utilising the reward before the stated expiry date (if any). There will be no re-issuing of expired gift cards or vouchers.<br/> </li>
												<li>  In the event that the stated reward is unable to be procured, the Referrer will receive a substitute reward of similar or equal value at FirstCom Academy's discretion subject to these Terms and Conditions.<br/> </li>
											</ul>
										</p>
										<FormGroup>
											<div className='custom-control custom-checkbox'>
												<input
													className='custom-control-input'
													type='checkbox'
													id='input-referee-consent'
													style={{width: "100%", zIndex: 2, height: "100%", cursor: "pointer"}}
													{...register("referee_consent")}
												/>
												<label
													className='consent-label custom-control-label h-auto'
													htmlFor='input-referee-consent'
												>
													{"By submitting this form, I confirm that I have notified the referees and obtained their permission to provide their details to FirstCom Academy Pte. Ltd."}
												</label>
											</div>

											<div
												className={"invalid-feedback " + (errors.referee_consent ? "d-block" : "d-none")}
											>
												{errors.referee_consent?.message}
											</div>
										</FormGroup>										
									</Col>

									<Col md='12' xl='12'>
										{message && (
											<div className='form-group'>
												<div
													className='alert alert-danger'
													role='alert'
													dangerouslySetInnerHTML={{ __html: message }}
												></div>
											</div>
										)}
									</Col>
									<Col md='12' xl='12'>
										<div className='flex justify-content-end contact-submit mb-4 mt-4 pr-4'>
											<Button className='btn-gradient' type='submit' disabled={loading}>
												{loading && <span className='spinner-border spinner-border-sm'></span>}
												<span>Send</span>
											</Button>
										</div>
									</Col>
								</Row>
							</Form>
						</CardBody>
					</Card>
				</div>
			)}
		</div>
	);
}

export default IndividualForm;
