
import React, { useEffect, useRef, useState } from "react";
import { Button, Container } from "reactstrap";
import Slider from "react-slick";
import girl1Image from "assets/img/Login/Girl1/gal01.png";
import CongraCreateAccountImage from "assets/img/Checkout/congra-create-account.png";
import { ReactComponent as GuySVG } from "assets/img/Checkout/AccountCreation/register_guy.svg";
import { ReactComponent as PencilSVG } from "assets/img/Checkout/AccountCreation/register_pencil.svg";
import { ReactComponent as BackgroundSVG } from "assets/img/Checkout/AccountCreation/bg_circle.svg";
import { ReactComponent as BookSVG } from "assets/img/Checkout/AccountCreation/register_book.svg";
import { ReactComponent as ClockSVG } from "assets/img/Checkout/AccountCreation/24hour-icon.svg";
import { ReactComponent as HatSVG } from "assets/img/Checkout/AccountCreation/register_hat.svg";
import { ReactComponent as StarSVG } from "assets/img/Checkout/AccountCreation/register_star.svg";
import { ReactComponent as PaperSVG } from "assets/img/Checkout/AccountCreation/register_paper.svg";
import Lottie from "react-lottie";
import * as fireAnimation from "assets/img/Checkout/AccountCreation/fire1.json";
import * as fireAnimation2 from "assets/img/Checkout/AccountCreation/fire2.json";

import { debounce } from "lodash";
import styles from "../checkoutregsiterpage.module.scss";


const CreateAccountFloating = () => {
	const defaultOptions = {
		loop: true,
		autoplay: true, 
		animationData: fireAnimation2,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice"
		}
	};

	return (
		<div className={`${styles.success_account_container}`}>
			<div className={`${styles.vector_animate_ele} ${styles.vector_animate_wrap}`}>
				<div className={`${styles.item_1} ${styles.scale_animation_1}`}><BackgroundSVG className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_2} `}><GuySVG className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_3}`}><PencilSVG className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_4} ${styles.moving_horizontal_3}`}><PaperSVG className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_5} ${styles.moving_vertical_1}`}><ClockSVG className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_8} ${styles.star_animation1}`}><StarSVG className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_9} ${styles.star_animation2}`}><StarSVG className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_10} ${styles.star_animation3}`}><StarSVG className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_11}`}><Lottie className={styles.banner_1_item_image} options={defaultOptions} /></div>
				<div className={`${styles.item_12} ${styles.star_animation4}`}><StarSVG className={`${styles.banner_1_item_image}`} /></div>
			</div>
		</div>
	);
};
export default CreateAccountFloating;