/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import { Outlet, Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";

// reactstrap components
import { Row, Col, Button } from "reactstrap";
// core components
import styles from "assets/scss/module/auth/login.module.scss";
import { analytics } from "utilities/firebase";
import { logEvent } from "@firebase/analytics";
import axios from "axios";
import { getErrorMessage } from "utilities/error";
import { resetUser } from "actions/auth";
import { logGTMScreen, removeLogGTMUser } from "utilities/googleTagManager";
import LoginGirl1Floating from "views/pages/auth/Floating/LoginGirl1Floating";
import LoginGirl2Floating from "views/pages/auth/Floating/LoginGirl2Floating";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import LoginGirl1FloatingBigger from "views/pages/auth/Floating/LoginGirl1FloatingBigger";
// import UpskillingBackground from "assets/img/Video/upskilling100/register-background.jpg";
// import UpskillingTrophy from "assets/img/Video/upskilling100/regsiter-trophy.png";
import Upskilling100Bacground from "assets/img/Video/upskilling100/upskilling100_banner_registration_background.png";
import Upskilling100Text from "assets/img/Video/upskilling100/upskilling100_banner_registration_text.png";
import { useUpskilling100Flag } from "hooks/useUpskilling100Flag";

function AuthLayout(props) {
	// make sure search param is not lost, during navigation
	const { mobileAlwaysShown } = props;
	const search = window.location.search.replace(/^(\?+)/, "");
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const isMobileMode = useMediaQuery("(max-width:991px)");
	const { upskilling100Flag } = useUpskilling100Flag();

	useEffect(() => {
		// logEvent(analytics, "select_content", {
		// 	content_type: "image",
		// 	content_id: "P12453",
		// 	items: [{ name: "Kittens" }]
		// });
	}, []);

	const { user: currentUser, isLoggedIn } = useSelector((state) => state.auth);
	let location = useLocation();
	const [loginFormVisible, setLoginFormVisible] = React.useState(mobileAlwaysShown);
	const shouldSlideUp = mobileAlwaysShown || loginFormVisible;
	useEffect(() => {
		logGTMScreen(location, isLoggedIn, currentUser);
	}, [location]);

	useEffect(() => {
		const UNAUTHORIZED = 401;
		const interceptor = axios.interceptors.response.use(
			(response) => response,
			(error) => {
				const { status } = error.response;
				let error_message = getErrorMessage(error);
				if (status === UNAUTHORIZED && error_message == "Unauthenticated.") {
					removeLogGTMUser();
					dispatch(resetUser());
					localStorage.removeItem("user");
					navigate("/login");
				}
				throw error;
			},
		);

		return () => {
			axios.interceptors.response.eject(interceptor);
		};
	}, []);

	if (isLoggedIn) {
		if (!currentUser.user.email_verified_at && location.pathname != "/login/otp-validation") {
			return <Navigate to={"/login/otp-validation?" + search} />;
		}

		if (!currentUser.user.reset_password && location.pathname == "/login") {
			return <Navigate to={"/?" + search} />;
		}

		if (location.pathname == "/login/register") {
			return <Navigate to={"/?" + search} />;
		}
	}

	const mobileFloatingOffset = 250;

	return (
		<div className='main-content' style={{ overflowY: "hidden" }}>
			<Row style={{ marginLeft: 0, marginRight: 0 }} className={styles.login_wrap}>
				{isMobileMode ? (
					<div
						style={{
							position: "absolute",
							flexDirection: "column",
							display: "flex",
							// top: shouldSlideUp? "calc(5vh)":"calc()" ,
							paddingTop: 20,
							bottom: shouldSlideUp ? "0" : `calc(-100vh + ${mobileFloatingOffset}px)`,
							backgroundColor: shouldSlideUp ? "rgba(0,0,0,0.3)" : "transparent",
							transition: "all 0.5s",
							height: "100vh",
							width: "100%",
							zIndex: 10,
						}}>
						<div
							style={{
								marginTop: 20,
								paddingTop: 25,
								borderTopLeftRadius: "20px",
								borderTopRightRadius: "20px",
								flexGrow: 1,
								backgroundColor: "white",
								flexShrink: 1,
								overflowY: shouldSlideUp ? "auto" : "hidden",
								paddingRight:
									"17px" /* Increase/decrease this value for cross-browser compatibility */,
								boxSizing: "content-box" /* So the width will be 100% + 17px */,
								paddingBottom: 100,
								boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.3)",
							}}>
							<Outlet
								context={{
									signButton: !shouldSlideUp && (
										<div className='d-flex justify-content-center'>
											<Button
												size='lg'
												style={{
													width: 200,
												}}
												className={classnames("btn-gradient", styles.submit_button)}
												onClick={() => {
													if (mobileAlwaysShown) {
														return;
													}
													setLoginFormVisible(!shouldSlideUp);
												}}>
												{shouldSlideUp ? "Close" : "Sign in"}
											</Button>
										</div>
									),
								}}
							/>
						</div>
					</div>
				) : (
					<Col lg='6' md='12' className={`${styles.login_right_col}  order-sm-last`}>
						<div className={styles.wrap}>
							<Outlet />
						</div>
					</Col>
				)}
				{
					upskilling100Flag ? <Col lg='6' md='12' className='px-0'>
						<div
							style={{
								height: "100%",
								width: "100%",
								backgroundImage: `url(${Upskilling100Bacground})`,
								backgroundSize: "cover",
								backgroundPosition: "center",
								display: "flex",
								justifyContent: "center",
								alignItems: isMobileMode ? "flex-start" : "center",
							}}>
							<img 
								src={Upskilling100Text} 
								style={{ 
									width: "100%",
									maxWidth: 800, 
									height: "auto",
								}} />
						</div>
					</Col> : 
						<Col className={`${styles.login_left_col} `} lg='6' md='12'>
					 <div className={`${styles.img_container}`}>
								<div className='shining_img_container'>
									<img
										className={`${styles.logo_image}`}
										alt='fca-logo'
										src={require("assets/img/Login/logo.png")}
									/>
								</div>
							</div>
							<div className={styles.main_girls_container}>
								{isMobileMode ? (
									<LoginGirl1FloatingBigger />
								) : (
									<>
										<LoginGirl1Floating />
										<LoginGirl2Floating />
									</>
								)}
							</div> 
						</Col>
				}
			</Row>
			{/* <NavigateFunctionComponent /> */}
		</div>
	);
}

export default AuthLayout;
