/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components

import { Link } from "react-router-dom";
import {
	Card,
} from "reactstrap";
import { dateFormatting } from "utilities/date";
import "../../assets/scss/custom/notification-card.scss";

function NotificationCard(props) {

	const getSmartBold = (desc) => {
		if (desc) {
			// Split the text around **
			const arr = desc.split("**");

			// Here we will store an array of Text components
			const newTextArr = [];

			// Loop over split text
			arr.forEach((element, index) => {
			// If its an odd element then it is inside **...** block
				if (index % 2 !== 0) {
					// Wrap with bold text style
					const newElement = (
						<span style={{fontWeight: "bold"}} key={index}>
							{element}
						</span>
					);
					newTextArr.push(newElement);
				} else {
					// Simple Text
					const newElement =
					<span key={index} >
						{element}
					</span>;

					newTextArr.push(newElement);
				}
			});

			return newTextArr;
		}
		return desc;
	};

	const getDescription = (item) => {
		if (
			item.notification.pushnotification_type === "reminder_4_weeks" ||
			item.notification.pushnotification_type ===
			"reminder_20days_tiktok_shop_class" ||
			item.notification.pushnotification_type ===
			"reminder_1_week_woodsquare" ||
			item.notification.pushnotification_type === "reminder_1_week_kallang" ||
			item.notification.pushnotification_type ===
			"reminder_1_week_paya_lebar" ||
			item.notification.pushnotification_type ===
			"reminder_1_week_2_woodsquare" ||
			item.notification.pushnotification_type ===
			"reminder_6days_tiktok_shop_classes" ||
			item.notification.pushnotification_type === "reminder_1_day_woodsquare" ||
			item.notification.pushnotification_type === "reminder_1_day_kallang"
		) {
			return getSmartBold(item.notification.pushnotification_short_description);
		} else {
			return item.notification.pushnotification_description;
		}
	};

	const renderGeneral = (item) => {
		return (
			<Link className='text-dark' to={"/notification/" + item.pushnotificationrecord_id} state={{ notification: item }}>
				<Card className='h-100 p-3 pl-4 pr-2' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
					<div style={{flex: 1}}>
						<p className="notification-title m-0">
							{item.notification.pushnotification_title}
						</p>
						<p className="notification-description m-0 mb-3">
							{getDescription(item)}
						</p>
						<p className="notification-date m-0">
							{`${dateFormatting(
								item.created_at,
							)}`}
						</p>
					</div>
					<div className="ml-2">
						<i style={{ color: item.pushnotificationrecord_seen_at === null ? "rgb(55,132,196)" : "#AAAAAA"}} className='fa fa-eye eye-icon' aria-hidden='true'></i>
					</div>
				</Card>
			</Link>
		);
	};

	const renderBundle = (item) => {
		return (
			<Link to={"/course/bundle/" + item.notification.bundle.bundle_id}>
				<Card className='h-100 p-3 pl-4 pr-4'  style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
					<div style={{flex: 1}}>
						<p className="notification-title m-0">
							{item.notification.pushnotification_title}
						</p>
						<p className="notification-description m-0 mb-1">
							{getDescription(item)}
						</p>
						<div style={{display:"flex", flexDirection: "row"}} className="mb-3">
							<p className="notification-bundle-title pr-1"  style={{margin: 0}}>
								{"Bundle:"}
							</p>
							<p className="notification-bundle-description" style={{margin: 0}}>
								{item.notification.bundle?.bundle_name}
							</p>
						</div>
				
						<p className="notification-date m-0">
							{`${dateFormatting(
								item.created_at,
							)}`}
						</p>
					</div>
					<div className="ml-2">
						<i style={{ color: item.pushnotificationrecord_seen_at === null ? "rgb(55,132,196)" : "#AAAAAA"}} className='fa fa-eye eye-icon' aria-hidden='true'></i>
					</div>
				</Card>
			</Link>
		);
	};

	const renderCourse = (item) => {
		return (
			// <Link className='text-dark' to={"/course/course-info/" + item.notification.course.course_id}>
			<Link className='text-dark' to={item.notification.course.fca_seo_url}>
				<Card className='h-100 p-3 pl-4 pr-4'  style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
					<div style={{flex: 1}}>
						<p className="notification-title m-0">
							{item.notification.pushnotification_title}
						</p>
						<p className="notification-description m-0 mb-1">
							{getDescription(item)}
						</p>
						<div style={{display:"flex", flexDirection: "row"}} className="mb-3">
							<p className="notification-bundle-title pr-1"  style={{margin: 0}}>
								{"Course:"}
							</p>
							<p className="notification-bundle-description" style={{margin: 0}}>
								{item.notification.course?.course_title}
							</p>
						</div>
						<p className="notification-date mt-0">
							{`${dateFormatting(
								item.created_at,
							)}`}
						</p>
					</div>
					<div className="ml-2">
						<i style={{ color: item.pushnotificationrecord_seen_at === null ? "rgb(55,132,196)" : "#AAAAAA"}} className='fa fa-eye eye-icon' aria-hidden='true'></i>
					</div>
				</Card>
			</Link>
		);
	};

	const renderComment = (item) => {
		return (
			<Link className='text-dark' to={"/notification/article/" + item.notification.article?.article_id.toString()} state={{ comment_id: item.notification.comment?.reply_comment_id.toString() || "" }}>
				<Card className='h-100 p-3'>
					<div style={{display:"flex", flexDirection: "row"}} className="pl-0">
						<img className="notification-comment-image mr-3" alt='{item.notification.article}' src={item.notification.article?.article_image_url} />
						<div style={{ justifyContent: "space-between" }}>
							<div>
								<p className="notification-title m-0">
									{item.notification.pushnotification_title}
								</p>
								<p className="notification-description m-0 mb-3">
									{getDescription(item)}
								</p>
							</div>
							<p className="notification-date m-0 lh-100">
								{`${dateFormatting(
									item.created_at,
								)}`}
							</p>
						</div>
					</div>
				</Card>
			</Link>
		);
	};
 
	const renderNotification = (item) => {
		if (item.notification.pushnotification_type === "text") {
			return renderGeneral(item);
		} else if (item.notification.pushnotification_type === "course") {
			if (item.notification.bundle) {
				// bundle
				return renderBundle(item);
			} else if (item.notification.course) {
				// course
				return renderCourse(item);
			}
		} else if (item.notification.pushnotification_type === "comment") {
			if (item.notification.comment) {
				// reply article
				return renderComment(item);
			}
		} else {
			return renderGeneral(item);
		}
	};

	return (

		<div key={props.key}>
			{renderNotification(props.notification)}
		</div>
	);
}

export default React.memo(NotificationCard);

