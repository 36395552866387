import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ReactComponent as CourseSvg } from "assets/img/Bundle/progres.svg";

import { Row, Card, CardBody, Col } from "reactstrap";

import { ReactComponent as BusIcon } from "assets/img/Course/upcoming/bus.svg";
import { ReactComponent as MealIcon } from "assets/img/Course/upcoming/meal.svg";
import { ReactComponent as TimeIcon } from "assets/img/Course/upcoming/date.svg";
// import { ReactComponent as RescheduleIcon } from "assets/img/Course/upcoming/reschedule.svg";
// import { ReactComponent as WithdrawalIcon } from "assets/img/Course/trainer.svg";
import { ReactComponent as CertificateIcon } from "assets/img/Course/registration/success.svg";
// import { ReactComponent as TrainerIcon } from "assets/img/Course/trainer.svg";

import ModalCertificates from "./ModalCertificates";
import { PrintCourseDate, PrintDate } from "../../../../common_function/function.js";
import generalApi from "services/general-api";
import { getBundlePercentages } from "utilities/number";
import { getErrorMessage } from "utilities/error";
import { useWindow } from "hooks/UseWindow";
import defaultCourse from "assets/img/default_course.jpg";

const VERSION = process.env.REACT_APP_APP_VERSION;

function ModalBundleInfo() {
	let navigate = useNavigate();
	const params = useParams();
	const [certModalChange, setCertModalChange] = useState(false);
	const [message, setmessage] = useState("");
	const MySwal = withReactContent(Swal);
	const [accordionOpen, setAccordionOpen] = useState(false);
	const [bundle, setBundle] = useState([]);
	const { width } = useWindow();
	const [isBundleLoading, setIsBundleLoading] = useState(false);

	const toggleAccordion = () => {
		setAccordionOpen(!accordionOpen);
	};

	useEffect(() => {
		setIsBundleLoading(true);
		generalApi.getBundleDetail(params.id).then(
			(response) => {
				setBundle(response);
				setIsBundleLoading(false);
			},
			(error) => {
				setmessage(getErrorMessage(error));
			},
		);
	}, []);

	const handlePopup = (displayMSG) => {
		MySwal.fire({
			title: "Unable to Proceed!",
			html: displayMSG,
			icon: "error",
			// icon: "warning",
			// didOpen: () => {
			// 	// `MySwal` is a subclass of `Swal` with all the same instance & static methods
			// 	MySwal.showLoading();
			// },
		});
	};
	console.log(bundle);
	return (
		<>
			<div className='bundle-info-container'>
				<div className='title-container'>
					<span className='mb-0 fs40 text-fca-dark font_2'>
						{"Professional Certificate Attainment"}
					</span>
					{isBundleLoading && 
						<div className='justify-content-center w-100 flex'><span className='spinner-border spinner-border-sm'></span>
						</div>
					}
				</div>
				{Object.keys(bundle).length !== 0 && !isBundleLoading && (
					<div className={"fadeInDiv"}>
						<Card className="mt-2" style={{ boxShadow: "none" }}>
							<CardBody style={{ padding: 0 }}>
								<div className="bundle_card_container">
									<div className="bundle_card_data_container">
										<Row className='justify-content-between mx-0'>
											<Col md='auto' xl='auto' xs='auto' className="px-0">
												{bundle?.logos?.map((logo, i) => (
													<img
														key={i}
														className='m-1 h-30px display_ini'
														alt={bundle.bundle_name}
														src={logo.bundle_logo_image}
													/>
												))}
											</Col>
										</Row>
										<Row className='align-items-center pt-2'>
											<Col md='12' xl='12'>
												<p className='bundle_title'>{bundle.bundle_banner_title}<span className='bundle_title_blue' style={{ color: bundle.bundle_color ? bundle.bundle_color : "rgb(0, 133, 202)" }}>{` ${bundle.bundle_name}`}</span></p>
												<p className='bundle_description'>{bundle.bundle_banner_description}</p>
												{/* <p className="fs22 font-weight-bold text-fca-dark font_2">{"test"}</p> */}
											</Col>
										</Row>
									</div>
									<div className="bundle_card_banner_container" style={{ backgroundImage: `url("${bundle.bundle_banner_background_image }")`, backgroundSize: "contain"}}>
										<img
											src={bundle.bundle_banner_image || null}
											width='100%'
											className={"bundle_banner_image"}
										/>
									</div>
								</div>

							</CardBody>
						</Card>
						<Card className="mt-2" style={{ boxShadow: "none" }}>
							<CardBody style={{ padding: 0 }}>
								<div className="courseinfo_details_container">
									<Row className='flex align-items-center mb-3'>
										<div>
											<CourseSvg className='ml-2' style={{ width: "60px" }} />
										</div>
										<div className={"fs22 ml-2 text-fca-dark font_2"}>Course Progression</div>
									</Row>
									<Row className="mb-4">
										<Col>
											<div
												className='px-4 py-4 bundle-detail-div'
												style={{
													borderTopColor: "rgb(0, 133, 202)",
												}}>
												{bundle.total_completed !== null && bundle.total_completed > 0 &&
													<p className='bundle_details_title'>
														<span className='bundle_details_title_green'>
															{`Congratulations! You have completed ${bundle.total_completed > 1 ? `${bundle.total_completed} courses` : `${bundle.total_completed} course`}`}
														</span>
														{` from the ${bundle.bundle_name}. You are one step closer to becoming a ${bundle.bundle_name} Professional!`}
													</p>
												}
												
												<Row className='justify-content-end mx-0 mt-2'>
													<Col md='auto' xl='auto' xs='auto' className='px-0'>
														<span className='p-2 ml-2 course-sticker green-sticker font-weight-600'>
															{getBundlePercentages(bundle.total_completed, bundle.total_courses)}
														</span>
													</Col>
												</Row>
												<Row className='mx-0 mt-3'>
													{bundle?.courses.length > 0 ? bundle.courses.map((course, i) => (
														<Col xl='6' lg='6' sm="12" xs='12' key={i} className="mb-3 px-2">
															<Link key={i} className='text-dark' to={course.fca_seo_url}>
																<Row className="p-2 mx-0 bundle-course-card" style={course.has_registered && course.is_completed ? { backgroundColor: "#00ab8420" } : { backgroundColor: "#f7f7f7" }}>
																	{course.has_registered && course.is_completed && (
																		<Col xs="auto" className='p-0 pr-3 col-auto flex align-items-center'>
																			<div className="bundle-course-icon-container">
																				<div className={"circle"}>
																					<img alt='medal' src={require("assets/img/Course/medal.png")} />
																				</div>
																			</div>
																		</Col>
																	)}
																	{course.has_registered && !course.is_completed && (
																		<Col xs="auto" className='p-0 pr-3 col-auto flex align-items-center'>
																			<div className="bundle-course-icon-selected-container">
																				<div className={"bundle-course-selected-circle"}>
																				</div>
																			</div>
																		</Col>
																	)}
																	{!course.has_registered && (
																		<Col xs="auto" className='p-0 pr-3 col-auto flex align-items-center'>
																			<div className="bundle-course-icon-container">
																				<div className={"circle"}>
																				</div>
																			</div>
																		</Col>
																	)}
																	<Col className="mx-auto p-0 pr-2 py-2 flex" style={{ flexDirection: "column", justifyContent: "space-between" }}>
																		<span className="fs14 font-weight-600 text-underline" style={course.has_registered && course.is_completed ? { color: "#00ab84" } : course.has_registered && !course.is_completed ? {color: "#101820"} : { color: "#aaaaaa" }}>
																			{course.course_title}
																		</span>
																		{course.has_registered && course.is_completed && course.completed_date && (
																			<p className='text-fca-dark fs14 mt-2'>
																				Completed on {PrintDate(course.completed_date)}
																  			</p>
																		)}
																		{course.has_registered && !course.is_completed &&
																			<p className='text-fca-dark fs14 mt-2'>
                      															{"Course Registered"}
																			</p>
																		}
																	</Col>
																	<Col className="p-0" sm="auto" xs="12" style={ width <= 576 ? { order: -1} : {}}>
																		<img
																			src={course.course_banner_image || defaultCourse}
																			className={"bundle_course_banner_image"}
																			style={ width <= 576 ? { width: "100%", height: "auto" } : {}}
																		/>
																	</Col>
																</Row>
															</Link>
														</Col>
													)) : ""}
												</Row>
												<p className='fs15 font-weight-600 text-fca-dark mt-2'>
													{`Complete ${bundle?.courses?.length} courses from the ${bundle.bundle_name} Program to earn a `}
													<span className='bundle_details_title_green'>
														{"professional certificate"}
													</span>
													{"."}
												</p>
											</div>
										</Col>
									</Row>


									{message && (
										<div className='form-group'>
											<div className='alert alert-danger' role='alert'>
												{message}
											</div>
										</div>
									)}

									<div className="pt-0">
										<Row className='mx-0'>
											<Col xs="12" sm="12" md="2" className="px-0">
												<div className='btn-wrap mb-3'>
													<Link to='/course' className='btn btn-default'>
														{"Back"}
													</Link>
												</div>
											</Col>
											<Col xs="12" sm="12" md="10" className="d-sm-flex justify-content-end px-0" style={width<=767 ? {order: -1} : {}}>
												<Row className='mx-0' style={width<=767 ? {width: "100%"} : {flexDirection:"row-reverse"}}>
													{/* {status == "completed" && (
														<>
															<Link
																// to='/course/shuttle-bus'
																// state={{ learner_id: course.learner_id }}
																className='btn btn-outline-green'
															>
																<TimeIcon />
																<span>Attendance</span>
																<i className='fa fa-angle-right' aria-hidden='true'></i>
															</Link>
															<Link
																// to='/course/shuttle-bus'
																// state={{ learner_id: course.learner_id }}
																className='btn btn-outline-green'
															>
																<BusIcon />
																<span>Resources</span>
																<i className='fa fa-angle-right' aria-hidden='true'></i>
															</Link>
														</>
													)} */}
													{bundle.professional_cert && bundle.professional_cert.length > 0 && (
														<>
															<div className='btn-wrap mb-3' style={width<=767 ? {width: "100%"} : {paddingLeft: "10px"}}>
																<Link
																	to={"/course/bundle-list/" + bundle.bundle_id}
																	className='btn btn-outline-green'
																>
																	<span>View Certificate</span>
																	<i className='fa fa-angle-right' aria-hidden='true'></i>
																</Link>
															</div>
															
															{/* <ModalCertificates
																	course_title={bundle.bundle_name}
																	cert_file_url={bundle.professional_cert.cert_file_url}
																	showModal={certModalChange}
																	setShowModal={setCertModalChange}
																/>
																<Button
																	className='btn btn-outline-green cert-icon'
																	onClick={() => setCertModalChange(true)}
																>
																	<CertificateIcon />
																	<span>Certificate</span>
																	<i className='fa fa-angle-right' aria-hidden='true'></i>
																</Button>
															*/}
														</>
													)}
												</Row>
											</Col>
										</Row>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				)}
			</div>
		</>
	);
}

export default ModalBundleInfo;
