import axios from "axios";
import { NOTIFICATION_LENGTH } from "utilities/constant";

const API_URL = process.env.REACT_APP_API_URL;
import authHeader from "./auth-header";

const getNotifications = (pageParam = 0) => {
	let length = NOTIFICATION_LENGTH;
	return axios
		.get(API_URL + `/api/mobile/pushnotification?start=${pageParam}&length=${length}`,{ headers: authHeader() },)
		.then((response) => {
			return { ...response.data.data, nextPage: pageParam + length, previousPage: pageParam };
		});
};

const checkNotification = (pushnotification_id) => {
	return axios
		.post(API_URL + "/api/mobile/pushnotification/see_notification", {pushnotification_id},{ headers: authHeader() },)
		.then((response) => {
			return response.data.data;
		});
};

const replyNotification = (pushnotification_id, reply_content) => {
	let request_data = {
		pushnotification_id: pushnotification_id,
		reply_content: reply_content,
	};
	return axios
		.post(API_URL + "/api/mobile/pushnotification/reply", request_data,{ headers: authHeader() },)
		.then((response) => {
			return response.data;
		});
};

export default {
	getNotifications,
	checkNotification,
	replyNotification,
};
