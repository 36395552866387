
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useOutletContext, useLocation, Outlet } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
import * as yup from "yup";
// eslint-disable-next-line
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form";
// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Row,
	Col,
} from "reactstrap";
// core components
import styles from "assets/scss/module/auth/login.module.scss";
import { login } from "../../../actions/auth";
import ModalFirstTimeResetPassword from "./ModalFirstTimeResetPassword";
import { ReactComponent as UserIcon } from "../../../assets/img/Login/user.svg";
import { ReactComponent as LockIcon } from "../../../assets/img/Login/password.svg";
import { clearMessage } from "actions/message";
import { logGTMScreen, logGTMUser } from "utilities/googleTagManager";
import ContactUs2 from "views/pages/learner/course/ContactUs2";
import { useSessionStorageState } from "ahooks";
const schema = yup
	.object({
		email: yup.string().email().required(),
		password: yup.string().required(),
	})
	.required();

function Login() {
	let navigate = useNavigate();
	const pageProps  = useOutletContext();
	console.log("pageProps", pageProps);

	const [passwordType, setPasswordType] = useState("password");
	const [loading, setLoading] = useState(false);
	const [modalOpen, setmodalOpen] = React.useState(false);
	
	const { user: currentUser } = useSelector((state) => state.auth);
	const { message } = useSelector((state) => state.message);
	// required to keep track the 'ts_ccc' value, so that it can be used in the referral form after user successful login
	const [cachedTSCCC, setCachedTSCCC] = useSessionStorageState("ts_ccc", {
		defaultValue: null
	});

	const [contactFormOpen, setContactFormOpen] = useState(false);
	const hasOpend = useRef(false);
	const {search} = useLocation();
	const params = new URLSearchParams(search);
	const hasContactUs = params.get("contactus");
	const enquiryModalMetadata = {
		"ts_ccc": params.get("ts_ccc"),
		"contactus": params.get("contactus"),
	};

	useEffect(() => {
		if(params.get("ts_ccc")){
			setCachedTSCCC(params.get("ts_ccc"));
		}
	},[]);

	useEffect(() => { 
		if( !hasOpend.current && parseInt(hasContactUs) == 1){
			hasOpend.current = true;
			setContactFormOpen(true);
		}
	},[hasContactUs]);

	const dispatch = useDispatch();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const togglePassword = () => {
		if (passwordType === "password") {
			setPasswordType("text");
			return;
		}
		setPasswordType("password");
	};

	const handleLogin = (data) => {
		setLoading(true);
		dispatch(login(data.email, data.password))
			.then((data) => {
				if (data.isHyperAdminLogin) {
					navigate("/login/hyperadmin-otp-validation");
				} else {
					const loggingInUser = data?.user;
					logGTMUser(
						data.user.learnermaster_id ? data.user.learnermaster_id.toString() : "",
						data.user.learnermaster_name ? data.user.learnermaster_name.toString() : "",
						data.user.learnermaster_dob ? data.user.learnermaster_dob : "",
						data.user.learnermaster_nationality ? data.user.learnermaster_nationality : "",
						data.user.learnermaster_gender ? data.user.learnermaster_gender : "",
					);

					if (loggingInUser && !loggingInUser?.email_verified_at) {
						navigate("/login/otp-validation");
					} else if (loggingInUser && loggingInUser?.reset_password) {
						setmodalOpen(true);
					} else if (cachedTSCCC){
						navigate("/referral");
					} else {
						navigate("/");
					}
				}
			})
			.catch((e) => {
				setLoading(false);
			});
	};

	React.useEffect(() => {
		dispatch(clearMessage());
	}, []);

	React.useEffect(() => {
		if (currentUser && currentUser.user.reset_password) {
			setmodalOpen(true);
		}
	});


	const LoginForm = (a) => { 
		return <Container className=''>
			<div className={styles.title_container}>
				<p className={styles.title_text}>If you are willing to learn<br/>You are unstoppabble</p>
				<p className={styles.desc_text}>Learner's Portal</p>
			</div>
			{
				pageProps?.signButton 
			}
			<Row className='justify-content-center'>
				<Col lg='12' md='12' className='mw-580px'>
					<Card className='bg-transparent shadow-none border-0 mb-0'>
						<CardBody className='px-lg-4 py-lg-'>
							<div className='text-center text-muted mb-4'>
								<p className="fs-18 text-color-dark font-weight-bold font_1">Sign In</p>
							</div>
							<Form id='login_form' onSubmit={handleSubmit(handleLogin)}>
								<FormGroup>
									<p className="fs-15 text-color-dark font-weight-600 mb-1">Email</p>
									<InputGroup
										className={`input-group-merge ${styles.input_container} ` + (errors.email ? "is-invalid" : "")}>
										<InputGroupAddon addonType='prepend'>
											<InputGroupText
												className={"form-control " + (errors.email ? "is-invalid" : "")}>
												{/* <i className='ni ni-email-83' /> */}
												<UserIcon width={30} height={30} />
											</InputGroupText>
										</InputGroupAddon>
										<input
											placeholder='Email'
											type='email'
											className={`form-control fs-15 ${styles.input_container} ` + (errors.email ? "is-invalid" : "")}
											{...register("email")}
										/>
									</InputGroup>
									<div className='invalid-feedback'>{errors.email?.message}</div>
								</FormGroup>
								<FormGroup className="mb-1">
									<p className="fs-15 text-color-dark font-weight-600 mb-1">Password</p>
									<InputGroup
										className={
											`input-group-merge ${styles.input_container} ` +
												(errors.password ? "needs-validation is-invalid" : "")
										}>
										<InputGroupAddon addonType='prepend'>
											<InputGroupText
												className={"form-control " + (errors.password ? "is-invalid" : "")}>
												{/* <i className='ni ni-lock-circle-open' /> */}
												<LockIcon width={30} height={30} />
											</InputGroupText>
										</InputGroupAddon>
										<input
											placeholder='Password'
											type={passwordType}
											className={`form-control fs-15 ${styles.input_container} ` + (errors.password ? "is-invalid" : "")}
											{...register("password")}
										/>
										<InputGroupAddon addonType='append'>
											<InputGroupText onClick={togglePassword}>
												{passwordType === "password" ? (
													<i className='far fa-eye-slash'></i>
												) : (
													<i className='far fa-eye'></i>
												)}
											</InputGroupText>
										</InputGroupAddon>
									</InputGroup>
									<div className='invalid-feedback'>{errors.password?.message}</div>
								</FormGroup>
								<div className='custom-control custom-control-alternative custom-checkbox text-center d-none'>
									<input
										className='custom-control-input'
										id=' customCheckLogin'
										type='checkbox'
									/>
									<label className='custom-control-label d-none' htmlFor=' customCheckLogin'>
										<span className='text-muted'>Remember me</span>
									</label>
								</div>
								<div className='pdt10 d-flex justify-content-end'>
									<span>
										<Link to='/login/reset-password' className='text-blue bold ml-1 fs-15'>
										Forget Password?
										</Link>
									</span>
								</div>
								<div className='text-center mt-5'>
									<Button
										className={`${classnames("btn-gradient", styles.submit_button)}`}
										type='submit'
										disabled={loading}>
										{loading && <span className='spinner-border spinner-border-sm'></span>}
										<span className="fs-15">Sign in</span>
									</Button>
								</div>

								{message && (
									<div className='form-group pt-3'>
										<div className='alert alert-danger' role='alert'>
											{message}
										</div>
									</div>
								)}
							</Form>
						</CardBody>

						<CardHeader className='bg-transparent pb-5 d-none'>
							<div className='text-center text-muted mb-4'>
								<small>Or sign in with credentials</small>
							</div>
							<div className='text-muted text-center mt-2 mb-3'>
								<small>Sign in with</small>
							</div>
							<div className='btn-wrapper text-center'>
								<Button
									className='btn-neutral btn-icon'
									color='default'
									href='#pablo'
									onClick={(e) => e.preventDefault()}>
									<span className='btn-inner--icon mr-1'>
										<img alt='...' src={require("assets/img/icons/common/github.svg").default} />
									</span>
									<span className='btn-inner--text'>Github</span>
								</Button>
								<Button
									className='btn-neutral btn-icon'
									color='default'
									href='#pablo'
									onClick={(e) => e.preventDefault()}>
									<span className='btn-inner--icon mr-1'>
										<img alt='...' src={require("assets/img/icons/common/google.svg").default} />
									</span>
									<span className='btn-inner--text'>Google</span>
								</Button>
							</div>
						</CardHeader>
					</Card>
					<div className='sinup_wrap text-center pt-2'>
						<div className='pdt20'>
							<span>
									Don't have an account?
								<Link to='/register' className='text-blue bold ml-1'>
										Create new account
								</Link>
							</span>
						</div>

						<div className='pdt20'>
							<a 
								className='text-blue bold ml-1'
								style={{
									textDecoration:"underline"
								}}
								onClick={(e) => { 
									e.preventDefault();
									setContactFormOpen(true);
								}}>
									📞 Contact us
							</a>
						</div>
						{/* <a href={"http://localhost:3000/checkout/account?courserun_id=1294&course_id=28"} target={"_blank"} rel="noreferrer">test</a> */}
						{/* <a href={"http://localhost:3000/checkout/account?courserun_id=1294&course_id=28&campaign_type=gravity&utm_campaign=TIkTokShop&utm_source=Facebook&utm_medium=Paid&utm_content=&utm_term="} target={"_blank"} rel="noreferrer">test</a> */}
					</div>
				</Col>
			</Row>
				
		</Container>;
	 };

	return (
		<>
			<Outlet/>
			<LoginForm/>
			<ModalFirstTimeResetPassword modalChange={modalOpen} setModalChange={setmodalOpen} />
			<ContactUs2 
				show={contactFormOpen} 
				onSuccess={() => { setContactFormOpen(false); }}
				metadata={enquiryModalMetadata}
			>
			</ContactUs2>
		</>
	);
}

export default Login;
