import React from "react";
import * as yup from "yup";
import { yupHelper } from "utilities/yupHelpers";
import { Button,  Modal,ModalHeader,ModalFooter,ModalBody} from "reactstrap";
import generalApi from "services/general-api";


function ModalAttendanceConfirmation({ onSuccess = () => {}, isOpen = false, onClose = () => {}, event = null, isLoading=false }) {
	const handleUpdateEventAttendance = async (isAttending ) => {
		return generalApi.updateEventConfirmation({
			event_registration_id: event.event_registration_id,
			attendance_confirmation: isAttending,
		}).then(() => { 
			onClose();
		 });
	};

	return (
		<>
			<Modal
				className={"modal-dialog-centered event_register_modal"}
				isOpen={isOpen}
			>
				<ModalHeader >Event Reminder</ModalHeader>
				<ModalBody>
					You have registered for this event. Will you be attending ?
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={(e)=>{
						e.preventDefault();
						handleUpdateEventAttendance(false);
					}}>
            I cannot make it
					</Button>
					<Button color="primary" onClick={(e)=>{
						e.preventDefault();
						handleUpdateEventAttendance(true);
					}}>
            Yes I am attending
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}

export default ModalAttendanceConfirmation;
