import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import courseRegisterSlice from "./courseRegisterSlice";
import notification from "./notification";
import news from "./news";

export default combineReducers({
	auth,
	message,
	courseRegisterSlice,
	notification,
	news,
});
