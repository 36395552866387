import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// nodejs library that concatenates classes
import classnames from "classnames";
import * as yup from "yup";
import { yupHelper } from "utilities/yupHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// reactstrap components
import {
	Button,
	Card,
	CardBody,
	FormGroup,
	Form,
	Container,
} from "reactstrap";

import AuthService from "../../../../services/auth-api";
import ModalChangePassword from "./ModalChangePassword";
import { getErrorMessage } from "utilities/error";

const schema = yup.object({
	otp: yupHelper.otp(),
});

function ProfileChangePasswordOtpValidation() {
	const { user: userData } = useSelector((state) => state.auth);
	const [modalOpen, setmodalOpen] = React.useState(false);
	const currentUser = userData.user;
	// const { user: currentUser, isLoggedIn } = useSelector((state) => state.auth);
	const [loading, setLoading] = useState(false);
	const [otp, setotp] = useState("");
	const [message, setmessage] = useState("");
	const [seconds, setSeconds] = useState(30);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const handleOTP = (data) => {
		setLoading(true);

		data.email = currentUser.learnermaster_email ? currentUser.learnermaster_email : "";
		let submit_otp = data.otp;

		AuthService.forgetPasswordVerifyOtp(data)
			.then(
				() => {
					setLoading(false);
					setotp(submit_otp);
					setmodalOpen(true);
				},
				(error) => {
					setLoading(false);
					setmessage(getErrorMessage(error));
				},
			)
			.catch(() => {
				setLoading(false);
			});
	};

	const handleSendOTP = () => {
		if (!loading && seconds === 0) {
			setLoading(true);
			AuthService.forgetPasswordSendEmail(
				currentUser.learnermaster_email ? currentUser.learnermaster_email : "",
			)
				.then(
					() => {
						setSeconds(30);
						setLoading(false);
					},
					() => {
						setSeconds(30);
						setLoading(false);
					},
				)
				.catch(() => {
					setLoading(false);
				});
		}
		
	};

	useEffect(() => {
		if (seconds !== 0) {
			const interval = setInterval(() => {
				setSeconds(seconds - 1);
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [seconds]);
	

	return (
		<>
			<ModalChangePassword modalChange={modalOpen} setModalChange={setmodalOpen} otp={otp} />
			<Container fluid>
				<div className='title-container'>
					<h2>Change Password OTP Verification</h2>
				</div>
				<Card className='border-0 mx-auto mt-4' style={{ maxWidth: "600px" }}>
					<CardBody className='px-lg-5 py-lg-5 text-center'>
						<div className='pb-4'>
							<p className='mb-0'>Enter the OTP verification code that sent to</p>
							<p>
								<u className='text-lowercase'>{currentUser.learnermaster_email}</u>
							</p>
						</div>

						<Form role='form' onSubmit={handleSubmit(handleOTP)}>
							<FormGroup>
								<input
									className={"form-control " + (errors.otp ? "is-invalid" : "")}
									defaultValue=''
									placeholder='OTP Verification Code'
									type='text'
									{...register("otp")}
								/>
								<div className='mt-2 invalid-feedback text-center'>{errors.otp?.message}</div>
							</FormGroup>

							<div className='mt-2 text-center'>
								Do not receive OTP Code?{" "}
								{seconds !== 0 && <span className='text-blue bold'>{seconds}</span>}
								{seconds === 0 && (
									<span disabled={loading} onClick={handleSendOTP} role='button' className='text-blue bold'>
										Resend
									</span>
								)}
							</div>

							<div className='justify-content-center mb-4 mt-2'>
								<Button className='mt-2 w-100 btn-gradient' type='submit' disabled={loading}>
									{loading && <span className='spinner-border spinner-border-sm'></span>}
									<span>Verify</span>
								</Button>
							</div>

							{message && (
								<div className='form-group'>
									<div
										className={classnames(
											"alert",
											message === "OTP Sent Success" ? "alert-success" : "alert-danger",
										)}
										role='alert'
									>
										{message}
									</div>
								</div>
							)}
						</Form>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default ProfileChangePasswordOtpValidation;
