import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Modal, ModalHeader } from "reactstrap";

import { useSelector } from "react-redux";
import "./ContactUs2.css";
import IndividualForm from "../contact/individual";
import CorporateForm from "../contact/corporate";
import { ReactComponent as CancelBtnWhite } from "assets/img/Checkout/cancel01.svg";
import { ReactComponent as CancelBtnBlack } from "assets/img/Checkout/cancel02.svg";

function ContactUs2(props) {


	const handleModalClose = () => {
		props.onSuccess(true);
	}; 
	
	return (
		<>
			<Modal
				className='modal-dialog-centered registration_modal'
				isOpen={props.show}
			>
				<ModalHeader className="contact-form-close" toggle={props.toggle}>
					<div className="close close-black">
						<CancelBtnBlack onClick={handleModalClose}></CancelBtnBlack>
					</div>
					<div className="close close-white">
						<CancelBtnWhite onClick={handleModalClose}></CancelBtnWhite>
					</div>
				</ModalHeader>
				{/* <div className='title-container'>
					<h2>Contact Us</h2>
				</div> */}
				<div>
					<div className="contact_banner">
						<img
							alt='Contact Us'
							src={require("assets/img/learner/contact_banner.jpg")}
						/>
					</div>
					<div className="form-section">
						<div className='desc pb-4 flex justify-content-center'>
							<p className="contact_header">
								Schedule a <span className="contact_header_colored"> non-obligatory call back </span>with our friendly
								Education Consultants and <span className="contact_header_colored"> start your learning journey </span>today!
							</p>
						</div>
						<Tabs>
							<TabList className="contact_form_tab">
								<Tab>Individual</Tab>
								<Tab>Corporate</Tab>
							</TabList>
							<TabPanel>
								<IndividualForm onSuccess={props.onSuccess} metadata={props.metadata}/>
							</TabPanel>
							<TabPanel>
								<CorporateForm onSuccess={props.onSuccess} metadata={props.metadata}/>
							</TabPanel>
						</Tabs>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default ContactUs2;
