import React, { useEffect } from "react";
import { Modal } from "reactstrap";

function EmbeddedResetPasswordModal(props) {
	useEffect(() => {
		const listiner = (event) => {
			console.log("received message", event);
			if (event.origin === window.location.origin && event.data.type === "changePasswordSuccess") {
				props.onSuccess(event.data.data);
			}
		};
		window.addEventListener("message", listiner, false);
		return function cleanup() {
			window.removeEventListener("message", listiner);
		};
	}, []);

	return (
		<>
			<Modal size='xl' className='modal-dialog-centered' {...props}>
				<iframe
					style={{ width: "100%", height: "80vh" }}
					src={window.location.origin + "/login/reset-password"}
				/>
			</Modal>
		</>
	);
}

export default EmbeddedResetPasswordModal;
