import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// reactstrap components
import { Button, Card, CardBody, FormGroup, Form, Container, Row, Col,Modal } from "reactstrap";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import { clearMessage } from "actions/message";

import generalApi from "services/general-api";
import { useCheckout } from "hooks/selectors/useCheckout";
import { GTMCheckoutCCPaymentFailed, GTMCheckoutCCPaymentSuccess, GTMCheckoutCompleted, GTMCheckoutFacebookCheckoutCompleted, GTMCheckoutGoogleAdsCheckoutCompleted, GTMCheckoutPurchased, GTMCheckoutStep2_5GoSuccess } from "utilities/googleTagManager";


function Omiseloading() {
	const { user: userData } = useSelector((state) => state.auth);
	const currentUser = userData.user;
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const { message } = useSelector((state) => state.message);
	const dispatch = useDispatch();
	const MySwal = withReactContent(Swal);
	const { checkoutAnalyticsData } = useCheckout();

	const [paymentmethod, setPaymentmethod] = useState("paynow");
	const [countDown, setCountDown] = useState(6);
	const courseRegisterData = useSelector((state) => state.courseRegisterSlice);
	var timeoutHandle = [];
	var interval;

	function displayCountDown() {
		checkPaymentStatus();
		countdown(10, 0);
	}
	function countdown(minutes, seconds) {
		const searchParams = new URLSearchParams(window.location.search);
		const data = {};
		data.payment_id = searchParams.get("payment_id");
		function tick() {
			var counter = document.getElementById("timer");
			setCountDown(minutes.toString() + ":" + (seconds < 10 ? "0" : "") + String(seconds));
			seconds--;
			// clearInterval(interval);
			if (seconds >= 0) {
				timeoutHandle.push(setTimeout(tick, 1000));
			} else {
				if (minutes >= 1) {
					setTimeout(function () {
						countdown(minutes - 1, 59);
					}, 1000);
				} else {
					if (seconds == 0 || seconds == -1) {
						clearInterval(interval);
						console.log("done");
						navigate(
							"/checkout/course",
							{ state: { payment_id: searchParams.get("payment_id"), payment_status:false,countdown:true } }
						);
					}
				}
			}
		}
		tick();
	}

	function checkPaymentStatus(){
		const searchParams = new URLSearchParams(window.location.search);
		const data = {};
		data.payment_id = searchParams.get("payment_id");

		const interval = setInterval(async () => {
			// Dispatch the async action to check payment status
			try {
				// Assuming 'dispatch' and 'generalApi' are defined
				await generalApi.checkpaymentStatus(data)
					.then((jsonObj) => {
						console.log(jsonObj.status);
						if (jsonObj.status === 1) {
							if (jsonObj.payment_status === "SUCCESSFUL") {
								
								let requestData = {
									registrationform_id: courseRegisterData.registrationform_id,
								};
								if (checkoutAnalyticsData && checkoutAnalyticsData.campaign_type) {
									requestData.lead_type = checkoutAnalyticsData.campaign_type;
								}
								generalApi.updateOrderSubmissionLeadSource(requestData).then(
									(response) => {
										if (response.status == "Success") {
											requestData.course_id = checkoutAnalyticsData && checkoutAnalyticsData.course_id;
											requestData.courserun_id = checkoutAnalyticsData && checkoutAnalyticsData.courserun_id;
											requestData.campaign_type = checkoutAnalyticsData && checkoutAnalyticsData.campaign_type;
											requestData.referral = checkoutAnalyticsData && checkoutAnalyticsData.referral;
											// requestData.invite = checkoutAnalyticsData && checkoutAnalyticsData.invite;
											requestData.utm_campaign = checkoutAnalyticsData && checkoutAnalyticsData.utm_campaign;
											requestData.utm_content = checkoutAnalyticsData && checkoutAnalyticsData.utm_content;
											requestData.utm_medium = checkoutAnalyticsData && checkoutAnalyticsData.utm_medium;
											requestData.utm_source = checkoutAnalyticsData && checkoutAnalyticsData.utm_source;
											requestData.utm_term = checkoutAnalyticsData && checkoutAnalyticsData.utm_term;
											requestData.url = checkoutAnalyticsData && checkoutAnalyticsData.url;

											requestData.ccc = checkoutAnalyticsData && checkoutAnalyticsData.CCC ? checkoutAnalyticsData.CCC : null;

											generalApi
												.SubmitBigBangPurchasedConversionLog(requestData)
												.then(
													(response) => {
													},
													(error) => {
													},
												)
												.catch(() => {
												});
												
											clearInterval(interval);
											// GTMCheckoutCCPaymentSuccess(checkoutAnalyticsData, userData);
											GTMCheckoutCompleted(checkoutAnalyticsData, userData);
											GTMCheckoutPurchased(checkoutAnalyticsData, userData, jsonObj.internal_reference);
											if (checkoutAnalyticsData && checkoutAnalyticsData.campaign_type && checkoutAnalyticsData.campaign_type.toUpperCase() === "STELLAR") {
												GTMCheckoutFacebookCheckoutCompleted(checkoutAnalyticsData, userData);
											}
											if (checkoutAnalyticsData && checkoutAnalyticsData.campaign_type && checkoutAnalyticsData.campaign_type.toUpperCase() === "GRAVITY") {
												GTMCheckoutGoogleAdsCheckoutCompleted(checkoutAnalyticsData, userData);
											}
											GTMCheckoutStep2_5GoSuccess(checkoutAnalyticsData, userData);
											setTimeout(() => {
												navigate("/checkout/success", { state: { payment_id: searchParams.get("payment_id")} });
											}, 500);
										}
									},
									(error) => {
										// navigate("/checkout/success", { state: { payment_id: searchParams.get("payment_id")} });
									},
								);

							} else if(jsonObj.payment_status === "FAILED") {
								// GTMCheckoutCCPaymentFailed(checkoutAnalyticsData, userData);
								clearInterval(interval);
								navigate(
									"/checkout/course",
									{ state: { payment_id: searchParams.get("payment_id"), regform_id: 1,payment_status:false,countdown:false } }
								);
							} else {
								// Do something else when status is not 1
								// No action required in this block for now
							}
						} else {
							// Do something else when status is not 1
							// No action required in this block for now
						}
					})
					.catch((error) => {
						
					});
			} catch (error) {
				console.error("Error checking payment status:", error);
			}
		}, 8000);
	}

	React.useEffect(() => {
		displayCountDown();

		dispatch(clearMessage());

		return function cleanup() {
			timeoutHandle.forEach((timeout) => {
				clearTimeout(timeout);
			});
			timeoutHandle = [];
		};
	}, []);
	// console.log(Moment(currentUser.learnermaster_dob).format("DD-MM-YYYY"));
	return (
		<>

		
			<Container className='mt-5 loading' fluid>

				<div className='message'>
					<h3>Please Wait...</h3>
					<div>We are currently verifying your payment.</div>
					<div>Please contact us if you need further assistance.</div>
				</div>
				<div className='mt-5 note'>
					<div><span>Note:</span> Do not refresh, close or click the back button.</div>
				</div>

			</Container>

		</>
	);
}

export default Omiseloading;
