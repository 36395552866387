import React from "react";
import { Container, Row, Col } from "reactstrap";
import styles from "./checkout/checkoutpage.module.scss";

function MaintenancePage() {
	return (
		<div className={styles.root}>

			<Container fluid className={styles.main}>
				<Row>
					{location.pathname !== "/checkout/success" ? (
						<>
							<Col lg={12}>
								<div className={styles.maintenance_page}>
									<h1>Site Under Maintenance</h1>
									<p>We are currently performing maintenance. Please check back later.</p>
								</div>
							</Col>
						</>
					) : (
						<div>Loading...</div>
					)}
				</Row>
			</Container>
		</div>

	);
}

export default MaintenancePage;