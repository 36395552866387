import { useAuth } from "hooks/selectors/useAuth";
import { useEventCheckout } from "reducers/eventRegisterSlice";
import  styles from "./EventCheckoutSummaryPage.module.scss";
import { Button, Col, Row } from "reactstrap";
import { ReactComponent as LearnerSvg } from "assets/img/endorse/icon_learner.svg";
import { ReactComponent as EventSvg } from "assets/img/Events/icon_event.svg";
import { ReactComponent as LocationSvg } from "assets/img/Events/icon_locate.svg";
import { ReactComponent as TimeSvg } from "assets/img/Events/icon_clock.svg";
import { ReactComponent as TicketSvg } from "assets/img/Events/icon_tics.svg";
import { ReactComponent as TncSvg } from "assets/img/endorse/icon_T&C.svg";
import SignatureCanvas from "react-signature-canvas";
import { useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Divider } from "@material-ui/core";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";

import generalApi from "services/general-api";
import { convert24hTo12h } from "utilities/common";


export const EventCheckoutSummaryPage = () => {
	
	const {checkoutInfo} = useEventCheckout();	
	const [accordionOpen, setAccordionOpen] = useState(false);
	const { userData } = useAuth();
	const MySwal = withReactContent(Swal);
	const [paymentmethod, setPaymentmethod] = useState("paynow");
	const {event} = checkoutInfo;
	let navigate = useNavigate();
	const [searchParams] = useSearchParams();


	const [loading, setLoading] = useState(false);
	const [disabled, setDisabled] = useState(false);
	

	console.log("checkoutInfo", checkoutInfo,userData);



	const personal_details = [
		{
			label: "Name",
			value: userData.user.learnermaster_name,
		},
		{
			label: "NRIC",
			value: userData.user.learnermaster_nricmask,
		},
		{
			label: "Date of Birth",
			value: userData.user.learnermaster_dob,
		},
		{
			label: "Nationality",
			value: userData.user.learnermaster_nationality
		},
		{
			label: "Email",
			value: userData.user.learnermaster_email
		},
		{
			label: "Mobile",
			value: userData.user.learnermaster_mobile
		},
		{
			label: "Gender",
			value: userData.user.learnermaster_gender,
		},
	];


	const event_details = [
		{
			label: "Location",
			value: event.event_location,
			icon : LocationSvg
		},
		{
			label: "Event Date",
			value:event.event_from === event.event_to 
				? `${moment(event.event_from).format("DD MMM YYYY")}`
				: `${moment(event.event_from).format("DD MMM YYYY")} - ${moment(event.event_to).format("DD MMM YYYY")}`,
			icon: TimeSvg,
		},
		{
			label: "Event Time",
			value: event.event_time_from 
				? `${convert24hTo12h(event.event_time_from) } - ${convert24hTo12h(event.event_time_to)}`
				: "-",
			icon: TimeSvg,
		},
		{
			label: "No of Tickets/Registration",
			value:checkoutInfo.eventregform_quantity,
			icon: TicketSvg
		}
	];


	const toggleAccordion = () => {
		setAccordionOpen(!accordionOpen);
	};


	const checkboxTNC = useRef(null);
	const canvasRef = useRef(null);



	function selectPaymentmethod(event) {
		const selectedMethod = event.currentTarget.dataset.method;
		setPaymentmethod(selectedMethod);
		console.log(window.OmiseCard);
	}



	async function handlePayment(){
		const dataUrl = canvasRef.current.toDataURL("image/png");
		if(checkboxTNC.current.checked === false){
			MySwal.fire({
				title: "",
				html: "Please agree to the terms and conditions",
				icon: "error",
			});
			return;
		}



		if(canvasRef.current.isEmpty()){
			// alert("Please sign the signature box");
			// 	const MySwal = withReactContent(Swal);
			MySwal.fire({
				title: "",
				html: "Please sign before continue",
				icon: "error",
				// icon: "warning",
				// didOpen: () => {
				// 	// `MySwal` is a subclass of `Swal` with all the same instance & static methods
				// 	MySwal.showLoading();
				// },
			});
			return;
		}

		if (paymentmethod == "creditcard") {
			const omisePublicKeyUAT = process.env.REACT_APP_OMISE_PKEY_UAT;
			const omisePublicKeyPROD = process.env.REACT_APP_OMISE_PKEY_PROD;
			const currentUrl = window.location.href;

			if (currentUrl.includes("learn.fca.edu.sg")) {
				// production to load production pkey
				window.OmiseCard.configure({
					publicKey: omisePublicKeyPROD,
				});
			} else {
				// uat / development to load uat pkey
				window.OmiseCard.configure({
					publicKey: omisePublicKeyUAT,
				});
			}

			const epaymentPayload = {
				omiseToken: "",
				omiseSource: "",
				eventregform_id: checkoutInfo.eventregform_id,
				payment_amount: checkoutInfo.eventregform_total_price,
				payment_method: "omise_credit_card",
				signature: dataUrl,
			};


			setLoading(true);
			const  d = await generalApi.epaymentForEvent(epaymentPayload);
			if(d.status !== 1){
				setLoading(false);
				return;
			}

			window.OmiseCard.open({
				amount: d.data.payment_amount,
				currency: "SGD",
				defaultPaymentMethod: "credit_card",
				onCreateTokenSuccess: (nonce) => {
					const chargePayload = {
						payment_id : d.data.payment_id,
						payment_mode : "omise_credit_card",
						payment_amount : d.data.payment_amount,
					};

					if (nonce.startsWith("tokn_")) {
						chargePayload.omiseToken = nonce;
					} else {
						chargePayload.omiseSource = nonce;
					}

					generalApi
						.epaymentChargeCreditCardForEvent(chargePayload)
						.then((d) => {
							if (d.status == 2) {
								window.location.href = d.url;
								// const newSearchParam = [];
								// for(let entry of searchParams.entries()) {
								// 	newSearchParam.push(entry);
								// }
								// if (d.url.includes("?")) {
								// 	window.location.href = d.url + "&" + createSearchParams(getUrlParams(newSearchParam, checkoutAnalyticsData, courserun_id)).toString();
								// } else {
								// 	window.location.href = d.url + "?" + 
								// }
							}
						})
						.catch((error) => {
							console.warn("error", error);
							// console.log(error);
							setLoading(false);
						});
				},
			});
			
		} else if (paymentmethod == "paynow") {
			const data = {
				omiseToken:  "",
				omiseSource:  "",
				payment_method:  "omise_paynow",
				signature:  dataUrl,
				eventregform_id : checkoutInfo.eventregform_id,
			};
			const epaymentResult =  await generalApi.epaymentForEvent(data);
			const newSearchParam = [];
			for(let entry of searchParams.entries()) {
				newSearchParam.push(entry);
			}
			// GTMCheckoutStep2GoPaynow(checkoutAnalyticsData, userData);
			navigate({
				pathname: "/event/checkout/omise/paynow",
				// search: createSearchParams(getUrlParams(newSearchParam, checkoutAnalyticsData, courserun_id)).toString(),
			},{
				state: {
					payment_id: epaymentResult.data.payment_id,
					payment_paynow_qr: epaymentResult.data.payment_paynow_qr,
				}
			});

		}

		// dispatch(
		// 	saveRegisterForm({
		// 		signature: dataUrl,
		// 	}),
		// );
		// navigate("/course/confirm");

	}

	return <div>


		{/* Personal Detail */}
		<div className={styles.root}>
			<Row>
				<Col>
					<div className={"fs40 font-weight-bold text-color-dark"}>
							Welcome,{" "}
						<span className={styles.text_color_green}>
							{userData.user.learnermaster_name}
						</span>
					</div>
				</Col>
			</Row>
			<Row className='flex align-items-center mb-3'>
				<div>
					<LearnerSvg className='ml-2' style={{ width: "60px" }} />
				</div>
				<div className={"font-weight-bold fs22 ml-2 text-color-dark"}>Learner Information</div>
			</Row>


			<Row 
				style={{
					border: "1px solid #FFBD43",
					borderTop: "3px solid #FFBD43",
					padding: "30px 10px",
				}}
			>	
				{
					personal_details.map((detail, index) => {
						return <Col xs={12} md={6} lg={4} className="mt-2">
							<div className='font-weight-bold text-color-dark '>
								{detail.label}
							</div>
							<div className='pb-3 text-color-dark $fs15' style={{ wordWrap: "break-word" }}>
								{detail.value}
							</div>
						</Col>;
					})
				}
			</Row>



			{/* Event Detail */}
			<Row className='mt-4 flex align-items-center mb-3' >
				<div>
					<EventSvg className='ml-2' style={{ width: "60px" }} />
				</div>
				<div className={"font-weight-bold fs22 ml-2 text-color-dark"}>Event Details</div>
			</Row>

			<Row
				style={{
					border: "1px solid #0085CA",
					borderTop: "3px solid #0085CA",
					padding: "30px 10px",
				}}
			>
				<Col xs={12}>{event.event_title}</Col>
				{
					event_details.map((item) => { 
						return <Col xs={12} md={6} lg={4} className="mt-4">
							<div className={"font-weight-bold fs16 text-color-dark"}>
								{<item.icon/>}
								<span style={{paddingLeft: 5}} >{item.label}</span>
							</div>
							<div className={"text-color-dark fs15"}>{item.value}</div>
						</Col>;
					})
				}
			</Row>
		</div>


		{/* Registration Fee */}
		<div className={styles.root}>
			<Row className='flex align-items-center mb-3'>
				<div className={"font-weight-bold fs30 ml-2 text-color-dark"}>Registration Fees</div>
			</Row>
			<Row className='flex align-items-center mb-3 fs22'>
				<Col 
					className="mt-2"
					xs={12}
					style={{
						border: "1px solid #0085CA",
						padding: "30px",
					}}
				>
					<div className="mb-3 fontwe">Registration Fees Breakdown</div>	
					<Row>
						<Col >Total Nett Fees Payable</Col>
						<Col className="text-right">${checkoutInfo.eventregform_total_price}</Col>
					</Row>
					<Row>
						<Col>{checkoutInfo.eventregform_gst_percent} % GST </Col>
						<Col className="text-right">${checkoutInfo.eventregform_total_gst}</Col>
					</Row>

					<Divider style={{ margin:"40px 0"}}/>
					<Row>
						<Col >
							<div style={{fontWeight:"bold",fontSize:"26px", color:"#0085CA"}}>Total nett Fees Payable</div>
							<div className="fs16"> (includes {checkoutInfo.eventregform_gst_percent}% GST) </div>
						</Col>
						<Col 
							className="text-right"
							style={{
								fontWeight:"bold",
								color:"#0085CA"
							}}
						>${checkoutInfo.eventregform_total_price_gst}</Col>
					</Row>
				</Col>
			</Row>
		</div>


		{/* Endorsement & Payment*/}
		<div className={styles.root}>
			<div className={"fs30 font-weight-bold text-color-dark"}>
				Endorsement & Payment
			</div>

			<div
				id='terms-and-conditions'
				onClick={toggleAccordion}
				className='cursor-pointer mt-4'
				style={{
					borderBottomWidth: "3px",
					borderBottomStyle: "solid",
					borderBottomColor: "rgb(207, 77, 134)",
				}}>
				<Row className='mb-3'>
					<Col className='col col-auto'>
						<TncSvg style={{ width: "60px" }} />
					</Col>
					<Col className='col d-flex align-items-center pl-0 font-weight-bold fs22 text-color-dark'>
							Terms & Conditions
					</Col>
				</Row>
			</div>

			<div 
				className="p-4"
				style={{whiteSpace:"normal", wordWrap:"break-word"}}
				dangerouslySetInnerHTML={{ __html: event.event_term_condition }}
			>
			</div>

			<Row className='pt-3  pb-3'>
				<Col>
					<div className='pt-3'>
						<div className='pb-2'>
							<b className='text-color-dark'>Your Signature</b>
							<a
								href='#'
								className='ml-2'
								onClick={(e) => {
									e.preventDefault();
									canvasRef.current.clear();
								}}>
									Clear
							</a>
						</div>
						<SignatureCanvas
							ref={canvasRef}
							clearOnResize={false}
							canvasProps={{height: 200, className: "sigCanvas border", style: {width: "100%", maxWidth: "500px"}}}
						/>
					</div>

					<div className='pt-2'>
						<label htmlFor='tnc-checkbox'>
							<input type='checkbox' id='tnc-checkbox' ref={checkboxTNC}></input>
							<span>
									&nbsp; I acknowledge that I have read and agree to the above
								<a
									href='#terms-and-conditions'
									className='ml-2 text-fca-blue text-underline cursor-pointer'
								>
										terms and conditions.
								</a><br />
									我确认我已阅读并同意上述
								<a
									href='#terms-and-conditions'
									className='ml-2 text-fca-blue text-underline cursor-pointer'
									onClick={(e) => {
										setAccordionOpen(true);
									}}>
										条款和条件 
								</a>
							</span>
						</label>
					</div>
				</Col>
			</Row>

			<Row className='p-3'>
				<Col className='border pb-3'>
					{/* {Number(nettFeePayableAmount - sfcClaimedAmount) > 0 ? ( */}
					<Row>
						<Col>
							<div className='mt-4 select-payment-method'>
								<span className='fs18 text-color-dark'>Select One Payment Method</span>
							</div>
							<div className='mt-3 ml-4'>
								<div className='payment-method-div flex flex-row justify-content-center'>
									<div
										className={`payment-method-div-paynow text-center ${
											paymentmethod === "paynow" ? "active" : ""
										}`}
										onClick={selectPaymentmethod}
										data-method='paynow'>
										<img
											className='payment-logo-paynow'
											src={require("assets/img/endorse/paynow-logo.png")}
										/>
										<span>Paynow</span>
										<span>
													Pay the balance amount using<br></br>Paynow
										</span>
									</div>
									<div
										className={`payment-method-div-paynow text-center ${
											paymentmethod === "creditcard" ? "active" : ""
										}`}
										onClick={selectPaymentmethod}
										data-method='creditcard'>
										<img
											className='payment-logo-cc'
											src={require("assets/img/endorse/cc-logo.png")}
										/>
										<span>Credit Card</span>
										<span>
													Pay the balance amount using<br></br>credit card
										</span>
									</div>
								</div>
							</div>
						</Col>
					</Row>

					

					<Row className='pt-2 align-items-end'>
						<Col className=''>
							<div
								style={{
									display: "flex",
									alignItems: "flex-end",
									justifyContent: "flex-end",
									width: "100%",
								}}>
								{loading && (
									<div className='d-flex justify-content-center'>
										<div className='spinner-border' role='status'>
											<span className='sr-only'>Loading...</span>
										</div>
									</div>
								)}
								<Button
									type='button'
									className='btn-gradient'
									disabled={disabled}
									onClick={handlePayment}
								>
										Continue
								</Button>
							</div>
						</Col>
					</Row>
	
				</Col>
			</Row>
		</div>
	</div>; 
};