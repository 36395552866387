import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import $ from "jquery";

// reactstrap components
import { Col, Row } from "reactstrap";
import { useWindow } from "hooks/UseWindow";
import OthersEventCard from "components/Event/OthersEventCard";
import styles from "../../views/pages/learner/event/Event.module.scss";

function OtherEvents({ events }) {
	const [currentDisplayEvent, setCurrentDisplayEvent] = useState(1);
	let totalEvents = events.length;
	const slider = React.useRef(null);
	const { width } = useWindow();

	var slider_settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
		beforeChange: (oldIndex, newIndex) => {
			// if (newIndex > oldIndex) {
			// 	newIndex = newIndex + 1;
			// }
			if (newIndex == 0) {
				$(".completed-arrow .arrow-left").addClass("disable");
				$(".completed-arrow .arrow-right").removeClass("disable");
			} else if (newIndex == totalEvents - 1) {
				$(".completed-arrow .arrow-right").addClass("disable");
				$(".completed-arrow .arrow-left").removeClass("disable");
			} else {
				$(".completed-arrow .arrow-left,.completed-arrow .arrow-right").removeClass("disable");
			}
		},
	};

	return (
		<div className={"fadeInDiv"}>
			<Row className='align-items-center justify-content-between' style={{ paddingRight: "10px", paddingLeft: "10px" }}>
				<Col className=''>
					<span className='mb-0 fs24 font_2 text-fca-darks'>
						{"Explore More Fun Experiences You Might Enjoy!"}
					</span>
				</Col>
				{Object.keys(events).length !== 0 && width <= 575 && (
					<Col xl='auto' md='auto' xs='auto'>
						<div className='arrow-wrap flex completed-arrow'>
							<div
								className='arrow arrow-left disable'
								onClick={() => slider?.current?.slickPrev()}
							>
								<i className='fa fa-angle-left' aria-hidden='true'></i>
							</div>
							<div
								className={"arrow arrow-right ml-2" + (totalEvents == 1 ? " disable" : "")}
								onClick={() => slider?.current?.slickNext()}
							>
								<i className='fa fa-angle-right' aria-hidden='true'></i>
							</div>
						</div>
					</Col>
				)}
			</Row>

			<div className={"w-100 pb-2 pt-0"}>
				{Object.keys(events).length !== 0 ? (
					<div className={"other_event_slider"}>
						<Slider ref={slider} className={`${width > 575 ? "adaptive-slider" : "adaptive-mobile-slider"}`} arrows={width > 575 ? true : false} {...slider_settings} >
							{events.map((event, i) => (
								<OthersEventCard key={i} event={event} />
							))}
						</Slider>
					</div>
					
				) : (
					<div>
					</div>
				)}
			</div>
		</div>
	);
}

export default OtherEvents;
