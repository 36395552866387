import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interaction from "@fullcalendar/interaction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Button, Row, Modal, Card, CardBody, Col, CardHeader, Collapse } from "reactstrap";

import { ReactComponent as BusIcon } from "assets/img/Course/upcoming/bus.svg";
import { ReactComponent as MealIcon } from "assets/img/Course/upcoming/meal.svg";
import { ReactComponent as TimeIcon } from "assets/img/Course/upcoming/date.svg";
// import { ReactComponent as RescheduleIcon } from "assets/img/Course/upcoming/reschedule.svg";
// import { ReactComponent as WithdrawalIcon } from "assets/img/Course/trainer.svg";
import { ReactComponent as CertificateIcon } from "assets/img/Course/registration/success.svg";
// import { ReactComponent as TrainerIcon } from "assets/img/Course/trainer.svg";

import ModalCertificates from "./ModalCertificates";
import { PrintCourseDate, PrintDate } from "../../../../common_function/function.js";
import generalApi from "services/general-api";
import BundleCertCard from "components/LearnerPortal/BundleCertCard";
import { getErrorMessage } from "utilities/error";

const VERSION = process.env.REACT_APP_APP_VERSION;

function ModalBundleList() {
	let navigate = useNavigate();
	const params = useParams();
	const [certModalChange, setCertModalChange] = useState(false);
	const [message, setmessage] = useState("");
	const MySwal = withReactContent(Swal);
	const [accordionOpen, setAccordionOpen] = useState(false);
	const [bundle, setBundle] = useState([]);

	const toggleAccordion = () => {
		setAccordionOpen(!accordionOpen);
	};

	useEffect(() => {
		generalApi.getBundleDetail(params.id).then(
			(response) => {
				setBundle(response);
			},
			(error) => {
				setmessage(getErrorMessage(error));
			},
		);
	}, []);

	const handlePopup = (displayMSG) => {
		MySwal.fire({
			title: "Unable to Proceed!",
			html: displayMSG,
			icon: "error",
			// icon: "warning",
			// didOpen: () => {
			// 	// `MySwal` is a subclass of `Swal` with all the same instance & static methods
			// 	MySwal.showLoading();
			// },
		});
	};

	return (
		<>
			<div className=''>
				<div className='title-container'>
					<h2>Certificates</h2>
					<p className="mb-2" style={{ fontSize: 20, fontWeight: "600", color: "rgb(131,77,149)"}}>Professional Certifications</p>
				</div>
				{Object.keys(bundle).length !== 0 &&
					bundle.professional_cert &&
					bundle.professional_cert.length > 0 && (
					<>
						{bundle?.professional_cert?.map((cert, i) => (
							<BundleCertCard key={i} cert={cert} />
						))}
						{/* 
						<Row className='pt-6'>
							<Col xl='auto' className='ml-auto'>
								{" "}
								<div className='btn-wrap'>
									<Link to='/course' className='btn btn-default'>
										Back
									</Link>
								</div>
							</Col>
						</Row> */}
					</>
				)}
			</div>
		</>
	);
}

export default ModalBundleList;
