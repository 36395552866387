import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import * as yup from "yup";
import { yupHelper } from "utilities/yupHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Modal,
	Row,
	Col,
} from "reactstrap";

import { verifyOtp, resendOtp, hyperAdminResendOtp, hyperAdminVerifyOtp } from "../../actions/auth";
import { clearMessage } from "actions/message";

const schema = yup.object({
	otp: yupHelper.otp(),
});

function HyperAdminOTPModal() {
	const [modalChange, setModalChange] = React.useState(true);
	const [loading, setLoading] = useState(false);
	const [resendLoading, setResendLoading] = useState(false);
	const [seconds, setSeconds] = useState(30);
	const { message } = useSelector((state) => state.message);
	const dispatch = useDispatch();
	let navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		if (seconds !== 0) {
			const interval = setInterval(() => {
				setSeconds(seconds - 1);
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [seconds]);

	const handleOTP = (data) => {
		setLoading(true);
		dispatch(hyperAdminVerifyOtp(data.otp))
			.then(() => {
				navigate("/login/hyperadmin-user-selection");
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const handleResendOTP = () => {
		setResendLoading(true);
		setSeconds(30);
		dispatch(hyperAdminResendOtp())
			.then(() => {
				setResendLoading(false);
			})
			.catch(() => {
				setResendLoading(false);
			});
	};

	useEffect(() => {
		dispatch(clearMessage());
	}, []);

	return (
		<>
			<Modal
				className='modal-dialog-centered'
				isOpen={modalChange}
				// toggle={() => setModalChange(false)}
			>
				<button
					aria-label='Close'
					className='close d-none'
					data-dismiss='modal'
					type='button'
					onClick={() => setModalChange(false)}
				>
					<span aria-hidden={true}>×</span>
				</button>

				<div className='modal-header pt-5'>
					<div className='modal-header-wrap text-center'>
						<h3>OTP Verification</h3>
						<p>To enter hyper admin panel, you need to enter the OTP verification code that sent to hyper admin email, or you can ask manvee</p>
					</div>
				</div>
				<div className='modal-body'>
					<Form role='form' onSubmit={handleSubmit(handleOTP)}>
						<FormGroup>
							<input
								className={"form-control " + (errors.otp ? "is-invalid" : "")}
								defaultValue=''
								placeholder='OTP Verification Code'
								type='text'
								{...register("otp")}
							/>
							<div className='mt-2 invalid-feedback text-center'>{errors.otp?.message}</div>
							<div className='mt-2 text-center'>
                Do not receive OTP Code?{" "}
								{seconds !== 0 && <span className='text-blue bold'>{seconds}</span>}
								{seconds === 0 && (
									<span onClick={handleResendOTP} role='button' className='text-blue bold'>
                    Resend
									</span>
								)}
							</div>
						</FormGroup>
						<div className='modal-footer justify-content-center mb-4'>
							<Button className='mt-4' color='success' type='submit' disabled={loading}>
								{loading && <span className='spinner-border spinner-border-sm'></span>}
								<span>Verify</span>
							</Button>
						</div>

						{message && (
							<div className='form-group'>
								<div
									className={classnames(
										"alert",
										message === "OTP Sent Success" ? "alert-success" : "alert-danger",
									)}
									role='alert'
								>
									{message}
								</div>
							</div>
						)}
					</Form>
				</div>
			</Modal>
		</>
	);
}

export default HyperAdminOTPModal;
