import { useState } from "react";
import { Badge, Card, CardBody, CardSubtitle, CardTitle, Col, Row } from "reactstrap";
import defaultImage from "assets/img/default_img.jpg";
import styles from "./bundleinfo.module.scss";
import defaultCourse from "assets/img/default_course.jpg";

const CourseCard = ({ course }) => {
	const [hovered, setHovered] = useState(false);

	const handleHover = () => {
		setHovered(!hovered);
	};

	return (
		<Card
			key={course.course_id}
			className={styles.course_card}
			// onMouseEnter={handleHover}
			// onMouseLeave={handleHover}
		>
			<img
				src={course.course_banner_image || defaultCourse}
				width='100%'
				className={`${styles.course_banner_image} ${hovered ? styles.image_hovered : ""}`}
			/>
			<CardBody className={`${styles.card_body}`}>
				<CardTitle tag='h5' className={styles.course_title}>
					{course.course_title}
				</CardTitle>
				{course.bundles[0] ? (
					<CardSubtitle className=' text-muted' tag='h6'>
						<Badge
							className={`mr-1 ${styles.course_badge}`}
							style={{
								backgroundColor: course.bundles[0].bundle_color,
								color: "white",
								fontWeight: "normal",
							}}>
							{course.bundles[0].bundle_name}
						</Badge>
					</CardSubtitle>
				) : null}
				<Row>
					{course.course_learner_count ? (
						<Col>
							<span className={styles.learner_count}>
								<i className='fa fa-users' aria-hidden='true'></i> {course.course_learner_count}{" "}
								Learners
							</span>
						</Col>
					) : null}

					{course.course_duration ? (
						<Col>
							<span className={styles.learner_count}>
								<i
									className='fa fa-users'
									aria-hidden='true'
									style={{
										color: "#3A83C4",
									}}></i>{" "}
								{`${course.course_duration} Days`}
							</span>
						</Col>
					) : null}
				</Row>
				<div className={`${styles.skill_container} ${hovered ? styles.skill_hovered : ""}`}>
					<span>{"Skills Acquired"}</span>
					<p>test</p>
				</div>
			</CardBody>
		</Card>
	);
};

export default CourseCard;
