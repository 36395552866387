import React from "react";
// reactstrap components
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import newsApi from "services/news-api";
import { toast } from "react-toastify";
import { getErrorMessage } from "utilities/error";
import { useDebounceFn } from "ahooks";
import { useInfiniteQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ARTICLE_COMMENT_LENGTH } from "utilities/constant";
import { postCommentThunk } from "actions/news";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

function CommentArea({ article, isComment, isReachEnd, onReplyPress, comment_id }) {
	moment.locale("en", {
		relativeTime: {
			future: "in %s",
			past: "%s ago",
			s: "seconds",
			ss: "%ss",
			m: "a minute",
			mm: "%dm",
			h: "an hour",
			hh: "%dh",
			d: "a day",
			dd: "%dd",
			M: "a month",
			MM: "%dM",
			y: "a year",
			yy: "%dY",
		},
	});
	const [comment, setComment] = React.useState("");
	const [replyId, setReplyId] = React.useState(null);
	const [replyUser, setReplyUser] = React.useState(null);

	const ref = React.useRef();
	const dispatch = useDispatch();
	const { user: currentUser } = useSelector((state) => state.auth);
	let navigate = useNavigate();

	// get article comments
	let articleCommentsQuery = null;
	if (currentUser) {
		articleCommentsQuery = useInfiniteQuery(["article-comments", article.article_id], ({ pageParam }) => {
			return newsApi.getArticleComment(
				pageParam,
				article.article_id,
				comment_id ? comment_id.toString() : null,
			);
		},
		{
			getNextPageParam: lastPage => {
				// if last page
				if (lastPage.data.length < ARTICLE_COMMENT_LENGTH) {
					return undefined;
				}
				return lastPage.nextPage;
			},
			cacheTime: 0,
		},
		);
	}
	

	const flattenData = articleCommentsQuery && articleCommentsQuery.data
		? articleCommentsQuery.data.pages.flatMap(page => page.data)
		: [];

	React.useEffect(() => {
		if (isComment) {
			if (currentUser) {
				ref.current.focus();
			} else {
				navigate("/login");
			}
		}
	}, [isComment]);

	React.useEffect(() => {
		if (currentUser) {
			if ( isReachEnd && !articleCommentsQuery.isFetchingNextPage && articleCommentsQuery.hasNextPage ) {
				articleCommentsQuery.fetchNextPage();
			}
		}
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isReachEnd]);

	// comment 
	const postCommentMutation = useMutation(
		(payload) => {
			return dispatch(postCommentThunk(payload)).unwrap();
		},
		{
			onSuccess: _data => {
				setComment("");
				setReplyId(null);
				setReplyUser(null);
				articleCommentsQuery.refetch();
			},
			onError: err => {
				toast.error(getErrorMessage(err));
			},
		},
	);
	const handlePostCommentPress = useDebounceFn(
		async (article, commentString) => {
			if (commentString !== "") {
				try {
					const data = await postCommentMutation.mutateAsync({
						article_id: article.article_id,
						comment: commentString,
						reply_to_comment: replyId,
					});
				} catch (error) {
					// do nothing
				}
			
			}
		},
		{ leading: true, trailing: false, wait: 1000 },
	).run; 

	return (
		<div>
			{currentUser && (
				<>
					{replyId !== null && (
						<div style={{
							display: "flex",
							flexDirection: "row",
							backgroundColor: "rgb(204,204,204)",
							alignItems: "center",
							justifyContent: "space-between",
							paddingLeft: 16,
							marginBottom: 10,
						}}>
							<span style={{ fontSize: 14 }}>{`Replying to ${replyUser}`}</span>
							<div
								onClick={() => {
									setReplyId(null);
									setReplyUser(null);
								}}
								style={{ alignItems: "center", justifyContent: "center", padding: 10, paddingLeft: 20, paddingRight: 20 }}
							>
								<i style={{ fontSize: 20 }} className='fa fa-times'></i>
							</div>
						</div>
					)}
					<div style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}>
						<img
							src={require("assets/img/Articles/user.png")}
							style={{ objectFit:"contain", marginRight: 10, height: 30, width: 30, borderRadius: 15 }}
						/>
						<input
							style={{display: "flex", flex: 1}}
							className={"form-control"}
							id='input-comment'
							placeholder='Write a comment'
							value={comment}
							ref={ref}
							onChange={(e) => setComment(e.target.value)}
						/>
						<div onClick={() => {
							if (!postCommentMutation.isLoading) {
								handlePostCommentPress(article, comment);
							}
						}} className={`${postCommentMutation.isLoading ? "is-button-disabled" : "" } article-comment-submit-btn article-comment-btn-gradient ml-2`}>
							{postCommentMutation.isLoading &&
						<span className='spinner-border spinner-border-sm'></span>
							}
							{!postCommentMutation.isLoading &&
						<span>{"Post"}</span>
							}
						</div>
					</div>
					<hr className="mt-4 mb-4"></hr>
					{articleCommentsQuery.isLoading && (
						<div className='justify-content-center mt-4 w-100 flex'>
							<span className='spinner-border spinner-border-sm'></span>
						</div>
					)}
					{!articleCommentsQuery.isLoading && flattenData && flattenData.length > 0 && flattenData.map((articleComment, index) => {
						return (
							<div key={index} style={{marginBottom: 16}}>
								<div style={{ display: "flex", flexDirection: "row" }}>
									<img
										src={articleComment.is_user ? require("assets/img/Articles/user.png") :  require("assets/img/fca-profile.png")}
										style={{ objectFit:"cover", height: 30, width: 30, borderRadius: 15 }}
									/>
									<div style={{ marginLeft: 10, flexShrink: 1, backgroundColor: "rgb(238,238,238)", padding: 10 }}>
										<div style={{ display: "flex", flexDirection: "row" }}>
											<span style={{ fontSize: 14, fontWeight: "600" }}>{articleComment.username}</span>
											<span style={{ fontSize: 14, marginLeft: 10, color: "rgb(73,85,103)" }}>
												{moment
													.utc(articleComment.created_at)
													.local()
													.startOf("seconds")
													.fromNow()}
											</span>
										</div>
										<p className="mt-1">
											{articleComment.comment}
										</p>
										<div style={{ cursor: "pointer" }} onClick={() => {
											ref.current.focus();
											setReplyId(Number(articleComment.articlecomment_id));
											setReplyUser(articleComment.username);
										}}>
											<span style={{ fontSize: 14, fontWeight: "600", color: "rgb(73,85,103)" }}>{"Reply"}</span>
										</div>
									</div>

								</div>
								{articleComment.subcomments && articleComment.subcomments.length > 0 && 
							<div style={{ paddingTop: 6, marginTop: 10, marginLeft: 20, borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "rgb(55,132,196)", paddingLeft: 30 }}>
								{articleComment.subcomments && articleComment.subcomments.length > 0 && articleComment.subcomments.map((reply, replyIndex) => {
									return (
										<div key={replyIndex} style={{ display: "flex", flexDirection: "row", marginBottom: 6 }}>
											<img
												src={reply.is_user ? require("assets/img/Articles/user.png") :  require("assets/img/fca-profile.png")}
												style={{ objectFit:"cover", height: 30, width: 30, borderRadius: 15 }}
											/>
											<div style={{ marginLeft: 10, flexShrink: 1, backgroundColor: "rgb(238,238,238)", padding: 10 }}>
												<div style={{ display: "flex", flexDirection: "row" }}>
													<span style={{ fontWeight: "600" }}>{reply.username}</span>
													<span style={{ fontSize: 14, marginLeft: 10, color: "rgb(73,85,103)" }}>
														{moment
															.utc(reply.created_at)
															.local()
															.startOf("seconds")
															.fromNow()}
													</span>
												</div>
												<p className="mt-1">
													{reply.comment}
												</p>
											</div>
										</div>
									);
								})}
							</div>
								}
						
							</div>
						);
					})}
					{articleCommentsQuery.isFetchingNextPage && 
						<div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10 }}>
							<p style={{ fontSize: 12, fontWeight: "400"}}>
								{"Loading Comments"}
							</p>
						</div>
					}
				</>
			)}
			{!currentUser && (
				<div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", overflow: "hidden"}}>
					<Button className='btn-gradient w-100' style={{ padding: 16 }} onClick={() => navigate("/login")}>
						<span>{"Login now to view comments"}</span>
					</Button>
				</div>
			)}
		</div>
		
	);
}

export default CommentArea;
