export function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export function convert24hTo12h(time) {
	// Split the time string into hours and minutes
	const [hours, minutes] = time.split(":").map(num => parseInt(num, 10));
	// Determine the suffix and adjust hours to 12-hour format
	const suffix = hours >= 12 ? "PM" : "AM";
	const adjustedHours = hours % 12 || 12; // Converts "0" to "12"

	// Return the formatted string
	return `${adjustedHours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${suffix}`;
}
