import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useAuth } from "hooks/selectors/useAuth";

// reactstrap components
import { Row, Col, Button, Card, CardBody } from "reactstrap";

import { ReactComponent as BusIcon } from "assets/img/Course/upcoming/bus.svg";
import { ReactComponent as MealIcon } from "assets/img/Course/upcoming/meal.svg";
import { ReactComponent as TimeIcon } from "assets/img/Course/upcoming/date_blue.svg";
import { ReactComponent as CourseIcon } from "assets/img/Course/upcoming/course_ID_blue.svg";
import { ReactComponent as UserIcon } from "assets/img/Course/upcoming/user_blue.svg";
import { ReactComponent as PhoneIcon } from "assets/img/Course/upcoming/phone_blue.svg";
import { ReactComponent as LocationIcon } from "assets/img/Course/upcoming/location_blue.svg";
import { ReactComponent as AlmsIcon } from "assets/img/Course/upcoming/alms_blue.svg";
import defaultCourse from "assets/img/default_course.jpg";

import { ReactComponent as CertificateIcon } from "assets/img/Course/registration/success.svg";
import ModalCertificates from "views/pages/learner/course/ModalCertificates";
import { PrintCourseDate } from "../../common_function/function.js";

import styles from "../../views/pages/learner/event/Event.module.scss";
import { useWindow } from "hooks/UseWindow.js";


function OthersEventCard({ event }) {
	const VERSION = process.env.REACT_APP_APP_VERSION;
	const MySwal = withReactContent(Swal);
	const authData = useAuth();
	// const userData = authData.userData.user;
	const { width } = useWindow();
	
	// const handlePopup = (e, displayMSG) => {
	// 	e.preventDefault();
	// 	e.stopPropagation();
	// 	MySwal.fire({
	// 		title: "Unable to Proceed!",
	// 		html: displayMSG,
	// 		icon: "error",
	// 	});
	// };

	// const handleSFCClick = (e) => {
	// 	e.preventDefault();
	// 	e.stopPropagation();
	// 	const data = {};
	// 	const reschedule_to = course.reschedule_to;

	// 	data.callback_url = process.env.REACT_APP_WEB_URL + "/course";
	// 	data.course_id = reschedule_to.course.course_ref;
	// 	data.course_fee = course.learner_sfc;
	// 	data.courserun_id = reschedule_to.courserun_apicourserunid;
	// 	data.courserun_start_date = reschedule_to.courserun_course_from;
	// 	data.nric = userData.learnermaster_nric;
	// 	data.email = userData.learnermaster_email;
	// 	data.mobile = userData.learnermaster_mobile;
	// 	data.tms_regform_id = course.registrationform_id_encrypt;
	// 	data.tms_course_id = reschedule_to.courserun_course_id;
	// 	data.tms_courserun_id = reschedule_to.courserun_id;
	// 	data.tms_learner_id = course.learner_id;
	// 	data.tms_learnermaster_id = userData.learnermaster_id;
	// 	data.purpose = "Reschedule";
		
	// 	// console.log(data);
	// 	const json_str = btoa(JSON.stringify(data));
	// 	// console.log(json_str);
	// 	// setLoading(true);
	// 	// GTMCheckoutYesSFCButton(checkoutAnalyticsData, userData);

	// 	window.location.href = process.env.REACT_APP_API_URL + "/api/sfc_pay?encoded=" + json_str;

	// };

	// const getStatusTag = (
	// 	course_reschedule_status,
	// 	tobe_rescheduled,
	// 	course_withdrawal_status,
	// 	pending_assessment_date,
	// ) => {
	// 	if (course_withdrawal_status == "PENDING") {
	// 		return (
	// 			<span className='p-2 ml-2 course-sticker pending-withdrawal'>
	// 				{"Withdrawal Processing"}
	// 			</span>
	// 		);
	// 	}
	// 	if (course_withdrawal_status == "REJECTED") {
	// 		return <span className='p-2 ml-2 course-sticker rejected'>{"Withdrawal Rejected"}</span>;
	// 	}
	// 	if (tobe_rescheduled && course_reschedule_status !== "Submitted") {
	// 		return (
	// 			<span className='p-2 ml-2 course-sticker pending-reschedule'>{"Reschedule Required"}</span>
	// 		);
	// 	}
	// 	if (course_reschedule_status == "Submitted") {
	// 		return (
	// 			<span className='p-2 ml-2 course-sticker pending-reschedule'>
	// 				{"Reschedule Processing"}
	// 			</span>
	// 		);
	// 	}
	// 	if (course_reschedule_status == "Pending SFC Cancellation" || course_reschedule_status == "Pending SFC Resubmission" || course_reschedule_status == "Pending SFC Approval") {
	// 		return (
	// 			<span className='p-2 ml-2 course-sticker pending-reschedule'>
	// 				{course_reschedule_status}
	// 			</span>
	// 		);
	// 	}
	// 	if (course_reschedule_status == "Rejected") {
	// 		return <span className='p-2 ml-2 course-sticker rejected'>{"Reschedule Rejected"}</span>;
	// 	}
	// 	if (course_reschedule_status == "Assessment") {
	// 		if (pending_assessment_date) {
	// 			return (
	// 				<>
	// 					<span className='p-2 ml-2 course-sticker pending-assessment'>
	// 						{"Pending Assessment"}
	// 					</span>
	// 					<span className='pt-3 d-block course-date'>{pending_assessment_date}</span>
	// 				</>
	// 			);
	// 		} else {
	// 			return (
	// 				<>
	// 					<span className='p-2 ml-2 course-sticker pending-assessment'>
	// 						{"Pending Assessment"}
	// 					</span>
	// 				</>
	// 			);
	// 		}
	// 	}
	// };

	return (
		<Card className={`cursor-pointer ${styles.other_event_card_container}`} style={{ marginBottom: "20px",marginLeft: "10px", marginRight: "10px", height: "100%" }}>
			{/* <Card className={`m-0 ${width <= 575 ? "" : "h-100"} cursor-pointer pb-1 ${styles.other_event_card_container}`}> */}
			{/* <Card style={{ marginBottom: 0 }}> */}
			{/* <Link to={{pathname:"/course/course-details/", state: {course: course}}} className='text-dark'> */}
			<Link
				to={"/events/" + event.event_id} state={{ event: event }}
				target="_blank"
				className='text-dark h-100'>
				<CardBody style={{ padding: 0, height: "100%" }}>
					<div style={{ height: "100%", overflowWrap: "anywhere" }}>
						<Row className="mb-3 mx-0" style={{ overflow: "hidden" }}>
							<img
								alt={event.event_title}
								src={event.event_listing_image_url ? event.event_listing_image_url : require("assets/img/FALCOMM_LOGO.png")}
								width='100%'
								className={`${styles.other_event_banner_image}`}
							/>
						</Row>
						<Row className={`align-items-center m-0 mb-2 ${styles.other_event_main_date_container}`}>
							<div className={`${styles.other_event_date_container}`}>
								{/* ${moment(
										event.event_published,
									).format("MMMM DD YYYY, hh:ssA")} */}
								<i className={`fa fa-calendar mr-2 ${styles.other_event_date_icon}`} aria-hidden></i>
								<p className={`mb-0 ${styles.other_event_date_text}`}>
									{event.event_from &&
										`${moment(event.event_from).format("DD MMM")}`
									}
									{event.event_to && event.event_from &&
										" - "
									}
									{event.event_to &&
										`${moment(event.event_to).format("DD MMM")}`
									}
								</p>
								{(event.event_time_to || event.event_time_from) &&
									<p className={"mb-0"} style={{ fontSize: "14px", color: "#fff", marginLeft: "10px", marginRight: "10px"}}> | </p>
								}
								{(event.event_time_to || event.event_time_from) &&
									<i className={`fa fa-clock mr-2 ${styles.other_event_date_icon}`} aria-hidden></i>
								}
								<p className={`mb-0 ${styles.other_event_date_text}`}>
									{event.event_time_from &&
										`${moment(event.event_time_from, "HH:mm").format("h:mmA")}`
									}
									{event.event_time_to && event.event_time_from &&
										" - "
									}
									{event.event_time_to &&
										`${moment(event.event_time_to, "HH:mm").format("h:mmA")}`
									}
								</p>
							</div>
						</Row>
						<div className={`${styles.other_event_card_data_container}`}>
							{/* <Row className='align-items-center m-0 mt-2 mb-2'>
								{event && event.categories && event.categories.length > 0 &&
									<div className={`${styles.other_event_category_container}`}>
										{event && event.categories && event.categories.map((category, i) => {
											return (
												<span key={i} className={`${styles.other_event_category_text}`} style={{color: category.category_color}}>
													{category.category_title}
												</span>
											);
										})}
									</div>
								}
							</Row> */}
							<Row className='align-items-center'>
								<Col md='12' xl='12'>
									<p className={`${styles.other_event_title_text}`}>{event.event_title}</p>
								</Col>
							</Row>
							<Row className='align-items-center mt-2'>
								<Col md='12' xl='12'>
									<p className={`${styles.other_event_desc_text}`}>{event.event_description}</p>
								</Col>
							</Row>
							<Row className='align-items-center mt-4'>
								<Col size="12">
									<div className='flex w-100 align-items-center'>
										{/* <Button className='btn-gradient p-3 pl-4 pr-4 w-100' type='submit'>
											<span className="fs14 font-weight-600 font_1">Register Now</span>
										</Button> */}

										<button className={`${styles.event_side_register_addtocalendar_button}`} type='submit'>
											<span className={`${styles.event_side_register_addtocalendar_title}`}>{"View Event"}</span>
										</button>
									</div>
								</Col>
							</Row>
						</div>
					</div>
					
				</CardBody>
			</Link>
		</Card>
	);
}

export default OthersEventCard;
