import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// reactstrap components
import { Row, Col } from "reactstrap";

function CourseHeader({ courseList }) {
	const [greetings, setGreetings] = useState("");
	const dispatch = useDispatch();

	const [isVisible, setIsVisible] = useState(false);
	const targetRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				const [entry] = entries;
				if (entry.isIntersecting) {
					setIsVisible(true);
					observer.unobserve(entry.target);
				}
			},
			{
				threshold: 0.1, // Adjust as needed
			}
		);

		if (targetRef.current) {
			observer.observe(targetRef.current);
		}

		return () => {
			if (targetRef.current) {
				observer.unobserve(targetRef.current);
			}
		};
	}, []);

	const { user: userData } = useSelector((state) => state.auth);
	const currentUser = userData.user;
	const generateGreetings = () => {
		var currentHour = moment().format("HH");
		if (currentHour >= 5 && currentHour < 12) {
			setGreetings("Good morning");
		} else if (currentHour >= 12 && currentHour < 17) {
			setGreetings("Good afternoon");
		} else if (currentHour >= 17 && currentHour < 5) {
			setGreetings("Good evening");
		} else {
			setGreetings("Hello");
		}
	};
	useEffect(() => {
		generateGreetings();
	}, [greetings]);

	return (
		<div ref={targetRef} className={`upcomingcourse_main_container ${isVisible ? "visible" : ""}`}>
			<Row ref={targetRef} className='align-items-center pb-4'>

				<div className='col'>
					<h2 className="font_2 fs40 text-fca-dark">
						{greetings},{" "}
						<span className='text-capitalize text-fca-green'>{currentUser.learnermaster_name.toLowerCase()}</span>
					</h2>
					{/* <p>Lorem ipsum dolor sir amet, consectetur adipiscinge dlit, sed do eiusmod</p> */}
				</div>
				<Col className='col-auto'>
					{/* <a className='' href='#pablo' onClick={(e) => e.preventDefault()}>
		<img alt='...' src={require("assets/img/learner/Search.png")} />
	</a> */}
					{/* <Link className="font-weight-bold fs18" to='/contact-us' state={{ courseList: courseList }}>
		{"Contact Us"}
	</Link> */}
				</Col>
			</Row>
		</div>

	
	);
}

export default CourseHeader;
