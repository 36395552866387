import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Col, Row, Modal, Card, Button, FormGroup, InputGroup, Form, CardBody } from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SFIcon from "assets/img/Course/Payment/SF.png";
import PSEAIcon from "assets/img/Course/Payment/PSEA.png";
import PaynowIcon from "assets/img/Course/Payment/PAYNOW.png";
import { ReactComponent as CCIcon } from "assets/img/Course/Payment/card.svg";
import { useDispatch, useSelector } from "react-redux";
import generalApi from "services/general-api";
import { saveRegisterForm } from "reducers/courseRegisterSlice";
import * as yup from "yup";
import { yupHelper } from "utilities/yupHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import moment from "moment";
import { FileUploader } from "react-drag-drop-files";
import { getErrorMessage } from "utilities/error";

const schema = yup.object({
	name: yupHelper.fullname(),
	// email: yup.string().email().required(),
	email: yupHelper.email(),
	mobileno: yupHelper.mobile(),
	course: yup.string().required(),
	message: yup.string().required(),
	consent: yup
		.boolean()
		.oneOf([true], "You must agree to the Privacy Policy")
		.required("You must agree to the Privacy Policy"),
});

function ContactUs() {
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");
	const { user: userData } = useSelector((state) => state.auth);
	const currentUser = userData.user;

	let navigate = useNavigate();

	const location = useLocation();
	const courseList = location.state?.courseList;

	const MySwal = withReactContent(Swal);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	useEffect(() => {}, []);

	const handleContactUs = (data) => {
		setLoading(true);
		//
		setMessage("");
		generalApi
			.ContactUsSubmitForm(data)
			.then(
				(response) => {
					// setLoading(false);
					MySwal.fire({
						title: "Congratulations!",
						html: <p>Contact Form Submitted Successfully.</p>,
						icon: "success",
					}).then(() => {
						navigate("/course");
					});
				},
				(error) => {
					setLoading(false);
					setMessage(getErrorMessage(error));
				},
			)
			.catch(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<div className=''>
				<div className='title-container'>
					<h2>Contact Us</h2>
				</div>

				<div>
					<Card className='mt-4'>
						<CardBody>
							<div className='desc pb-4'>
								<p>
                  Excited about gaining new knowledge in the world of digital marketing? FirstCom
                  Academy is here to help. Leave your details below and we’ll get back to you as
                  soon as possible.
								</p>
							</div>

							<Form role='form' onSubmit={handleSubmit(handleContactUs)}>
								<Row>
									<Col lg='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-fullname'>
                        Name*
											</label>
											<input
												className={"form-control " + (errors.name ? "is-invalid" : "")}
												id='input-fullname'
												placeholder='Enter Your Name'
												defaultValue={
													currentUser.learnermaster_name ? currentUser.learnermaster_name : ""
												}
												type='text'
												{...register("name")}
											/>
											<div className='invalid-feedback'>{errors.name?.message}</div>
										</FormGroup>
									</Col>
									<Col lg='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-mobileNo'>
                        Contact Number*
											</label>
											<input
												className={"form-control " + (errors.mobileno ? "is-invalid" : "")}
												id='input-mobileNo'
												placeholder='Enter Your Contact Number'
												defaultValue={
													currentUser.learnermaster_mobile ? currentUser.learnermaster_mobile : ""
												}
												type='text'
												{...register("mobileno")}
											/>
											<div className='invalid-feedback'>{errors.mobileno?.message}</div>
										</FormGroup>
									</Col>

									<Col lg='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-email'>
                        Email address*
											</label>
											<input
												className={"form-control " + (errors.email ? "is-invalid" : "")}
												id='input-email'
												placeholder='Enter Your Email address'
												defaultValue={
													currentUser.learnermaster_email ? currentUser.learnermaster_email : ""
												}
												type='email'
												{...register("email")}
											/>
											<div className='invalid-feedback'>{errors.email?.message}</div>
										</FormGroup>
									</Col>

									<Col lg='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-email'>
                        Company
											</label>
											<input
												className={"form-control "}
												id='input-company'
												placeholder='Enter Your Company Name'
												type='text'
												{...register("company")}
											/>
										</FormGroup>
									</Col>

									<Col md='12' xl='12'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-course'>
                        Course*
											</label>
											<select
												className={"form-control " + (errors.course ? "is-invalid" : "")}
												id='input-course'
												{...register("course")}
											>
												<option value=''>Select Course</option>
												{Object.keys(courseList).map((key) => (
													<optgroup key={key} label={key}>
														{courseList[key].map((course) => (
															<option key={course.course_id} value={course.course_id}>
																{course.course_title}
															</option>
														))}
													</optgroup>
												))}
											</select>
											<div className='invalid-feedback'>{errors.course?.message}</div>
										</FormGroup>
									</Col>

									<Col md='12' xl='12'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-message'>
                        Message*
											</label>
											<textarea
												className={"form-control " + (errors.message ? "is-invalid" : "")}
												id='input-message'
												placeholder='Message'
												rows='5'
												{...register("message")}
											/>
											<div className='invalid-feedback'>{errors.message?.message}</div>
										</FormGroup>
									</Col>

									<Col md='12' xl='12'>
										<FormGroup>
											<div className='custom-control custom-checkbox'>
												<input
													className='custom-control-input'
													type='checkbox'
													id='input-consent'
													{...register("consent")}
												/>
												<label
													className='consent-label custom-control-label h-auto'
													htmlFor='input-consent'
												>
                          I agree that I have read and understood Firstcom Academy Pte Ltd{" "}
													<a
														target='_blank'
														rel='noreferrer'
														href='https://www.fca.edu.sg/privacy-policy/'
													>
                            Privacy Policy
													</a>{" "}
                          and consent to the collection, use and/or disclosure of my particulars,
                          which I have provided in this form, for the purposes set out in the said
                          Privacy Policy. I may withdraw my consent at any time with future effect
                          in line with the said Notice as well.
												</label>
											</div>

											<div
												className={"invalid-feedback " + (errors.consent ? "d-block" : "d-none")}
											>
												{errors.consent?.message}
											</div>
										</FormGroup>
									</Col>

									<Col md='12' xl='12'>
										{message && (
											<div className='form-group'>
												<div
													className='alert alert-danger'
													role='alert'
													dangerouslySetInnerHTML={{ __html: message }}
												></div>
											</div>
										)}
									</Col>
									<Col md='12' xl='12'>
										<div className='flex justify-content-center justify-content-between mb-4 mt-4'>
											<Link to='..' className='btn btn-default ml-auto'>
                        Back
											</Link>
											<Button className='btn-gradient' type='submit' disabled={loading}>
												{loading && <span className='spinner-border spinner-border-sm'></span>}
												<span>Send</span>
											</Button>
										</div>
									</Col>
								</Row>
							</Form>
						</CardBody>
					</Card>
				</div>
			</div>
		</>
	);
}

export default ContactUs;
