/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link , useLocation } from "react-router-dom";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { yupHelper } from "utilities/yupHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
// reactstrap components
import {
	Button,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Row,
	Col,
} from "reactstrap";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

import SingpassButton from "assets/img/Checkout/MyinfoButton/Neutral@4x.png";
import { NATIONALITY_OPTIONS } from "../../../utilities/constant";
import { register as registerApi } from "../../../actions/auth";
import { clearMessage } from "../../../actions/message";
import { useEffect } from "react";
import moment from "moment";
import generalApi from "services/general-api";
import { Checkbox, Divider } from "@material-ui/core";
import { useUpskilling100Flag} from "hooks/useUpskilling100Flag";
import { toast } from "react-toastify";

const schema = yup.object({
	name: yupHelper.fullname(),
	nric: yupHelper.nric(),
	dob: yup.date("Dob must be a date").required("Dob is required"),
	nationality: yup.string().required(),
	email: yup.string().email().required(),
	mobileno: yupHelper.mobile(),
	password: yupHelper.password(),
	fill_with_singpass: yup.boolean().default(false),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("password"), null], "Passwords must match")
		.required("Confirm Password is required"),
});

function Register() {
	let navigate = useNavigate();
	const location = useLocation();
	const param = new URLSearchParams(location.search);
	const [disableFields,setDisableFields] = useState({});
	const uuid = param.get("uuidv4");
	// const { user: currentUser, isLoggedIn } = useSelector((state) => state.auth);
	const [loading, setLoading] = useState(false);
	const { message } = useSelector((state) => state.message);
	const dispatch = useDispatch();
	const [passwordType, setPasswordType] = useState("password");
	const { upskilling100Flag } = useUpskilling100Flag();
	const [givenConsent, setGivenConsent] = useState(false);

	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,	
		watch
	} = useForm({
		resolver: yupResolver(schema),
	});

	const togglePassword = () => {
		if (passwordType === "password") {
			setPasswordType("text");
			return;
		}
		setPasswordType("password");
	};

	const handleRegister = (data) => {

		if(!givenConsent){
			toast.error("Please give consent to for collection, use and disclosure of your personal data by FirstCom Academy for marketing purposes and contact.");
			return;
		}


		setLoading(true);
		data.dob = moment(data.dob).format("DD-MM-YYYY");
		data.upskilling100 = upskilling100Flag ? 1 : 0;

		dispatch(registerApi(data))
			.then(() => {
				// return <Navigate to='/login/otp-validation' />;
				navigate("/login/otp-validation");
				// window.location.reload();
			})
			.catch(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		dispatch(clearMessage());
	}, []);

	const handleSingpassLogin = (data) => {
		// GTMCheckoutSingpassRetrieveInfoButton(checkoutAnalyticsData, userData);
		// window.location.href = process.env.REACT_APP_API_URL + "/api/generateSingpassUrl?uuidv4=" + uuidv4();
		window.location.href = process.env.REACT_APP_API_URL + "/api/generateRegisterSingpassUrl?uuidv4=" + uuidv4();
	};

	useEffect(() => {
		if(uuid){
			generalApi
				.fetchSingpassData({
					uuidv4: uuid,
				})
				.then((singpassObj) => {
					const singpassUser = singpassObj.data[0];
					if (singpassUser) {
						console.warn("Singpass response is empty.");
					}
					const newFormValue = {
						name: singpassUser.name,
						nric: singpassUser.nric,
						dob: moment(singpassUser.dob).toDate() ,
						nationality: singpassUser.nationality,
						email: singpassUser.email,
						mobileno: singpassUser.mobile,
						fill_with_singpass: 1,
					};

					// if this is a webview, send the data back to the webview
					if(window.ReactNativeWebView){
						window.ReactNativeWebView.postMessage(JSON.stringify(newFormValue));
						return;
					}

					setDisableFields(
						Object.keys(newFormValue).reduce((acc, key) => {
							acc[key] = Boolean(newFormValue[key]);
							return acc;
						},{})
					);
					reset(newFormValue);
				});
		}
	}, [uuid]);
	


	return (
		<>
			<Container className='pt-4 register-page'>
				<Row className='justify-content-center'>
					<Col lg='12' md='12'>
						<div className='border-0'>
							<CardHeader className='bg-transparent' style={{borderBottom:0}}>
								<Link to='/login'>
									<div style={{cursor:"pointer" }}>
										<i className="fa fa-arrow-left"></i> Back to Log In
									</div>
								</Link>
								<h3>Account Registration</h3>
								<div className='dec small'>
                  It is mandatory for individuals who have signed up for an SSG-funded course to
                  provide the training provider with their name, NRIC, personal email address and
                  mobile number to facilitate the conduct of TRAQOM surveys - SkillsFuture
                  Singapore-TRAQOM Survey Initiative
								</div>
							</CardHeader>
							<CardBody className='px-lg-4 py-lg-2'>
								<Form role='form' onSubmit={handleSubmit(handleRegister)}>
									<div onClick={handleSingpassLogin} style={{cursor:"pointer"}}>
										<img
											style={{
												width: "auto",
												height: "50px",
											}}
											src={SingpassButton}
											alt='Singpass Myinfo'
										/>
									</div>
									<Row style={{alignItems:"center"}} className="py-4">
										<Col>
											<Divider />
										</Col>
										<span>OR</span>
										<Col>
											<Divider />
										</Col>
									</Row>
									<Row>
										<Divider />
										<Col lg='6'>
											<FormGroup>
												<label className='form-control-label' htmlFor='input-fullname'>
                          Full Name (As Shown in NRIC)
												</label>
												<input
													disabled={disableFields["name"]}
													className={"form-control " + (errors.name ? "is-invalid" : "")}
													defaultValue=''
													id='input-fullname'
													placeholder='Full Name'
													type='text'
													{...register("name")}
												/>
												<div className='invalid-feedback'>{errors.name?.message}</div>
											</FormGroup>
										</Col>
										<Col lg='6'>
											<FormGroup>
												<label className='form-control-label' htmlFor='input-nric'>
                          NRIC or ID No.
												</label>
												<input
													disabled={disableFields["nric"]}
													className={"form-control " + (errors.nric ? "is-invalid" : "")}
													id='input-nric'
													placeholder='NRIC or ID No.'
													type='text'
													{...register("nric")}
												/>
												<div className='invalid-feedback'>{errors.nric?.message}</div>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col lg='6'>
											<FormGroup>
												<label className='form-control-label' htmlFor='input-dob'>
                          Date of Birth
												</label>
												<Controller
													name='dob'
													control={control}
													render={({ field: { onChange, value }, fieldState: { error } }) => (
														<ReactDatetime
															onChange={onChange}
															value={value}
															className={(error ? "needs-validation" : "")}	
															inputProps={{
																disabled : disableFields["name"],
																id: "input-dob",
																placeholder: "Date of Birth",
															}}
															dateFormat='DD-MM-YYYY'
															timeFormat={false}
														/>
													)}
												/>
												<div className={"invalid-feedback " + (errors.dob ? "d-block" : "d-none")}>
													{errors.dob?.message}
												</div>
											</FormGroup>
										</Col>
										<Col lg='6'>
											<FormGroup>
												<label className='form-control-label' htmlFor='input-nationality'
												>
                          Nationality
												</label>
												<select
													disabled={disableFields["nationality"]}
													defaultValue=''
													className={"form-control " + (errors.nationality ? "is-invalid" : "")}
													{...register("nationality")}
													id='input-nationality'
												>
													<option key='' value='' disabled>
                            Please Select Your Nationality
													</option>
													{NATIONALITY_OPTIONS.map((option) => (
														<option key={option.value} value={option.value}>
															{option.label}
														</option>
													))}
												</select>
												<div className='invalid-feedback'>{errors.nationality?.message}</div>
											</FormGroup>
										</Col>
									</Row>

									<Row>
										<Col lg='6'>
											<FormGroup>
												<label className='form-control-label' htmlFor='input-email'>
                          Email Address
												</label>
												<input
													disabled={disableFields["email"]}
													className={"form-control " + (errors.email ? "is-invalid" : "")}
													id='input-email'
													placeholder='Email Address'
													type='email'
													{...register("email")}
												/>
												<div className='invalid-feedback'>{errors.email?.message}</div>
											</FormGroup>
										</Col>
										<Col lg='6'>
											<FormGroup>
												<label className='form-control-label' htmlFor='input-mobileNo'>
                          Mobile No.
												</label>
												<input
													disabled={disableFields["mobileno"]}
													className={"form-control " + (errors.mobileno ? "is-invalid" : "")}
													id='input-mobileNo'
													placeholder='Mobile No.'
													type='text'
													{...register("mobileno")}
												/>
												<div className='invalid-feedback'>{errors.mobileno?.message}</div>
											</FormGroup>
										</Col>
									</Row>

									<Row>
										<Col lg='6'>
											<FormGroup>
												<label className='form-control-label' htmlFor='input-password'>
                          Password
												</label>
												<InputGroup
													className={
														"input-group-merge " +
                            (errors.password ? "needs-validation is-invalid" : "")
													}
												>
													<input
														className={"form-control " + (errors.password ? "is-invalid" : "")}
														id='input-password'
														placeholder='Password'
														type={passwordType}
														{...register("password")}
													/>
													<InputGroupAddon addonType='append'>
														<InputGroupText onClick={togglePassword}>
															{passwordType === "password" ? (
																<i className='far fa-eye-slash'></i>
															) : (
																<i className='far fa-eye'></i>
															)}
														</InputGroupText>
													</InputGroupAddon>
												</InputGroup>
												<div className='invalid-feedback'>{errors.password?.message}</div>
											</FormGroup>
										</Col>
										<Col lg='6'>
											<FormGroup>
												<label className='form-control-label' htmlFor='input-reEnterPassword'>
                          Re-enter Password
												</label>
												<InputGroup
													className={
														"input-group-merge " +
                            (errors.confirmPassword ? "needs-validation is-invalid" : "")
													}
												>
													<input
														className={
															"form-control " + (errors.confirmPassword ? "is-invalid" : "")
														}
														id='input-reEnterPassword'
														placeholder='Re-enter Password'
														type={passwordType}
														{...register("confirmPassword")}
													/>
													<InputGroupAddon addonType='append'>
														<InputGroupText onClick={togglePassword}>
															{passwordType === "password" ? (
																<i className='far fa-eye-slash'></i>
															) : (
																<i className='far fa-eye'></i>
															)}
														</InputGroupText>
													</InputGroupAddon>
												</InputGroup>
												<div className='invalid-feedback'>{errors.confirmPassword?.message}</div>
											</FormGroup>
										</Col>
									</Row>

									<div className='text-muted font-italic d-none'>
										<small>
                      password strength:{" "}
											<span className='text-success font-weight-700'>strong</span>
										</small>
									</div>

									<Row className='my-4 d-none'>
										<Col xs='12'>
											<div className='custom-control custom-control-alternative custom-checkbox'>
												<input
													className='custom-control-input'
													id='customCheckRegister'
													type='checkbox'
												/>
												<label className='custom-control-label' htmlFor='customCheckRegister'>
													<span className='text-muted'>
                            I agree with the{" "}
														<a href='#pablo' onClick={(e) => e.preventDefault()}>
                              Privacy Policy
														</a>
													</span>
												</label>
											</div>
										</Col>
									</Row>
									<div 
										className="d-flex align-items-start" 
										onClick={() => { 
											setGivenConsent(!givenConsent);
										}}>
										<Checkbox color="green" checked={givenConsent} value={givenConsent}/>
										<p>
												I have read and consent to the collection, use, and disclosure of my personal data by FirstCom Academy for
												marketing purposes and contact.
										</p>
									</div>
									<div className='mt-4 mb-4 d-flex align-items-center btn-container'>
										<Button className='btn-gradient' color='success' type='submit' disabled={loading}>
											{loading && <span className='spinner-border spinner-border-sm'></span>}
											<span>Sign Up</span>
										</Button>
										


										{/* TODO: ADD A  CONSENT BUTTONG HERE */}

										<div className='ml-3 signup_wrap'>
											<span>
                        I already have an account.
												<Link to='/login' className='text-blue bold ml-1'>
                          Login
												</Link>
											</span>
										</div>
									</div>

									{message && (
										<div className='form-group'>
											<div className='alert alert-danger' role='alert'dangerouslySetInnerHTML={{ __html: message }}>
											</div>
										</div>
									)}
								</Form>
							</CardBody>
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Register;
