import React, { useState } from "react";
import ReactPlayer from "react-player";

function VideoPlayer({
	thumbnail,
	endThumbnail,
	url,
}) {
	
	const renderVideo = () => {
		return (
			<ReactPlayer
				url={url}
				playing={true}
				light={
					<img
						className='article-details-image'
						style={{ objectFit: thumbnail ? "cover" : "contain" }} 
						src={thumbnail ? thumbnail : require("assets/img/FALCOMM_LOGO.png")}
						alt='Thumbnail'
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src= require("assets/img/FALCOMM_LOGO.png");
						}}
					/>
				}
				controls
				width='100%'
				height='450px'
				style={{ backgroundColor: "#000" }}
				config={{ file: { attributes: { controlsList: "nodownload", disablePictureInPicture: true }}}}
			/>
		);
	};

	return (
		<div>
			{renderVideo()}
		</div>
	);
}

export default React.memo(VideoPlayer);

