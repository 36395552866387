import React, { useEffect } from "react";
import styles from "./EventDetailCard.module.scss";
import defaultLogo from "assets/img/FALCOMM_LOGO.png";
import { Col, Row } from "reactstrap";
import { ReactComponent as BookIcon } from "assets/img/Checkout/Success/icon_book.svg";
import { ReactComponent as CalendarIcon } from "assets/img/Checkout/Success/icon_cal.svg";
import { ReactComponent as LearnIcon } from "assets/img/Checkout/Success/icon_learn.svg";
import { ReactComponent as LocateIcon } from "assets/img/Checkout/Success/icon_locate.svg";
import { ReactComponent as PhoneIcon } from "assets/img/Checkout/Success/icon_phone.svg";
import { ReactComponent as StudentIcon } from "assets/img/Checkout/Success/icon_student.svg";
import { ReactComponent as TimeIcon } from "assets/img/Checkout/Success/icon_time.svg";
import trainerImg from "assets/img/Course/profile-dummy.jpg";

export function EventDetailCard({ data }) {
	const {
		event_title,
		event_gathering_point,
		formatted_date,
		formatted_time,
		event_location
	}= data.event;
	const {
		eventregform_quantity
	} = data;
	return (
		<div>
			<span className={styles.course_title}>{event_title}</span>
			<hr className='mb-4 mt-3 w-100'></hr>
			<Row>
				<Col xl='3'>
					<div className={styles.course_info_wrap}>
						<div className={styles.course_info_title_container}>
							<LocateIcon className={styles.course_info_title_icon} />
							<span className={styles.course_info_title}>Location</span>
						</div>
						<div className={styles.course_info_description}>{event_location}</div>
					</div>
				</Col>
				{
					event_gathering_point 
						? <Col xl='3'>
							<div className={styles.course_info_wrap}>
								<div className={styles.course_info_title_container}>
									<LocateIcon className={styles.course_info_title_icon} />
									<span className={styles.course_info_title}>Gathering Location</span>
								</div>
								<div className={styles.course_info_description}>{event_gathering_point}</div>
							</div>
						</Col>
						:  null
				}
				<Col xl='3'>
					<div className={styles.course_info_wrap}>
						<div className={styles.course_info_title_container}>
							<CalendarIcon className={styles.course_info_title_icon} />
							<span className={styles.course_info_title}>Event Date</span>
						</div>
						<div className={styles.course_info_description}>{formatted_date}</div>
					</div>
				</Col>
				<Col xl='3'>
					<div className={styles.course_info_wrap}>
						<div className={styles.course_info_title_container}>
							<TimeIcon className={styles.course_info_title_icon} />
							<span className={styles.course_info_title}>Event Timing</span>
						</div>	
						<div className={styles.course_info_description}>{formatted_time}</div>
					</div>
				</Col>	

				<Col xl='3'>
					<div className={styles.course_info_wrap}>
						<div className={styles.course_info_title_container}>
							<PhoneIcon className={styles.course_info_title_icon} />
							<span className={styles.course_info_title}>No of Ticket</span>
						</div>
						<div className={styles.course_info_description}>{eventregform_quantity}</div>
					</div>
				</Col>
			</Row>
		</div>
	);
}

export default EventDetailCard;
