import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// reactstrap components
import { Card, CardBody, Container } from "reactstrap";

// react plugin used to create datetimepicker
import { clearMessage } from "actions/message";

import generalApi from "services/general-api";
import { useCheckout } from "hooks/selectors/useCheckout";
import { GTMCheckoutCompleted, GTMCheckoutFacebookCheckoutCompleted, GTMCheckoutGoogleAdsCheckoutCompleted, GTMCheckoutPaynowPaymentFailed, GTMCheckoutPaynowPaymentSuccess, GTMCheckoutPurchased, GTMCheckoutStep2_5GoSuccess, GTMCheckoutStep2_5Paynow, GTMCheckoutStep2_5PaynowExit } from "utilities/googleTagManager";


function Omisepaynow() {
	const { user: userData } = useSelector((state) => state.auth);
	const currentUser = userData.user;
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const { message } = useSelector((state) => state.message);
	const dispatch = useDispatch();
	const MySwal = withReactContent(Swal);

	const { state } = useLocation();
	const { payment_id, payment_paynow_qr } = state;
	const [countDown, setCountDown] = useState(6);
	const { checkoutAnalyticsData } = useCheckout();
	const [isFirstTime, setIsFirstTime] = useState(true);
	const courseRegisterData = useSelector((state) => state.courseRegisterSlice);
	var timeoutHandle = [];
	var interval;

	function displayPaynow() {
		checkPaymentStatus();
		countdown(10, 0);
	}

	function countdown(minutes, seconds) {
		const data = {};
		data.payment_id = payment_id;
		function tick() {
			var counter = document.getElementById("timer");
			setCountDown(minutes.toString() + ":" + (seconds < 10 ? "0" : "") + String(seconds));
			seconds--;
			// clearInterval(interval);
			if (seconds >= 0) {
				timeoutHandle.push(setTimeout(tick, 1000));
			} else {
				if (minutes >= 1) {
					setTimeout(function () {
						countdown(minutes - 1, 59);
					}, 1000);
				} else {
					if (seconds == 0 || seconds == -1) {
						clearInterval(interval);
						console.log("done");
						navigate(
							"/checkout/course",
							{ state: { payment_id: payment_id, payment_status:false,countdown:true } }
						);
					}
				}
			}
		}
		tick();
	}

	function checkPaymentStatus() {
		const data = {};
		data.payment_id = payment_id;

		const interval = setInterval(async () => {
			// Dispatch the async action to check payment status
			try {
				// Assuming 'dispatch' and 'generalApi' are defined
				await generalApi.checkpaymentStatus(data).then((jsonObj) => {
					if (jsonObj.status === 1) {
						if (jsonObj.payment_status === "SUCCESSFUL") {
							
							let requestData = {
								registrationform_id: courseRegisterData.registrationform_id,
							};
							if (checkoutAnalyticsData && checkoutAnalyticsData.campaign_type) {
								requestData.lead_type = checkoutAnalyticsData.campaign_type;
							}
							generalApi.updateOrderSubmissionLeadSource(requestData).then(
								(response) => {
									if (response.status == "Success") {
										requestData.course_id = checkoutAnalyticsData && checkoutAnalyticsData.course_id;
										requestData.courserun_id = checkoutAnalyticsData && checkoutAnalyticsData.courserun_id;
										requestData.campaign_type = checkoutAnalyticsData && checkoutAnalyticsData.campaign_type;
										requestData.referral = checkoutAnalyticsData && checkoutAnalyticsData.referral;
										// requestData.invite = checkoutAnalyticsData && checkoutAnalyticsData.invite;
										requestData.utm_campaign = checkoutAnalyticsData && checkoutAnalyticsData.utm_campaign;
										requestData.utm_content = checkoutAnalyticsData && checkoutAnalyticsData.utm_content;
										requestData.utm_medium = checkoutAnalyticsData && checkoutAnalyticsData.utm_medium;
										requestData.utm_source = checkoutAnalyticsData && checkoutAnalyticsData.utm_source;
										requestData.utm_term = checkoutAnalyticsData && checkoutAnalyticsData.utm_term;
										requestData.url = checkoutAnalyticsData && checkoutAnalyticsData.url;
										requestData.ccc = checkoutAnalyticsData && checkoutAnalyticsData.CCC ? checkoutAnalyticsData.CCC : null;

										generalApi
											.SubmitBigBangPurchasedConversionLog(requestData)
											.then(
												(response) => {
												},
												(error) => {
												},
											)
											.catch(() => {
											});
											
										clearInterval(interval);
										// GTMCheckoutPaynowPaymentSuccess(checkoutAnalyticsData, userData);
										GTMCheckoutCompleted(checkoutAnalyticsData, userData);
										GTMCheckoutPurchased(checkoutAnalyticsData, userData, jsonObj.internal_reference);
										if (checkoutAnalyticsData && checkoutAnalyticsData.campaign_type && checkoutAnalyticsData.campaign_type.toUpperCase() === "STELLAR") {
											GTMCheckoutFacebookCheckoutCompleted(checkoutAnalyticsData, userData);
										}
										if (checkoutAnalyticsData && checkoutAnalyticsData.campaign_type && checkoutAnalyticsData.campaign_type.toUpperCase() === "GRAVITY") {
											GTMCheckoutGoogleAdsCheckoutCompleted(checkoutAnalyticsData, userData);
										}
										GTMCheckoutStep2_5GoSuccess(checkoutAnalyticsData, userData);
										setTimeout(() => {
											navigate("/checkout/success", { state: { payment_id: payment_id, regform_id: 1 } });
										}, 500);
									}
								},
								(error) => {
									// navigate("/checkout/success", { state: { payment_id: payment_id, regform_id: 1 } });
								},
							);
						} else if (jsonObj.payment_status === "FAILED") {
							// GTMCheckoutPaynowPaymentFailed(checkoutAnalyticsData, userData);
							clearInterval(interval);
							navigate(
								"/checkout/course",
								{ state: { payment_id: payment_id,payment_status:false,countdown:false } }
							);
							// Payment status is not successful
							// No action required in this block for now
						} else {
							// clearInterval(interval);
							// navigate("/checkout/course", { state: { payment_id: payment_id, regform_id: 1,payment_status:false } });
						}
					} else {
						console.log(1);
					}
				});
			} catch (error) {
				console.error("Error checking payment status:", error);
			}
		}, 8000);

		return interval;
	}

	React.useEffect(() => {
		displayPaynow();
		dispatch(clearMessage());

		return function cleanup() {
			timeoutHandle.forEach((timeout) => {
				clearTimeout(timeout);
			});
			timeoutHandle = [];
		};
	}, []);

	// React.useEffect(() => {
	// 	if (checkoutAnalyticsData && checkoutAnalyticsData.course_id !== null && isFirstTime) {
	// 		GTMCheckoutStep2_5Paynow(checkoutAnalyticsData, userData);
	// 		setIsFirstTime(false);
	// 	}
		
	// 	// analytics for close tab
	// 	const handleTabClosing = () => {
	// 		if (checkoutAnalyticsData && checkoutAnalyticsData.course_id !== null) {
	// 			GTMCheckoutStep2_5PaynowExit(checkoutAnalyticsData, userData);
	// 		}
	// 	};

	// 	window.addEventListener("unload", handleTabClosing);
	// 	return () => {
	// 		window.removeEventListener("unload", handleTabClosing);
	// 	};
	// }, [checkoutAnalyticsData, userData]);
	
	return (
		<>
			<Container className=''>
				<Card className='border-0 '>
					<CardBody className=''>
						<div className='title-container'>
							<h2>Checkout</h2>
						</div>
						<div className='font-weight-bold fs16'>Omise Paynow</div>
						<div className='mt-3 flex justify-content-between content_center'>
							<div className='col-md-8'>
								<h4>Instruction</h4>
								<div className='sub-title'>For PC/Laptop users:</div>
								<div className='sub-content'>
									<ul className='no-bullets'>
										<li> 1. Launch your preferred banking app</li>
										<li>2. Scan the QR Code</li>
										<li>3. Verify the amount and authorize payment</li>
										<li>4. Page will refresh upon successful receipt of payment</li>
									</ul>
								</div>

								<div className='sub-title pt-5'>For Mobile users:</div>
								<div className='sub-content'>
									<ul className='no-bullets'>
										<li> 1. Screenshot / save this QR code to your photo gallery </li>
										<li>2. On your banking app go to "Scan QR"</li>
										<li>3. Verify the amount and authorize payment</li>
										<li>4. Page will refresh upon successful receipt of payment</li>
									</ul>
								</div>
							</div>
							<div className='col-md-4'>
								<div className='my-2' style={{ background: "#ffffff" }}>
									<div className='paynowImgDiv'>
										<img src={payment_paynow_qr} />
									</div>
									<div className='countdownDiv flex'>
										<div>Please make payment within </div>
										<span className='ml-1' id='countdown'>
											{countDown}
										</span>
									</div>
								</div>
							</div>
						</div>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default Omisepaynow;
