import moment from "moment";

export const isTrainerTBA = (trainer) => {
	return trainer?.trainer_name?.toLowerCase() === "tba";
};

export const PrintCourseDate = (course_from, course_to) => {
	return (
		moment(course_from).format("DD MMM YYYY, ddd") +
		" - " +
		moment(course_to).format("DD MMM YYYY, ddd")
	);
};

export const PrintDate = (date) => {
	return moment(date).format("DD MMM YYYY, ddd");
};

export const isFileTypeImage = (fileType) => {
	// List of common image MIME types
	const imageMimeTypes = [
		"image/jpeg",
		"image/jpg",
		"image/png",
		"image/webp",
		"image/svg+xml",
		// Add more image MIME types as needed
	];

	// Check if the provided fileType is in the list of image MIME types
	return imageMimeTypes.includes(fileType);
};

const formatTime = (time) => {
	const [hour, minute] = time.split(":");
	return `${parseInt(hour, 10) % 12}:${minute}${parseInt(hour, 10) >= 12 ? "pm" : "am"}`;
};

export const formatCSession = (sessionData) => {
	const formattedSessions = [];

	let currentDate = null;
	let currentFormattedSession = null;

	sessionData.forEach((session, i) => {
		const startTime = formatTime(session.csession_start_time);
		const endTime = formatTime(session.csession_end_time);

		const sessionDate = new Date(session.csession_start_date);
		const formattedDate = sessionDate.toLocaleDateString("en-US", { weekday: "long" });

		if (!currentDate || sessionDate.toISOString() !== currentDate.toISOString()) {
			if (currentDate) {
				formattedSessions.push(currentFormattedSession);
			}
			currentDate = sessionDate;
			currentFormattedSession = `Day ${formattedSessions.length + 1}: ${startTime} - ${endTime}`;
		} else {
			// let prev = formattedSessions.pop();
			let prevStart = formatTime(sessionData[i - 1].csession_start_time);
			currentFormattedSession = `Day ${formattedSessions.length + 1}: ${prevStart} - ${endTime}`;
			// currentFormattedSession += `, ${startTime} - ${endTime}`;
		}
	});

	if (currentFormattedSession) {
		formattedSessions.push(currentFormattedSession);
	}

	return formattedSessions;
};

export const getAllDateArray = (startDateString, endDateString) => {
	const startDate = new Date(startDateString);
	const endDate = new Date(endDateString);

	const dateArray = [];

	while (startDate <= endDate) {
		dateArray.push(startDate.toISOString().split("T")[0]);
		startDate.setDate(startDate.getDate() + 1);
	}

	return dateArray;
};

export const formatPrice = (number) => {
	const formattedNumber = Number(number).toFixed(2);
	return `$${formattedNumber}`;
};
