/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components

import { Link, useNavigate } from "react-router-dom";
import {
	Button,
	Card,
	CardHeader,
	CardTitle,
	CardText,
	CardImg,
	CardBody,
	Modal,
	Row,
	Col,
	UncontrolledTooltip,
	CardFooter,
} from "reactstrap";
import moment from "moment";
import LikeButton from "./News/LikeButton";
import CommentButton from "./News/CommentButton";
import ShareButton from "./News/ShareButton";
import styles from "../../views/pages/learner/event/Event.module.scss";
import { useWindow } from "hooks/UseWindow";

const WEB_URL = process.env.REACT_APP_WEB_URL;

function EventCard(props) {
	let navigate = useNavigate();
	const { width } = useWindow();
	
	return (
		<Card className={`h-100 w-100 mb-4 fadeInDiv ${styles.available_event_card}`}>
			<Link to={"/events/" + props.event.event_id} state={{ event: props.event }}>
				<div className={`${styles.available_event_card_container}`}>
					<div className={`${styles.available_event_card_data_container}`}>
						{width > 991 && (
							<div className={`${styles.avaiable_event_date_container}`}>
								<div style={{display: "flex", flexDirection: "row"}}>
									{props.event.event_from &&
										<div>
											<p className={`${styles.available_event_date_day_text} m-0 px-2`}>{moment(props.event.event_from).format("DD")}</p>
											<p className={`${styles.available_event_date_month_text} m-0 px-2`}>{moment(props.event.event_from).format("MMM")}</p>
										</div>
									}
									{(props.event.event_from && props.event.event_to) &&
										<div style={{ display: "flex", alignItems: "center" }}>
											<p className={`${styles.available_event_date_day_text} m-0`}>{"-"}</p>
										</div>
									}
									{props.event.event_to &&
										<div>
											<p className={`${styles.available_event_date_day_text} m-0 px-2`}>{moment(props.event.event_to).format("DD")}</p>
											<p className={`${styles.available_event_date_month_text} m-0 px-2`}>{moment(props.event.event_to).format("MMM")}</p>
										</div>
									}
								</div>
								{(props.event.event_time_to || props.event.event_time_from) &&
									<hr className="w-100 mt-3 mb-3" style={{ borderColor: "#00000050" }} />
								}
								<p className={`${styles.available_event_date_time_text} m-0 px-2`}>
									{props.event.event_time_from &&
										`${moment(props.event.event_time_from, "HH:mm").format("h:mmA")}`
									}
									{props.event.event_time_to && props.event.event_time_from &&
										" - "
									}
									{props.event.event_time_to &&
										`${moment(props.event.event_time_to, "HH:mm").format("h:mmA")}`
									}
								</p>
							</div>
						)}
						<div className={`${styles.avaiable_event_details_container}`}>
							{props.event && props.event.categories && props.event.categories.length > 0 &&
								<div className={`${styles.event_details_category_container}`}>
									{props.event && props.event.categories && props.event.categories.map((category, i) => {
										return (
											<span key={i} className={`${styles.event_details_category_text}`} style={{color: category.category_color}}>
												{category.category_title}
											</span>
										);
									})}
								</div>
							}
							<p className={`${styles.available_event_title_text} mt-1 mb-2`}>{props.event.event_title}</p>
							<p className={`${styles.available_event_desc_text}`}>{props.event.event_description}</p>
							<div className='flex w-100 align-items-center'>
								<Button className='btn-gradient pl-4 pr-4' type='submit'>
									<span className="fs14 font-weight-600 font_1">Register Now</span>
								</Button>
								{/* <Button className={`btn pl-4 pr-4 ${styles.available_event_viewdetails_button}`} type='submit'>
									<span className={`${styles.available_event_viewdetails_button_text}`}>View Details</span>
								</Button> */}
							</div>
						</div>
					</div>
					<div className={`${styles.available_event_card_banner_container}`}>
						<img
							src={props.event.event_listing_image_url ? props.event.event_listing_image_url : require("assets/img/FALCOMM_LOGO.png")}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.src= require("assets/img/FALCOMM_LOGO.png");
							}}
							className={`${styles.available_event_banner_image}`}
						/>
						{width <= 991 && (
							<Row className={`align-items-center m-0 mb-2 ${styles.feature_event_main_date_container}`}>
								<div className={`${styles.featured_event_date_container}`}>
									<i className={`fa fa-calendar mr-2 ${styles.featured_event_date_icon}`} aria-hidden></i>
									<p className={`mb-0 ${styles.featured_event_date_text}`}>
										{props.event.event_from &&
											`${moment(props.event.event_from).format("DD MMM")}`
										}
										{props.event.event_to && props.event.event_from &&
											" - "
										}
										{props.event.event_to &&
											`${moment(props.event.event_to).format("DD MMM")}`
										}
									</p>
									{(props.event.event_time_to || props.event.event_time_from) &&
										<p className={"mb-0"} style={{ fontSize: "14px", color: "#fff", marginLeft: "10px", marginRight: "10px"}}> | </p>
									}
									{(props.event.event_time_to || props.event.event_time_from) &&
										<i className={`fa fa-clock mr-2 ${styles.featured_event_date_icon}`} aria-hidden></i>
									}
									<p className={`mb-0 ${styles.featured_event_date_text}`}>
										{props.event.event_time_from &&
											`${moment(props.event.event_time_from, "HH:mm").format("h:mmA")}`
										}
										{props.event.event_time_to && props.event.event_time_from &&
											" - "
										}
										{props.event.event_time_to &&
											`${moment(props.event.event_time_to, "HH:mm").format("h:mmA")}`
										}
									</p>
								</div>
							</Row>
						)}
					</div>
				</div>
			</Link>
		</Card>
	);
}

export default EventCard;

