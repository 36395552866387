import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Modal, ModalHeader } from "reactstrap";

import { useSelector } from "react-redux";
import "./ReferralContactUsModal.css";
import IndividualForm from "./ReferralContactUsIndividual";
import CorporateForm from "./ReferralContactUsCorporate";
import { ReactComponent as CancelBtnWhite } from "assets/img/Checkout/cancel01.svg";
import { ReactComponent as CancelBtnBlack } from "assets/img/Checkout/cancel02.svg";
import ReferralBannerFloating from "views/pages/learner/referral/components/ReferralBannerFloating";

function ReferralContactUsModal(props) {
	const [loading, setLoading] = useState(false);
	const [modalChange, setModalChange] = useState(true);
	const [message, setMessage] = useState("");
	const { user: userData } = useSelector((state) => state.auth);

	const handleModalClose = () => {
		props.onClose(true);
	}; 
	
	let navigate = useNavigate();

	useEffect(() => {}, []);

	return (
		<>
			<Modal
				className='modal-dialog-centered registration_modal referral_contactus_modal'
				isOpen={props.show}
			>
				<ModalHeader className="contact-form-close" toggle={props.toggle}>
					<div className="close close-black">
						<CancelBtnBlack onClick={handleModalClose}></CancelBtnBlack>
					</div>
					<div className="close close-white">
						<CancelBtnWhite onClick={handleModalClose}></CancelBtnWhite>
					</div>
				</ModalHeader>
			
				<div>
					<div className="contact_banner">
						<div className="contact_image_banner">
							<ReferralBannerFloating id={"9ja8j"} />
						</div>
					</div>
					<div className="form-section">
						<div className='desc pb-4 flex justify-content-center'>
							<p className="contact_header">
								{"Refer & Earn. Share. Get Paid. Repeat!"}
							</p>
						</div>
						<Tabs>
							<TabList className="contact_form_tab">
								<Tab>Individual</Tab>
								<Tab>Corporate</Tab>
							</TabList>
							<TabPanel>
								<IndividualForm ts_ccc={props.ts_ccc} onSuccess={props.onSuccess}/>
							</TabPanel>
							<TabPanel>
								<CorporateForm ts_ccc={props.ts_ccc} onSuccess={props.onSuccess}/>
							</TabPanel>
						</Tabs>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default ReferralContactUsModal;
