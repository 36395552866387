import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useOutletContext, useLocation, Outlet } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
import * as yup from "yup";
// eslint-disable-next-line
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form";
// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Row,
	Col,
	Carousel,
	CarouselItem,
} from "reactstrap";
// core components
import styles from "assets/scss/module/auth/login.module.scss";
import { login } from "actions/auth";
import ModalFirstTimeResetPassword from "../ModalFirstTimeResetPassword";
import { ReactComponent as UserIcon } from "assets/img/Login/user.svg";
import { ReactComponent as LockIcon } from "assets/img/Login/password.svg";
import { clearMessage } from "actions/message";
import { logGTMScreen, logGTMUser } from "utilities/googleTagManager";
import ContactUs2 from "views/pages/learner/course/ContactUs2";
import { useSessionStorageState } from "ahooks";
import { useMutation } from "@tanstack/react-query";
import Slider from "react-slick";

import authApi from "services/auth-api";
import { toast } from "react-toastify";
import { getErrorMessage } from "utilities/error";

const emailSchema = yup
	.object({
		email: yup.string().email().required(),
	})
	.required();

const passwordSchema = yup
	.object({
		password: yup.string().required(),
	})
	.required();

var settings = {
	dots: false,
	infinite: false,
	speed: 300,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false, 
};

function Login() {
	let navigate = useNavigate();
	const pageProps = useOutletContext();
	console.log("pageProps", pageProps);

	const [passwordType, setPasswordType] = useState("password");
	const [loading, setLoading] = useState(false);
	const [modalOpen, setmodalOpen] = React.useState(false);

	const { user: currentUser } = useSelector((state) => state.auth);
	const { message } = useSelector((state) => state.message);
	// required to keep track the 'ts_ccc' value, so that it can be used in the referral form after user successful login
	const [cachedTSCCC, setCachedTSCCC] = useSessionStorageState("ts_ccc", {
		defaultValue: null,
	});

	const [contactFormOpen, setContactFormOpen] = useState(false);
	const hasOpend = useRef(false);
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const hasContactUs = params.get("contactus");
	const enquiryModalMetadata = {
		ts_ccc: params.get("ts_ccc"),
		contactus: params.get("contactus"),
	};

	useEffect(() => {
		if (params.get("ts_ccc")) {
			setCachedTSCCC(params.get("ts_ccc"));
		}
	}, []);

	useEffect(() => {
		if (!hasOpend.current && parseInt(hasContactUs) == 1) {
			hasOpend.current = true;
			setContactFormOpen(true);
		}
	}, [hasContactUs]);

	const dispatch = useDispatch();
	
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(emailSchema),
	});

	const emailInputForm = useForm({
		resolver: yupResolver(emailSchema),
	});

	const passwordForm = useForm({
		resolver: yupResolver(passwordSchema),
	});

	const togglePassword = () => {
		if (passwordType === "password") {
			setPasswordType("text");
			return;
		}
		setPasswordType("password");
	};

	React.useEffect(() => {
		dispatch(clearMessage());
	}, []);

	React.useEffect(() => {
		if (currentUser && currentUser.user.reset_password) {
			setmodalOpen(true);
		}
	});

	const [preloginInfo, setPreLoginInfo] = useState({
		email: null,
		account_options: [],
		account_type: null,
	});
	let sliderRef = useRef(null);


	const handleEnterEmail = useMutation(async ({ email }) => {
		try {
			const responseData = await authApi.preloginEmailCheck(email);
			console.log("responseData", responseData);
			setPreLoginInfo({
				...preloginInfo,
				email: email,
				account_options: responseData.data.account_types,
				account_type:
					responseData.data.account_types.length === 1
						? responseData.data.account_types[0]
						: null,
			});
		} catch (error) {
			toast.error(getErrorMessage(error));
		}
	});

	const handleEnterPassword = useMutation(async ({ password }) => {
		/**
		 * 1. add back/cancel button to allow user to reset
		 * 2. at appropriate time, at forget password 
		 * 3. how forget password know which account to reset ??
		 */
		return dispatch(login(preloginInfo.email, password, preloginInfo.account_type)).then((data) => {
			if (data.isHyperAdminLogin) {
				navigate("/login/hyperadmin-otp-validation");
			} else {
				const loggingInUser = data?.user;
				logGTMUser(
					data.user.learnermaster_id ? data.user.learnermaster_id.toString() : "",
					data.user.learnermaster_name ? data.user.learnermaster_name.toString() : "",
					data.user.learnermaster_dob ? data.user.learnermaster_dob : "",
					data.user.learnermaster_nationality ? data.user.learnermaster_nationality : "",
					data.user.learnermaster_gender ? data.user.learnermaster_gender : "",
				);

				if (loggingInUser && !loggingInUser?.email_verified_at) {
					navigate("/login/otp-validation");
				} else if (loggingInUser && loggingInUser?.reset_password) {
					setmodalOpen(true);
				} else if (cachedTSCCC) {
					navigate("/referral");
				} else {
					navigate("/");
				}
			}
		}).catch((error) => {
			toast.error(getErrorMessage(error));
		});
	});


	const handleBackFromPassword = (e) => {
		e.preventDefault();
		if(preloginInfo.account_options.length > 1){
			setPreLoginInfo({
				...preloginInfo,
				account_type: null,
			});
		}else{

			setPreLoginInfo({
				...preloginInfo,
				email: null,
			});
			
		}

	};


	const progress = [
		{
			title: "Email",
			pass: preloginInfo.email ? true : false,
			component: () => {
				return (
					<div>
						<p className='fs-18 text-color-dark font-weight-bold font_1'>Sign In</p>
						<Form id='login_form' onSubmit={emailInputForm.handleSubmit(handleEnterEmail.mutate)}>
							<FormGroup>
								<InputGroup
									className={
										`input-group-merge ${styles.input_container} ` +
										(emailInputForm.formState.errors.email ? "is-invalid" : "")
									}>
									<InputGroupAddon addonType='prepend'>
										<InputGroupText
											className={
												"form-control " +
												(emailInputForm.formState.errors.email ? "is-invalid" : "")
											}>
											<UserIcon width={30} height={30} />
										</InputGroupText>
									</InputGroupAddon>
									<input
										placeholder='Email'
										type='email'
										className={
											`form-control fs-15 ${styles.input_container} ` +
											(emailInputForm.formState.errors.email ? "is-invalid" : "")
										}
										{...emailInputForm.register("email")}
									/>
								</InputGroup>
								<div className='invalid-feedback'>
									{emailInputForm.formState.errors.email?.message}
								</div>
							</FormGroup>
							<div className='custom-control custom-control-alternative custom-checkbox text-center d-none'>
								<input className='custom-control-input' id=' customCheckLogin' type='checkbox' />
								<label className='custom-control-label d-none' htmlFor=' customCheckLogin'>
									<span className='text-muted'>Remember me</span>
								</label>
							</div>

							<div className='text-center mt-5'>
								<Button
									className={`${classnames("btn-gradient", styles.submit_button)}`}
									type='submit'
									disabled={handleEnterEmail.isLoading}>
									{loading && <span className='spinner-border spinner-border-sm'></span>}
									{handleEnterEmail.isLoading ? (
										<span className='fs-15'>Loading...</span>
									) : (
										<span className='fs-15'>Next</span>
									)}
								</Button>
							</div>
							{message && (
								<div className='form-group pt-3'>
									<div className='alert alert-danger' role='alert'>
										{message}
									</div>
								</div>
							)}
						</Form>
					</div>
				);
			},
		},
		{
			title: "Select Account Type",
			pass: preloginInfo.account_type ? true : false,
			component: () => {
				return (
					<div className="text-center">
						<p className='fs-18 text-color-dark font-weight-bold font_1'>
								Select Account Type
						</p>
						<div>
							{preloginInfo.account_options.map((a) => {
								return (
									<div key={a} className="mt-4">
										<Button
											type='submit'
											disabled={loading}
											onClick={() => {
												setPreLoginInfo({
													...preloginInfo,
													account_type: a,
												});
											}}>
											{a}
										</Button>
									</div>
								);
							})}
						</div>						
					</div>
				);
			},
		},
		{
			title: "Enter Password",
			pass: false,
			component: () => {
				return (
					<div>
						<p className='fs-18 text-color-dark font-weight-bold font_1'>Enter Password</p>
						<Form id='login_form' onSubmit={passwordForm.handleSubmit(handleEnterPassword.mutate)}>
							<FormGroup>
								<InputGroup
									className={
										`input-group-merge ${styles.input_container} ` +
										(emailInputForm.formState.errors.email ? "is-invalid" : "")
									}>
									<InputGroupAddon addonType='prepend'>
										<InputGroupText
											className={
												"form-control " +
												(emailInputForm.formState.errors.email ? "is-invalid" : "")
											}>
											<UserIcon width={30} height={30} />
										</InputGroupText>
									</InputGroupAddon>
									<input
										placeholder='Email'
										type='email'
										className={
											`form-control fs-15 ${styles.input_container} ` +
											(emailInputForm.formState.errors.email ? "is-invalid" : "")
										}
										readOnly
										value={
											(preloginInfo.account_type === "corporate" ? `(${preloginInfo.account_type})` : "") + " " + preloginInfo.email }
									/>
								</InputGroup>
								<div className='invalid-feedback'>
									{emailInputForm.formState.errors.email?.message}
								</div>
							</FormGroup>
							<FormGroup className='mb-1'>
								<InputGroup
									className={
										`input-group-merge ${styles.input_container} ` +
										(passwordForm.formState.errors.password ? "needs-validation is-invalid" : "")
									}>
									<InputGroupAddon addonType='prepend'>
										<InputGroupText
											className={"form-control " + (passwordForm.formState.errors.password ? "is-invalid" : "")}>
											{/* <i className='ni ni-lock-circle-open' /> */}
											<LockIcon width={30} height={30} />
										</InputGroupText>
									</InputGroupAddon>
									<input
										placeholder='Password'
										type={passwordType}
										className={
											`form-control fs-15 ${styles.input_container} ` +
											(passwordForm.formState.errors.password ? "is-invalid" : "")
										}
										{...passwordForm.register("password")}
									/>
									<InputGroupAddon addonType='append'>
										<InputGroupText onClick={togglePassword}>
											{passwordType === "password" ? (
												<i className='far fa-eye-slash'></i>
											) : (
												<i className='far fa-eye'></i>
											)}
										</InputGroupText>
									</InputGroupAddon>
								</InputGroup>
								<div className='invalid-feedback'>{passwordForm.formState.errors.password?.message}</div>
							</FormGroup>
							<div className='pdt10 d-flex justify-content-end'>
								<span>
									<Link to='/login/reset-password' state={{ email: preloginInfo.email, account_type: preloginInfo.account_type }} className='text-blue bold ml-1 fs-15'>
										Forget Password?
									</Link>
								</span>
							</div>
							{message && (
								<div className='form-group pt-3'>
									<div className='alert alert-danger' role='alert'>
										{message}
									</div>
								</div>
							)}
							<div className='d-flex justify-content-between mt-4'>
								<Button
									onClick={handleBackFromPassword}
									className={`${styles.submit_button} col-4 mx-0`}
									disabled={handleEnterPassword.isLoading}>
										Back
								</Button>
								<Button
									className={`${classnames("btn-gradient", styles.submit_button)} col-4 mx-0`}
									type='submit'
									disabled={handleEnterPassword.isLoading}>
									{loading && <span className='spinner-border spinner-border-sm'></span>}
									{handleEnterPassword.isLoading ? (
										<span className='fs-15'>Loading...</span>
									) : (
										<span className='fs-15'>Sign In</span>
									)}
								</Button>
							</div>
							
						</Form>
					</div>
				);
			},
		},
		{
			title: "",
			pass: false,
			component: () => {
				return (
					<></>
				);
			},
		}
	];

	/**
	 * Find the last component we hasn't complete
	 */
	 useEffect(() => {
		const index= progress.findIndex((a) => !a.pass);
		console.log("index", index);
		sliderRef.current.slickGoTo(index);
	}, [preloginInfo, message]);


	return (
		<>
			<Outlet />
			<Container>
				<div className={styles.title_container}>
					<p className={styles.title_text}>
						If you are willing to learn
						<br />
						You are unstoppabble
					</p>
					<p className={styles.desc_text}>Learner's Portal</p>
				</div>
				{pageProps?.signButton}
				<Row className='justify-content-center'>
					<Col lg='12' md='12' className='mw-580px'>
						<Card className='bg-transparent shadow-none border-0 mb-0'>
							<CardBody className='px-lg-4 py-lg-'>
								<Slider {...settings} ref={sliderRef}>
									{
										progress.map((p, index) => {
											return (
												<div key={index}>
													{p.component()}
												</div>
											);
										})
									}
								</Slider>
							</CardBody>
						</Card>

						<div className='sinup_wrap text-center pt-2'>
							<div className='pdt20'>
								<span>
									Don't have an account?
									<Link to='/register' className='text-blue bold ml-1'>
										Create new account
									</Link>
								</span>
							</div>
							<div className='pdt20'>
								<a
									className='text-blue bold ml-1'
									style={{
										textDecoration: "underline",
									}}
									onClick={(e) => {
										e.preventDefault();
										setContactFormOpen(true);
									}}>
									📞 Contact us
								</a>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
			{/* <LoginForm /> */}
			<ModalFirstTimeResetPassword modalChange={modalOpen} setModalChange={setmodalOpen} />
			<ContactUs2
				show={contactFormOpen}
				onSuccess={() => {
					setContactFormOpen(false);
				}}
				metadata={enquiryModalMetadata}></ContactUs2>
		</>
	);
}

export default Login;
