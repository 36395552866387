import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Col, Row, Modal, Card, Button, FormGroup, InputGroup, Form, CardBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

function ModalWithdrawalDesc() {
	const dispatch = useDispatch();

	let navigate = useNavigate();

	const location = useLocation();
	const data = location.state?.data;
	const learner_id = data?.learner_id;
	const course = data?.course;
	const status = data?.status;


	useEffect(() => {
		if (!data || data.length < 0) {
			navigate("/course");
		}
	}, []);

	return (
		<>
			{course && (
				<div className='reschedule_main_container'>
					<div className='title-container'>
						<span className="fs40 text-fca-dark font_2">Withdrawal</span>
					</div>
					<div>
						<Card className='mt-2'>
							<CardBody>
								<p className="fs18 text-fca-dark">
									Any withdrawal/cancellation/no-show for confirmed enrolments must be submitted at least seven (7) days prior to course date with supporting documents.In the event of withdrawal/cancellation/no-show without submission of a withdrawal form containing supporting documents, trainee(s) will be liable to pay the full course fees. Please note that government funding is not applicable for withdrawal/cancellation/no-show and the penalty fees may need to be paid via cash/bank transfer.
								</p>
								<Col md='12' xl='12'>
									<div className='flex justify-content-center justify-content-between mb-4 mt-6'>
										<Button
											onClick={() => navigate(-1)}
											className='btn btn-default ml-auto'>
											Back
										</Button>
										<Link
											to='/course/withdrawal'
											state={{
												data: {
													learner_id: course.learner_id,
													courserun_id: course.courserun_id,
													course_title: course.course.course_title,
													course: course,
													status: status,
												},
											}}
										>
											<Button className='btn-gradient'>
												<span>Proceed to Withdrawal</span>
											</Button>
										</Link>
										
									</div>
								</Col>
							</CardBody>
						</Card>
					</div>
				</div>
			)}
		</>
	);
}

export default ModalWithdrawalDesc;
