import React, { useState, useEffect, useCallback } from "react";
import Profile from "components/Sidebar/Profile.js";
// reactstrap components
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";
// core components
import Slider from "react-slick";
import generalApi from "services/general-api.js";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "actions/auth";
import { Outlet, useLocation } from "react-router-dom";
import CourseHeader from "components/LearnerPortal/CourseHeader";
import UpcomingCourses from "components/LearnerPortal/UpcomingCourses";
import CompletedCourses from "components/LearnerPortal/CompletedCourses";
import ToBeRescheduledCourses from "components/LearnerPortal/ToBeRescheduledCourses";
import PendingCourses from "components/LearnerPortal/PendingCourses";
import AvailableCourse from "components/LearnerPortal/AvailableCourse";
import BundleCourses from "components/LearnerPortal/BundleCourses";
import { getErrorMessage } from "utilities/error";
import { toast } from "react-toastify";

const VERSION = process.env.REACT_APP_APP_VERSION;

function Dashboard() {
	const dispatch = useDispatch();
	const { user: userData, isHyperAdminLogin } = useSelector((state) => state.auth);

	//course content
	const [isUpcomingLoading, setIsUpcomingLoading] = useState(false);
	const [isBundleLoading, setIsBundleLoading] = useState(false);
	const [isCourseLoading, setIsCourseLoading] = useState(false);
	const [courses, setContent] = useState([]);
	const [allupcomingCourses, setallupcomingCourses] = useState({});
	const [contactUsCourseList, setContactUsCourseList] = useState({});
	const [bundles, setBundle] = useState([]);

	useEffect(() => {
		setIsUpcomingLoading(true);
		setIsBundleLoading(true);
		setIsCourseLoading(true);
		generalApi.getCourses().then(
			(response) => {
				setContent(response);
				setIsCourseLoading(false);
			},
			(error) => {
				let error_message = getErrorMessage(error);
				// setContent(_content);
			},
		);

		generalApi.getUpcomingCourse().then(
			(response) => {
				setallupcomingCourses(response);
				setIsUpcomingLoading(false);
			},
			(error) => {
				let error_message = getErrorMessage(error);
				// setmessage(error_message);
			},
		);

		generalApi.getBundle().then(
			(response) => {
				setBundle(response);
				setIsBundleLoading(false);
			},
			(error) => {
				let error_message = getErrorMessage(error);
				// setmessage(error_message);
			},
		);

		let msg = getQueryVariable("msg");
		let msg_status = getQueryVariable("status");
		if (msg) {
			// GTMCheckoutSFCPaymentFailed(checkoutAnalyticsData, userData);
			if(msg_status == 1){
				toast.success(decodeURI(msg));
			}else{
				toast.error(decodeURI(msg));
			}
		}
	}, []);

	function getQueryVariable(variable) {
		var query = window.location.search.substring(1);
		var vars = query.split("&");
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split("=");
			if (pair[0] == variable) {
				return pair[1];
			}
		}
		return false;
	}

	React.useEffect(() => {
		let newBundleList = {};
		courses?.map((item) => {
			if (item.bundles.length > 0) {
				if (item.bundles[0].bundle_name) {
					if (item.bundles[0].bundle_name in newBundleList) {
						// exist
						let courseList = newBundleList[item.bundles[0].bundle_name];
						courseList.push(item);
						newBundleList[item.bundles[0].bundle_name] = courseList;
					} else {
						newBundleList[item.bundles[0].bundle_name] = [item];
					}
				}
			} else {
				if (newBundleList.Others) {
					let courseList = newBundleList.Others;
					courseList.push(item);
					newBundleList.Others = courseList;
				} else {
					newBundleList.Others = [item];
				}
			}
		});
		setContactUsCourseList(newBundleList);
	}, [courses]);

	return (
		<Container fluid>
			<div className="my_course_dashboard_container">
				<Outlet></Outlet>
				<CourseHeader courseList={contactUsCourseList} />
				{isUpcomingLoading && 
					<div className='justify-content-center w-100 flex'><span className='spinner-border spinner-border-sm'></span>
					</div>
				}
				
				{allupcomingCourses.upcoming && allupcomingCourses.upcoming.length > 0 && (
					<UpcomingCourses courses={allupcomingCourses.upcoming} />
				)}
				{allupcomingCourses.unverified && allupcomingCourses.unverified.length > 0 && (
					<PendingCourses courses={allupcomingCourses.unverified} />
				)}
				{allupcomingCourses.tobe_reschedule && allupcomingCourses.tobe_reschedule.length > 0 && (
					<ToBeRescheduledCourses courses={allupcomingCourses.tobe_reschedule} />
				)}
				{isBundleLoading && 
					<div className='justify-content-center w-100 flex'><span className='spinner-border spinner-border-sm'></span>
					</div>
				}
				{!isBundleLoading && bundles && bundles.length > 0 && <BundleCourses bundles={bundles} />}
				{allupcomingCourses.completed && allupcomingCourses.completed.length > 0 && (
					<CompletedCourses courses={allupcomingCourses.completed} />
				)}
				{isCourseLoading && 
					<div className='justify-content-center w-100 flex'><span className='spinner-border spinner-border-sm'></span>
					</div>
				}
				{!isCourseLoading && courses && <AvailableCourse courses={courses} />}

				{/* {allupcomingCourses.upcoming &&
        allupcomingCourses.upcoming.length == 0 &&
        allupcomingCourses.unverified &&
        allupcomingCourses.unverified.length == 0 &&
        allupcomingCourses.tobe_reschedule &&
        allupcomingCourses.tobe_reschedule.length == 0 &&
        allupcomingCourses.completed &&
        allupcomingCourses.completed.length == 0 && (
				<p className='text-center'>
					<b>No Course</b>
				</p>
			)} */}
			</div>
		</Container>
	);
}

export default Dashboard;
