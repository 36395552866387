import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import * as yup from "yup";
import { yupHelper } from "utilities/yupHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Button, FormGroup, Form, Modal, Label } from "reactstrap";
import styles from "../Event.module.scss";
import { useWindow } from "hooks/UseWindow";

function ModalEventTNC({ isOpen = false, onClose = () => {}, event = null }) {
	const { width } = useWindow();

	return (
		<>
			<Modal
				className={"modal-dialog-centered event_register_modal"}
				isOpen={isOpen}
			>
				<div className={`${styles.register_modal_close_container}`} onClick={onClose}>
					<i className={`fa fa-times ${styles.register_modal_close_icon}`} aria-hidden='true'></i>
				</div>

				<div className={`${styles.register_modal_header}`}>
					<p className={`${styles.register_modal_header_title}`}>
						{"Terms & Conditions"}
					</p>
				    <hr className="mt-1 mb-2 w-100" style={{ borderColor: "#101820" }} />
				</div>
				<div className='modal-body pt-0'>
					<p className={`${styles.register_modal_tnc_title} mb-2`}>{event.event_term_condition}</p>
				</div>
			</Modal>
		</>
	);
}

export default ModalEventTNC;
