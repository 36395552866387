import React from "react";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import {
	Form,
	FormGroup,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Button,
	Row,
	Col,
	Carousel,
	CarouselItem,
} from "reactstrap";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import classnames from "classnames";
import { login } from "actions/auth";
import { getErrorMessage } from "utilities/error";
import authApi from "services/auth-api";
import { logGTMUser } from "utilities/googleTagManager";
import { ReactComponent as UserIcon } from "assets/img/Login/user.svg";
import { ReactComponent as LockIcon } from "assets/img/Login/password.svg";

import styles from "./LoginFormWithAccountType.module.scss";

import Slider from "react-slick";
const settings = {
	dots: false,
	infinite: false,
	speed: 300,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
};

const emailSchema = yup
	.object({
		email: yup.string().email().required(),
	})
	.required();

const passwordSchema = yup
	.object({
		password: yup.string().required(),
	})
	.required();

export function LoginFormWithAccountType({ onSuccess ,redirect_url}) {
	const [passwordType, setPasswordType] = useState("password");
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const [preloginInfo, setPreLoginInfo] = useState({
		email: null,
		account_options: [],
		account_type: null,
	});
	let sliderRef = useRef(null);
	const [message, setMessage] = useState(null);

	const emailInputForm = useForm({
		resolver: yupResolver(emailSchema),
	});

	const passwordForm = useForm({
		resolver: yupResolver(passwordSchema),
	});

	const handleEnterEmail = useMutation(async ({ email }) => {
		try {
			const responseData = await authApi.preloginEmailCheck(email);
			console.log("responseData", responseData);
			setPreLoginInfo({
				...preloginInfo,
				email: email,
				account_options: responseData.data.account_types,
				account_type:
					responseData.data.account_types.length === 1 ? responseData.data.account_types[0] : null,
			});
		} catch (error) {
			toast.error(getErrorMessage(error));
		}
	});

	const handleEnterPassword = useMutation(async ({ password }) => {
		return dispatch(login(preloginInfo.email, password, preloginInfo.account_type))
			.then((data) => {
				if (data.isHyperAdminLogin) {
					navigate("/login/hyperadmin-otp-validation");
				} else {
					logGTMUser(
						data.user.learnermaster_id ? data.user.learnermaster_id.toString() : "",
						data.user.learnermaster_name ? data.user.learnermaster_name.toString() : "",
						data.user.learnermaster_dob ? data.user.learnermaster_dob : "",
						data.user.learnermaster_nationality ? data.user.learnermaster_nationality : "",
						data.user.learnermaster_gender ? data.user.learnermaster_gender : "",
					);

					onSuccess && onSuccess(data);
				}
			})
			.catch((error) => {
				const message = getErrorMessage(error);
				toast.error(message);
				setMessage(message);
			});
	});

	const handleBackFromPassword = (e) => {
		e.preventDefault();
		if (preloginInfo.account_options.length > 1) {
			setPreLoginInfo({
				...preloginInfo,
				account_type: null,
			});
		} else {
			setPreLoginInfo({
				...preloginInfo,
				email: null,
			});
		}
	};

	const togglePassword = () => {
		if (passwordType === "password") {
			setPasswordType("text");
			return;
		}
		setPasswordType("password");
	};

	const progress = [
		{
			title: "Email",
			pass: preloginInfo.email ? true : false,
			component: () => {
				return (
					<div>
						<p className='fs-18 text-color-dark font-weight-bold font_1'>Sign In</p>
						<Form id='login_form' onSubmit={emailInputForm.handleSubmit(handleEnterEmail.mutate)}>
							<FormGroup>
								<InputGroup
									className={
										`input-group-merge ${styles.input_container} ` +
										(emailInputForm.formState.errors.email ? "is-invalid" : "")
									}>
									<InputGroupAddon addonType='prepend'>
										<InputGroupText
											className={
												"form-control " +
												(emailInputForm.formState.errors.email ? "is-invalid" : "")
											}>
											<UserIcon width={30} height={30} />
										</InputGroupText>
									</InputGroupAddon>
									<input
										placeholder='Email'
										type='email'
										className={
											`form-control fs-15 ${styles.input_container} ` +
											(emailInputForm.formState.errors.email ? "is-invalid" : "")
										}
										{...emailInputForm.register("email")}
									/>
								</InputGroup>
								<div className='invalid-feedback'>
									{emailInputForm.formState.errors.email?.message}
								</div>
							</FormGroup>
							<div className='custom-control custom-control-alternative custom-checkbox text-center d-none'>
								<input className='custom-control-input' id=' customCheckLogin' type='checkbox' />
								<label className='custom-control-label d-none' htmlFor=' customCheckLogin'>
									<span className='text-muted'>Remember me</span>
								</label>
							</div>

							<div className='text-center mt-5'>
								<Button
									className={`${classnames("btn-gradient", styles.submit_button)}`}
									type='submit'
									disabled={handleEnterEmail.isLoading}>
									{handleEnterEmail.isLoading ? (
										<span className='fs-15'>Loading...</span>
									) : (
										<span className='fs-15'>Next</span>
									)}
								</Button>
							</div>
							{message && (
								<div className='form-group pt-3'>
									<div className='alert alert-danger' role='alert'>
										{message}
									</div>
								</div>
							)}
						</Form>
					</div>
				);
			},
		},
		{
			title: "Select Account Type",
			pass: preloginInfo.account_type ? true : false,
			component: () => {
				return (
					<div className='text-center'>
						<p className='fs-18 text-color-dark font-weight-bold font_1'>Select Account Type</p>
						<div>
							{preloginInfo.account_options.map((a) => {
								return (
									<div key={a} className='mt-4'>
										<Button
											type='submit'
											onClick={() => {
												setPreLoginInfo({
													...preloginInfo,
													account_type: a,
												});
											}}>
											{a}
										</Button>
									</div>
								);
							})}
						</div>
					</div>
				);
			},
		},
		{
			title: "Enter Password",
			pass: false,
			component: () => {
				return (
					<div>
						<p className='fs-18 text-color-dark font-weight-bold font_1'>Enter Password</p>
						<Form id='login_form' onSubmit={passwordForm.handleSubmit(handleEnterPassword.mutate)}>
							<FormGroup>
								<InputGroup
									className={
										`input-group-merge ${styles.input_container} ` +
										(emailInputForm.formState.errors.email ? "is-invalid" : "")
									}>
									<InputGroupAddon addonType='prepend'>
										<InputGroupText
											className={
												"form-control " +
												(emailInputForm.formState.errors.email ? "is-invalid" : "")
											}>
											<UserIcon width={30} height={30} />
										</InputGroupText>
									</InputGroupAddon>
									<input
										placeholder='Email'
										type='email'
										className={
											`form-control fs-15 ${styles.input_container} ` +
											(emailInputForm.formState.errors.email ? "is-invalid" : "")
										}
										readOnly
										value={
											(preloginInfo.account_type === "corporate"
												? `(${preloginInfo.account_type})`
												: "") +
											" " +
											preloginInfo.email
										}
									/>
								</InputGroup>
								<div className='invalid-feedback'>
									{emailInputForm.formState.errors.email?.message}
								</div>
							</FormGroup>
							<FormGroup className='mb-1'>
								<InputGroup
									className={
										`input-group-merge ${styles.input_container} ` +
										(passwordForm.formState.errors.password ? "needs-validation is-invalid" : "")
									}>
									<InputGroupAddon addonType='prepend'>
										<InputGroupText
											className={
												"form-control " +
												(passwordForm.formState.errors.password ? "is-invalid" : "")
											}>
											{/* <i className='ni ni-lock-circle-open' /> */}
											<LockIcon width={30} height={30} />
										</InputGroupText>
									</InputGroupAddon>
									<input
										placeholder='Password'
										type={passwordType}
										className={
											`form-control fs-15 ${styles.input_container} ` +
											(passwordForm.formState.errors.password ? "is-invalid" : "")
										}
										{...passwordForm.register("password")}
									/>
									<InputGroupAddon addonType='append'>
										<InputGroupText onClick={togglePassword}>
											{passwordType === "password" ? (
												<i className='far fa-eye-slash'></i>
											) : (
												<i className='far fa-eye'></i>
											)}
										</InputGroupText>
									</InputGroupAddon>
								</InputGroup>
								<div className='invalid-feedback'>
									{passwordForm.formState.errors.password?.message}
								</div>
							</FormGroup>
							<div className='pdt10 d-flex justify-content-end'>
								<span>
									<Link
										to='/login/reset-password'
										state={{ email: preloginInfo.email, account_type: preloginInfo.account_type, redirect_url: redirect_url }}
										className='text-blue bold ml-1 fs-15'>
										Forget Password?
									</Link>
								</span>
							</div>
							{message && (
								<div className='form-group pt-3'>
									<div className='alert alert-danger' role='alert'>
										{message}
									</div>
								</div>
							)}
							<div className='d-flex justify-content-between mt-4'>
								<Button
									style={{
										width:"auto",
										minWidth: "inherit", // added this line to fix the width of the button 'Back
										flexBasis:100,
										flexShrink:1
									}}
									onClick={handleBackFromPassword}
									className={`${styles.submit_button} col-4 mx-0`}
									disabled={handleEnterPassword.isLoading}>
									Back
								</Button>
								<Button
									style={{
										width:"auto",
										minWidth: "inherit", // added this line to fix the width of the button 'Back
										flexBasis:100,
										flexShrink:1,
										whiteSpace:"nowrap"
									}}
									className={`${classnames("btn-gradient", styles.submit_button)} col-4 mx-0`}
									type='submit'
									disabled={handleEnterPassword.isLoading}>
									{handleEnterPassword.isLoading ? (
										<span className='fs-15'>Loading...</span>
									) : (
										<span className='fs-15'>Sign In</span>
									)}
								</Button>
							</div>
						</Form>
					</div>
				);
			},
		},
		{
			title: "",
			pass: false,
			component: () => {
				return <></>;
			},
		},
	];

	useEffect(() => {
		const index = progress.findIndex((a) => !a.pass);
		console.log("index", index);
		sliderRef.current.slickGoTo(index);
	}, [preloginInfo, message]);

	return (
		<>
			<Slider {...settings} ref={sliderRef} className="">
				{progress.map((p, index) => {
					return <div key={index}>{p.component()}</div>;
				})}
			</Slider>
		</>
	);

	/**
	 * Find the last component we hasn't complete
	 */
}
