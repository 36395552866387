import {  useSessionStorageState } from "ahooks";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const useUpskilling100Flag = () => {
	const [searchParams,_] = useSearchParams();
	const [upskilling100Flag, setUpskilling100Flag] = useSessionStorageState("upskilling100", "");
	useEffect(() => {
		const upskilling100Flag = searchParams.get("upskilling100") ;
		if(upskilling100Flag){
			setUpskilling100Flag(true);
		}
	},[searchParams]);

	return {
		upskilling100Flag,
		activateUpskilling100: () => setUpskilling100Flag(true),
		clearUpskilling100: () => setUpskilling100Flag(""),
	};
};