import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interaction from "@fullcalendar/interaction";
import Slider from "react-slick";
import { toast } from "react-toastify";
import CourseBannerPlaceholder from "assets/img/Course/course-banner-placeholder.png";
import ProfilePlaceholder from "assets/img/Course/profile-dummy.jpg";

import {
	Button,
	Row,
	Modal,
	Card,
	CardBody,
	Col,
	Container,
	Badge,
	Nav,
	NavItem,
	TabPane,
	TabContent,
	CardSubtitle,
	CardTitle,
} from "reactstrap";
import styles from "./ModalCourseInfo.module.scss";

import { useDispatch, useSelector } from "react-redux";
import generalApi from "services/general-api";
import {
	saveCourse,
	resetcourseRegister,
	saveCourseRun,
	setCheckout,
} from "reducers/courseRegisterSlice";
import moment from "moment";
import { isTrainerTBA } from "common_function/function.js";

import { ReactComponent as TrainerIcon } from "assets/img/Course/trainer.svg";
import { getErrorMessage } from "utilities/error";
import { useQuery } from "@tanstack/react-query";
import PlaceHolder from "assets/img/Course/course-banner-placeholder.png";
import OneCert from "assets/img/Course/Benefit/cert01.png";
import TwoCert from "assets/img/Course/Benefit/cert02.png";
import BenefitCertIcon from "assets/img/Course/Benefit/icon_cert01.svg";
import BenefitHandUpIcon from "assets/img/Course/Benefit/icon_cert02.svg";
import BenefitStairIcon from "assets/img/Course/Benefit/icon_cert03.svg";
import classNames from "classnames";
import CollapsibleCard from "components/LearnerPortal/CollapsibleCard";
import { isFileTypeImage, formatPrice } from "common_function/function";
import CourseInfo from "components/LearnerPortal/Course/CourseInfo";
import CourseOutline from "components/LearnerPortal/Course/CourseOutline";
import CourseRightInfo from "components/LearnerPortal/Course/CourseRightInfo";
import CourseTrainingLocation from "components/LearnerPortal/Course/CourseTrainingLocation";
import RatingDisplay from "components/LearnerPortal/Course/RatingDisplay";
import FaqCard from "components/LearnerPortal/Course/FaqCard";
import ContactUs2 from "./ContactUs2";
import CourseCard from "views/pages/home/BundleInfo/CourseCard";

let calendar;

var slickSettings = {
	dots: false,
	infinite: false,
	arrows: false,
	slidesToShow: 2,
	slidesToScroll: 2,
	responsive: [
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				initialSlide: 1,
			},
		},
	],
};

var reviewSlickSettings = {
	dots: true,
	infinite: false,
	arrows: false,
	slidesToShow: 3,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 1199,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				initialSlide: 1,
			},
		},
		{
			breakpoint: 575,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
};

var courseSlickSettings = {
	dots: true,
	infinite: false,
	arrows: false,
	slidesToShow: 3,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 1199,
			settings: {
				slidesToShow: 2,
			},
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				initialSlide: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
};

function ModalCourseInfo() {
	const [activeTab, setActiveTab] = useState("tab1");
	const params = useParams();
	const course_query = useQuery(["couser-info", params.id], () => {
		return generalApi.getCourseDetail(params.id);
	});
	const dispatch = useDispatch();

	const [faqDetails, setFaqDetails] = useState(null);
	const [selectedLocation, setSelectedLocation] = useState(0);
	const [selectedCourseRunID, setSelectedCourseRunID] = useState(null);
	const [courseTrainer, setCourseTrainer] = useState([]);
	const latest_course_trainer = useRef();
	latest_course_trainer.current = courseTrainer;

	const [openModal, setOpenModal] = React.useState(0);

	const handleOpenModal = () => {
		setOpenModal(true);
	};
	const handleSuccess = () => {
		setOpenModal(false);
	};

	const course = course_query.data;
	const customFields = course_query.data?.custom_fields;
	const bundle = course?.bundles && course.bundles[0] ? course.bundles[0] : null;
	const toggleTab = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const [is980Below, setIs980Below] = useState(window.innerWidth <= 980);

	const handleWindowSizeChange = () => {
		setIs980Below(window.innerWidth <= 980);
	};

	const [isOpenEnrollModal, setIsOpenEnrollModal] = useState(false);

	const handleEnrollClick = () => {
		setIsOpenEnrollModal((prevIsOpenEnrollModal) => !prevIsOpenEnrollModal); // Toggle the state
	};

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);

		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	React.useEffect(() => {
		getFaq();
	}, []);

	React.useEffect(() => {
		console.log(courseTrainer);
	}, [courseTrainer]);

	const renderFaqQuestions = (faqList) => {
		return (
			<Row className='faq-row mx--2' style={{ marginTop: 30 }}>
				{faqList.map((item, index) => {
					return (
						<Col md={6} key={index} className='p-2'>
							<FaqCard headerText={item.article_title} body={item.article_content} />
						</Col>
					);
				})}
			</Row>
		);
	};

	const getFaq = () => {
		generalApi.getFAQ().then(
			(response) => {
				setFaqDetails(response.data);
			},
			(error) => {
				let error_message = getErrorMessage(error);
				toast.error(error_message);
				setFaqDetails(null);
			},
		);
	};

	const query = useQuery(
		["bundle-info", bundle?.bundle_id],
		() => {
			return generalApi.getBundleDetailV2(bundle?.bundle_id);
		},
		{
			retry: 0,
			enabled: Boolean(bundle?.bundle_id),
		},
	);

	const replaceContent = (content, color) => {
		let svg =
			"<svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 512 512' fill='" +
			color +
			"'><path d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z' /></svg>";

		return content ? content.replace(/<li>/g, `<li>${svg}`) : "";
	};

	const navTrainerSliderRef = useRef(null);
	const trainerSliderRef = useRef(null);

	var navTrainerSlickSettings = {
		dots: false,
		infinite: false,
		arrows: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		asNavFor: trainerSliderRef.current,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
		],
	};

	var trainerSlickSettings = {
		dots: false,
		infinite: false,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		asNavFor: navTrainerSliderRef.current,
	};

	const handleNavTrainerSlideClick = (index) => {
		// Go to the corresponding slide in the main trainer slider
		trainerSliderRef.current.slickGoTo(index);
	};

	if (course_query.isLoading) return <div>Loading...</div>;

	return (
		<div className={styles.container}>
			<Container fluid>
				{customFields?.course_banner && typeof customFields?.course_banner === "object" && (
					<Slider {...slickSettings} className={styles.media_slick}>
						{Object.keys(customFields.course_banner).map((key, i) => (
							<div key={key}>
								{isFileTypeImage(customFields.course_banner[key].content.type) ? (
									<img
										className={styles.media_block}
										src={customFields.course_banner[key].content.url}
									/>
								) : (
									<video className={styles.media_block} controls>
										<source
											src={customFields.course_banner[key].content.url}
											type={customFields.course_banner[key].content.type}
										/>
									</video>
								)}
							</div>
						))}
					</Slider>
				)}
				<Row className='pt-5'>
					<Col xl={9} lg={8} md={12}>
						<div className={`shadow-sm ${styles.content_block}`}>
							<CourseInfo
								course_title={course.course_title}
								course_logos={course.logos}
								course_duration={course.course_duration}
								course_categories={course.categories}
								courserun_modeoftraining='Face-to-Face Classroom'
								course_description={course.course_description}
								course_registered_learners={course.registered_learners}
							/>
						</div>

						{customFields?.course_outline_content && (
							<div className={`mt-4 shadow-sm ${styles.content_block}`}>
								<CourseOutline
									title={customFields.course_outline_title}
									description={customFields.course_outline_description}
									content={customFields.course_outline_content}
								/>
							</div>
						)}

						{customFields?.cert_section_1_content && customFields?.cert_section_1_title && (
							<div className={`mt-4 shadow-sm ${styles.cert_section_1} ${styles.content_block}`}>
								<Row>
									<Col xl={6} className={`${styles.benefit_content}`}>
										<h5>{customFields?.cert_section_1_title}</h5>
										{customFields?.cert_section_1_content && (
											<div className='mt-4'>
												{Object.keys(customFields.cert_section_1_content).map((key) => (
													<div key={key} className={styles.benefit_box}>
														<img
															className={styles.benefit_icon}
															src={customFields.cert_section_1_content[key].icon.url}
															alt={customFields.cert_section_1_content[key].title}
														/>
														<div className=''>
															<h3 className={styles.benefit_title}>
																{customFields.cert_section_1_content[key].title}
															</h3>
															<p
																className={styles.benefit_description}
																dangerouslySetInnerHTML={{
																	__html: customFields.cert_section_1_content[key].description,
																}}></p>
														</div>
													</div>
												))}
											</div>
										)}
									</Col>
									<Col className={`${styles.benefit_illustration}`} xl={6}>
										<img
											className={styles.benefit_cert_img}
											src={customFields?.cert_section_1_image?.url}
											alt='cert'
										/>
									</Col>
								</Row>
							</div>
						)}

						{customFields?.cert_section_2_content && (
							<div className={`mt-4 shadow-sm ${styles.content_block}`}>
								<Row>
									<Col xl={6} className={styles.benefit_illustration}>
										<img
											className={styles.benefit_cert_img}
											src={customFields?.cert_section_2_image?.url}
											alt='cert'
										/>
									</Col>
									<Col xl={6}>
										<div
											className='desc svg_li_wrap'
											dangerouslySetInnerHTML={{
												__html: customFields?.cert_section_2_content
													? replaceContent(customFields.cert_section_2_content, "#3A83C4")
													: "",
											}}></div>
									</Col>
								</Row>
							</div>
						)}

						{course?.training_location && (
							<div className={`mt-4 shadow-sm ${styles.content_block}`}>
								<CourseTrainingLocation
									course_training_location={course.training_location}
									selectedLocation={selectedLocation}
									setSelectedLocation={setSelectedLocation}
									selectedCourseRunID={selectedCourseRunID}
									setSelectedCourseRunID={setSelectedCourseRunID}
								/>
							</div>
						)}

						<div className={`mt-4 shadow-sm ${styles.content_block}`}>
							<h2 className={styles.heading}>Course Fees & Subsidies</h2>
							<div className={`${styles.course_fee_wrap} pt-4`}>
								<Nav tabs className={`${styles.nav_header}`}>
									<NavItem className={`${styles.nav_button}`}>
										<div
											className={classNames({ [styles.active]: activeTab === "tab1" })}
											onClick={() => toggleTab("tab1")}>
											<div className='text-center'>
												<b>Singaporean & PR</b>
												<span>(Aged 21 to 39 years old)</span>
											</div>
										</div>
									</NavItem>
									<NavItem className={`${styles.nav_button}`}>
										<div
											className={classNames({ [styles.active]: activeTab === "tab2" })}
											onClick={() => toggleTab("tab2")}>
											<div className='text-center'>
												<b>Singaporean & PR</b>
												<span>(Aged 40 years old & above)</span>
											</div>
										</div>
									</NavItem>
									<NavItem className={`${styles.nav_button}`}>
										<div
											className={classNames({ [styles.active]: activeTab === "tab3" })}
											onClick={() => toggleTab("tab3")}>
											<div className='text-center'>
												<b>Others</b>
											</div>
										</div>
									</NavItem>
								</Nav>
								<TabContent activeTab={activeTab}>
									<TabPane tabId='tab1'>
										<div className={styles.content_wrap}>
											<table className='w-100 fee-table-top m-b-40'>
												<thead>
													<tr>
														<td
															className='text-center bold f25 text-white'
															colSpan='2'
															style={{ backgroundColor: "#FFBD43" }}>
															Singaporean & PR (Aged 21 to 39 years old)
														</td>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>Full Course Fees (Before GST)</td>
														<td className='text-center'>{formatPrice(course.course_price_3)}</td>
													</tr>

													<tr>
														<td>8% GST (On Full Course Fees)</td>
														<td className='text-center'>
															{formatPrice(Number(course.course_price_3) * 0.08)}
														</td>
													</tr>

													<tr>
														<td>
															<strong>SkillsFuture Subsidy: </strong>
															<br />
															Up to 50% of course fee
															<br />
															For more information on{" "}
															<a
																href='https://www.skillsfuture.gov.sg/funding-individuals'
																target='_blank'
																rel='noreferrer'>
																Course Fee Funding
															</a>
														</td>
														<td className='text-center'>
															-{formatPrice(Number(course.course_price_3) * 0.5)}
														</td>
													</tr>

													<tr>
														<td>Nett Fees Payable</td>
														<td className='text-fca-green text-center font-weight-bold'>
															{formatPrice(
																Number(course.course_price_3) -
																	Number(course.course_price_3) * 0.5 +
																	Number(course.course_price_3) * 0.08,
															)}
														</td>
													</tr>
												</tbody>
											</table>
											<table className='w-100'>
												<thead>
													<tr>
														<td
															className='text-center bold f25 text-white'
															colSpan='2'
															style={{ backgroundColor: "#E85941" }}>
															Additional Support
														</td>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td className='text-center'>
															SkillsFuture Credits eligible for Singapore citizens aged 25 & above
														</td>
													</tr>
												</tbody>
											</table>

											<div className={`${styles.note_wrap} mt-3`}>
												<ul>
													<li>
														<span>
															Subjected to individual’s eligibility and availability of SkillsFuture
															Credit.
														</span>
													</li>
													<li>
														<span>
															Nett Fees Payable are inclusive of GST charged based on the full
															course fees.
														</span>
													</li>
												</ul>
											</div>
										</div>
									</TabPane>
									<TabPane tabId='tab2'>
										<div className={styles.content_wrap}>
											<table className='w-100 fee-table-top m-b-40'>
												<thead>
													<tr>
														<td
															className='text-center bold f25 text-white'
															colSpan='2'
															style={{ backgroundColor: "#CF4D86" }}>
															Singapore Citizens(40 years and above)
														</td>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>Full Course Fees (Before GST)</td>
														<td className='text-center'>
															{formatPrice(Number(course.course_price_3))}
														</td>
													</tr>

													<tr>
														<td>8% GST (On Full Course Fees)</td>
														<td className='text-center'>
															{formatPrice(Number(course.course_price_3) * 0.08)}
														</td>
													</tr>

													<tr>
														<td>
															<strong>SkillsFuture Subsidy: </strong>
															<br />
															Up to 70% of course fee
															<br />
															For more information on{" "}
															<a
																href='https://www.skillsfuture.gov.sg/funding-individuals'
																target='_blank'
																rel='noreferrer'>
																Course Fee Funding
															</a>
														</td>
														<td className='text-center'>
															-{formatPrice(Number(course.course_price_3) * 0.7)}
														</td>
													</tr>

													<tr>
														<td>Nett Fees Payable</td>
														<td className='text-fca-green text-center font-weight-bold'>
															{formatPrice(
																Number(course.course_price_3) -
																	Number(course.course_price_3) * 0.7 +
																	Number(course.course_price_3) * 0.08,
															)}
														</td>
													</tr>
												</tbody>
											</table>
											<table className='w-100'>
												<thead>
													<tr>
														<td
															className='text-center bold f25 text-white'
															colSpan='2'
															style={{ backgroundColor: "#E85941" }}>
															Additional Support
														</td>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td className='text-center'>
															SkillsFuture Credits eligible for Singapore citizens aged 25 & above
														</td>
													</tr>
												</tbody>
											</table>

											<div className={`${styles.note_wrap} mt-3`}>
												<ul>
													<li>
														<span>
															Subjected to individual’s eligibility and availability of SkillsFuture
															Credit.
														</span>
													</li>
													<li>
														<span>
															Nett Fees Payable are inclusive of GST charged based on the full
															course fees.
														</span>
													</li>
												</ul>
											</div>
										</div>
									</TabPane>
									<TabPane tabId='tab3'>
										<div className={styles.content_wrap}>
											<table className='w-100 fee-table-top m-b-40'>
												<thead>
													<tr>
														<td
															className='text-center bold f25 text-white'
															colSpan='2'
															style={{ backgroundColor: "#52D3C6" }}>
															Others
														</td>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>Full Course Fees (Before GST)</td>
														<td className='text-center'>
															{formatPrice(Number(course.course_price_3))}
														</td>
													</tr>
													<tr>
														<td>8% GST (On Full Course Fees)</td>
														<td className='text-center'>
															{formatPrice(Number(course.course_price_3) * 0.08)}
														</td>
													</tr>
													<tr>
														<td>Nett Fees Payable</td>
														<td className='text-fca-green text-center font-weight-bold'>
															{formatPrice(
																Number(course.course_price_3) * 0.08 +
																	Number(course.course_price_3),
															)}
														</td>
													</tr>
												</tbody>
											</table>
											{/* <table className='w-100'>
											<thead>
												<tr>
													<td
														className='text-center bold f25 text-white'
														colSpan='2'
														style={{ backgroundColor: "#E85941" }}>
														Additional Support
													</td>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td className='text-center'>
														SkillsFuture Credits eligible for Singapore citizens aged 25 & above
													</td>
												</tr>
											</tbody>
										</table> */}

											<div className={`${styles.note_wrap} mt-3`}>
												<ul>
													<li>
														<span>
															Subjected to individual’s eligibility and availability of SkillsFuture
															Credit.
														</span>
													</li>
													<li>
														<span>
															Nett Fees Payable are inclusive of GST charged based on the full
															course fees.
														</span>
													</li>
												</ul>
											</div>
										</div>
									</TabPane>
								</TabContent>
							</div>
						</div>

						{courseTrainer && courseTrainer.length > 0 && (
							<div className={`mt-4 shadow-sm ${styles.content_block}`}>
								<h2 className={styles.heading}>Trainers For This Course</h2>
								<Slider
									{...navTrainerSlickSettings}
									className={`mt-3 sameheight-slider ${styles.trainer_slider_nav}`}
									ref={navTrainerSliderRef}>
									{courseTrainer.map((data, index) => (
										<div
											key={index}
											className={`h-100 ${styles.trainer_box}`}
											onClick={() => handleNavTrainerSlideClick(index)}>
											<Row className='h-100'>
												<Col sm={4}>
													<img
														className='img-fluid'
														src={data.trainer_image_url || ProfilePlaceholder}
														alt={data.trainer_name}
													/>
												</Col>
												<Col sm={8} className='d-flex align-items-center'>
													<div>
														<p className={styles.name}>{data.trainer_name}</p>
														<p className={styles.qualification}>
															{data.trainer_qualification_level}
														</p>
													</div>
												</Col>
											</Row>
										</div>
									))}
								</Slider>

								<Slider
									{...trainerSlickSettings}
									className={`mt-5 ${styles.trainer_slider}`}
									ref={trainerSliderRef}>
									{courseTrainer.map((data, index) => (
										<div key={index} className={styles.trainer_box}>
											<Row>
												<Col sm={4}>
													<img
														className='img-fluid'
														src={data.trainer_image_url || ProfilePlaceholder}
														alt={data.trainer_name}
													/>
												</Col>
												<Col sm={8} className='d-flex align-items-center pt-4 pt-sm-0'>
													<div>
														<h4 className={styles.trainer_title}>{data.trainer_name}</h4>
														<p
															className={styles.trainer_description}
															dangerouslySetInnerHTML={{
																__html: data.trainer_qualification_description,
															}}></p>
														<p
															className={styles.trainer_description}
															dangerouslySetInnerHTML={{ __html: data.trainer_keysector }}></p>
													</div>
												</Col>
											</Row>
										</div>
									))}
								</Slider>
							</div>
						)}

						{course.custom_fields?.review_content && (
							<div className={`mt-4 shadow-sm ${styles.content_block}`}>
								<h2 className={styles.heading}>Reviews From Learners</h2>
								<Slider {...reviewSlickSettings} className='mt-3 mb-5 sameheight-slider'>
									{Object.values(course.custom_fields.review_content).map((data, index) => (
										<div key={index} className={styles.review_box}>
											<div>
												<Row>
													<Col md='auto' className='pr-0'>
														<img className='w-40px' src={data.image.url} alt={data.title} />
													</Col>
													<Col className='d-flex align-items-center'>
														<div>
															<h4 className={styles.review_title}>{data.title}</h4>
															<div className='rating_wrap'>
																<RatingDisplay rating={Number(data.rating)} />
															</div>
														</div>
													</Col>
												</Row>
												<div className='pt-3'>
													<p
														className={styles.trainer_description}
														dangerouslySetInnerHTML={{ __html: data.description }}></p>
												</div>
											</div>
										</div>
									))}
								</Slider>
							</div>
						)}

						{faqDetails && faqDetails.course_related.length > 0 && (
							<div className={`mt-4 shadow-sm ${styles.content_block}`}>
								<h2 className={styles.heading}>Frequently Asked Questions</h2>
								{renderFaqQuestions(faqDetails.course_related, "Course Related")}
							</div>
						)}

						{query?.data?.courses && (
							<div className={`mt-4 shadow-sm pb-3 ${styles.content_block}`}>
								<h2 className={styles.heading}>Learners Who Took This Course Also Enrolled In</h2>
								<Slider {...courseSlickSettings} className={styles.course_slick}>
									{query?.data?.courses.map((course) => {
										return (
											<Link
												key={course.course_id}
												to={{
													pathname:
														"/course/course-info/" + (course.course_slug || course.course_id),
												}}>
												<CourseCard key={course.course_id} course={course} />
											</Link>
										);
									})}
								</Slider>
							</div>
						)}
					</Col>
					<Col
						xl={3}
						lg={4}
						md={12}
						className={`course-right-container ${isOpenEnrollModal ? "active" : ""}`}>
						{is980Below && (
							<div className={"mobile-back-button"} onClick={handleEnrollClick}>
								<i className='fa fa-angle-left' aria-hidden='true'></i>
							</div>
						)}
						<div className={`${styles.content_sticky} content-sticky`}>
							<div className={`${styles.content_block} shadow-sm`}>
								<CourseRightInfo
									course_title={course.course_title}
									course_duration={course.course_duration}
									courserun_modeoftraining='Face-to-Face Classroom'
									course_registered_learners={course.registered_learners}
									course_training_location={course.training_location}
									selectedLocation={selectedLocation}
									setSelectedLocation={setSelectedLocation}
									selectedCourseRunID={selectedCourseRunID}
									setSelectedCourseRunID={setSelectedCourseRunID}
									courseTrainer={courseTrainer}
									setCourseTrainer={setCourseTrainer}
									is980Below={is980Below}
								/>
							</div>
							<div className={classNames(styles.enquiry, "shadow-sm mt-4")}>
								<h4>Please feel free to send us your enquiries online.</h4>
								<Row className='ml-0 mr-0 my-4'>
									<Col>
										<p>
											Drop us a message, our trained Education Consultant will assist you with your
											learning journey.
										</p>
									</Col>
								</Row>
								<Button
									outline
									className={classNames(styles.call_us_btn, "mt-4")}
									onClick={handleOpenModal}>
									{"Enquiry Now"}
								</Button>
								<ContactUs2 show={openModal} onSuccess={handleSuccess}></ContactUs2>
							</div>
						</div>
					</Col>
				</Row>
			</Container>

			{is980Below && (
				<div className=' pd20'>
					<Button target='_blank' rel='noreferrer' href='https://www.fca.edu.sg/courses/' className='btn-gradient w-100'>
						Enroll Now
					</Button>
					{/* <Button className='btn-gradient w-100' onClick={handleEnrollClick}>
						Enroll Now
					</Button> */}
				</div>
			)}
		</div>
	);
}

export default ModalCourseInfo;
