import { LOGIN_SUCCESS } from "actions/types";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import { useEventCheckout } from "reducers/eventRegisterSlice";
import authApi from "services/auth-api";
import generalApi from "services/general-api";

export const EventAppCheckoutPage = () => {
	const { setIsAppCheckout }= useEventCheckout();
	const location = useLocation();
	const param = new URLSearchParams(location.search);
	const eventRegformId = param.get("eventregform_id");
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => { 
		if(!eventRegformId){
			alert("Invalid Event Registration Form ID");
			return;
		}

		// INJECTED VIA FALCOMM APP
		let token = window.FALCOMM_APP_USER_TOKEN; 

		// for development testing purposes
		// can turn web into mobile checkout flow , for easier debugging
		if(!token && process.env.NODE_ENV === "development"){
			token = new URLSearchParams(window.location.search).get("token");
		}

		if(!token){
			alert("Invalid Session");
			return;
		}

		authApi.getUserByToken(token).then((data) => {
			if(data.user){
				let test = dispatch({
					type: LOGIN_SUCCESS,
					payload: {user: data}
				});
				console.log(test);
				setIsAppCheckout(true);
				setTimeout(() => {
					navigate({
						pathname: "/event/checkout",
						search: `eventregform_id=${eventRegformId}`,
					},{
						replace: true 
					});
				}, 2000);
			}else{
				alert("Unable to initialize session");
			}
		}).catch((error) => { 
			if(axios.isAxiosError(error)){
				alert("error: " + error.response.status);
			}
		});
	}, [ eventRegformId ]);

	return (
		<div style={{height:"100%",width:"100%", display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
			<Spinner />
			<span>Loading</span>
		</div>
	);
};