import {
	GET_EVENT_FIRST,
	GET_EVENT,
	GET_EVENT_SUCCESS,
	GET_EVENT_FAIL,
	GET_DAILY_NEWS,
	GET_DAILY_NEWS_SUCCESS,
	GET_DAILY_NEWS_FAIL,
	GET_TEASERS,
	GET_TEASERS_SUCCESS,
	GET_TEASERS_FAIL,
	ARTICLE_LIKE,
	ARTICLE_LIKE_SUCCESS,
	ARTICLE_LIKE_FAIL,
	ARTICLE_SHARE,
	ARTICLE_SHARE_SUCCESS,
	ARTICLE_SHARE_FAIL,
	SET_CURRENT_NEWS_INDEX,
} from "./types";

import NewsService from "../services/news-api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import newsApi from "../services/news-api";

export const getEvent = (paramPage = 0, event_types = "event", searchText="") => (dispatch) => {
	console.log(paramPage);
	if (paramPage == 0) {
		dispatch({
			type: GET_EVENT_FIRST,
			payload: { page: paramPage },
		});
	} else {
		dispatch({
			type: GET_EVENT,
			payload: { page: paramPage },
		});
	}

	return NewsService.getEvent(paramPage, event_types, searchText).then(
		(data) => {
			dispatch({
				type: GET_EVENT_SUCCESS,
				payload: { events: data.data, page: paramPage },
			});

			return Promise.resolve(data);
		},
		(error) => {
			dispatch({
				type: GET_EVENT_FAIL,
			});
			return Promise.reject(error);
		},
	);
};


export const getDailyNews = (paramPage = 0, article_types = "article") => (dispatch) => {
	dispatch({
		type: GET_DAILY_NEWS,
	});

	return NewsService.getArticles(paramPage, article_types).then(
		(data) => {
			dispatch({
				type: GET_DAILY_NEWS_SUCCESS,
				payload: { daily_news: data.data, page: paramPage },
			});

			return Promise.resolve(data);
		},
		(error) => {
			dispatch({
				type: GET_DAILY_NEWS_FAIL,
			});
			return Promise.reject(error);
		},
	);
};

export const getTeasers = (paramPage = 0, article_types = "teaser") => (dispatch) => {
	dispatch({
		type: GET_TEASERS,
	});

	return NewsService.getArticles(paramPage, article_types).then(
		(data) => {
			dispatch({
				type: GET_TEASERS_SUCCESS,
				payload: { teasers: data.data, page: paramPage  },
			});

			return Promise.resolve(data);
		},
		(error) => {
			dispatch({
				type: GET_TEASERS_FAIL,
			});
			return Promise.reject(error);
		},
	);
};


export const articleLike = (article_id, liked) => (dispatch) => {
	dispatch({
		type: ARTICLE_LIKE,
		payload: { liked: liked, article_id: article_id },
	});

	return NewsService.articleLike(article_id).then(
		(data) => {
			dispatch({
				type: ARTICLE_LIKE_SUCCESS,
				payload: { liked: data.liked, article_id: article_id },
			});

			return Promise.resolve(data);
		},
		(error) => {
			dispatch({
				type: ARTICLE_LIKE_FAIL,
				payload: { liked: liked, article_id: article_id },
			});
			return Promise.reject(error);
		},
	);
};

export const articleShare = (article_id) => (dispatch) => {
	dispatch({
		type: ARTICLE_SHARE,
		payload: { article_id: article_id },
	});

	return NewsService.articleShare(article_id).then(
		(data) => {
			dispatch({
				type: ARTICLE_SHARE_SUCCESS,
				payload: { article_id: article_id },
			});

			return Promise.resolve(data);
		},
		(error) => {
			dispatch({
				type: ARTICLE_SHARE_FAIL,
				payload: { article_id: article_id },
			});
			return Promise.reject(error);
		},
	);
};

export const postCommentThunk = createAsyncThunk(
	"article/post_comment",
	async (payload) => {
		const postCommentResponse = await newsApi.postComment(
			payload.article_id,
			payload.comment,
			payload.reply_to_comment,
		);
		return postCommentResponse.data;
	},
	{
		serializeError: x => x,
	},
);

export const setCurrentNewsIndex = (index) => (dispatch) => {
	dispatch({
		type: SET_CURRENT_NEWS_INDEX,
		payload: { currentIndex: index },
	});
};
