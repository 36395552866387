import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// reactstrap components
import { Card, CardBody, Container } from "reactstrap";

// react plugin used to create datetimepicker
import { clearMessage } from "actions/message";

import generalApi from "services/general-api";
import { useCheckout } from "hooks/selectors/useCheckout";
import { GTMCheckoutCompleted, GTMCheckoutFacebookCheckoutCompleted, GTMCheckoutGoogleAdsCheckoutCompleted, GTMCheckoutPaynowPaymentFailed, GTMCheckoutPaynowPaymentSuccess, GTMCheckoutStep2_5GoSuccess, GTMCheckoutStep2_5Paynow, GTMCheckoutStep2_5PaynowExit } from "utilities/googleTagManager";
import { sleep } from "utilities/common";


function EventOmisePaynow() {
	let navigate = useNavigate();
	const { state } = useLocation();
	const { payment_id, payment_paynow_qr } = state;
	const [countDown, setCountDown] = useState(6);


	/**
	 * Update count down display every second
	 */
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		let cancel = false;
		let remaningSeconds = 60 * 10; // 10 mins

		countDown();
		async function countDown() {
			for (remaningSeconds; remaningSeconds >= 0; remaningSeconds--) {
				// if cancel , exit the count down functino
				if (cancel) {
					return;
				}

				// more than 60 sec: 1 minute 30 seconds 
				// less than than 60 sec:  59 seconds
				const timeFormat = remaningSeconds > 60 
					? `${Math.floor(remaningSeconds / 60)} minutes ${remaningSeconds % 60} seconds` 
					: `${remaningSeconds} seconds`;

				setCountDown(timeFormat);
				await sleep(1000);
			}

			// count down is over redirect to /event/checkout
			navigate("/event/checkout", {
				state: {
					payment_id: payment_id,
					payment_status: false,
				},
			});
		}

		return () => {
			cancel = true;
		};
	}, []);

	/**
	 * Check Payment Status Every X seconds (8 seconds for now)
	 */
	useEffect(() => {
		// const searchParams = new URLSearchParams(window.location.search);
		// const payment_id = searchParams.get("payment_id");

		const interval = setInterval(async () => {
			// Dispatch the async action to check payment status
			// Assuming 'dispatch' and 'generalApi' are defined
			let jsonObj = await generalApi.checkpaymentStatus({
				payment_id: payment_id,
			});

			if(jsonObj.status !== 1) {
				console.warn("Payment Status is not 1");
				// TODO: Display error message to user ?
				// maybe redirect them to 
				alert("Something is wrong");
				return; 
			}
			if (jsonObj.payment_status === "SUCCESSFUL") {
				navigate("/event/checkout/success");
				// TODO: Redirect to /event/success_page
			} else if (jsonObj.payment_status === "FAILED") {
				// TODO: Redirect to /event/success_page
				clearInterval(interval);
				navigate("/event/checkout/course", {
					state: {
						payment_id: payment_id,
						regform_id: 1,
						payment_status: false,
						countdown: false,
					},
				});
			} 
		}, 8000);

		return () => {
			clearInterval(interval);
		};
	}, []);
	
	return (
		<>
			<Container className=''>
				<Card className='border-0 '>
					<CardBody className=''>
						<div className='title-container'>
							<h2>Checkout</h2>
						</div>
						<div className='font-weight-bold fs16'>Omise Paynow</div>
						<div className='mt-3 flex justify-content-between content_center'>
							<div className='col-md-8'>
								<h4>Instruction</h4>
								<div className='sub-title'>For PC/Laptop users:</div>
								<div className='sub-content'>
									<ul className='no-bullets'>
										<li> 1. Launch your preferred banking app</li>
										<li>2. Scan the QR Code</li>
										<li>3. Verify the amount and authorize payment</li>
										<li>4. Page will refresh upon successful receipt of payment</li>
									</ul>
								</div>

								<div className='sub-title pt-5'>For Mobile users:</div>
								<div className='sub-content'>
									<ul className='no-bullets'>
										<li> 1. Screenshot / save this QR code to your photo gallery </li>
										<li>2. On your banking app go to "Scan QR"</li>
										<li>3. Verify the amount and authorize payment</li>
										<li>4. Page will refresh upon successful receipt of payment</li>
									</ul>
								</div>
							</div>
							<div className='col-md-4'>
								<div className='my-2' style={{ background: "#ffffff" }}>
									<div className='paynowImgDiv'>
										<img src={payment_paynow_qr} />
									</div>
									<div className='countdownDiv flex'>
										<div>Please make payment within </div>
										<span className='ml-1' id='countdown'>
											{countDown}
										</span>
									</div>
								</div>
							</div>
						</div>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default EventOmisePaynow;
