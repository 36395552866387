
import React, { useEffect, useRef, useState } from "react";
import styles from "../AvatarPage.module.scss";

const AvatarOccupationListing = ({ data = [], currentAvatar = null, onSelectAvatar = () => null }) => {
	const [groupedImages, setGroupedImages] = React.useState([]);
	const [fade, setFade] = useState(true);

	useEffect(() => {
		if (data && data.length > 0) {
			setGroupedImages([]);
			const newGroupedImages = [];
			for (let i = 0; i < data.length; i += 3) {
				newGroupedImages.push(data.slice(i, i + 3));
			}
			setGroupedImages(newGroupedImages);

			setFade(false);
			setTimeout(() => {
				setFade(true);
			}, 300); // Duration of fade-out transition
		}
	}, [data]);

	return (
		<div className={`${styles.avatar_listing_selection_main_container} ${fade ? styles.fade_in : styles.fade_out} mt-2`}>
			{groupedImages.map((group, index) => (
				<div key={index} className={`${styles.avatar_listing_selection_container} ${fade ? styles.fade_in : styles.fade_out}`}>
					{group.map((item, idx) => (
						<div key={idx} className={`${styles.avatar_listing_image_container}`}>
							<img
								src={item && item.avatar_occupation_selection_image ? item.avatar_occupation_selection_image : null}
								alt={`Image ${idx}`}
								className={`${styles.avatar_listing_image} ${currentAvatar && currentAvatar.id && currentAvatar.id == item.id ? styles.selected : null} cursor-pointer`}
								onClick={() => onSelectAvatar(item)}
							/>
						</div>
					))}
				</div>
			))}
		</div>
	);
};
export default AvatarOccupationListing;