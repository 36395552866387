import React, { useEffect } from "react";
// reactstrap components
import { Button, CardBody, CardFooter, CardHeader, Col, Row} from "reactstrap";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
	Card,
} from "reactstrap";
import { toast } from "react-toastify";
import newsApi from "services/news-api";
import { getErrorMessage } from "utilities/error";
import moment from "moment";
import "../../../../assets/scss/custom/_inner-html.scss";
import { useSelector } from "react-redux";
import styles from "./Event.module.scss";
import OtherEvents from "components/Event/OthersEvents";
import ModalEventRegister from "views/pages/learner/event/components/ModalEventRegister";
import { useWindow } from "hooks/UseWindow";
import ModalEventTNC from "views/pages/learner/event/components/ModalEventTNC";
import ModalAttendanceConfirmation from "views/pages/learner/event/components/ModalAttedanceConfirmation";

const WEB_URL = process.env.REACT_APP_WEB_URL;

function EventDetails() {
	const params = useParams();
	// const param_event_registration_id = params.event_registration_id;
	const searchParam = useSearchParams();
	const [event, setEvent] = React.useState(null);
	const [isButtonLoading, setIsButtonLoading] = React.useState(false);
	const [isError, setIsError] = React.useState(false);
	const [isRegisterLoading, setIsRegisterLoading] = React.useState(false);
	const [isRegisterModalOpen, setIsRegisterModalOpen] = React.useState(false);
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState(false);
	const [isTNCModalOpen, setIsTNCModalOpen] = React.useState(false);
	const [isEventDetailsOpen, setIsEventDetailsOpen] = React.useState(false);
	const { user: currentUser } = useSelector((state) => state.auth);
	let navigate = useNavigate();
	const { width } = useWindow();

	useEffect(() => {
		getEventDetails();
	}, [searchParam.event_registration_id]);

	const getEventDetails = () => {
		setIsButtonLoading(true);
		setIsError(false);
		const urlParam = new URLSearchParams(window.location.search);
		newsApi.getEventDetails(params.id, urlParam.get("event_registration_id")).then(
			(response) => {
				setIsButtonLoading(false);
				let newEventdetails = response.data;
				let newDescHtml = newEventdetails.event_content;
				newDescHtml = newDescHtml.split("<oembed").join("<iframe");
				newDescHtml = newDescHtml.split("</oembed>").join("</iframe>");
				newDescHtml = newDescHtml.split("url=").join("src=");
				newEventdetails.event_content = newDescHtml;
				setEvent(newEventdetails);
				setIsError(false);
				if(newEventdetails.display_attendance_confirmation){
					setIsConfirmationModalOpen(true);
				}
			},
			(error) => {
				toast.error(getErrorMessage(error));
				setIsButtonLoading(false);
				setEvent(null);
				setIsError(true);
			},
		);
	};

	const registerEvent = (event_id, ticketValue) => {
		if (event.event_regfee_product) {
			// payment
			// 	// TODO: create a event registration
			// 	generalApi.createEventRegistrationForm({
			// 		event_id : event.event_id,
			// 		ticket_number : ticketValue
			// 	}).then((data) => { 
			// 		if(data.event_registrationform_id ){
			// 			navigate(`/event/checkout?event_registrationform_id=${data.event_registrationform_id}`);
			// 		}else{
			// 			toast.error("Failed to create registration form");
			// 		}
			// 	}).catch((error) => { 
			// 		toast.error(getErrorMessage(error));
			// 	});
		} else {
			// non payment
			setIsRegisterLoading(true);
			newsApi.registerEvent(event_id, ticketValue).then(
				(_data) => {
					setEvent(_data.data);
					setIsRegisterLoading(false);
					toast.success("Event Registered Successfully!");
				},
				(error) => {
					toast.error(getErrorMessage(error));
					setIsRegisterLoading(false);
				},
			);
		}
	};

	function convertDateFormat(dateString) {
		// Parse the input date string
		const date = new Date(dateString);
	
		// Extract date components
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed
		const day = date.getDate().toString().padStart(2, "0");
		const hours = date.getHours().toString().padStart(2, "0");
		const minutes = date.getMinutes().toString().padStart(2, "0");
		const seconds = date.getSeconds().toString().padStart(2, "0");
	
		// Format the date string in the desired format 'YYYYMMDDTHHMMSSZ'
		const formattedDate = `${year}${month}${day}T${hours}${minutes}${seconds}.000Z`;
	
		return formattedDate;
	}

	function convertToGMTPlus8(dateTimeString) {
		// Parse the input date string
		const parsedDateTime = new Date(dateTimeString);
	  
		// Adjust for GMT+8 (adding 8 hours)
		parsedDateTime.setHours(parsedDateTime.getHours() - 8);
	  
		return parsedDateTime;
	}

	// add to calendar button
	const handleAddToCalendar = (event) => {
		const eventDetails = {
			text: event.event_title ?? "",
			dates: "",
			// 20240401T110000.000Z/20240410T150000.000Z  Event dates in ISO 8601 format
			details: event.event_description ?? "",
			location: event.event_location ?? "",
		};

		let event_time_from = "";
		let event_time_to = "";
		let final_date = "";
		if (event.event_from) {
			const eventFromDate = new Date(event.event_from);
			const formattedDate = eventFromDate.toISOString().split("T")[0];
			event_time_from = formattedDate;
			if (event.event_time_from) {
				event_time_from += ` ${event.event_time_from}`;
			}
		}
		if (event.event_to) {
			const eventToDate = new Date(event.event_to);
			const formattedDate = eventToDate.toISOString().split("T")[0];
			event_time_to = formattedDate;
			if (event.event_time_to) {
				event_time_to += ` ${event.event_time_to}`;
			}
		}

		if (event_time_from !== "") {
			const parsedDateTime = convertToGMTPlus8(event_time_from);
			final_date += `${convertDateFormat(parsedDateTime)}/`;
		}
		if (event_time_to !== "") {
			const parsedDateTime = convertToGMTPlus8(event_time_to);
			final_date += `${convertDateFormat(parsedDateTime)}`;
		} else {
			const parsedDateTime = convertToGMTPlus8(event_time_from);
			final_date += `${convertDateFormat(parsedDateTime)}`;
		}
		eventDetails.dates = final_date;
		// Generate Google Calendar URL
		const googleCalendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(eventDetails.text)}&dates=${encodeURIComponent(eventDetails.dates)}&details=${encodeURIComponent(eventDetails.details)}&location=${encodeURIComponent(eventDetails.location)}`;

		// Open Google Calendar URL in a new tab
		window.open(googleCalendarUrl, "_blank");
	};

	const handleShare = (event) => {
		if (navigator.share) {
			navigator.share({
				title: event.event_title ?? "",
				url: window.location.href
			})
				.then(() => console.log("Successfully shared"))
				.catch(error => console.error("Error sharing:", error));
		} else {
			// Fallback: Copy the URL to the clipboard
			const textField = document.createElement("textarea");
			textField.value = window.location.href;
			document.body.appendChild(textField);
			textField.select();
			document.execCommand("copy");
			document.body.removeChild(textField);
			toast.success(`Copied: ${window.location.href}`);
			
			console.log("URL copied to clipboard");
		}
	};

	const RegisterButton = () => {
		if(event.type=="General"|| event.event_type=="General"){
			return null;
		}
		/**
		 * If the user is not logged in, show a button to login
		 */
		
		if(!currentUser){
			return (
				<div className="" style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", overflow: "hidden"}}>
					<Button className='btn-gradient w-100' style={{ padding: 14 }} onClick={() => navigate("/login")} disabled={isRegisterLoading}>
						{isRegisterLoading && <span className='spinner-border spinner-border-sm'></span>}
						<span className={`${styles.event_side_register_button_title}`}>{"Login now to participate!"}</span>
					</Button>
				</div>
			);
		} 
		/**
		 * If the event is a general event, show a button to register
		 */
		if(event.is_registered){
			return <p className={`${styles.event_side_register_completed_text}`} style={{ textAlign: "center" }} >
				{"Registration completed!"}
			</p>;
		}

		return (
			<div className="" style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", overflow: "hidden"}}>
				<Button className='btn-gradient w-100' style={{ padding: 14, paddingLeft: 32, paddingRight: 32 }} onClick={() => setIsRegisterModalOpen(true)} disabled={isRegisterLoading}>
					{isRegisterLoading && <span className='spinner-border spinner-border-sm'></span>}
					<span className={`${styles.event_side_register_button_title}`}>{event.event_regfee_product ? "Register Now (Payment)" : "Register Now"}</span>
				</Button>
			</div>
		);
	};


	const AttendanceButton = () => {
		if(!event.is_registered){
			return null;
		}

		if(event.attendance_confirmation !== null || event.attendance_confirmation !== undefined){
			return <div className="fs14 text-center">
				{
					event.event_completed ? 
						"Thank you for attending!"
						:  event.has_event_ended  
							? "This Event has ended" 
							: event.attendance_confirmation 	
								? "You're coming to this event. See you there! 👋"
								:  event.user_responded_confirmation
									? "You're not coming to this event 😢"
									: "Are you coming to this event ?"
				}
				{
					event.event_completed  || event.has_event_ended 
						? null
						: <p style={{textAlign:"center",cursor:"pointer"}}>
							<i className="fa fa-edit" onClick={()=>setIsConfirmationModalOpen(true)}></i>
						</p>
				}
			</div>;
		}
	};


	return (
		<div className={`fadeInDiv ${styles.event_main_container}`}>
			{isButtonLoading && (
				<div className='justify-content-center mt-4 w-100 flex'>
					<span className='spinner-border spinner-border-sm'></span>
				</div>
			)}
			{!isButtonLoading && isError && (
				<div onClick={() => getEventDetails()}>
					<Card className='mt-4'>
						<CardBody className='flex justify-content-center'>
							<div className='btn btn-gradient'>
								<span>{"Reload"}</span>
							</div>
						</CardBody>
					</Card>
				</div>
			)}
			{!isButtonLoading && !isError && event && (
				<>
					{width < 768 && 
						<Row>
							<Col md='12' xl='12' className='mt-1'>
								<div className='flex flex-direction-row justify-content-between'>
									<div className={`${styles.event_details_top_back_button}`}>
										<Link
											to='/events'
											className='flex justify-content-center align-items-center'>
											<i className={`fa fa-chevron-left ${styles.event_details_top_back_button_icon}`} aria-hidden></i>
										</Link>
									</div>
									<div className="flex flex-direction-row">
										{/* <button className={`${styles.event_details_top_share_button}`} onClick={() => handleShare(event)}>
											<i className={`fa fa-share ${styles.event_details_top_share_button_icon}`} aria-hidden></i>
										</button> */}
										<button className={`ml-3 ${styles.event_details_top_share_button}`} onClick={()=>handleAddToCalendar(event)}>
											<i className={`fa fa-calendar-plus ${styles.event_details_top_share_button_icon}`} aria-hidden></i>
										</button>
									</div>
								</div>
							</Col>
						</Row>
					}
					
					<Row className='pt-2'>
						<Col xl={9} lg={8} md={8} sm={12} style={{ paddingTop: "20px" }}>
							<Card className='mb-4'>
								<CardHeader className='border-bottom-0 pt-4'>
									{event && event.categories && event.categories.length > 0 &&
										<div className={`${styles.event_details_category_container}`}>
											{event && event.categories && event.categories.map((category, i) => {
												return (
													<span key={i} className={`${styles.event_details_category_text}`} style={{color: category.category_color}}>
														{category.category_title}
													</span>
												);
											})}
										</div>
									}
									<p className={`${styles.event_details_title_text} mb-2`}>{event.event_title}</p>
									<Row className='align-items-center mt-2'>
										<Col xl='8' lg="8" md='8' sm="12" className="d-flex flex-row align-items-center">
											<p className={`${styles.event_details_author_text} m-0 mr-2`}>{"by"}</p>
											{event.author.author_image_url && (
												<img alt='{event.author}' src={event.author.author_image_url} className={`${styles.event_details_author_image} mr-1`}/>
											)}
											{!event.author.author_image_url && event.author.author_name && (
												<div className={`${styles.event_details_author_initial_div}`}>
													<b className={`${styles.event_details_author_initial_text}`}>
														{event.author.author_name[0]}
													</b>
												</div>
											)}
											<p className={`m-0 ${styles.event_details_author_name}`}>{event.author.author_name}</p>
										</Col>
										{width >= 768 && 
											<Col xl='4' lg="4" md='4' sm="12" className="d-flex flex-row align-items-center justify-content-end">
												<div className="" style={{display: "flex", flexDirection: "row"}}>
													{/* <button className={`${styles.event_details_share_button}`} onClick={() => handleShare(event)}>
														<i className={`fa fa-share mr-2 ${styles.event_details_share_button_icon}`} aria-hidden></i>
														<span className={`${styles.event_details_share_button_title}`}>{"Share"}</span>
													</button> */}
													<button className={`ml-3 ${styles.event_details_share_button}`} onClick={()=>handleAddToCalendar(event)}>
														<i className={`fa fa-calendar-plus mr-1 ${styles.event_details_share_button_icon}`} aria-hidden></i>
														<span className={`${styles.event_side_register_addtocalendar_title}`}>{"Add to Calendar"}</span>
													</button>
												</div>
											</Col>
										}
									</Row>

								</CardHeader>
								<CardBody className='p-0 pb-3 pr-3 pl-3'>
									<img
										className={`${styles.event_details_image}`}
										alt={event.event_title}
										src={event.event_image_url ? event.event_image_url : require("assets/img/FALCOMM_LOGO.png")}
										onError={({ currentTarget }) => {
											currentTarget.onerror = null; // prevents looping
											currentTarget.src= require("assets/img/FALCOMM_LOGO.png");
										}}
										style={{ maxHeight: 980, objectFit: "contain" }}
									/>
									{event.event_description &&
										<p className={`${styles.event_details_description_text} mt-4`}>{event.event_description}</p>
									}
									<hr className="" />
									<div className={`${styles.event_details_content}`}>
										{event.event_content &&
											<div className="inner-html" dangerouslySetInnerHTML={{ __html: event.event_content }}></div>
										}
									</div>
									{width >= 768 && 
										<Row>
											<Col md='12' xl='12' className='mt-1'>
												<div className='flex flex-direction-sm-col justify-content-between'>
													<div>
														<Link
															to='/events'
															className='article-comment-submit-btn article-comment-btn-default ml-auto'>
															{"Back"}
														</Link>
													</div>
												</div>
											</Col>
										</Row>
									}
								</CardBody>
							</Card>
							{event.others_event.length > 0 &&
								<Card className='mb-4'>
									<CardBody className='p-3 pb-3 pr-3 pl-3'>
										<OtherEvents events={event.others_event} />
									</CardBody>
								</Card>
							}
						</Col>
						{width >= 768 && 
							<Col xl={3} lg={4} md={4} sm={12}>
								<div className={`${styles.sticky_right}`}>
									<Card className={`${styles.event_side_register_container}`}>
										<p className={`m-0 ${styles.event_side_register_title}`}>{"Event Details"}</p>
										<hr className={"mt-2 mb-3"} />
										<Row className="ml-0 mr-0 mb-2 d-flex align-items-center" style={{ flexWrap: "nowrap" }}>
											<div  style={{width: "24px"}}>
												<i className={`fa fa-calendar ${styles.event_side_register_details_icon}`} aria-hidden></i>
											</div>
											<span className={`${styles.event_side_register_details_text}`}>
												{event.event_from &&
													`${moment(event.event_from).format("DD MMM")}`
												}
												{event.event_to && event.event_from &&
													" - "
												}
												{event.event_to &&
													`${moment(event.event_to).format("DD MMM")}`
												}
											</span>
										</Row>
										<Row className="ml-0 mr-0 mb-2 d-flex align-items-center" style={{ flexWrap: "nowrap" }}>
											<div  style={{width: "24px"}}>
												<i className={`fa fa-clock mr-2 ${styles.event_side_register_details_icon}`} aria-hidden></i>
											</div>
											{(event.event_time_from || event.event_time_to) &&
												<span className={`${styles.event_side_register_details_text}`}>
													{event.event_time_from &&
														`${moment(event.event_time_from, "HH:mm").format("h:mmA")}`
													}
													{event.event_time_to && event.event_time_from &&
														" - "
													}
													{event.event_time_to &&
														`${moment(event.event_time_to, "HH:mm").format("h:mmA")}`
													}
												</span>
											}
											{!event.event_time_from && !event.event_time_to &&
												<span className={`${styles.event_side_register_details_text}`}>
													{"-"}
												</span>
											}
										</Row>
										<Row className="ml-0 mr-0 mb-2 d-flex align-items-center" style={{ flexWrap: "nowrap" }}>
											<div  style={{width: "24px"}}>
												<i className={`fa-solid fa-location-dot mr-2 ${styles.event_side_register_details_icon}`} aria-hidden></i>
											</div>
											<span className={`${styles.event_side_register_details_text}`}>Location: {event.event_location ? event.event_location : "-"}</span>
										</Row>
										{
											event.event_gathering_point ? <Row className="ml-0 mr-0 d-flex align-items-center" style={{ flexWrap: "nowrap" }}>
												<div  style={{width: "24px"}}>
													<i className={`fa fa-arrows-to-dot mr-2 ${styles.event_side_register_details_icon}`} aria-hidden></i>
												</div>
												<span className={`${styles.event_side_register_details_text}`}>Gathering Location: {event.event_gathering_point ? event.event_gathering_point : "-"}</span>
											</Row>
												:null
										}
										
										<hr className={"mt-3 mb-3"} />
										{event.event_regfee_product &&
											<div className={`${styles.event_side_register_fee_container}`}>
												<span className={`${styles.event_side_register_fee_title}`}>
													{`A registration fee of $${event.event_regfee ? event.event_regfee.toFixed(2) : "0"} is required to register for this event and you will be redirected to a payment page.`}
												</span>
											</div>
										}
										<div className="mb-3" style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
											<button className={`${styles.event_side_register_addtocalendar_button}`} onClick={()=>setIsTNCModalOpen(true)}>
												<span className={`${styles.event_side_register_addtocalendar_title}`}>{"Terms & Conditions"}</span>
											</button>
										</div>
										{
													 event.is_registered && event.user_responded_confirmation 
												?  <AttendanceButton/>
												: <RegisterButton />
										}
									</Card>
								</div>
							</Col>
						}
						{width < 768 &&
							<div className={`${styles.event_details_mobile_main_container} ${ isEventDetailsOpen ? styles.event_details_mobile_main_container_active : null }`}>
								<div className={`${styles.event_details_mobile_container}`} style={ isEventDetailsOpen ? {} : { paddingTop: "1rem" } }>
									{isEventDetailsOpen &&
										<>
											<div className={`${styles.event_details_mobile_details_container}`}>
												<button className={`${styles.event_details_top_share_button}`} style={{ height: "2.5rem", width: "2.5rem" }} onClick={() => setIsEventDetailsOpen(false)}>
													<i className={`fa fa-chevron-left ${styles.event_details_top_back_button_icon}`} aria-hidden></i>
												</button>
												<div className={`${styles.event_details_mobile_title_container}`}>
													<p className={`m-0 ${styles.event_side_register_title}`} style={{ justifySelf: "center" }}>{"Event Details"}</p>
												</div>
											</div>
											<hr className={"mt-2 mb-3 w-100"} style={{ borderColor: "#BBBBBB" }} />
											<Row className="ml-0 mr-0 mb-2 d-flex align-items-center" style={{ flexWrap: "nowrap" }}>
												<i className={`fa fa-calendar mr-2 ${styles.event_side_register_details_icon}`} aria-hidden></i>
												<span className={`${styles.event_side_register_details_text}`}>
													{event.event_from &&
														`${moment(event.event_from).format("DD MMM")}`
													}
													{event.event_to && event.event_from &&
														" - "
													}
													{event.event_to &&
														`${moment(event.event_to).format("DD MMM")}`
													}
												</span>
											</Row>
											<Row className="ml-0 mr-0 mb-2 d-flex align-items-center" style={{ flexWrap: "nowrap" }}>
												<i className={`fa fa-clock mr-2 ${styles.event_side_register_details_icon}`} aria-hidden></i>
												{(event.event_time_from || event.event_time_to) &&
													<span className={`${styles.event_side_register_details_text}`}>
														{event.event_time_from &&
															`${moment(event.event_time_from, "HH:mm").format("h:mmA")}`
														}
														{event.event_time_to && event.event_time_from &&
															" - "
														}
														{event.event_time_to &&
															`${moment(event.event_time_to, "HH:mm").format("h:mmA")}`
														}
													</span>
												}
												{!event.event_time_from && !event.event_time_to &&
													<span className={`${styles.event_side_register_details_text}`}>
														{"-"}
													</span>
												}
											</Row>
											<Row className="ml-0 mr-0 mb-2 d-flex align-items-center" style={{ flexWrap: "nowrap" }}>
												<i className={`fa-solid fa-location-dot mr-2 ${styles.event_side_register_details_icon}`} aria-hidden></i>
												<span className={`${styles.event_side_register_details_text}`}>Location: {event.event_location ? event.event_location : "-"}</span>
											</Row>
											{
												event.event_gathering_point 
													? <Row className="ml-0 mr-0 d-flex align-items-center" style={{ flexWrap: "nowrap" }}>
														<i className={`fa fa-arrows-to-dot mr-2 ${styles.event_side_register_details_icon}`} aria-hidden></i>
														<span className={`${styles.event_side_register_details_text}`}>Gathering Location: {event.event_gathering_point ? event.event_gathering_point : "-"}</span>
													</Row>
													:null
											}
											
											<hr className={"mt-3 mb-3 w-100"} style={{ borderColor: "#BBBBBB" }} />
											{event.event_regfee_product &&
												<div className={`${styles.event_side_register_fee_container}`}>
													<span className={`${styles.event_side_register_fee_title}`}>
														{`A registration fee of $${event.event_regfee ? event.event_regfee.toFixed(2) : "0"} is required to register for this event and you will be redirected to a payment page.`}
													</span>
												</div>
											}
											<div className="mb-3" style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
												<button className={`${styles.event_side_register_addtocalendar_button}`} onClick={()=>setIsTNCModalOpen(true)}>
													<span className={`${styles.event_side_register_addtocalendar_title}`}>{"Terms & Conditions"}</span>
												</button>
											</div>
										</>
									}
									{!isEventDetailsOpen &&
										<div className={`${styles.event_details_mobile_button_container}`}>
											<div style={{ flex: 4, display: "flex", alignItems: "center" ,flexShrink:0}}>
												<button className={`${styles.event_side_register_addtocalendar_button}`} onClick={()=>setIsEventDetailsOpen(true)}>
													<span className={`${styles.event_side_register_addtocalendar_title}`}>{"Show Event Details"}</span>
												</button>
											</div>
											<div style={{
												flexBasis: "50%",
												flexGrow:1,
											}}>
												{
													 event.is_registered && event.user_responded_confirmation 
														?  <AttendanceButton/>
														: <RegisterButton />
												}
											</div>
										</div>
									}
								</div>
							</div>
							
						}
						<ModalEventRegister isLoading={isRegisterLoading} event={event} onSuccess={(ticketValue) => registerEvent(event.event_id.toString(), ticketValue)} isOpen={isRegisterModalOpen} onClose={() => setIsRegisterModalOpen(false)} />
						<ModalEventTNC event={event} isOpen={isTNCModalOpen} onClose={() => setIsTNCModalOpen(false)} />
						<ModalAttendanceConfirmation 
							event={event} isOpen={isConfirmationModalOpen} 
							onClose={() => {
								setIsConfirmationModalOpen(false);
								getEventDetails();
							}} 
						/>
					</Row>
				</>
				
			)}
		</div>
		
	);
}

export default EventDetails;
