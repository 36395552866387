import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button, Modal } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import generalApi from "services/general-api";
import SignatureCanvas from "react-signature-canvas";
import { saveRegisterForm } from "reducers/courseRegisterSlice";
import "../../../../assets/scss/custom/_inner-html.scss";

function ModalEndorse() {
	const [loading, setLoading] = useState(false);
	const [modalChange, setModalChange] = useState(true);
	const [tncContent, setTncContent] = useState("");
	const [message, setMessage] = useState("");
	const dispatch = useDispatch();

	const courseRegisterData = useSelector((state) => state.courseRegisterSlice);

	const registerFormData = courseRegisterData.registerForm;
	let navigate = useNavigate();

	useEffect(() => {
		if (
			Object.keys(courseRegisterData.course).length === 0 ||
			Object.keys(courseRegisterData.courserun).length === 0
		) {
			navigate("/course");
		}

		generalApi.getTermsAndConditions(registerFormData.sponsoredCompany).then(
			(response) => {
				let newDescHtml = response.data;
				newDescHtml = newDescHtml.split("<oembed").join("<iframe");
				newDescHtml = newDescHtml.split("</oembed>").join("</iframe>");
				newDescHtml = newDescHtml.split("url=").join("src=");
				setTncContent(newDescHtml);
			},
			(error) => {
				navigate("/course");
			},
		);
	}, []);

	const canvasRef = useRef(null);

	const handleConfirmCourse = () => {
		if (canvasRef.current.isEmpty()) {
			setMessage("Please Sign to Endorse");
		} else {
			const dataUrl = canvasRef.current.toDataURL("image/png");
			dispatch(
				saveRegisterForm({
					signature: dataUrl,
				}),
			);
			navigate("/course/confirm");
		}
	};

	return (
		<>
			<Modal className='modal-dialog-centered registration_modal' isOpen={modalChange}>
				<div className='modal-body'>
					{tncContent && (
						<div className='inner-html' dangerouslySetInnerHTML={{ __html: tncContent }} />
					)}
					<div className='pt-3'>
						<div className='pb-2'>
							<b>Your Signature</b>
						</div>
						<SignatureCanvas
							ref={canvasRef}
							clearOnResize={false}
							canvasProps={{ width: 500, height: 200, className: "sigCanvas border" }}
						/>
					</div>
					{message && (
						<div className='form-group'>
							<div className='alert alert-danger' role='alert'>
								{message}
							</div>
						</div>
					)}
				</div>

				<div className='modal-footer justify-content-between'>
					<Link to='/course' className='btn btn-default'>
						Close
					</Link>
					<Link to={"/course/payment"} className='btn btn-primary mr-auto'>
						Back
					</Link>
					<Button color='success' type='button' onClick={handleConfirmCourse}>
						Proceed To Endorse
					</Button>
				</div>
			</Modal>
		</>
	);
}

export default ModalEndorse;
