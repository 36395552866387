import React from "react";
import {
	Navigate,
	createBrowserRouter,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import App from "layouts/App.js";
import HomeApp from "layouts/HomeApp.js";
import AuthLayout from "layouts/AuthLayout.js";
import NewsLayout from "layouts/News.js";
import FCAHomeDashboard from "views/pages/home/Dashboard.js";
import FCAHomeBundle from "views/pages/home/BundleInfo/BundleInfo";
import FCADashboard from "views/pages/learner/Dashboard.js";

import LoginOld from "views/pages/auth/Login-Old.js";
import Login from "views/pages/auth/Login/Login.js";
import Register from "views/pages/auth/Register.js";
import ResetPassword from "views/pages/auth/ResetPassword.js";
import ResetPasswordOtpValidation from "views/pages/auth/ResetPasswordOtpValidation.js";
import HyperAdminUserSelection from "views/pages/auth/HyperAdminUserSelection.js";
import OTPModal from "components/Modals/OtpModal.js";
import HyperAdminOtpModal from "components/Modals/HyperAdminOtpModal.js";
import DailyNewsDetails from "views/pages/learner/news/DailyNewsDetails";
import EventDetails from "views/pages/learner/event/EventDetails";
import TeasersDetails from "views/pages/learner/news/TeasersDetails";
import DailyNews from "views/pages/learner/news/DailyNews.js";
import NewsHomeScreen from "views/pages/learner/news/NewsHomeScreen.js";
import Teaser from "views/pages/learner/news/Teaser.js";
import Notifications from "views/pages/learner/news/Notifications.js";
import Profile from "views/pages/learner/profile/Profile.js";
import ProfileEdit from "views/pages/learner/profile/ProfileEdit.js";
import ProfileChangePassword from "views/pages/learner/profile/ProfileChangePassword.js";
import ProfileChangeEmail from "views/pages/learner/profile/ProfileChangeEmail.js";
import ProfileDeleteAccount from "views/pages/learner/profile/ProfileDeleteAccount.js";
import ProfileChangePasswordOtpValidation from "views/pages/learner/profile/ProfileChangePasswordOtpValidation.js";
import ProfileChangeEmailOtpValidation from "views/pages/learner/profile/ProfileChangeEmailOtpValidation.js";
import ProfileDeleteAccountOtpValidation from "views/pages/learner/profile/ProfileDeleteAccountOtpValidation.js";
import ModalPayment from "views/pages/learner/course/ModalPayment";
import ModalEndorse from "views/pages/learner/course/ModalEndorse";
import ModalConfirm from "views/pages/learner/course/ModalConfirm";
import ModalSuccess from "views/pages/learner/course/ModalSuccess";
import ModalCourseInfo from "views/pages/learner/course/ModalCourseInfo";
import ModalRegistration from "views/pages/learner/course/ModalRegistration";
import ModalUpcomingCourseInfo from "views/pages/learner/course/ModalUpcomingCourseInfo";
import ModalShuttleBus from "views/pages/learner/course/ModalShuttleBus";
import ModalDownloadResources from "views/pages/learner/course/ModalDownloadResources";
import ModalResourceDetails from "views/pages/learner/course/ModalResourceDetails";
import ModalReschedule from "views/pages/learner/course/ModalReschedule";
import ModalRescheduleDesc from "views/pages/learner/course/ModalRescheduleDesc";
import SuccessPage from "views/pages/learner/course/SuccessPage";
import MealSuccessPage from "views/pages/learner/course/MealSuccessPage";
import ShuttleBusSuccessPage from "views/pages/learner/course/ShuttleBusSuccessPage";
import ModalWithdrawal from "views/pages/learner/course/ModalWithdrawal";
import ModalWithdrawalDesc from "views/pages/learner/course/ModalWithdrawalDesc";
import AppWithProfile from "layouts/AppWithProfile";
import ErrorFallback from "views/pages/error/ErrorFallback";
import ModalCertificates from "views/pages/learner/course/ModalCertificates";
import ModalMealSelection from "views/pages/learner/course/ModalMealSelection";
import ContactUs from "views/pages/learner/course/ContactUs";
import ContactUs2 from "views/pages/learner/course/ContactUs2";
import ModalBundleInfo from "views/pages/learner/course/ModalBundleInfo";
import ModalBundleList from "views/pages/learner/course/ModalBundleList";
// Notification
import NotificationMain from "views/pages/learner/notification/NotificationMain";
import NotificationDetails from "views/pages/learner/notification/NotificationDetails";
import ArticleReply from "views/pages/learner/notification/ArticleReply";
// FAQ
import FaqMain from "views/pages/learner/faq/FaqMain";
import { CheckoutPage } from "views/pages/checkout/CheckoutPage";
import { CheckoutRegisterPage } from "views/pages/checkout/CheckoutRegisterPage";
import { CheckoutCourseSummaryPage } from "views/pages/checkout/CheckoutCourseSummaryPage";
import { CheckoutSuccessPage } from "views/pages/checkout/CheckoutSuccessPage";
import { useSelector } from "react-redux";
// Referral
import ReferralDashboard from "views/pages/learner/referral/ReferralDashboard";
//avatar
import AvatarScreen from "views/pages/learner/avatar/AvatarScreen";

import OmisePage from "views/pages/learner/Omise";
import Omiseloading from "views/pages/learner/Omiseloading";
import Omisepaynow from "views/pages/learner/Omisepaynow";
import Omisesuccess from "views/pages/learner/Omisesuccess";
import MaintenancePage from "views/pages/MaintenancePage";
import EventHome from "views/pages/learner/event/EventHome";
import { EventCheckout } from "views/pages/learner/event/checkout/EventCheckout";
import { EventCheckoutSuccessPage } from "views/pages/learner/event/checkout/EventCheckoutSuccessPage";
import { EventCheckoutSummaryPage } from "views/pages/learner/event/checkout/EventCheckoutSummaryPage";
import { EventOmiseloading } from "views/pages/learner/event/checkout/EventOmiseloading";
import EventOmisePaynow from "views/pages/learner/event/checkout/EventOmisePaynow";
import { EventAppCheckoutPage } from "views/pages/learner/event/checkout/EventAppCheckoutPage";
import ModalAssessmentDownload from "views/pages/learner/assessment/ModalAssessmentDownload";
import ModalAssessmentUpload from "views/pages/learner/assessment/ModalAssessmentUpload";
import VideoHome from "views/pages/learner/video/VideoHome";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "production") {
	Sentry.init({
		environment: process.env.REACT_APP_ENV,
		dsn: "https://89a455bc63784c09843589c1650963fe@o4503969180024832.ingest.sentry.io/4505079456989184",
		// integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
		integrations: [
			new Sentry.BrowserTracing({
				routingInstrumentation: Sentry.reactRouterV6Instrumentation(
					React.useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes,
				),
			}),
			new Sentry.Replay(),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

function PrivateRoute({ children }) {
	const { user: currentUser } = useSelector((state) => state.auth);
	return currentUser ? children : <Navigate to="/login" />;
}
  

const route = [
	// new landing page home
	{
		path: "/",
		element: (
			<Sentry.ErrorBoundary fallback={<ErrorFallback />}>
				<HomeApp />
			</Sentry.ErrorBoundary>
		),
		children: [
			{
				path: "/",
				element: <Navigate to='course' replace />,
			},
			// {
			// 	path: "/home",
			// 	element: <FCAHomeDashboard />,
			// 	children: [],
			// },
			{
				path: "/bundle/:id",
				element: <FCAHomeBundle />,
				children: [],
			},
			{
				path: "/course",
				element: (<PrivateRoute><FCADashboard /></PrivateRoute>),
				children: [
					// {
					// 	path: "/course/certificates",
					// 	element: <ModalCertificates />,
					// },
				],
			},
			{
				path: "/course/course-details",
				element: (<PrivateRoute><ModalUpcomingCourseInfo /></PrivateRoute>),
			},
			{
				path: "/course/upcoming-course-info",
				element: (<PrivateRoute><ModalUpcomingCourseInfo /></PrivateRoute>),
			},
			{
				path: "/course/reschedule",
				element: (<PrivateRoute><ModalReschedule /></PrivateRoute>),
			},
			{
				path: "/course/reschedule_description",
				element: (<PrivateRoute><ModalRescheduleDesc /></PrivateRoute>),
			},
			{
				path: "/course/withdrawal",
				element: (<PrivateRoute><ModalWithdrawal /></PrivateRoute>),
			},
			{
				path: "/course/withdrawal_description",
				element: (<PrivateRoute><ModalWithdrawalDesc /></PrivateRoute>),
			},
			{
				path: "/course/course-info/:id",
				element: (<PrivateRoute><ModalCourseInfo /></PrivateRoute>),
			},
			{
				path: "/course/shuttle-bus",
				element: (<PrivateRoute><ModalShuttleBus /></PrivateRoute>),
			},
			{
				path: "/course/bundle/:id",
				element: (<PrivateRoute><ModalBundleInfo /></PrivateRoute>),
			},
			{
				path: "/course/bundle-list/:id",
				element: (<PrivateRoute><ModalBundleList /></PrivateRoute>),
			},
			{
				path: "/course/download-resources",
				element: (<PrivateRoute><ModalDownloadResources /></PrivateRoute>),
			},
			{
				path: "/course/resource-details/:id",
				element: (<PrivateRoute><ModalResourceDetails /></PrivateRoute>),
			},
			{
				path: "/course/register",
				element: (<PrivateRoute><ModalRegistration /></PrivateRoute>),
			},
			{
				path: "/course/payment",
				element: (<PrivateRoute><ModalPayment /></PrivateRoute>),
			},
			{
				path: "/course/endorse",
				element: (<PrivateRoute><ModalEndorse /></PrivateRoute>),
			},
			{
				path: "/course/confirm",
				element: (<PrivateRoute><ModalConfirm /></PrivateRoute>),
			},
			{
				path: "/course/success",
				element: (<PrivateRoute><ModalSuccess /></PrivateRoute>),
			},
			{
				path: "/contact-us",
				element: (<PrivateRoute><ContactUs /></PrivateRoute>),
			},
			{
				path: "/enquiry",
				element: (<PrivateRoute><ContactUs2 /></PrivateRoute>),
			},
			{
				path: "/course/meal-selection",
				element: (<PrivateRoute><ModalMealSelection /></PrivateRoute>),
			},
			{
				path: "/course/success-page",
				element: (<PrivateRoute><SuccessPage /></PrivateRoute>),
			},
			// events
			// {
			// 	path: "events",
			// 	element:  <NewsLayout />,
			// 	children: [
			// 		{
			// 			path: "",
			// 			element: (<NewsHomeScreen />),
			// 		},
			// 		// {
			// 		// 	path: "daily",
			// 		// 	element: <DailyNews />,
			// 		// },
			// 		// {
			// 		// 	path: "/news/article/:id",
			// 		// 	element: <DailyNewsDetails />,
			// 		// },
			// 		// {
			// 		// 	path: "/news/event/:id",
			// 		// 	element: <EventDetails />,
			// 		// },
			// 		// {
			// 		// 	path: "teaser",
			// 		// 	element: <Teaser />,
			// 		// },
			// 		// {
			// 		// 	path: "/news/teaser/:id",
			// 		// 	element: <TeasersDetails />,
			// 		// },
			// 	],
			// },
			{
				path: "/events",
				element: <EventHome />,
			},
			// {
			// 	path: "/news/daily",
			// 	element: <DailyNews />,
			// },
			// {
			// 	path: "/news/article/:id",
			// 	element: <DailyNewsDetails />,
			// },
			{
				path: "/events/:id",
				element: <EventDetails />,
			},
			{
				path: "upskilling100",
				element:  <VideoHome/>
			},

			// {
			// 	path: "/news/teaser",
			// 	element: <Teaser />,
			// },
			// {
			// 	path: "/news/teaser/:id",
			// 	element: <TeasersDetails />,
			// },
			// notification
			{
				path: "notification",
				element: (<PrivateRoute><NotificationMain /></PrivateRoute>),
			},
			{
				path: "/notification/:id",
				element: (<PrivateRoute><NotificationDetails /></PrivateRoute>),
			},
			{
				path: "/notification/article/:id",
				element: (<PrivateRoute><ArticleReply /></PrivateRoute>),
			},
			// FAQ
			{
				path: "faq",
				element: (<PrivateRoute><FaqMain /></PrivateRoute>),
			},
			//Profile
			{
				path: "profile",
				element: (<PrivateRoute><Profile /></PrivateRoute>),
			},
			{
				path: "profile/edit",
				element: (<PrivateRoute><ProfileEdit /></PrivateRoute>),
			},
			{
				path: "profile/change-password",
				element: (<PrivateRoute><ProfileChangePassword /></PrivateRoute>),
			},
			{
				path: "profile/change-password/otp-validation",
				element: (<PrivateRoute><ProfileChangePasswordOtpValidation /></PrivateRoute>),
			},
			{
				path: "profile/change-email",
				element: (<PrivateRoute><ProfileChangeEmail /></PrivateRoute>),
			},
			{
				path: "profile/change-email/otp-validation",
				element: (<PrivateRoute><ProfileChangeEmailOtpValidation /></PrivateRoute>),
			},
			{
				path: "profile/delete-account",
				element: <ProfileDeleteAccount />,
			},
			{
				path: "profile/delete-account/otp-validation",
				element: <ProfileDeleteAccountOtpValidation />,
			},
			{
				path: "/course/meal-selection",
				element: <ModalMealSelection />,
			},
			{
				path: "/course/success-page",
				element: <SuccessPage />,
			},
			// alumni referral
			{
				path: "referral",
				element: (<PrivateRoute><ReferralDashboard /></PrivateRoute>),
			},
			{
				path: "/assessment/download",
				element:<ModalAssessmentDownload />,
			},
			{
				path: "/assessment/upload",
				element: <ModalAssessmentUpload />,
			// avatar
			},
			{
				path: "avatar",
				element: (<PrivateRoute><AvatarScreen /></PrivateRoute>),
			},
		],
	},
	// error page
	{
		path: "/maintenance",
		element: <MaintenancePage />,
	},
	// checkout
	{
		path: "/checkout",
		element: (
			<Sentry.ErrorBoundary fallback={<ErrorFallback />}>
				<CheckoutPage />
			</Sentry.ErrorBoundary>
		),
		children: [
			{
				path: "",
				element: <Navigate to='account' />,
			},
			{
				path: "account",
				element: <CheckoutRegisterPage />,
			},
			{
				path: "course",
				element: <CheckoutCourseSummaryPage />,
			},
			// {
			// 	path: "omise/",
			// 	element: <OmisePage />,
			// },
			{
				path: "omise/paynow",
				element: <Omisepaynow />,
			},
			{
				path: "omise/loading",
				element: <Omiseloading />,
			},
			{
				// path: "success",
				// element: <Omisesuccess />,
				path: "success",
				element: <CheckoutSuccessPage />,
			},
		],
	},
	// auth
	{
		path: "/event/appCheckout",
		element: <EventAppCheckoutPage/>
	},
	{
		path: "/event/checkout",
		element: (
			<Sentry.ErrorBoundary fallback={<ErrorFallback />}>
				<EventCheckout />
			</Sentry.ErrorBoundary>
		),
		children: [
			{
				path: "",
				element: <EventCheckoutSummaryPage />,
			},
			{
				path: "omise/paynow",
				element: <EventOmisePaynow />,
			},
			{
				path: "omise/loading",
				element: <EventOmiseloading />,
			},
			{
				path: "success",
				element: <EventCheckoutSuccessPage />,
			},
		],
	},
	{
		path: "/event/checkout/omise",
		children: [
			{
				path: "paynow",
				element: <Omisepaynow />,
			},
			{
				path: "loading",
				element: <EventOmiseloading />,
			},
		],
	},
	{
		path: "/login",
		element: (
			<Sentry.ErrorBoundary fallback={<ErrorFallback />}>
				<AuthLayout />
			</Sentry.ErrorBoundary>
		),
		children: [
			{
				path: "/login",
				element: <Login />,
				children: [
					{ path: "otp-validation", element: <OTPModal /> },
					{ path: "hyperadmin-otp-validation", element: <HyperAdminOtpModal /> },
				],
			},
			// {
			// 	path: "/login",
			// 	element: <LoginOld />,
			// 	children: [
			// 		{ path: "otp-validation", element: <OTPModal /> },
			// 		{ path: "hyperadmin-otp-validation", element: <HyperAdminOtpModal /> },
			// 	],
			// },
			{
				path: "hyperadmin-user-selection",
				element: <HyperAdminUserSelection />,
			},
			{
				path: "reset-password",
				element: <ResetPassword />,
			},
			{
				path: "reset-password/otp-validation",
				element: <ResetPasswordOtpValidation />,
			},
			// {
			// 	path: "first-time-reset-password",
			// 	element: <ModalFirstTimeResetPassword />,
			// },
		],
	},
	{
		path: "/register",
		element: (
			<Sentry.ErrorBoundary fallback={<ErrorFallback />}>
				<AuthLayout mobileAlwaysShown/>
			</Sentry.ErrorBoundary>
		),
		children: [
			{
				path: "/register",
				element: <Register />,
			},
		],
	},
	// others
	{
		path: "/course/meal-success-page",
		element: (<PrivateRoute><MealSuccessPage /></PrivateRoute>),
	},
	{
		path: "/course/shuttle-bus-success-page",
		element: (<PrivateRoute><ShuttleBusSuccessPage /></PrivateRoute>),
	},
	{
		path: "*",
		element: <ErrorFallback />,
	},

	//{
	//    path: '/',
	//    element: <RootLayout />,
	//    children: [
	//      {
	//        path: '/',
	//        element: <Posts />,
	//        children: [{ path: '/create-post', element: <NewPost /> }],
	//      },
	//    ],
	//  },
];

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(route);

export default router;
