/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components

import { Link, useNavigate } from "react-router-dom";
import {
	Button,
	Card,
	CardHeader,
	CardTitle,
	CardText,
	CardImg,
	CardBody,
	Modal,
	Row,
	Col,
	UncontrolledTooltip,
	CardFooter,
} from "reactstrap";
import moment from "moment";
import LikeButton from "./News/LikeButton";
import CommentButton from "./News/CommentButton";
import ShareButton from "./News/ShareButton";

const WEB_URL = process.env.REACT_APP_WEB_URL;

function NewsCard(props) {
	let navigate = useNavigate();

	return (
		<Card className='h-100 w-100 mb-4 fadeInDiv'>
			<Link to={"/news/article/" + props.article.article_id} state={{ article: props.article }}>
				<CardHeader className='border-bottom-0'>
					<Row className='align-items-center'>
						{props.article.author.author_image_url && (
							<Col className='col-auto pr-0'>
								<img alt='{props.article.author}' src={props.article.author.author_image_url} className="article-author-image"/>
							</Col>
						)}
						{!props.article.author.author_image_url &&
								props.article.author.author_name && (
							<Col className='col-auto pr-0'>
								<div className="article-author-initial-div">
									<b className="article-author-initial-text">
										{props.article.author.author_name[0]}
									</b>
								</div>
							</Col>
						)}
						<div className='col'>
							<p className='m-0 article-author-name'>{props.article.author.author_name}</p>
							<p className='article-author-date'>
								{`${moment(
									props.article.article_published,
								).format("MMMM DD YYYY, hh:ssA")}`}
							</p>
						</div>
					</Row>
					<p className='article-title mt-3'>{props.article.article_title}</p>
				</CardHeader>
				<CardBody className='p-0'>
					<img
						className='article-image'
						alt='{props.title}'
						src={props.article.article_image_url ? props.article.article_image_url : require("assets/img/FALCOMM_LOGO.png")}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src= require("assets/img/FALCOMM_LOGO.png");
						}}
					/>
				</CardBody>
				<CardFooter className="p-2" style={{ borderTopWidth: 1, borderTopColor: "rgba(0, 0, 0, 0.1)" }}>
					<div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", overflow: "hidden"}}>
						<div className="like-main-button-container">
							<LikeButton
								isLike={props.article.liked === 1}
								totalLike={props.article.likes_count}
								article_id={props.article.article_id.toString()}
							/>
						</div>
						<div className="comment-main-button-container">
							<CommentButton  totalComment={props.article.comments_count} onPress={() => {
								navigate(`/news/article/${props.article.article_id}`, { state: {article: props.article}});
							}} />
						</div>
						<div className="share-main-button-container">
							<ShareButton
								article_id={props.article.article_id}
								totalShare={props.article.shares_count}
								title={props.article.article_title}
								url={`${WEB_URL}/news/article/${props.article.article_id}`}
								desc={""}
							/>
						</div>
					</div>
				</CardFooter>
			</Link>
		</Card>
	);
}

export default NewsCard;

