import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupHelper } from "utilities/yupHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Button, FormGroup, Modal, Label } from "reactstrap";
import styles from "../Event.module.scss";
import { useWindow } from "hooks/UseWindow";
import generalApi from "services/general-api";
import { toast } from "react-toastify";
import { getErrorMessage } from "utilities/error";

const schema = yup.object({
	otp: yupHelper.otp(),
});

function ModalEventRegister({ onSuccess = () => {}, isOpen = false, onClose = () => {}, event = null, isLoading=false }) {
	const { message } = useSelector((state) => state.message);
	const dispatch = useDispatch();
	const [ticketValue, setTicketValue] = React.useState("");
	const [ticketError, setTicketError] = React.useState("");
	const [ticketMaxAmount, setTicketMaxAmount] = React.useState([]);
	const { width } = useWindow();
	const navigate = useNavigate();
	const [isRegFormLoading, setIsRegFormLoading] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		if (event && event.max_ticket_amount) {
			let maxAmount = [];
			for (let index = 0; index < event.max_ticket_amount; index++) {
				maxAmount = [...maxAmount, `${index + 1}`];
			}
			setTicketMaxAmount(maxAmount);
		} else {
			setTicketMaxAmount(["1"]);
		}
	}, [event]);

	const handleAgree = () => {
		if (event && event.event_type == "Ticket" && ticketValue === "") {
			setTicketError("Please select tickets amount");
		} else {
			if(event.event_regfee_product){
				setIsRegFormLoading(true);
				return generalApi.createEventRegistrationForm({
					event_id : event.event_id,
					ticket_number : ticketValue || 1
				}).then((data) => { 
					if(data.status === "Success"){
						if(data.data.eventregform_id){
							navigate(`/event/checkout?eventregform_id=${data.data.eventregform_id}`);
						}else{
							toast.error(data.message || "Failed to create registration form");
						}
					}else{
						toast.error(data.message || "Failed to create registration form");
					}
				}).catch((error) => { 
					toast.error(getErrorMessage(error));
				}).finally(() => { 
					setIsRegFormLoading(false);
				});
			}else{
				onSuccess(ticketValue);
				onClose();
			}
		}
	};


	const totalRegfee = (()  => {
		if (event && event.event_regfee) {
			if(event.event_type == "Ticket" ){
				return (event.event_regfee * ticketValue).toFixed(2);
			}else{
				return event.event_regfee.toFixed(2);
			}
		}
		return 0;
	})();
	
	
	return (
		<>
			<Modal
				className={"modal-dialog-centered event_register_modal"}
				isOpen={isOpen}
			>
				<div className={`${styles.register_modal_close_container}`} onClick={() => isLoading ? null : onClose()} disabled={isLoading}>
					<i className={`fa fa-times ${styles.register_modal_close_icon}`} aria-hidden='true'></i>
				</div>
				<div className={`${styles.register_modal_header}`}>
					<p className={`${styles.register_modal_header_title}`}>
						{"Experience the excitement of our "}
						<span className={`${styles.register_modal_event_title}`}>{event.event_title}</span>
						{" as you "}
						<span className={`${styles.register_modal_event_title}`}>{"Register"}</span>
						{" for an unforgettable day of fun and celebration!"}
					</p>
					<p className={`${styles.register_modal_header_desc}`}>
						{"Secure your slot today for our spectacular event! Register the number of tickets now to guarantee your spot and go beyond physical fitness to promote mental well-being."}
					</p>
				</div>
				<div className='modal-body pt-0'>
					{event && event.event_type == "Ticket" &&
						<FormGroup>
							<Label className={`${styles.register_modal_ticket_title}`}>Tickets</Label>
							<select
								className={`form-control ${styles.register_modal_ticket_select}`}
								type='select'
								placeholder='Tickets Amount'
								value={ticketValue}
								disabled={isLoading ? true : false}
								onChange={e => {
									setTicketValue(e.target.value);
									setTicketError("");
								}}
							>
								<option value='' disabled>Select number of tickets</option>
								{event && event.max_ticket_amount && 
									ticketMaxAmount.map((amount) => (
										<option key={amount} value={amount}>{amount}</option>
									))
								}
							</select>
							{ticketError !== "" &&
								<div style={{ width: "100%", marginTop: "10px", color: "#fb6340" }}>{ticketError !== "" ? ticketError : null}</div>
							}
						</FormGroup>
					}
					{event && event.event_regfee_product &&
						<div className={`${styles.register_modal_regfee_container}`}>
							<div className={`${styles.register_modal_regfee_title_container}`}>
								<p className={`${styles.register_modal_regfee_title} mb-0`}>{"Registration Fees"}</p>
								<p className={`${styles.register_modal_regfee_price} mb-0`}>{`$${event.event_regfee ? event.event_regfee.toFixed(2) : "0"}`} {ticketValue? `x ${ticketValue}` : "" }</p>
							</div>
							<div className={`${styles.register_modal_regfee_desc_container}`}>
								<p className={`${styles.register_modal_regfee_desc} mb-0`}>{`A registration fee of $${totalRegfee} is required to register for this event and you will be redirected to a payment page.`}</p>
							</div>
						</div>
					}
					
					<hr className="mt-3 mb-4" style={{ borderColor: "#101820" }} />
					<div className={`${styles.register_modal_button_container} ${width < 474 ? "flex-column" : ""}`}>
						<div className="mb-3" style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", overflow: "hidden"}}>
							<Button className='btn-default w-100' style={{ padding: 12, paddingLeft: 32, paddingRight: 32 }} onClick={onClose} disabled={isLoading}>
								<span className={`${styles.register_modal_button_cancel_title}`}>{"Cancel"}</span>
							</Button>
						</div>
						
						<div className="mb-3" style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly", overflow: "hidden"}}>
							<Button 
								className='btn-gradient w-100' 
								style={{ padding: 12, paddingLeft: 32, paddingRight: 32 }} 
								onClick={handleAgree} 
								disabled={isLoading||isRegFormLoading}
							>
								{(isLoading||isRegFormLoading) && <span className='spinner-border spinner-border-sm'></span>}
								<span className={`${styles.register_modal_button_proceed_title}`}>{event.event_regfee_product ? "Proceed to Payment" : "Register Now"}</span>
							</Button>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default ModalEventRegister;
