import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Row, Card, CardBody, Col, Form, FormGroup, Button } from "reactstrap";
import generalApi from "services/general-api";
import { getErrorMessage } from "utilities/error";
import _ from "lodash";
import { saveAs } from "file-saver";
import { ReactComponent as DownloadIcon } from "assets/img/Course/upcoming/download.svg";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object({
	supportingDocument: yup.mixed().required(),
});

function ModalAssessmentUpload() {
	const location = useLocation();
	// const data = location.state?.data;
	// const learner_id = data?.learner_id;
	// const learner_sfc = Number(data?.learner_sfc);
	// const course = data?.course;
	// const status = data?.status;

	const learner_id = location.state?.learner_id;
	const course = location.state?.course;
	const status = location.state?.status;

	const [loading, setLoading] = useState(false);
	const [message, setmessage] = useState("");

	let navigate = useNavigate();

	const {
		setValue,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	useEffect(() => {
	}, []);

	const handleUpload = async (data) => {
		setLoading(true);

		const formData = new FormData();
		formData.append("learner_id", learner_id);
		if (data.supportingDocument.length < 1) {
			toast.error("Please Upload at least 1 Assessment Document.");
			setLoading(false);
		} else if (data.supportingDocument.length > 10) {
			toast.error("Maximum of documents you can upload is 10");
			setLoading(false);
		} else {
			for (let i = 0; i < data.supportingDocument.length; i++) {
				formData.append("attachment" + (i + 1), data.supportingDocument[i]);
			}
			
			generalApi
				.uploadCourseAssessment(formData)
				.then(
					(response) => {
						setLoading(false);
						navigate("/course");
						toast.success("Successfully uploaded course assessment!");
					},
					(error) => {
						setLoading(false);
						setmessage(getErrorMessage(error));
					},
				)
				.catch(() => {
					setLoading(false);
				});
		}
	};
	

	return (
		<>
			<div className=''>
				<div className='title-container'>
					<h2>{`${course && course.course ? course.course.course_title : ""}`}</h2>
					<h3 className="text-blue">Upload Assessment</h3>

				</div>
				<div>
					<Card className='mt-4' style={{ boxShadow: "none" }}>
						<CardBody>
							<Form role='form' onSubmit={handleSubmit(handleUpload)}>
								<Row>
									<Col md='12' xl='12'>
										<FormGroup>
											<label className='fs14 text-fca-dark font-weight-600' htmlFor='input-supporting-document'>
													Assessment Document
											</label>
											<input
												type='file'
												accept='image/png, image/jpeg, .pdf'
												className={
													"form-control " + (errors.supportingDocument ? "is-invalid" : "")
												}
												id='input-supportingDocument'
												{...register("supportingDocument")}
												multiple
												// required
											/>
											{/* <small>Please upload 1 - 3 files</small> */}
											<div className='invalid-feedback'>{errors.supportingDocument?.message}</div>
										</FormGroup>
									</Col>
									<Col md='12' xl='12'>
										{message && (
											<div className='form-group'>
												<div
													className='alert alert-danger'
													role='alert'
													dangerouslySetInnerHTML={{ __html: message }}></div>
											</div>
										)}
									</Col>
									<Col md='12' xl='12'>
										<div className='flex justify-content-center justify-content-between mb-4 mt-6'>
											{/* <Link
												to='/course/course-details/'
												state={{ data: course, status: status }}
												className='btn btn-default ml-auto'>
													Back
											</Link> */}
											<div></div>
											<Button className='btn-gradient' type='submit' disabled={loading}>
												{loading && <span className='spinner-border spinner-border-sm'></span>}
												<span>Submit Assessment</span>
											</Button>
										</div>
									</Col>
								</Row>
							</Form>
						</CardBody>
					</Card>
				</div>
			</div>
		</>
	);
}

export default ModalAssessmentUpload;
