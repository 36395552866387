import generalApi from "services/general-api";

import { createStore, useStore } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const eventCheckoutStore = createStore(
	persist((set) => ({
		isAppCheckout : false,
		checkoutInfo: null,
		fetchCheckoutInfo: async (eventregform_id) => {
			if (eventregform_id) {
				const checkoutInfo = await generalApi.getEventCheckoutDetail(eventregform_id);
				set({ checkoutInfo });
				return checkoutInfo;
			}
		},
		setIsAppCheckout : (isAppCheckout) => set({ isAppCheckout }),
		clearCheckoutInfo: () => set({ checkoutInfo: {} }),
	}), {
		name: "event-checkout-storage", // name of the item in the storage (must be unique)
		storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
	},),
);

export const useEventCheckout = (selector = (state) => state) =>
	useStore(eventCheckoutStore, selector);

// Action creators are generated for each case reducer function
// const initialState = {
// 	metadata: {
// 		registrationform_id: null,
// 		courserun_id: null,
// 	},
// 	isLoading: false,
// 	checkoutResponse: null,
// 	personalInfo: {},
// }
