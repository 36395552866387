import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import $ from "jquery";

// reactstrap components
import { Col, Row } from "reactstrap";
import UpcomingCoursesCard from "./UpcomingCoursesCard";
import { useWindow } from "hooks/UseWindow";

function UpcomingCourses({ courses }) {
	const [currentDisplayCourse, setCurrentDisplayCourse] = useState(1);
	let totalCourses = courses.length;
	const slider = React.useRef(null);
	const { width } = useWindow();

	var slider_settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		beforeChange: (oldIndex, newIndex) => {
			setCurrentDisplayCourse(newIndex + 1);
			if (newIndex == 0) {
				$(".upcoming-arrow .arrow-left").addClass("disable");
				$(".upcoming-arrow .arrow-right").removeClass("disable");
			} else if (newIndex == totalCourses - 1) {
				$(".upcoming-arrow .arrow-right").addClass("disable");
				$(".upcoming-arrow .arrow-left").removeClass("disable");
			} else {
				$(".upcoming-arrow .arrow-left,.upcoming-arrow .arrow-right").removeClass("disable");
			}
		},
	};

	return (
		<div className={"fadeInDiv"}>
			<Row className='align-items-center justify-content-between'>
				<Col className=''>
					<span className='mb-0 fs24 font_2 text-fca-dark'>
						{"Upcoming Courses"}
						<span className='mb-0 fs24 font_2 text-fca-dark'>
							{totalCourses > 0 && ` (${currentDisplayCourse} / ${totalCourses})`}
						</span>
					</span>
				</Col>
				{Object.keys(courses).length !== 0 && width <= 575 && (
					<Col xl='auto' md='auto' xs='auto'>
						<div className='arrow-wrap flex upcoming-arrow'>
							<div
								className='arrow arrow-left disable'
								onClick={() => slider?.current?.slickPrev()}
							>
								<i className='fa fa-angle-left' aria-hidden='true'></i>
							</div>
							<div
								className={"arrow arrow-right ml-2" + (totalCourses == 1 ? " disable" : "")}
								onClick={() => slider?.current?.slickNext()}
							>
								<i className='fa fa-angle-right' aria-hidden='true'></i>
							</div>
						</div>
					</Col>
				)}
			</Row>

			<div className='w-100 upcoming_course pb-2'>
				{Object.keys(courses).length !== 0 ? (
					<Slider ref={slider} className={`${width > 575 ? "adaptive-slider" : "adaptive-mobile-slider"}`} arrows={width > 575 ? true : false} {...slider_settings} >
						{courses.map((course, i) => (
							<UpcomingCoursesCard key={i} course={course} status='upcoming' />
						))}
					</Slider>
				) : (
					<div className='pt-4'>
						<p>Do not have any Upcoming Course Yet!</p>
					</div>
				)}
			</div>
		</div>
	);
}

export default UpcomingCourses;
