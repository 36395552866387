import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import classnames from "classnames";
import * as yup from "yup";
import { yupHelper } from "utilities/yupHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
	Button,
	FormGroup,
	Form,
	Modal,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
} from "reactstrap";

import AuthService from "../../../services/auth-api";
import { getErrorMessage } from "utilities/error";

const schema = yup.object({
	password: yupHelper.password(),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("password"), null], "Passwords must match")
		.required("Confirm Password is required"),
});

function ModalChangePassword({ modalChange, otp, setModalChange, email ,redirect_url}) {
	const [loading, setLoading] = useState(false);
	const [message, setmessage] = useState("");
	const [passwordType, setPasswordType] = useState("password");

	let navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const togglePassword = () => {
		if (passwordType === "password") {
			setPasswordType("text");
			return;
		}
		setPasswordType("password");
	};

	const handleChangePassword = (data) => {
		setLoading(true);

		data.email = email;
		data.otp = otp;

		AuthService.forgetPasswordResetPassword(data)
			.then(
				(userData) => {
					setLoading(false);

					// check is this form is embedded and it has to be our origin
					if (window.top != window && window.top.origin === window.location.origin) {
						window.parent.postMessage(
							{ type: "changePasswordSuccess", user: userData },
							window.location.origin,
						);
					} else {

						if(!redirect_url){
							navigate("/login");
							return;
						}
						
						if(redirect_url.startsWith("/")){
							// relative path
							navigate(redirect_url);
						}else if(redirect_url.startsWith("http") || redirect_url.startsWith("www")){
							// abosulte path
							window.location.href = redirect_url;
						}else{
							// match nothing
							navigate("/login");
						}

					}
				},
				(error) => {
					setLoading(false);
					setmessage(getErrorMessage(error));
				},
			)
			.catch(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<Modal className='modal-dialog-centered' isOpen={modalChange}>
				<div>
					<button
						aria-label='Close'
						className='close p-2'
						data-dismiss='modal'
						type='button'
						onClick={() => setModalChange(false)}>
						<span aria-hidden={true}>×</span>
					</button>
				</div>

				<div className='modal-header pt-3'>
					<div className='modal-header-wrap text-center'>
						<h3>Change Password</h3>
					</div>
				</div>
				<div className='modal-body'>
					<Form role='form' onSubmit={handleSubmit(handleChangePassword)}>
						<FormGroup>
							<label className='form-control-label' htmlFor='input-password'>
								Password
							</label>
							<InputGroup
								className={
									"input-group-merge " + (errors.password ? "needs-validation is-invalid" : "")
								}>
								<input
									className={"form-control " + (errors.password ? "is-invalid" : "")}
									id='input-password'
									placeholder='Password'
									type={passwordType}
									{...register("password")}
								/>
								<InputGroupAddon addonType='append'>
									<InputGroupText onClick={togglePassword}>
										{passwordType === "password" ? (
											<i className='far fa-eye-slash'></i>
										) : (
											<i className='far fa-eye'></i>
										)}
									</InputGroupText>
								</InputGroupAddon>
							</InputGroup>
							<div className='invalid-feedback'>{errors.password?.message}</div>
						</FormGroup>

						<FormGroup>
							<label className='form-control-label' htmlFor='input-reEnterPassword'>
								Re-enter Password
							</label>
							<InputGroup
								className={
									"input-group-merge " +
									(errors.confirmPassword ? "needs-validation is-invalid" : "")
								}>
								<input
									className={"form-control " + (errors.confirmPassword ? "is-invalid" : "")}
									id='input-reEnterPassword'
									placeholder='Re-enter Password'
									type={passwordType}
									{...register("confirmPassword")}
								/>
								<InputGroupAddon addonType='append'>
									<InputGroupText onClick={togglePassword}>
										{passwordType === "password" ? (
											<i className='far fa-eye-slash'></i>
										) : (
											<i className='far fa-eye'></i>
										)}
									</InputGroupText>
								</InputGroupAddon>
							</InputGroup>
							<div className='invalid-feedback'>{errors.confirmPassword?.message}</div>
						</FormGroup>
						<div className='justify-content-center mb-4'>
							<Button className='mt-4 w-100 btn-gradient' type='submit' disabled={loading}>
								{loading && <span className='spinner-border spinner-border-sm'></span>}
								<span>Save</span>
							</Button>
						</div>

						{message && (
							<div className='form-group'>
								<div
									className={classnames(
										"alert",
										message === "OTP Sent Success" ? "alert-success" : "alert-danger",
									)}
									role='alert'>
									{message}
								</div>
							</div>
						)}
					</Form>
				</div>
			</Modal>
		</>
	);
}

export default ModalChangePassword;
