import { useSelector } from "react-redux";

let routes = [];
const VERSION = process.env.REACT_APP_APP_VERSION;

routes.push({
	layout: "",
	path: "/course",
	name: "My Course",
	icon: "fas fa-book",
});
routes.push(
	{
		layout: "",
		path: "/events",
		name: "What's new",
		icon: "fas fa-calendar-alt",
	},
);
routes.push({
	layout: "",
	path: "/notification",
	name: "Notifications",
	icon: "fas fa-bell",
});
routes.push(
	{
		layout: "",
		path: "/referral",
		name: "Referral",
		icon: "fas fa-question-circle",
	},
);
routes.push(
	{
		layout: "",
		path: "/faq",
		name: "FAQ",
		icon: "fas fa-question-circle",
	},
);
routes.push({
	layout: "",
	path: "/profile",
	name: "Profile",
	icon: "fas fa-user-circle",
});

export const getNavRoutes = () => {
	const { user: currentUser } = useSelector((state) => state.auth);
	let routes = [];
	if (currentUser) {
		routes.push({
			layout: "",
			path: "/course",
			name: "My Course",
			icon: "fas fa-book",
		});
		
		// routes.push({
		// 	layout: "",
		// 	path: "/notification",
		// 	name: "Notifications",
		// 	icon: "fas fa-bell",
		// });
	}
	routes.push(
		{
			layout: "",
			path: "/events",
			name: "Events",
			icon: "fas fa-calendar-alt",
		},
	);

	routes.push(
		{
			layout: "",
			path: "/upskilling100",
			name: "Upskilling100",
			icon: "fas fa-calendar-alt",
		},
	);

	if (currentUser) {
		if (currentUser.user.has_registered_course) {
			routes.push(
				{
					layout: "",
					path: "/referral",
					name: "Referral",
					icon: "fas fa-question-circle",
				},
			);
		}
		
		routes.push(
			{
				layout: "",
				path: "/faq",
				name: "FAQ",
				icon: "fas fa-question-circle",
			},
		);

		// if (currentUser) {
		// 	routes.push({
		// 		layout: "",
		// 		path: "/profile",
		// 		name: "Profile",
		// 		icon: "fas fa-user-circle",
		// 	});
		// }
	}
	
	return routes;
};

export default routes;
