import { logout } from "actions/auth";
import classNames from "classnames";
import { useAuth } from "hooks/selectors/useAuth";
import { useCheckout } from "hooks/selectors/useCheckout";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { clearCheckout, clearCheckoutAnalyticsData, setCheckout } from "reducers/courseRegisterSlice";
import { GTMCheckoutStep1Account, GTMCheckoutUserLogoutButton, GTMCheckoutUserLogoutSuccess } from "utilities/googleTagManager";

export const CheckoutLogoutButton = ({ keep_courserun_id = true, className, ...rest }) => {
	const dispatch = useDispatch();
	const { courserun_id, checkoutAnalyticsData } = useCheckout();
	const location = useLocation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { userData } = useAuth();

	const handleLogout = () => {
		dispatch(logout());
		dispatch(clearCheckout());
		dispatch(clearCheckoutAnalyticsData());
		if (keep_courserun_id) {
			dispatch(
				setCheckout({
					courserun_id: courserun_id,
				}),
			);

			// make sure to strip off uuidv4 used for singpass sign on
			const newSearchParam = Object.fromEntries(searchParams.entries());
			if (newSearchParam.uuidv4) {
				delete newSearchParam.uuidv4;
			}

			navigate({
				pathname: "/checkout/account",
				search: location.search,
			});
		}
		setIsTryLogout(false);
		toast.success("Logout successfully");
		GTMCheckoutUserLogoutSuccess(checkoutAnalyticsData, userData);
		GTMCheckoutStep1Account(checkoutAnalyticsData, null);
	};
	const [isTryLogout, setIsTryLogout] = useState(false);
	return (
		<div className={classNames("d-flex justify-content-end mb-4", className)} {...rest}>
			{isTryLogout ? (
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "end",
						alignItems: "flex-end",
						gap: 10,
						verticalAlign: "middle",
					}}>
					<Button
						outline
						color='danger'
						className='mb-0'
						style={{ width: "fit-content" }}
						onClick={handleLogout}>
						Yes I want to logout
					</Button>
					<Button
						outline
						color='primary'
						style={{ width: "fit-content" }}
						onClick={() => {
							setIsTryLogout(false);
						}}>
						Cancel
					</Button>
				</div>
			) : (
				<span
					onClick={() => {
						setTimeout(() => {
							setIsTryLogout(true);
							GTMCheckoutUserLogoutButton(checkoutAnalyticsData, userData);
						}, 200);
					}}
					className='px-4 py-2'
					style={{ textDecoration: "underline", cursor: "pointer" }}>
					Log out of account
				</span>
			)}
		</div>
	);
};
