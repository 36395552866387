import React, { useState } from "react";
// reactstrap components
import { Card, CardBody, Row, Col, CardHeader, Collapse } from "reactstrap";

function FaqCard({ headerText, body }) {
	const [accordionOpen, setAccordionOpen] = useState(false);

	const toggleAccordion = () => {
		setAccordionOpen(!accordionOpen);
	};

	let newDescHtml = body;
	newDescHtml = newDescHtml.split("<oembed").join("<iframe");
	newDescHtml = newDescHtml.split("</oembed>").join("</iframe>");
	newDescHtml = newDescHtml.split("url=").join("src=");

	return (
		<div className='faq-card'>
			<div
				onClick={toggleAccordion}
				className='cursor-pointer header'
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
				}}>
				<b style={{ marginRight: 10 }}>{headerText}</b>
				<div className="icon-wrap">{accordionOpen ? "-" : "+"}</div>
			</div>
			<Collapse isOpen={accordionOpen}>
				<div className='pt-2 pb-0 content'>
					<div className='inner-html' dangerouslySetInnerHTML={{ __html: newDescHtml }}></div>
				</div>
			</Collapse>
		</div>
	);
}

export default FaqCard;
