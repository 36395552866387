import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function AlertDialog({ isOpen, title, description, onAgree, onCancel }) {
	const [open, setOpen] = React.useState(false);

	useEffect(() => {
		setOpen(isOpen);
	}, [isOpen]);

	const handleClose = () => {
		onCancel();
	};

	const handleAgree = () => {
		onAgree();
		onCancel();
	};

	const handleDisagree = () => {
		onCancel();
	};

	return (
		<div>
			{/* Dialog that is displayed if the state open is true */}
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{description}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDisagree} color="primary">
						{"Cancel"}
					</Button>
					<Button onClick={handleAgree} color="primary">
						{"OK"}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default AlertDialog;
