import { Row, Col } from "reactstrap";

function CourseOutline({ title, description, content }) {
	const replaceContent = (content, color) => {
		let svg =
			"<svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 512 512' fill='" +
			color +
			"'><path d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z' /></svg>";

		return content ? content.replace(/<li>/g, `<li>${svg}`) : "";
	};

	return (
		<div className='course-outline-wrap'>
			<h5>{title}</h5>
			<div
				className='desc-wrap'
				dangerouslySetInnerHTML={{
					__html: description,
				}}></div>

			{content && (
				<Row className='content-row mx--1'>
					{Object.keys(content).map((key, i) => {
						const isLastItem = i === Object.keys(content).length - 1;
						const colSize = isLastItem && Object.keys(content).length % 2 !== 0 ? "12" : "6";
						return (
							<Col
								md={colSize}
								xl={colSize}
								key={i}
								className={`content-col content-col-${key} p-1`}>
								<div className='content-wrap'>
									<div className='bg' style={{ backgroundColor: content[key]?.color }} />
									<div className='content-item'>
										<div className='title-wrap'>
											<div className='icon-wrap'>
												{content[key]?.icon?.url && <img src={content[key].icon.url} />}
											</div>
											<div className='title'>
												<h3 style={{ color: content[key]?.color }}>{content[key]?.title}</h3>
											</div>
										</div>
										<div
											className='desc svg_li_wrap'
											dangerouslySetInnerHTML={{
												__html: replaceContent(content[key]?.description, content[key]?.color),
											}}></div>
									</div>
								</div>
							</Col>
						);
					})}
				</Row>
			)}
		</div>
	);
}

export default CourseOutline;
