import React, { useEffect, useState } from "react";
import {
	Outlet,
	useLocation,
	useNavigate,
	Link,
	useSearchParams,
	createSearchParams,
	Navigate
} from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Stepper, Step, StepLabel, StepConnector } from "@material-ui/core";
import classNames from "classnames";
import { ReactComponent as HelpIcon } from "assets/img/Checkout/icon_help.svg";
import { ReactComponent as EnquireIcon } from "assets/img/Checkout/icon_enquire.svg";
import { ReactComponent as EnveloIcon } from "assets/img/Checkout/icon_envelo.svg";
import { useCheckout } from "hooks/selectors/useCheckout";
import { useDispatch } from "react-redux";
import { fetchCheckoutData, fetchCourseDetailsForAnalytics, saveCheckoutAnalyticsData, setCheckout } from "reducers/courseRegisterSlice";
import { getUser, resetUser } from "actions/auth";
import { useAuth } from "hooks/selectors/useAuth";
// import ContactUs2 from "../learner/course/ContactUs2";
import generalApi from "services/general-api";
import { CONTACT_NO_CHECKOUT } from "utilities/constant";
import { GTMCheckoutEnquiryButton, GTMCheckoutViewMoreCoursesButton, removeLogGTMUser } from "utilities/googleTagManager";
import axios from "axios";
import { getErrorMessage } from "utilities/error";
import { useEventCheckout } from "reducers/eventRegisterSlice";
import styles from "./eventCheckout.module.css";
import ContactUs2 from "views/pages/learner/course/ContactUs2";
// import { fetchCheckoutData, setCheckoutResponse } from "reducers/checkout";

const steps = [
	{
		label: "Registration Summary",
		route: ["/event/checkout"],
	},
	{
		label: "Payments",
		route: ["/event/checkout/omise"],
	},
	{
		label: "Successful Registration",
		route: ["/event/checkout/success"],
	},
];

export const getUrlParams = (params, cachedCheckoutAnalyticsData, cachedCourserunId) => {
	let searchParam = {};
	params.map(([key, value]) => {
		if (key == "courserun_id") {
			searchParam[key] = value || cachedCourserunId;
		} else if (key == "course_id") {
			searchParam[key] = value || cachedCheckoutAnalyticsData.course_id;
		} else {
			searchParam[key] = value;
		}
	});

	if (cachedCheckoutAnalyticsData.referral !== null) {
		searchParam.referral = params["referral"] || cachedCheckoutAnalyticsData.referral;
	} else {
		delete searchParam.referral;
	}
	// if (cachedCheckoutAnalyticsData.invite !== null) {
	// 	searchParam.invite = params["invite"] || cachedCheckoutAnalyticsData.invite;
	// } else {
	// 	delete searchParam.invite;
	// }
	delete searchParam.invite;

	if (cachedCheckoutAnalyticsData.campaign_type !== null) {
		searchParam.campaign_type = params["campaign_type"] || cachedCheckoutAnalyticsData.campaign_type;
	} else {
		delete searchParam.campaign_type;
	}
	if (cachedCheckoutAnalyticsData.utm_campaign !== null) {
		searchParam.utm_campaign = params["utm_campaign"] || cachedCheckoutAnalyticsData.utm_campaign;
	} else {
		delete searchParam.utm_campaign;
	}
	if (cachedCheckoutAnalyticsData.utm_content !== null) {
		searchParam.utm_content = params["utm_content"] || cachedCheckoutAnalyticsData.utm_content;
	} else {
		delete searchParam.utm_content;
	}
	if (cachedCheckoutAnalyticsData.utm_medium !== null) {
		searchParam.utm_medium = params["utm_medium"] || cachedCheckoutAnalyticsData.utm_medium;
	} else {
		delete searchParam.utm_medium;
	}
	if (cachedCheckoutAnalyticsData.utm_term !== null) {
		searchParam.utm_term = params["utm_term"] || cachedCheckoutAnalyticsData.utm_term;
	} else {
		delete searchParam.utm_term;
	}
	if (cachedCheckoutAnalyticsData.utm_source !== null) {
		searchParam.utm_source = params["utm_source"] || cachedCheckoutAnalyticsData.utm_source;
	} else {
		delete searchParam.utm_source;
	}
	if (cachedCheckoutAnalyticsData.CCC !== null) {
		searchParam.ccc = params["ccc"] || cachedCheckoutAnalyticsData.CCC;
	} else {
		delete searchParam.ccc;
	}
	return searchParam;
};
  

export function EventCheckout() {
	const {
		checkoutInfo,
		fetchCheckoutInfo,
		cachedCheckoutAnalyticsData= {},
		checkoutAnalyticsData= {},
	} = useEventCheckout();
	const { userData } = useAuth();
	const [isSingPassUnderMaintenance, setIsSingPassUnderMaintenance] = useState(false);
	const [isSfcUnderMaintenance, setIsSfcUnderMaintenance] = useState(false);
	const [currentStep, setCurrentStep] = React.useState(0);
	const [isReady, setIsReady] = useState(false);
	const [openModal, setOpenModal] = React.useState(0);
	const [searchParams] = useSearchParams();
	const params = Array.from(searchParams.entries());
	const paramEventId = searchParams.get("event_id");
	const paramEventRegForm = searchParams.get("eventregform_id");
	const invalidCheckoutInputs =  !paramEventRegForm; 
	const [invalidCheckout, setInvalidCheckout] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	console.log("EventCheckout", {checkoutInfo, cachedCheckoutAnalyticsData, checkoutAnalyticsData, userData});


	// Fetch and refetch all the data used for this page
	// based on the following key
	useEffect(() => {

		// payment page don't need to do anything
		if(window.location.pathname.includes("/event/checkout/omise")) {
			setIsReady(true);
			return ;
		}

		if(window.location.pathname.includes("/event/checkout/success")) {
			setIsReady(true);
			return ; 
		}

		if (invalidCheckoutInputs && !checkoutInfo) {
			console.warn("Invalid checkout inputs");
			setInvalidCheckout(true);
			return ;
		}

		// Check if there is a regform cache in redux
		// if so we try to fetch the checkout data using the cached regform id

		// But before that we need to check if the cached regform id  + cache courserun match
		fetchCheckoutInfo(paramEventRegForm)
			.then((eventReg) => {
				if (!eventReg) {
				// TODO: invalid regform or checkout , redirect back to event pages
				//  - option 1: navigate to event listing page
				//  - option 2: if have event_id navigate back to event
				// 
				// return navigate(
				// 	{
				// 		pathname: "/checkout/account",
				// 		search: createSearchParams(getUrlParams(params, cachedCheckoutAnalyticsData, event_id)).toString(),
				// 	},
				// 	{ replace: true },
				// );
				}


				const hasPaid =
					eventReg.payments &&
					eventReg.payments.length > 0 &&
					eventReg.payments.filter((x) => x.status === "SUCCESSFUL").length > 0;

				// Navigate to Success page if the payment is complete
				if (hasPaid) {
					if (!location.pathname.includes("omise/paynow") && !location.pathname.includes("omise/loading")) {
						navigate(
							{
								pathname: "/event/checkout/success",
								search: location.search,
							},
							{ replace: true },
						);
					}
				}

				// prevent user manually going to success page
				if (location.pathname === "/event/checkout/success" && !hasPaid) {
					navigate(
						{
							pathname: "/event/checkout",
							search: createSearchParams(getUrlParams(params, cachedCheckoutAnalyticsData, paramEventId)).toString(),
						},
						{ replace: true },
					);
				}

				setIsReady(true);
			});
	}, [paramEventRegForm, paramEventId]);



	useEffect(() => {
		let matchStepIndex= -1;
		steps.forEach((step,index) => { 
			step.route.forEach((routeStr) => {
				if(location.pathname.startsWith(routeStr)){
					matchStepIndex= index;
				}
			});
		});
		console.log("matchStepIndex", matchStepIndex, location.pathname);
		if (matchStepIndex >= 0) {
			setCurrentStep(2);
		} else {
			setCurrentStep(0);
			navigate(
				{
					pathname: "/event/checkout",
					search: location.search,
				},
				{ replace: true },
			);
		}
	}, [location]);


	/** Intercept any unauthorized request.
	 * dispatch logout action accordingly **/
	useEffect(() => { 
		const UNAUTHORIZED = 401;
		axios.interceptors.response.use(
			(response) => response,
			(error) => {
				const { status } = error.response;
				let error_message = getErrorMessage(error);
				if (status === UNAUTHORIZED && error_message == "Unauthenticated.") {
					removeLogGTMUser();
					dispatch(resetUser());
					localStorage.removeItem("user");
				}
				throw error;
			},
		);

	},[]);


	const handleOpenModal = () => {
		setOpenModal(true);
		// GTM Analytics
		// GTMCheckoutEnquiryButton(
		// 	checkoutAnalyticsData,
		// 	userData,
		// );
	};

	const handleSuccess = () => {
		setOpenModal(false);
	};


	return <div className={styles.root}>
		<Container fluid className={`p-0 ml-0 mr-0 ${styles.stepper_container}`} >
			<Stepper 
				key={currentStep}
				alternativeLabel 
				activeStep={currentStep === steps.length -1 ? steps.length  : currentStep} 
				connector={<ColorlibConnector />} 
				style={{
					paddingTop:0,
					paddingBottom:0,
					paddingLeft:0,
					paddingRight:0
				}}>
				{steps.map((step) => {
					return (
						<Step key={step.label} onClick={() => {}}>
							<StepLabel StepIconComponent={ColorlibStepIcon} >{step.label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
		</Container>
		<Container fluid className={styles.main}>
			<Row>
				{location.pathname !== "/checkout/success" ? (
					<>
						<Col lg={8}>
							{!isReady? (
								<div>Loading 3...</div>
							) : 
								<Outlet
									context={{
										openEnquiryModal: handleOpenModal,
										isSingPassUnderMaintenance: isSingPassUnderMaintenance,
										isSfcUnderMaintenance: isSfcUnderMaintenance,
									}}
								/>
							}
						</Col>
						<Col lg={4}>
							<div className={classNames(styles.callme, styles.callme_blue, "shadow-sm")}>
								<h4>Need help with your registration?</h4>
								<Row className='ml-0 mr-0 my-4'>
									<Col
										xs={2}
										style={{
											alignItems: "center",
											justifyContent: "center",
											display: "flex",
											paddingLeft: 0,
											paddingRight: 0,
										}}>
										<HelpIcon width={"50"} height={"auto"} />
									</Col>
									<Col>
										<p>
										Reach us at
											<a
												href={`tel:${CONTACT_NO_CHECKOUT}`}
												style={{
													color: "#4BA58C",
													fontWeight: "600",
													margin: "0 5px",
													whiteSpace: "nowrap",
												}}>
												{CONTACT_NO_CHECKOUT}
											</a>
										Our Student Services team will provide you with assistance.
										</p>
									</Col>
								</Row>

								<a href={`tel:${CONTACT_NO_CHECKOUT}`}>
									<Button outline className={classNames(styles.call_us_btn, "mt-4")}>
									Call Us Now
									</Button>
								</a>
							</div>
							<div
								id='enquiry-form'
								className={classNames(styles.enquiry, styles.callme_green, "shadow-sm mt-4")}>
								<h4>Enquiries regarding our courses?</h4>
								<Row className='ml-0 mr-0 my-4'>
									<Col
										xs={2}
										style={{
											alignItems: "center",
											justifyContent: "center",
											display: "flex",
											paddingLeft: 0,
											paddingRight: 0,
										}}>
										<EnveloIcon width={"50"} height={"auto"} />
									</Col>
									<Col>
										<p>
										Drop us a message via the enquiry form. Our team of Education Consultants
										will reach out to you within one working day.
										</p>
									</Col>
								</Row>
								<Button
									outline
									className={classNames(styles.call_us_btn, "mt-4")}
									onClick={handleOpenModal}>
									{"Enquire Now"}
								</Button>
								<ContactUs2 show={openModal} onSuccess={handleSuccess}></ContactUs2>
							</div>
							<div className={classNames(styles.enquiry, styles.callme_yellow, "shadow-sm mt-4")}>
								<h4>Still browsing around?</h4>
								<Row className='ml-0 mr-0 my-4'>
									<Col
										xs={2}
										style={{
											alignItems: "center",
											justifyContent: "center",
											display: "flex",
											paddingLeft: 0,
											paddingRight: 0,
										}}>
										<EnquireIcon width={"50"} height={"auto"} />
									</Col>
									<Col>
										<p>Visit our course overview page to view all available courses.</p>
									</Col>
								</Row>
								<Button
									href='https://www.fca.edu.sg/courses/'
									target='_blank'
									rel='noreferrer'
									outline
									className={classNames(styles.call_us_btn, "mt-4")}
									onClick={() => GTMCheckoutViewMoreCoursesButton(checkoutAnalyticsData, userData)}
								>
									{"View more courses"}
								</Button>
							</div>
						</Col>
					</>
				) : isReady ? (
					<Outlet />
				) : (
					<div>Loading 1...</div>
				)}
			</Row>
		</Container>
		<Modal isOpen={invalidCheckout} centered>
			<ModalHeader>Something is wrong</ModalHeader>
			<ModalBody>Invalid Checkout</ModalBody>
			<ModalFooter>
				<a href="https://www.fca.edu.sg/">
					<Button color='primary'>Back to Home</Button>
				</a>
			</ModalFooter>
		</Modal>
	</div>;
}




const useColorlibStepIconStyles = makeStyles({
	root: {
		backgroundColor: "#EEEEEE",
		zIndex: 1,
		color: "gray",
		border: "3px solid #ddd",
		width: 40,
		height: 40,
		display: "flex",
		borderRadius: "50%",
		justifyContent: "center",
		alignItems: "center",
		fontWeight: "bold",
	},
	active: {
		// backgroundImage: "linear-gradient( 90deg, #4BA58C 0%,  #3B85C4 100%)",
		backgroundColor: "white",
		boxShadow: "0 0 2px 2px rgba(0,0,0,.25)",
		color: "#4BA58C",
		border: "3px solid #54C49C",
	},
	completed: {
		backgroundImage: "linear-gradient( 90deg, #4BA58C 0%,  #3B85C4 100%)",
		border: "none",
		color: "white",
		boxShadow: "0 0 4px 4px rgba(0,0,0,.25)",
	},
});

const ColorlibConnector = withStyles({
	alternativeLabel: {
		top: 18,
	},
	active: {
		"& $line": {
			backgroundImage: "linear-gradient( 95deg, #4BA58C 0%, #3B85C4 100%)",
		},
	},
	completed: {
		"& $line": {
			backgroundImage: "linear-gradient( 95deg, #4BA58C 0%, #3B85C4 100%)",
		},
	},
	line: {
		height: 5,
		border: 0,
		backgroundColor: "#eaeaf0",
		borderRadius: 1,
	},
})(StepConnector);

function ColorlibStepIcon(props) {
	const classes = useColorlibStepIconStyles();
	const { active, completed } = props;

	return (
		<div
			className={`${classNames(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed,
				[styles.glowing_circle]: completed,
			})}`}>
			{props.icon}
		</div>
	);
}