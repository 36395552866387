import axios from "axios";
import { EVENT_LENGTH } from "utilities/constant";
import { ARTICLE_COMMENT_LENGTH } from "utilities/constant";
import { ARTICLE_LENGTH } from "utilities/constant";

const API_URL = process.env.REACT_APP_API_URL;
import authHeader from "./auth-header";

const getEvent = (pageParam = 0, event_type = "event", searchText="" ) => {
	let length = EVENT_LENGTH;
	return axios
		.get(
			API_URL +
			`/api/mobile/event?event_type=${event_type}&start=${pageParam}&length=${length}&searchText=${searchText}`,
			{ headers: authHeader() },
		)
		.then((response) => {
			return { ...response.data.data, nextPage: pageParam + length, previousPage: pageParam };
		});
};

const getFeaturedEvent = () => {
	return axios
		.get(
			API_URL +
			"/api/mobile/event/featured",
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};


// event details
const getEventDetails = (event_id,event_registration_id=null) => {
	return axios
		.get(API_URL + `/api/mobile/event/${event_id}`, { 
			params:{
				// this registration id is should only use for those user register for event but don't have an account
				// due to admin manual register user
				event_registration_id:event_registration_id
			},
			headers: authHeader() 
		})
		.then((response) => {
			return response.data;
		});
};

// event register
const registerEvent = (event_id, ticketValue) => {
	let request_data = {
		event_id: event_id,
		ticket_amount: ticketValue !== "" ? Number(ticketValue) : null,
		register_from: "FALCOMM_WEB"
	};
	return axios
		.post(API_URL + "/api/mobile/event/register", request_data,{ headers: authHeader() },)
		.then((response) => {
			return response.data;
		});
};

// news
const getArticles = (pageParam = 0, article_type = "article" ) => {
	let length = ARTICLE_LENGTH;
	return axios
		.get(
			API_URL +
		`/api/mobile/article?article_type=${article_type}&start=${pageParam}&length=${length}`,
			{ headers: authHeader() },
		)
		.then((response) => {
			return { ...response.data.data, nextPage: pageParam + length, previousPage: pageParam };
		});
};

const articleLike = (article_id) => {
	return axios
		.post(
			API_URL +
		"/api/mobile/article/like",
			{article_id},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data.data;
		});
};

const articleShare = (article_id) => {
	return axios
		.post(
			API_URL +
		"/api/mobile/article/share",
			{article_id},
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data.data;
		});
};


// article details
const getArticleDetails = (article_id) => {
	return axios
		.get(API_URL + `/api/mobile/article/${article_id}`, { headers: authHeader() })
		.then((response) => {
			return response.data;
		});
};

// article comments
const getArticleComment = (pageParam = 0, id, first_comment_id = null ) => {
	let length = ARTICLE_COMMENT_LENGTH;
	let url = API_URL + `/api/mobile/article/${id}/comments?start=${pageParam}&length=${length}`;
	if (first_comment_id) {
		url = url + `&first_comment_id=${first_comment_id}`;
	}
	return axios
		.get(
			url,
			{ headers: authHeader() },
		)
		.then((response) => {
			return { ...response.data.data, nextPage: pageParam + length, previousPage: pageParam };
		});
};

const postComment = (
	article_id,
	comment,
	reply_to_comment,
) => {
	let data = {
		article_id: article_id,
		comment: comment,
	};
	if (reply_to_comment) {
		data.reply_to_comment = reply_to_comment;
	}
	return axios
		.post(
			API_URL +
		"/api/mobile/article/add_comment",
			data,
			{ headers: authHeader() },
		)
		.then((response) => {
			return response.data;
		});
};

export default {
	getEvent,
	getFeaturedEvent,
	getEventDetails,
	registerEvent,
	getArticles,
	articleLike,
	articleShare,
	getArticleDetails,
	getArticleComment,
	postComment,
};
