import React, { useEffect, useState } from "react";
import styles from "./CheckoutSuccessPage.module.scss";
import { ReactComponent as SuccessIcon } from "assets/img/Checkout/Success/tick.svg";
import { Alert, Button, Spinner } from "reactstrap";
import { ReactComponent as BookIcon } from "assets/img/Checkout/Success/icon_book.svg";
import { ReactComponent as CalendarIcon } from "assets/img/Checkout/Success/icon_cal.svg";
import { ReactComponent as LearnIcon } from "assets/img/Checkout/Success/icon_learn.svg";
import { ReactComponent as LocateIcon } from "assets/img/Checkout/Success/icon_locate.svg";
import { ReactComponent as PhoneIcon } from "assets/img/Checkout/Success/icon_phone.svg";
import { ReactComponent as StudentIcon } from "assets/img/Checkout/Success/icon_student.svg";
import { ReactComponent as TimeIcon } from "assets/img/Checkout/Success/icon_time.svg";
import { ReactComponent as HeartIcon } from "assets/img/Checkout/Success/icon_heart.svg";
import { ReactComponent as ThumbIcon } from "assets/img/Checkout/Success/icon_thumb.svg";

import successImg from "assets/img/endorse/img_success.png";
import downloadPhoneImg from "assets/img/Checkout/Success/phone_app.png";
import FCALOGO from "assets/img/FALCOMM_LOGO_2.png";
import appleDownloadImg from "assets/img/Checkout/Success/apple_download.png";
import gooleDownloadImg from "assets/img/Checkout/Success/google_download.png";
import { useLocation, useNavigate } from "react-router-dom";
import CourseDetails from "components/Course/CourseDetails";
import { useCheckout } from "hooks/selectors/useCheckout";
import moment from "moment";
import { CheckoutLogoutButton } from "views/pages/checkout/components/LogoutButton";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { clearCheckout, fetchCheckoutData, saveCheckoutAnalyticsData } from "reducers/courseRegisterSlice";
import { useAuth } from "hooks/selectors/useAuth";
import { GTMCheckoutStep3Success, GTMCheckoutStep3SuccessExit } from "utilities/googleTagManager";
import CheckoutSuccessFloating from "views/pages/checkout/components/CheckoutSuccessFloating";

export function CheckoutSuccessPage() {
	let navigate = useNavigate();
	const { registrationform_id, courserun_id, course, courserun, hasLoaded, isLoading } =
		useCheckout();

	const dispatch = useDispatch();
	const { checkoutAnalyticsData } = useCheckout();
	const [isFirstTime, setIsFirstTime] = useState(true);
	const { userData } = useAuth();


	// Here will have double api call (one at CheckoutPage and one at CheckoutSuccessPage)
	// But should be fine, since the api call is not heavy and there is lesser user complete the full checkout flow
	useEffect(() => {
		dispatch(
			fetchCheckoutData({
				courserun_id: courserun_id,
				registrationform_id: registrationform_id,
			}),
		);
	}, []);


	useEffect(() => {
		return () => {
			if (history.action === "POP") {
				history.go(1);
			}
		};
	}, [history]);


	useEffect(() => {
		if (isFirstTime) {
			GTMCheckoutStep3Success(userData);
			setIsFirstTime(false);
		}
		
		// analytics for close tab
		const handleTabClosing = () => {
			GTMCheckoutStep3SuccessExit(userData);
		};

		window.addEventListener("unload", handleTabClosing);
		return () => {
			window.removeEventListener("unload", handleTabClosing);
		};
	}, [userData]);

	return (
		<div className={styles.checkout_success_container}>
			<div className={styles.success_title_container}>
				<CheckoutSuccessFloating />
				<span className={styles.title_text}>
					{"Congratulations! You have successfully signed up for the course below:"}
				</span>
				<span className={styles.desc_text}>
					{
						"You will receive an email notification shortly. Our Student Support Executive will also be in contact with you prior to the class to assist you with the preparation."
					}
				</span>

				<Alert color='primary' className={classNames(styles.sfc_text, "mt-4")}>
					{isLoading ? (
						<Spinner size={"sm"} />
					) : (
						"⏰ Please allow us up to one working day for your SkillsFuture claim to be confirmed."
					)}
				</Alert>
			</div>
			<div className={styles.success_course_container}>
				<CourseDetails
					logos={course.logos.map((logo) => logo.course_logo_image)}
					courseTitle={course.course_title}
					courseId={course.course_ref}
					location={
						<div>
							<div>{courserun.venue.traininglocation.traininglocation_title}</div>
							<div
								dangerouslySetInnerHTML={{
									__html: courserun.venue.traininglocation.traininglocation_address,
								}}
							/>
						</div>
					}
					courseDate={
						moment(courserun.courserun_course_from).format("DD MMMM YYYY, dddd") +
						" - " +
						moment(courserun.courserun_course_to).format("DD MMMM YYYY, dddd")
					}
					courseTimings={courserun.csessions_string}
					learningMode={courserun.courserun_modeoftraining}
					studentSupportName={
						courserun.training_support ? courserun.training_support.user_name : "Updating Soon"
					}
					studentSupportContact={
						courserun.training_support ? courserun.training_support.user_mobile : "Updating Soon"
					}
					trainerImage={courserun.trainer.trainer_image_url}
					trainerName={courserun.trainer.trainer_name}
				/>
				<div className={styles.download_container}>
					<div className={styles.download_image_container}>
						<img className={styles.download_phone_image} alt={"phone"} src={downloadPhoneImg} />
						<div className={styles.download_floating_image_container}>
							<div className={`${styles.item_1} ${styles.scale_animation_1}`}>
								<ThumbIcon height={28} width={28} className={styles.thumb_icon} />
							</div>
							<div className={`${styles.item_2} ${styles.scale_animation_2}`}>
								<HeartIcon height={24} width={24} className={styles.thumb_icon} />
							</div>
							<div className={`${styles.item_3} ${styles.scale_animation_1}`}>
								<HeartIcon height={20} width={20} className={styles.thumb_icon} />
							</div>
						</div>
					</div>
					<div className={styles.download_falcomm_container}>
						<div className={styles.download_floating_image_container}>
							<div className={`${styles.item_4} ${styles.scale_animation_1}`}>
								<ThumbIcon height={28} width={28} className={styles.thumb_icon} />
							</div>
							<div className={`${styles.item_5} ${styles.scale_animation_2}`}>
								<HeartIcon height={24} width={24} className={styles.thumb_icon} />
							</div>
						</div>
						<div className={styles.download_falcomm_title_container}>
							<div className={styles.download_fca_image_container}>
								<img className={styles.download_fca_image} alt={"phone"} src={FCALOGO} />
							</div>
							<div className={styles.download_title_text_container}>
								<span className={styles.downoad_title}>{"Download The FALCOMM App"}</span>
								<span className={styles.downoad_desc}>
									{
										"Please click on the link to download the FALCOMM app to be part of FCA’s Alumni. Download here"
									}
								</span>
							</div>
						</div>
						<div className={styles.download_benefit_title}>{"FCA’s Alumni benefits:"}</div>
						<div className={styles.download_benefit_desc_container}>
							<div className={styles.check_container}>
								<i className={`fa fa-check ${styles.check_icon}`} aria-hidden='true'></i>
							</div>
							<div className={styles.download_benefit_desc}>
								{"View your upcoming course information"}
							</div>
						</div>
						<div className={styles.download_benefit_desc_container}>
							<div className={styles.check_container}>
								<i className={`fa fa-check ${styles.check_icon}`} aria-hidden='true'></i>
							</div>
							<div className={styles.download_benefit_desc}>{"Select your meals"}</div>
						</div>
						<div className={styles.download_benefit_desc_container}>
							<div className={styles.check_container}>
								<i className={`fa fa-check ${styles.check_icon}`} aria-hidden='true'></i>
							</div>
							<div className={styles.download_benefit_desc}>{"Enroll for new courses"}</div>
						</div>
						<div className={styles.download_benefit_desc_container}>
							<div className={styles.check_container}>
								<i className={`fa fa-check ${styles.check_icon}`} aria-hidden='true'></i>
							</div>
							<div className={styles.download_benefit_desc}>{"Reschedule your courses"}</div>
						</div>
						<div className={styles.download_benefit_desc_container}>
							<div className={styles.check_container}>
								<i className={`fa fa-check ${styles.check_icon}`} aria-hidden='true'></i>
							</div>
							<div className={styles.download_benefit_desc}>{"Download certificates"}</div>
						</div>
						<div className={styles.download_benefit_desc_container}>
							<div className={styles.check_container}>
								<i className={`fa fa-check ${styles.check_icon}`} aria-hidden='true'></i>
							</div>
							<div className={styles.download_benefit_desc}>
								{"Receive updates on digital news and trends"}
							</div>
						</div>
						<div className={styles.download_benefit_desc_container}>
							<div className={styles.check_container}>
								<i className={`fa fa-check ${styles.check_icon}`} aria-hidden='true'></i>
							</div>
							<div className={styles.download_benefit_desc}>{"Enjoy complimentary workshops"}</div>
						</div>
						<div className={styles.download_benefit_desc_container}>
							<div className={styles.check_container}>
								<i className={`fa fa-check ${styles.check_icon}`} aria-hidden='true'></i>
							</div>
							<div className={styles.download_benefit_desc}>{"Invitation to exclusive events"}</div>
						</div>
						<div className={styles.download_button_container}>
							<img
								className={styles.apple_download_img}
								alt={"apple_download"}
								src={appleDownloadImg}
								onClick={() => window.open(process.env.REACT_APP_APPLE_APP_STORE_URL, "_blank")}
							/>
							<img
								className={styles.google_download_img}
								alt={"google_download"}
								src={gooleDownloadImg}
								onClick={() => window.open(process.env.REACT_APP_ANDROID_APP_STORE_URL, "_blank")}
							/>
							{/* <Button className={`btn-outline-green2 ${styles.button}`} onClick={() =>  {
							window.open(process.env.REACT_APP_APPLE_APP_STORE_URL, "_blank");
						}} >
							<span> ios</span>
						</Button>
						<Button className={`btn-outline-green2 ${styles.button}`} onClick={() => window.open(process.env.REACT_APP_ANDROID_APP_STORE_URL, "_blank")} >
							<span> android</span>
						</Button> */}
						</div>
					</div>
				</div>
				<div className={styles.button_container}>
					<Button
						className={`btn-outline-green ${styles.button}`}
						onClick={() => navigate("/login")}>
						<span> Visit to FALCOMM </span>
					</Button>

					<a href='https://www.fca.edu.sg/' className={`btn btn-gradient ${styles.button}`}>
						Back To Home
					</a>
				</div>
			</div>

			<div className='mt-4'>
				<CheckoutLogoutButton />
			</div>
		</div>
	);
}

// process.env.REACT_APP_authDomain,
