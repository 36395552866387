/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { useLocation, Outlet, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "side_menu.js";
import { Container } from "reactstrap";
import { logGTMScreen } from "utilities/googleTagManager";
import { logAnalyticsScreen, removeLogUser } from "utilities/firebase";
import axios from "axios";
import { getErrorMessage } from "utilities/error";
import { resetUser } from "actions/auth";

const first_start_at_upskillling =  localStorage.getItem("detect_start_at_upskilling");
const routeIsUpskilling=  window.location.pathname === "/upskilling100";
if(
	first_start_at_upskillling==="true" ||
	routeIsUpskilling && (
		first_start_at_upskillling === null 
		|| first_start_at_upskillling ===undefined 
		|| first_start_at_upskillling ===""
	) 
){
	localStorage.setItem("detect_start_at_upskilling",true);
	sessionStorage.setItem("upskilling100",true);
}

function App() {
	const [sidenavOpen, setSidenavOpen] = React.useState(true);
	const location = useLocation();
	const mainContentRef = React.useRef(null);
	const { user: currentUser, isLoggedIn } = useSelector((state) => state.auth);

	React.useEffect(() => {
		logGTMScreen(location, isLoggedIn, currentUser);
		if (document.documentElement && document.scrollingElement && mainContentRef.current) {
			document.documentElement.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
			mainContentRef.current.scrollTop = 0;
			window.scrollTo(0, 0);
		}
	}, [location]);

	const NavigateFunctionComponent = () => {
		/** Intercept any unauthorized request.
		 * dispatch logout action accordingly **/
		const dispatch = useDispatch();
		let navigate = useNavigate();

		const UNAUTHORIZED = 401;
		axios.interceptors.response.use(
			(response) => response,
			(error) => {
				const { status } = error.response;
				let error_message = getErrorMessage(error);
				if (status === UNAUTHORIZED && error_message == "Unauthenticated.") {
					removeLogUser();
					dispatch(resetUser());
					localStorage.removeItem("user");
					navigate("/login");
				}
				throw error;
			},
		);

		return <></>;
	};

	if (!currentUser) {
		return <Navigate to='/login' />;
	} else {
		if (!currentUser.user.email_verified_at) {
			return <Navigate to='/login/otp-validation' />;
		}

		if (currentUser.user.reset_password) {
			return <Navigate to='/login' />;
		}
	}

	// // toggles collapse between mini sidenav and normal
	// const toggleSidenav = (e) => {
	// 	if (document.body.classList.contains("g-sidenav-pinned")) {
	// 		document.body.classList.remove("g-sidenav-pinned");
	// 		document.body.classList.add("g-sidenav-hidden");
	// 	} else {
	// 		document.body.classList.add("g-sidenav-pinned");
	// 		document.body.classList.remove("g-sidenav-hidden");
	// 	}
	// 	setSidenavOpen(!sidenavOpen);
	// };
	// const getNavbarTheme = () => {
	// 	return location.pathname.indexOf("admin/alternative-dashboard") === -1 ? "dark" : "light";
	// };

	// const getBrandText = (path) => {
	// 	for (let i = 0; i < routes.length; i++) {
	// 		if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
	// 			return routes[i].name;
	// 		}
	// 	}
	// 	return "Brand";
	// };

	return (
		<>
			<Sidebar
				routes={routes}
				// toggleSidenav={toggleSidenav}
				// sidenavOpen={sidenavOpen}
				logo={{
					innerLink: "/",
					imgSrc: require("assets/img/FCA.png"),
					imgAlt: "...",
				}}
			/>
			<div className='main-content' ref={mainContentRef}>
				{/* <AdminNavbar
					theme={getNavbarTheme()}
					toggleSidenav={toggleSidenav}
					sidenavOpen={sidenavOpen}
					brandText={getBrandText(location.pathname)}
				/> */}
				<Container className='py-5 main-container' fluid>
					<Outlet react={React} />
				</Container>
				{/* <AdminFooter /> */}
			</div>
			{/* {sidenavOpen ? <div className='backdrop d-xl-none' onClick={toggleSidenav} /> : null} */}
			<NavigateFunctionComponent />
		</>
	);
}

export default App;
