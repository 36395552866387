import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// reactstrap components
import { Row, Col, Button, Card, CardBody } from "reactstrap";

import { ReactComponent as BusIcon } from "assets/img/Course/upcoming/bus.svg";
import { ReactComponent as MealIcon } from "assets/img/Course/upcoming/meal.svg";
import { ReactComponent as TimeIcon } from "assets/img/Course/upcoming/date.svg";
import { ReactComponent as CertificateIcon } from "assets/img/Course/registration/success.svg";
import ModalCertificates from "views/pages/learner/course/ModalCertificates";
import { PrintCourseDate, PrintDate } from "../../common_function/function.js";

function BundleCertCard({ cert }) {
	const [certModalChange, setCertModalChange] = useState(false);
	const VERSION = process.env.REACT_APP_APP_VERSION;
	const MySwal = withReactContent(Swal);
	
	const handlePopup = (e, displayMSG) => {
		e.preventDefault();
		e.stopPropagation();
		MySwal.fire({
			title: "Unable to Proceed!",
			html: displayMSG,
			icon: "error",
		});
	};

	return (
		<Card className='cert-card'>
			<CardBody>
				<Row className='justify-content-between'>
					<Col md='auto' xl='auto' xs='auto'>
						{cert.logos.map((logo, i) => (
							<img
								key={i}
								className='mr-1 h-40px display_ini'
								alt={cert.bundle_name}
								src={logo.bundle_logo_image}
							/>
						))}
					</Col>
					<Col md='auto' xl='auto' xs='auto' className='text-right'></Col>
				</Row>
				<Row className='align-items-center pt-3'>
					<Col md='12' xl='12'>
						<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }} >
							<h5>{`Bundle: ${cert.bundle_name}`}</h5>
							<img
								alt='cert-badge'
								className='h-40px'
								src={require("assets/img/Cert/badge.png")}
							/>
						</div>
					</Col>
				</Row>
				<Row className={"bundle-course-title m-0 cert_date_completed"}>
					<Col className='icon-container p-0 border-0'>
						<div className={"circle"}>
							<img alt='medal' src={require("assets/img/Attendance/check.png")} />
						</div>
					</Col>
					<Col className='title-container text-body'>
						Completed on {PrintDate(cert.cert_date_completed)}
					</Col>
				</Row>
				<div className='course-wrap pt-3'>
					<div className='allcourse-title'>
						<u>
							<b>All Courses</b>
						</u>
					</div>

					{cert?.related_certs.map((course, i) => (
						<>
							<Row className='align-items-center pt-2'>
								<Col md='12' xl='12'>
									<div className='title'>
										<b>{course.course_title}</b>
									</div>
								</Col>
							</Row>

							<Row className='course_data course_detail'>
								<Col xl='auto' md='auto' xs='auto' className='pl-3 pt-1'>
									<img
										alt='Course ID'
										className='pb-1 h-20px'
										src={require("assets/img/learner/book.png")}
									/>
									<label className='m-0 pl-2 text-dark'>
										<b>Course ID</b>
									</label>
									<p className='m-0'>{course?.course_ref}</p>
								</Col>

								<Col xl='auto' md='auto' xs='auto' className='pl-3 pt-1'>
									<img
										alt='Course ID'
										className='pb-1 h-20px'
										src={require("assets/img/learner/user_icon.png")}
									/>
									<label className='m-0 text-dark'>
										<b>Trainer</b>
									</label>
									<p className='m-0'>{course?.trainer_name}</p>
								</Col>

								<Col xl='auto' md='auto' xs='auto' className='pl-3 pt-1 mb-3'>
									<img
										alt='Location'
										className='pb-1 h-20px'
										src={require("assets/img/learner/Icon awesome-location-arrow.png")}
									/>
									<label className='m-0 pl-2 text-dark'>
										<b>Location</b>
									</label>
									<p className='m-0'>{course?.traininglocation_title}</p>
								</Col>
							</Row>
						</>
					))}
					{cert.cert_file_url && cert.cert_file_url !== "" && (
						<>
							<ModalCertificates
								course_title={cert.bundle_name}
								cert_file_url={cert.cert_file_url}
								showModal={certModalChange}
								setShowModal={setCertModalChange}
							/>
							<div
								style={{ display: "flex", justifyContent: "flex-end" }}
							>
								<Button
									style={{
										borderWidth: 0,
										boxShadow: "none",
										backgroundColor: "white",
									}}
									onClick={(e) => {
										e.stopPropagation();
										e.preventDefault();
										setCertModalChange(true);
									}}
								>
									<span className="font-weight-bold" style={{ color: "rgb(55,132,196)" }}>Certificate Available</span>
								</Button>
							</div>
						</>
					)}
				
					{(!cert.cert_file_url || cert.cert_file_url === "") && (
						<div
							className=''
							style={{ display: "flex", justifyContent: "flex-end" }}
						>
							<span className="font-weight-bold" style={{ color: "rgb(55,132,196)" }}>Certificate is processing</span>
						</div>
					)}
				</div>

					
				<Row className='pt-2'>
					<Col xl='auto' className='ml-auto'>
						{" "}
						<div className='btn-wrap'>
							<Link to={"/course/bundle/" + cert.bundle_id} className='btn btn-default'>
										Back
							</Link>
						</div>
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
}

export default BundleCertCard;
