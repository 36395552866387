import { PrintCourseDate, PrintDate } from "common_function/function";
import { useWindow } from "hooks/UseWindow";
import React from "react";
import { Link } from "react-router-dom";
import defaultCourse from "assets/img/default_course.jpg";

// reactstrap components
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import { getBundlePercentages } from "utilities/number";

function CompletedCoursesCard({ course, status }) {
	const { width } = useWindow();

	return (
		<div className='h-100 p-2'>
			<Card className={`m-0 ${width <= 575 ? "" : "h-100"} cursor-pointer completed-card pb-5`}>
				<Link
					to='/course/course-details/'
					state={{ data: course, status: status }}
					className='text-dark'>
					<CardBody style={{ padding: 0, position:"relative" }}>
						<Row className="mb-3 mx-0" style={{ overflow: "hidden" }}>
							<img
								src={course.course.course_banner_image || defaultCourse}
								width='100%'
								className={"completed_list_banner_image"}
							/>
						</Row>
						<Row className='justify-content-between mx-3'>
							<Col md='auto' xl='auto' xs='auto' className="px-0">
								{course.course.logos.map((logo, i) => (
									<img
										key={i}
										className='mr-1 h-20px display_ini'
										alt={course.course.course_title}
										src={logo.course_logo_image}
									/>
								))}
							</Col>
						</Row>
						{course.course.categories && course.course.categories.length > 0 && (
							<Row className='pt-2 align-items-center mx-3'>
								<Col className='col-12 px-0'>
									<Row className='align-items-center mx-0'>
										<Col xl='10' md='10' xs='7' className="px-0">
											<Row className="mx-0">
												{course.course.categories ? course.course.categories.map((category, i) => {
													return (
														<Col className='col-auto py-0 pr-1 pl-0' key={i}>
															<span
																style={{
																	backgroundColor: category.category_color,
																}}
																className='small-tag text-white'>
																{category.category_title}
															</span>
														</Col>
													);
											  	})
													:
													""}
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						    )
						}
						<Row className='align-items-center pt-2 pb-3 mx-3'>
							<Col className='col-auto px-0'>
								<p className="fs18 font-weight-bold text-fca-dark font_2">{course.course.course_title}</p>
							</Col>
						</Row>
					</CardBody>
				</Link>
				<Link
					to='/course/course-details/'
					state={{ data: course, status: status }}
				>
					<div className={`pb-2 px-2 ${width <= 576 ? "w-100" : ""}`} style={{ position:"absolute", bottom: 0, right: 0 }}>
						<Button className='btn-gradient' type='submit'>
							<span className="fs14">Read More</span>
						</Button>
					</div>
				</Link>
			</Card>
		</div>
	);
}

export default CompletedCoursesCard;
