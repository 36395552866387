/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate} from "react-router-dom";

// reactstrap components
import {
	Button,
	Card,
	CardBody,
	Container,
} from "reactstrap";

import AuthService from "../../../../services/auth-api";

function ProfileDeleteAccount() {
	const { user: userData } = useSelector((state) => state.auth);
	const currentUser = userData.user;
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const handleSendOTP = () => {
		setLoading(true);
		AuthService.deleteAccountSendOtp()
			.then(
				() => {
					navigate("/profile/delete-account/otp-validation");
				},
				() => {
				},
			)
			.catch(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<Container fluid>
				<div className='title-container'>
					<h2>{"Account Deletion"}</h2>
				</div>
				<Card className='border-0 mt-4 mx-auto' style={{ maxWidth: "600px" }}>
					<CardBody className='px-lg-5 py-lg-5 text-center'>
						<div className=''>
							<p>
								{"Please confirm if you would like to proceed with the account deletion request."}
							</p>
						</div>
						<div className='btn-container pt-4 '>
							<Button className='w-100 btn' style={{ backgroundColor: "rgb(216,70,70)", color: "#fff" }} onClick={handleSendOTP} disabled={loading}>
								{loading && <span className='spinner-border spinner-border-sm'></span>}
								{"Delete Account"}
							</Button>
						</div>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default ProfileDeleteAccount;
