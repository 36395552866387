import React, { useEffect, useRef, useState } from "react";
import styles from "./EventCheckoutSuccessPage.module.scss";
import { Alert, Button, Spinner } from "reactstrap";
import { ReactComponent as HeartIcon } from "assets/img/Checkout/Success/icon_heart.svg";
import { ReactComponent as ThumbIcon } from "assets/img/Checkout/Success/icon_thumb.svg";

import downloadPhoneImg from "assets/img/Checkout/Success/phone_app.png";
import FCALOGO from "assets/img/FALCOMM_LOGO_2.png";
import appleDownloadImg from "assets/img/Checkout/Success/apple_download.png";
import gooleDownloadImg from "assets/img/Checkout/Success/google_download.png";
import EventCheckoutSuccessSVG from "assets/img/Events/event-success-illustration.svg";
import { Link, useNavigate } from "react-router-dom";
import { CheckoutLogoutButton } from "views/pages/checkout/components/LogoutButton";
import { useEventCheckout } from "reducers/eventRegisterSlice";
import { EventDetailCard } from "views/pages/learner/event/checkout/components/EventDetailCard";
import confetti from "canvas-confetti";

export function EventCheckoutSuccessPage() {
	let navigate = useNavigate();
	const { checkoutInfo ,isAppCheckout} = useEventCheckout((state) => state);
	const {event_id} = checkoutInfo.event;

	// useEffect(() => {
	// 	return () => {
	// 		if (history.action === "POP") {
	// 			history.go(1);
	// 		}
	// 	};
	// }, [history]);


	// useEffect(() => {
	// 	if (isFirstTime) {
	// 		GTMCheckoutStep3Success(userData);
	// 		setIsFirstTime(false);
	// 	}
		
	// 	// analytics for close tab
	// 	const handleTabClosing = () => {
	// 		GTMCheckoutStep3SuccessExit(userData);
	// 	};

	// 	window.addEventListener("unload", handleTabClosing);
	// 	return () => {
	// 		window.removeEventListener("unload", handleTabClosing);
	// 	};
	// }, [userData]);

	useEffect(() => {

		if(isAppCheckout){
			const data = {
				event_id: event_id,
				action : "success"
			};

			if(window.ReactNativeWebView){
				window.ReactNativeWebView.postMessage(JSON.stringify(data));
			}
		}

	  },[]);

	const handleBackToApp = () => { 
		const data = {
			event_id: event_id,
			action : "close"
		};

		if(window.ReactNativeWebView){
			window.ReactNativeWebView.postMessage(JSON.stringify(data));
		}
	 };

	return (
		<div className={styles.checkout_success_container}>
			<div className={`${styles.success_title_container} `} >
				<div>
					<img 
						src={EventCheckoutSuccessSVG} 
						alt="success" 
						className="mb-4"
						onLoad={(event) => { 
							// calculate the origin based on the center of image 
							// image might be offset
							const boundingBox = event.currentTarget.getBoundingClientRect();
							const origin = {
								x : (boundingBox.left + boundingBox.width/2) /window.innerWidth,
								y : ((boundingBox.top + boundingBox.height/2) / window.innerHeight) + 0.1
							};
							
							confetti({
								particleCount: 100,
								spread: 70,
								origin: origin
							});
						}}
					/>
				</div>
				<span className={styles.title_text}>
					{"Congratulations! You have successfully signed up for the event below:"}
				</span>
				<span className={styles.desc_text}>
					{
						"An email notification regarding the details of the event will be sent to you shortly. Please kindly download the mobile application for attendance taking and further notification."
					}
				</span>
			</div>

			<div className={styles.success_course_container}>
				<EventDetailCard data={checkoutInfo} />	
			</div>
			<div className={styles.success_course_container}>
				<div className={styles.download_container}>
					<div className={styles.download_image_container}>
						<img className={styles.download_phone_image} alt={"phone"} src={downloadPhoneImg} />
						<div className={styles.download_floating_image_container}>
							<div className={`${styles.item_1} ${styles.scale_animation_1}`}>
								<ThumbIcon height={28} width={28} className={styles.thumb_icon} />
							</div>
							<div className={`${styles.item_2} ${styles.scale_animation_2}`}>
								<HeartIcon height={24} width={24} className={styles.thumb_icon} />
							</div>
							<div className={`${styles.item_3} ${styles.scale_animation_1}`}>
								<HeartIcon height={20} width={20} className={styles.thumb_icon} />
							</div>
						</div>
					</div>
					<div className={styles.download_falcomm_container}>
						<div className={styles.download_floating_image_container}>
							<div className={`${styles.item_4} ${styles.scale_animation_1}`}>
								<ThumbIcon height={28} width={28} className={styles.thumb_icon} />
							</div>
							<div className={`${styles.item_5} ${styles.scale_animation_2}`}>
								<HeartIcon height={24} width={24} className={styles.thumb_icon} />
							</div>
						</div>
						<div className={styles.download_falcomm_title_container}>
							<div className={styles.download_fca_image_container}>
								<img className={styles.download_fca_image} alt={"phone"} src={FCALOGO} />
							</div>
							<div className={styles.download_title_text_container}>
								<span className={styles.downoad_title}>{"Download The FALCOMM App"}</span>
								<span className={styles.downoad_desc}>
									{
										"Please click on the link to download the FALCOMM app to be part of FCA’s Alumni. Download here"
									}
								</span>
							</div>
						</div>
						<div className={styles.download_benefit_title}>{"FCA’s Alumni benefits:"}</div>
						<div className={styles.download_benefit_desc_container}>
							<div className={styles.check_container}>
								<i className={`fa fa-check ${styles.check_icon}`} aria-hidden='true'></i>
							</div>
							<div className={styles.download_benefit_desc}>
								{"View your upcoming course information"}
							</div>
						</div>
						<div className={styles.download_benefit_desc_container}>
							<div className={styles.check_container}>
								<i className={`fa fa-check ${styles.check_icon}`} aria-hidden='true'></i>
							</div>
							<div className={styles.download_benefit_desc}>{"Select your meals"}</div>
						</div>
						<div className={styles.download_benefit_desc_container}>
							<div className={styles.check_container}>
								<i className={`fa fa-check ${styles.check_icon}`} aria-hidden='true'></i>
							</div>
							<div className={styles.download_benefit_desc}>{"Enroll for new courses"}</div>
						</div>
						<div className={styles.download_benefit_desc_container}>
							<div className={styles.check_container}>
								<i className={`fa fa-check ${styles.check_icon}`} aria-hidden='true'></i>
							</div>
							<div className={styles.download_benefit_desc}>{"Reschedule your courses"}</div>
						</div>
						<div className={styles.download_benefit_desc_container}>
							<div className={styles.check_container}>
								<i className={`fa fa-check ${styles.check_icon}`} aria-hidden='true'></i>
							</div>
							<div className={styles.download_benefit_desc}>{"Download certificates"}</div>
						</div>
						<div className={styles.download_benefit_desc_container}>
							<div className={styles.check_container}>
								<i className={`fa fa-check ${styles.check_icon}`} aria-hidden='true'></i>
							</div>
							<div className={styles.download_benefit_desc}>
								{"Receive updates on digital news and trends"}
							</div>
						</div>
						<div className={styles.download_benefit_desc_container}>
							<div className={styles.check_container}>
								<i className={`fa fa-check ${styles.check_icon}`} aria-hidden='true'></i>
							</div>
							<div className={styles.download_benefit_desc}>{"Enjoy complimentary workshops"}</div>
						</div>
						<div className={styles.download_benefit_desc_container}>
							<div className={styles.check_container}>
								<i className={`fa fa-check ${styles.check_icon}`} aria-hidden='true'></i>
							</div>
							<div className={styles.download_benefit_desc}>{"Invitation to exclusive events"}</div>
						</div>
						<div className={styles.download_button_container}>
							<img
								className={styles.apple_download_img}
								alt={"apple_download"}
								src={appleDownloadImg}
								onClick={() => window.open(process.env.REACT_APP_APPLE_APP_STORE_URL, "_blank")}
							/>
							<img
								className={styles.google_download_img}
								alt={"google_download"}
								src={gooleDownloadImg}
								onClick={() => window.open(process.env.REACT_APP_ANDROID_APP_STORE_URL, "_blank")}
							/>
							{/* <Button className={`btn-outline-green2 ${styles.button}`} onClick={() =>  {
							window.open(process.env.REACT_APP_APPLE_APP_STORE_URL, "_blank");
						}} >
							<span> ios</span>
						</Button>
						<Button className={`btn-outline-green2 ${styles.button}`} onClick={() => window.open(process.env.REACT_APP_ANDROID_APP_STORE_URL, "_blank")} >
							<span> android</span>
						</Button> */}
						</div>
					</div>
				</div>

				{
					isAppCheckout 
						? <div className={styles.button_container}>
							<Button
								className={`btn-outline-green ${styles.button}`}
								onClick={handleBackToApp}>
									Back to App
							</Button>
						</div> 
						: <div className={styles.button_container}>
							<Button
								className={`btn-outline-green ${styles.button}`}
								onClick={() => navigate("/login")}>
								<span> Visit to FALCOMM </span>
							</Button>

							<Link to={`/events/${event_id}`} className={`btn btn-gradient ${styles.button}`}>
								Back To Event
							</Link>
						</div>
				}
				

			</div>
			<div className='mt-4'>
				<CheckoutLogoutButton />
			</div>

		</div>
	);
}

