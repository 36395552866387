import React, { useState } from "react";
// reactstrap components
import { Button, CardBody, Col, Row, Form, FormGroup } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
	Card,
} from "reactstrap";
import { getErrorMessage } from "utilities/error";
import generalApi from "services/general-api";
import "../../../../assets/scss/custom/_inner-html.scss";
import { useSelector } from "react-redux";

import * as yup from "yup";
import { yupHelper } from "utilities/yupHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import "../course/ContactUs2.css";
import { GTMCheckoutEnquiryFormCorporateSubmit, GTMCheckoutFacebookEnquiryFormCorporateSubmit, GTMCheckoutGoogleAdsEnquiryFormCorporateSubmit } from "utilities/googleTagManager";
import { useCheckout } from "hooks/selectors/useCheckout";

const WEB_URL = process.env.REACT_APP_WEB_URL;

const schema = yup.object({
	name: yupHelper.fullname().required(),
	email: yupHelper.emailOptional(),
	mobileno: yupHelper.mobile().required(),
	message: yup.string(),
	company_name: yup.string().required("Company Name is a required field"),
	designation: yup.string().required(),
	consent: yup
		.boolean()
		.oneOf([true], "You must agree to the Privacy Policy")
		.required("You must agree to the Privacy Policy"),
});

function CorporateForm(props) {
	const {metadata={}} = props;
	const [isButtonLoading, setIsButtonLoading] = React.useState(false);
	const [isError, setIsError] = React.useState(false);
	const [isSuccess, setSuccess] = React.useState(false);	
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");
	const { user: userData } = useSelector((state) => state.auth);
	const currentUser = userData?userData.user:false;
	const MySwal = withReactContent(Swal);
	const { checkoutAnalyticsData } = useCheckout();

	let navigate = useNavigate();

	
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const handleContactUs = (data) => {
		setLoading(true);
		setMessage("");
		if (checkoutAnalyticsData && checkoutAnalyticsData.campaign_type) {
			data.lead_type = checkoutAnalyticsData.campaign_type;
		}
		data.lead_url = window.location.href;
		data.referral = checkoutAnalyticsData && checkoutAnalyticsData.referral;
		// data.invite = checkoutAnalyticsData && checkoutAnalyticsData.invite;
		if(metadata && metadata.ts_ccc){
			data.ts_ccc = metadata.ts_ccc;
		}

		generalApi
			.ContactUsSubmitForm2(data)
			.then(
				(response) => {
					// setLoading(false);
					data.course_id = checkoutAnalyticsData && checkoutAnalyticsData.course_id;
					data.courserun_id = checkoutAnalyticsData && checkoutAnalyticsData.courserun_id;
					data.campaign_type = checkoutAnalyticsData && checkoutAnalyticsData.campaign_type;
					data.referral = checkoutAnalyticsData && checkoutAnalyticsData.referral;
					// data.invite = checkoutAnalyticsData && checkoutAnalyticsData.invite;
					data.utm_campaign = checkoutAnalyticsData && checkoutAnalyticsData.utm_campaign;
					data.utm_content = checkoutAnalyticsData && checkoutAnalyticsData.utm_content;
					data.utm_medium = checkoutAnalyticsData && checkoutAnalyticsData.utm_medium;
					data.utm_source = checkoutAnalyticsData && checkoutAnalyticsData.utm_source;
					data.utm_term = checkoutAnalyticsData && checkoutAnalyticsData.utm_term;
					data.ccc = checkoutAnalyticsData && checkoutAnalyticsData.CCC ? checkoutAnalyticsData.CCC : null;
					data.lead_id = response.data.id;

					generalApi
						.SubmitBigBangLeadConversionLog(data)
						.then(
							(response) => {
							},
							(error) => {
							},
						)
						.catch(() => {
						});
						
					GTMCheckoutEnquiryFormCorporateSubmit(checkoutAnalyticsData, userData);
					if (checkoutAnalyticsData && checkoutAnalyticsData.campaign_type && checkoutAnalyticsData.campaign_type.toUpperCase() === "STELLAR") {
						GTMCheckoutFacebookEnquiryFormCorporateSubmit(checkoutAnalyticsData, userData);
					}
					if (checkoutAnalyticsData && checkoutAnalyticsData.campaign_type && checkoutAnalyticsData.campaign_type.toUpperCase() === "GRAVITY") {
						GTMCheckoutGoogleAdsEnquiryFormCorporateSubmit(checkoutAnalyticsData, userData);
					}
					MySwal.fire({
						title: "Thank you for your enquiry",
						html: <p>We will contact you soon!</p>,
						icon: "success",
					}).then(() => {
						props.onSuccess(true);
					});
				},
				(error) => {
					setLoading(false);
					setMessage(getErrorMessage(error));
				},
			)
			.catch(() => {
				setLoading(false);
			});
	};

	return (
		<div className="form_content" id={"EventContainer"}>
			{isButtonLoading && (
				<div className='justify-content-center mt-4 w-100 flex'>
					<span className='spinner-border spinner-border-sm'></span>
				</div>
			)}
			{!isButtonLoading && !isError && (
				<div className="individual-contact-form">
					<Card className='mt-4'>
						<CardBody className='p-0'>
							<Form role='form' id="individual-contact-form" onSubmit={handleSubmit(handleContactUs)}>
								<input
									type="hidden"
									name="form_type" 
									value="corporate" 
									{...register("form_type")}
								/>
								<Row>
									<Col lg='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-fullname'>
                        Name
											</label>
											<input
												className={"form-control " + (errors.name ? "is-invalid" : "")}
												id='input-fullname'
												placeholder='Enter Your Name'
												defaultValue={
													currentUser.learnermaster_name ? currentUser.learnermaster_name : ""
												}
												type='text'
												{...register("name")}
											/>
											<div className='invalid-feedback'>{errors.name?.message}</div>
										</FormGroup>
									</Col>
									<Col lg='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-company_name'>
                        Company Name
											</label>
											<input
												className={"form-control " + (errors.company_name ? "is-invalid" : "")}
												id='input-company_name'
												placeholder='Company Name'
												type='text'
												{...register("company_name")}
											/>
											<div className='invalid-feedback'>{errors.company_name?.message}</div>
										</FormGroup>
									</Col>
									<Col lg='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-designation'>
                        Designation
											</label>
											<input
												className={"form-control " + (errors.designation ? "is-invalid" : "")}
												id='input-designation'
												placeholder='Designation'
												type='text'
												{...register("designation")}
											/>
											<div className='invalid-feedback'>{errors.designation?.message}</div>
										</FormGroup>
									</Col>
									<Col lg='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-mobileNo'>
                        Contact No.
											</label>
											<input
												className={"form-control " + (errors.mobileno ? "is-invalid" : "")}
												id='input-mobileNo'
												placeholder='Enter Your Contact Number'
												defaultValue={
													currentUser.learnermaster_mobile ? currentUser.learnermaster_mobile : ""
												}
												type='text'
												{...register("mobileno")}
											/>
											<div className='invalid-feedback'>{errors.mobileno?.message}</div>
										</FormGroup>
									</Col>

									<Col lg='12'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-email'>
                        Email <span>(optional)</span>
											</label>
											<input
												className={"form-control " + (errors.email ? "is-invalid" : "")}
												id='input-email'
												placeholder='Enter Your Email address'
												defaultValue={
													currentUser.learnermaster_email ? currentUser.learnermaster_email : ""
												}
												type='email'
												{...register("email")}
											/>
											<div className='invalid-feedback'>{errors.email?.message}</div>
										</FormGroup>
									</Col>

									<Col md='12' xl='12'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-remarks'>
                        Remarks <span>(optional)</span>
											</label>
											<textarea
												className={"form-control " + (errors.remarks ? "is-invalid" : "")}
												id='input-remarks'
												placeholder='Remarks'
												rows='5'
												{...register("remarks")}
											/>
											<div className='invalid-feedback'>{errors.remarks?.message}</div>
										</FormGroup>
									</Col>

									<Col md='12' xl='12'>
										<FormGroup>
											<div className='custom-control custom-checkbox'>
												<input
													className='custom-control-input'
													type='checkbox'
													id='input-consent'
													{...register("consent")}
													style={{width: "100%", zIndex: 2, height: "100%", cursor: "pointer"}}
												/>
												<label
													className='consent-label custom-control-label h-auto'
													htmlFor='input-consent'
												>
                          I have read and consent to the collection, use and disclosure of my personal data in accordance with the terms in this {" "}
													<a
														target='_blank'
														rel='noreferrer'
														href='https://www.fca.edu.sg/privacy-policy/'
														style={{ position:"relative", zIndex: "3 !important" }}
													>
                            Privacy Policy
													</a>{" "}
												</label>
											</div>

											<div
												className={"invalid-feedback " + (errors.consent ? "d-block" : "d-none")}
											>
												{errors.consent?.message}
											</div>
										</FormGroup>
									</Col>

									<Col md='12' xl='12'>
										{message && (
											<div className='form-group'>
												<div
													className='alert alert-danger'
													role='alert'
													dangerouslySetInnerHTML={{ __html: message }}
												></div>
											</div>
										)}
									</Col>
									<Col md='12' xl='12'>
										<div className='flex justify-content-end contact-submit mb-4 mt-4 pr-4'>
											<Button className='btn-gradient' type='submit' disabled={loading}>
												{loading && <span className='spinner-border spinner-border-sm'></span>}
												<span>Send</span>
											</Button>
										</div>
									</Col>
								</Row>
							</Form>
						</CardBody>
					</Card>
				</div>
			)}
		</div>
	);
}

export default CorporateForm;
