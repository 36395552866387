import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

// reactstrap components
import { Col, Row } from "reactstrap";
import AvailableCourseCard from "./AvailableCourseCard";
import generalApi from "services/general-api";
import { getErrorMessage } from "utilities/error";

const useResize = (myRef) => {
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);
  
	const handleResize = () => {
	  setWidth(myRef.current.offsetWidth);
	  setHeight(myRef.current.offsetHeight);
	};
  
	useEffect(() => {
	  myRef.current && myRef.current.addEventListener("resize", handleResize);
  
	  return () => {
			myRef.current.removeEventListener("resize", handleResize);
	  };
	}, [myRef]);
  
	return { width, height };
};
  
function AvailableCourse({ courses }) {
	const [CourseCategory, setCourseCategory] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("all-123");
	const [newCourses, setNewCourses] = useState([]);
	const [newHeight, setNewHeight] = useState(0);
	const divRef = useRef(null);

	useEffect(() => {
		if (!divRef.current) return;
		const resizeObserver = new ResizeObserver(() => {
		  // Do what you want to do when the size of the element changes
		  setNewHeight(divRef.current.clientHeight);
		});
		resizeObserver.observe(divRef.current);
		return () => resizeObserver.disconnect(); // clean up 
	  }, []);

	useEffect(() => {
		generalApi.getCourseCategory().then(
			(response) => {
				setCourseCategory(response);
			},
			(error) => {
				let error_message = getErrorMessage(error);
				// setmessage(error_message);
			},
		);
		setNewCourses(courses);
		setSelectedCategory("all-123");
	}, []);

	const onClickCategory = (id) => {
		setSelectedCategory(id);
		if (id === "all-123") {
			setNewCourses(courses);
		} else {
			const filteredData = courses.filter(item =>
				item.categories.some(categoryObj => categoryObj.category_id.toString() === id.toString() && !item.has_registered)
			);
			setNewCourses(filteredData);
		}
	};
	console.log(newCourses);				
	return (
		<>
			{courses.length > 0 &&
				<div className={"align-items-center fadeInDiv"}>
					<Row className='align-items-center justify-content-between mt-5'>
						<Col className=''>
							<span className='mb-0 fs24 font_2 text-fca-dark'>
								{"Courses Available"}
							</span>
						</Col>
					</Row>

					<div className='w-100 course-category-wrap pb-3 ml-2 mr-2'>
						<Row>
							<span
								id={"all-123"}
								style={
									selectedCategory !== "all-123" ? {
										backgroundColor: "#eeeeee",
										color: "#101820",
									} : {
										backgroundColor: "rgb(0, 133, 202)",
										color: "#ffffff",
									}}
								className='tag mx-2 mt-2 px-4 py-2 cursor-pointer'
								onClick={() => onClickCategory("all-123")}
							>
								{"All"}
							</span>
							{CourseCategory
								? CourseCategory.map((category, i) => {
									return (
										<span
											key={i}
											id={category.category_id}
											style={
												selectedCategory !== category.category_id ? {
													backgroundColor: "#eeeeee",
													color: "#101820",
												} : {
													backgroundColor: "rgb(0, 133, 202)",
													color: "#ffffff",
												}
											}
											className='tag mx-2 mt-2 px-4 py-2 cursor-pointer'
											onClick={() => onClickCategory(category.category_id)}
										>
											{category.category_title}
										</span>
									);
								})
								: ""}
						</Row>
					</div>

					<div className='w-100 available_course pb-5 p-2 divHeightTranstion' style={{ height: newHeight, overflow: "hidden" }}>
						<div ref={divRef} style={{ display: "flex", flexWrap: "wrap", marginRight: "-15px", marginLeft: "-15px"  }}>
							{newCourses.map((course) => (
								<AvailableCourseCard key={course.course_id} course={course} />
							))}
							{newCourses && newCourses.length === 0 &&
								<div style={{ flex: 1, justifyContent: "center" }}>
									<p className='text-center'>
										<b>Empty Course</b>
									</p>
								</div>
							}
						</div>
					</div>
				</div>
			}
		</>
	
	);
}

export default AvailableCourse;
