/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../actions/auth";
// reactstrap components
import { Container} from "reactstrap";
import { getNotification } from "actions/notification";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import { NOTIFICATION_LENGTH } from "utilities/constant";
import NotificationCard from "components/LearnerPortal/NotificationCard";

function NotificationMain() {
	const notificationState = useSelector((state) => state.notification);
	const dispatch = useDispatch();
	const { user: userData, isHyperAdminLogin } = useSelector((state) => state.auth);

	const [isLastPage, setIsLastPage] = useState(false);
	const [page, setPage] = useState(0);


	React.useEffect(() => {
		fetchNotification(page);
	}, []);

	const fetchNotification =  async (page) => {
		try {
			let response = await dispatch(getNotification(page));
			// if last page
			if (response.data.length < NOTIFICATION_LENGTH) {
				setIsLastPage(true);
			}
			setPage(response.nextPage);
		} catch (e) {
			toast.error(e);
		}
	};

	return (
		<div className="fadeInDiv my_course_dashboard_container">
			<h2 className='mb-3'>Notifications</h2>
			{notificationState.isGetNotificationLoading && 
				<div className='justify-content-center mt-4 w-100 flex'><span className='spinner-border spinner-border-sm'></span>
				</div>
			}
			{notificationState.notifications && notificationState.notifications.length > 0 &&
				<InfiniteScroll
					className="pl-1 pr-1"

					dataLength={notificationState.notifications.length} //This is important field to render the next data
					next={ () => {
						fetchNotification(page);
					}}
					hasMore={!isLastPage}
					loader={<div className='justify-content-center mt-4 w-100 flex'>
						<span className='spinner-border spinner-border-sm'></span>
					</div>}
					endMessage={
						<p style={{ textAlign: "center" }}>
						</p>
					}
					pullDownToRefresh={false}
				>
					{
						notificationState.notifications.map((notification, index) => (
							<NotificationCard
								key={`${index}-${notification.pushnotificationrecord_pushnotification_id}`}
								id={notification.pushnotificationrecord_pushnotification_id}
								notification={notification}
							/>
						))
					}
				
				</InfiniteScroll>
			}
			{!notificationState.isGetNotificationLoading && (!notificationState.notifications || (notificationState.notifications && notificationState.notifications.length === 0)) && (
				<div style={{display: "flex", flexDirection: "column", marginTop: 20, width: "100%", alignItems: "center", justifyContent: "center"}} >
					<i className='fa fa-bell' aria-hidden='true' style={{color: "#AAAAAA", fontSize: 30, marginBottom: 10}}></i>
					<p  style={{fontWeight: "bold", marginBottom: 0}}>
					You have no notifications
					</p>
					<p >
						{"New notification will be show here when you \n refresh the page"}
					</p>
				</div>
			)}
			
		</div>
	);
}

export default NotificationMain;
