import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupHelper } from "utilities/yupHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// reactstrap components
import { Button, Card, CardBody, FormGroup, Form, Container, Row, Col, InputGroupAddon, InputGroupText, InputGroup } from "reactstrap";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

import { NATIONALITY_OPTIONS, QUALIFICATOIN_LEVEL_OPTION } from "../../../../utilities/constant";
import { updateProfile } from "../../../../actions/auth";
import { clearMessage } from "actions/message";

const schema = yup.object({
	name: yupHelper.fullname(),
	dob: yup
		.date("Dob must be a date")
		.typeError("Dob must be a date")
		.required("Dob is required"),
	nationality: yup.string().required(),
	mobileno: yupHelper.mobile(),
	education: yup.string().required(),
	designation: yup.string().required(),
});

function ProfileEdit() {
	const { user: userData } = useSelector((state) => state.auth);
	const currentUser = userData.user;
	const singpassUser = JSON.parse(currentUser.fill_with_singpass);
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const { message } = useSelector((state) => state.message);
	const [open, setOpen] = React.useState(false);
	const dispatch = useDispatch();

	const MySwal = withReactContent(Swal);

	const {
		control,
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const handleUpdateProfile = (data) => {
		setLoading(true);
		data.dob = Moment(data.dob).format("DD-MM-YYYY");
		dispatch(updateProfile(data))
			.then(() => {
				MySwal.fire({
					title: "Congratulations!",
					html: <p>Profile Updated Successfully.</p>,
					icon: "success",
				}).then(() => {
					navigate("..");
				});
				
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	React.useEffect(() => {
		dispatch(clearMessage());
	}, []);

	const toggleOpen = () => {
		if (open) {
			setValue("nric", `${currentUser.learnermaster_nric?.replace(
				/(?=\w{5})\w/g,
				"\u25CF",
			)}`);
			setOpen(false);
		} else {
			setValue("nric", currentUser.learnermaster_nric ? currentUser.learnermaster_nric : "");
			setOpen(true);

		}
	};

	return (
		<>
			<Container className='profile-edit' fluid>
				<div className='title-container'>
					<h2>Update Profile</h2>
				</div>
				<Card className='border-0 mt-4'>
					<CardBody className='px-lg-5 py-lg-5'>
						<Form role='form' onSubmit={handleSubmit(handleUpdateProfile)}>
							<Row>
								<Col lg='6'>
									<FormGroup>
										<label className='form-control-label' htmlFor='input-fullname'>
                      Full Name (As Shown in NRIC)
										</label>
										<input
											className={"form-control " + (errors.name ? "is-invalid" : "")}
											defaultValue={
												currentUser.learnermaster_name ? currentUser.learnermaster_name : ""
											}
											id='input-fullname'
											placeholder='Full Name'
											type='text'
											readOnly={singpassUser === 1 ? true : false}
											{...register("name")}
										/>
										<div className='invalid-feedback'>{errors.name?.message}</div>
									</FormGroup>
								</Col>
								<Col lg='6'>
									<FormGroup>
										<label className='form-control-label' htmlFor='input-nric'>
											{"NRIC or ID No."}
										</label>
										<InputGroup>
											<input
												className={"form-control " + (errors.nric ? "is-invalid" : "")}
												defaultValue={
													`${currentUser.learnermaster_nric?.replace(
														/(?=\w{5})\w/g,
														"\u25CF",
													)}`
												}
												id='input-nric'
												placeholder='NRIC or ID No.'
												type='text'
												disabled
												{...register("nric")}
											/>
											<InputGroupAddon addonType='append'>
												<InputGroupText onClick={toggleOpen}>
													{open ? (
														<i className='far fa-eye-slash'></i>
													) : (
														<i className='far fa-eye'></i>
													)}
												</InputGroupText>
											</InputGroupAddon>
										</InputGroup>
										
										<div className='invalid-feedback'>{errors.nric?.message}</div>
									</FormGroup>
								</Col>
							</Row>

							<Row>
								<Col lg='6'>
									<FormGroup>
										<label className='form-control-label' htmlFor='input-dob'>
                      Date of Birth
										</label>
										<Controller
											name='dob'
											control={control}
											defaultValue={
												currentUser.learnermaster_dob
													? Moment(currentUser.learnermaster_dob).format("YYYY-MM-DD")
													: ""
											}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<ReactDatetime
													onChange={onChange}
													value={Moment(value).format("DD-MM-YYYY")}
													inputProps={{
														id: "input-dob",
														placeholder: "Date of Birth",
														disabled: singpassUser === 1 ? true : false,
													}}
													dateFormat='DD-MM-YYYY'
													timeFormat={false}
												/>
											)}
										/>
										<div className={"invalid-feedback " + (errors.dob ? "d-block" : "d-none")}>
											{errors.dob?.message}
										</div>
									</FormGroup>
								</Col>
								<Col lg='6'>
									<FormGroup>
										<label className='form-control-label' htmlFor='input-nationality'>
                      Nationality
										</label>
										<select
											defaultValue={
												currentUser.learnermaster_nationality
													? currentUser.learnermaster_nationality
													: ""
											}
											className={"form-control " + (errors.nationality ? "is-invalid" : "")}
											{...register("nationality")}
											id='input-nationality'
										>
											<option key='' value='' disabled>
                        Please Select Your Nationality
											</option>
											{NATIONALITY_OPTIONS.map((option) => (
												<option key={option.value} value={option.value}>
													{option.label}
												</option>
											))}
										</select>
										<div className='invalid-feedback'>{errors.nationality?.message}</div>
									</FormGroup>
								</Col>
							</Row>

							<Row>
								<Col lg='6'>
									<FormGroup>
										<label className='form-control-label' htmlFor='input-email'>
                      Email Address
										</label>
										<input
											className={"form-control " + (errors.email ? "is-invalid" : "")}
											defaultValue={
												currentUser.learnermaster_email ? currentUser.learnermaster_email : ""
											}
											id='input-email'
											placeholder='Email Address'
											type='email'
											disabled
											{...register("email")}
										/>
										<div className='invalid-feedback'>{errors.email?.message}</div>
									</FormGroup>
								</Col>
								<Col lg='6'>
									<FormGroup>
										<label className='form-control-label' htmlFor='input-mobileNo'>
                      Mobile No.
										</label>
										<input
											className={"form-control " + (errors.mobileno ? "is-invalid" : "")}
											defaultValue={
												currentUser.learnermaster_mobile ? currentUser.learnermaster_mobile : ""
											}
											id='input-mobileNo'
											placeholder='Mobile No.'
											type='text'
											{...register("mobileno")}
										/>
										<div className='invalid-feedback'>{errors.mobileno?.message}</div>
									</FormGroup>
								</Col>

								<Col md='12' xl='6'>
									<FormGroup>
										<label className='form-control-label' htmlFor='input-education'>
                      Education Qualification
										</label>
										<select
											defaultValue={
												currentUser.learnermaster_qualificationlevel
													? currentUser.learnermaster_qualificationlevel
													: ""
											}
											className={"form-control " + (errors.education ? "is-invalid" : "")}
											{...register("education")}
											id='input-education'
										>
											<option key='' value='' disabled>
                        Please Select Your Education Qualification
											</option>
											{QUALIFICATOIN_LEVEL_OPTION.map((option) => (
												<option key={option.value} value={option.value}>
													{option.label}
												</option>
											))}
										</select>
										<div className='invalid-feedback'>{errors.education?.message}</div>
									</FormGroup>
								</Col>

								<Col md='12' xl='6'>
									<FormGroup>
										<label className='form-control-label' htmlFor='input-designation'>
                      Designation
										</label>
										<input
											className={"form-control " + (errors.designation ? "is-invalid" : "")}
											id='input-designation'
											defaultValue={
												currentUser.learnermaster_designation
													? currentUser.learnermaster_designation
													: ""
											}
											placeholder='Designation'
											type='text'
											{...register("designation")}
										/>
										<div className='invalid-feedback'>{errors.designation?.message}</div>
									</FormGroup>
								</Col>
							</Row>

							<div className='btn-container flex-wrap mt-4 mb-4 d-flex align-items-center justify-content-end'>
								<Button className='' color='default' onClick={() => navigate(-1)} type='button'>
									<span>Cancel</span>
								</Button>
								<Button className='btn-gradient' type='submit' disabled={loading}>
									{loading && <span className='spinner-border spinner-border-sm'></span>}
									<span>Save</span>
								</Button>
							</div>

							{message && (
								<div className='form-group'>
									<div className='alert alert-danger' role='alert' dangerouslySetInnerHTML={{ __html: message }}>
									</div>
								</div>
							)}
						</Form>
					</CardBody>
				</Card>
			</Container>
		</>
	);
}

export default ProfileEdit;
