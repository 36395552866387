import {
	GET_NOTIFICATION,
	GET_NOTIFICATION_SUCCESS,
	GET_NOTIFICATION_FAIL,
} from "../actions/types";


const initialState = { notifications: null, isGetNotificationLoading: false};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
	case GET_NOTIFICATION:
		return {
			...state,
			isGetNotificationLoading: true,
		};
	case GET_NOTIFICATION_SUCCESS:
		return {
			...state,
			notifications: payload.page != 0 ?[...state.notifications, ...payload.notifications]: payload.notifications,
			isGetNotificationLoading: false,
		};
	case GET_NOTIFICATION_FAIL:
		return {
			...state,
			notifications: null,
			isGetNotificationLoading: false,
		};
	
	default:
		return state;
	}
}
