import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Modal, Card, CardBody, CardHeader, Row, Col } from "reactstrap";

import { useSelector } from "react-redux";
import moment from "moment";

import { ReactComponent as SuccessIcon } from "assets/img/Course/registration/success.svg";

function ModalSuccess() {
	const [loading, setLoading] = useState(false);
	const [modalChange, setModalChange] = useState(true);

	const courseRegisterData = useSelector((state) => state.courseRegisterSlice);

	const courseData = courseRegisterData.course;
	const courserunData = courseRegisterData.courserun;
	let navigate = useNavigate();

	useEffect(() => {
		if (
			Object.keys(courseRegisterData.course).length === 0 ||
      Object.keys(courseRegisterData.courserun).length === 0
		) {
			navigate("/course");
		}
	}, []);

	return (
		<>
			<Modal
				className='modal-dialog-centered registration_modal'
				isOpen={modalChange}
			>
				{Object.keys(courseRegisterData.course).length !== 0 &&
          Object.keys(courseRegisterData.courserun).length !== 0 && (
					<div className='modal-body'>
						<div className='title-container text-center'>
							<SuccessIcon />
							<h2 className='pt-2'>
                  Congratulations! You have successfully signed up for the course below:
							</h2>
						</div>
						<Card>
							<CardHeader>
								{courseData.logos.map((logo, i) => (
									<img
										key={i}
										className='m-1 w-40px display_ini'
										alt={courseData.course_title}
										src={logo.course_logo_image}
									/>
								))}
								<h5 className='h3 mb-0'>{courseData.course_title}</h5>
							</CardHeader>
							<CardBody>
								<div className='info-wrap'>
									<div className='title'>
										<b>Course ID</b>
									</div>
									<div className=''>{courseData.course_ref}</div>
								</div>
								<div className='info-wrap'>
									<div className='title'>
										<b>Location</b>
									</div>
									<div className=''>{courserunData.venue.venue_address}</div>
								</div>
								<div className='info-wrap'>
									<div className='title'>
										<b>Learning Mode</b>
									</div>
									<div className=''>{courserunData.courserun_modeoftraining}</div>
								</div>
								<div className='info-wrap'>
									<div className='title'>
										<b>Learning Date</b>
									</div>
									<div className=''>
										{moment(courserunData.courserun_course_from).format("DD MMM YYYY")} -{" "}
										{moment(courserunData.courserun_course_to).format("DD MMM YYYY")}
									</div>
								</div>
								<div className='info-wrap'>
									<div className='title'>
										<b>Sessions</b>
									</div>
									<div className='mt-2 mb-2'>
										<Row className="mx-1">
											<Col style={{ borderWidth: 1, borderStyle: "solid", borderColor: "rgb(204,204,204)", padding: 5 }}>
												<span>Date</span>
											</Col>
											<Col style={{ borderWidth: 1, borderStyle: "solid", borderColor: "rgb(204,204,204)", padding: 5 }}>
												<span>Time</span>
											</Col>
										</Row>
										{courserunData.csessions.map((session, i) => (
											<Row key={i} className="mx-1">
												<Col style={{ borderWidth: 1, borderStyle: "solid", borderColor: "rgb(204,204,204)", padding: 5 }}>
													<span>{moment(session.csession_start_date).format("DD MMM YYYY, ddd")} -{" "}
														{moment(session.csession_end_date).format("DD MMM YYYY, ddd")}</span>
												</Col>
												<Col style={{ borderWidth: 1, borderStyle: "solid", borderColor: "rgb(204,204,204)", padding: 5 }}>
													<span>{session.csession_start_time + " - " + session.csession_end_time}</span>
												</Col>
											</Row>
											
										))}
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				)}

				<div className='modal-footer justify-content-center'>
					<Link to='/course' className='btn btn-gradient'>
            Close
					</Link>
				</div>
			</Modal>
		</>
	);
}

export default ModalSuccess;
