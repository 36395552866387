import React from "react";

import { useDispatch } from "react-redux";
import HomeBottomBanner from "views/pages/home/components/HomeBottomBanner";
import HomeBannerCarousel from "./components/HomeBannerCarousel";
import HomeBundleListing from "./components/HomeBundleListing";
import HomeReviews from "./components/HomeReviews";

function Dashboard() {
	const dispatch = useDispatch();

	return (
		<div className="home-dashboard-container">
			<HomeBannerCarousel />
			<HomeBundleListing />
			<HomeReviews />
			<HomeBottomBanner />
		</div>
	);
}

export default Dashboard;
