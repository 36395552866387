import { useWindow } from "hooks/UseWindow";
import React from "react";
import { Link } from "react-router-dom";
import defaultCourse from "assets/img/default_course.jpg";

// reactstrap components
import { Card, CardBody, Row, Col, Button } from "reactstrap";

function AvailableCourseCard({ course }) {
	const { width } = useWindow();

	return (
		!course.has_registered && (
			<Col className='available-course-card p-2' xl='3' lg='3' md='4' sm='6' xs="12">
				<Card className={`m-0  ${course.course_banner_image ? "completed-card cursor-pointer" : "disabled-course-card"}`}>
					{course.course_banner_image && 
						<Link target={"_blank"}  className='text-dark' to={course.fca_seo_url}>
							<CardBody style={{ padding: 0, position:"relative" }}>
								<Row className="mb-3 mx-0" style={{ overflow: "hidden" }}>
									<img
										src={course.course_banner_image || defaultCourse}
										width='100%'
										className={"completed_list_banner_image"}
									/>
								</Row>
								<Row className='justify-content-between mx-3'>
									<Col md='auto' xl='auto' xs='auto' className="px-0">
										{course.logos.map((logo, i) => (
											<img
												key={i}
												className='mr-1 h-20px display_ini'
												alt={course.course_title}
												src={logo.course_logo_image}
											/>
										))}
									</Col>
								</Row>

								{course.categories && course.categories.length > 0 && (
									<Row className='pt-2 align-items-center mx-3'>
										<Col className='col-12 px-0'>
											<Row className='align-items-center mx-0'>
												<Col xl='10' md='10' xs='7' className="px-0">
													<Row className="mx-0">
														{course.categories ? course.categories.map((category, i) => {
															return (
																<Col className='col-auto py-0 pr-1 pl-0' key={i}>
																	<span
																		style={{
																			backgroundColor: category.category_color,
																		}}
																		className='small-tag text-white'>
																		{category.category_title}
																	</span>
																</Col>
															);
											  	})
															:
															""}
													</Row>
												</Col>
											</Row>
										</Col>
									</Row>
						    	)
								}
								<Row className='align-items-center pt-2 pb-3 mx-3'>
									<Col className='col-auto px-0'>
										<p className="fs18 font-weight-bold text-fca-dark font_2">{course.course_title}</p>
									</Col>
								</Row>
								{/* {course.has_registered && (
								<Row>
									<Col className='col-auto pt-2 text-primary'>
										<p>You already registered this course.</p>
									</Col>
								</Row>
							)} */}
							</CardBody>
						</Link>
					}
					{!course.course_banner_image && 
						<CardBody className={`${course.course_banner_image ? "" : "disabled-course-card"}`} style={{ padding: 0, position:"relative"}}>
							<Row className="mb-3 mx-0" style={{ overflow: "hidden" }}>
								<img
									src={course.course_banner_image || defaultCourse}
									width='100%'
									className={"completed_list_banner_image"}
								/>
							</Row>
							<Row className='justify-content-between mx-3'>
								<Col md='auto' xl='auto' xs='auto' className="px-0">
									{course.logos.map((logo, i) => (
										<img
											key={i}
											className='mr-1 h-20px display_ini'
											alt={course.course_title}
											src={logo.course_logo_image}
										/>
									))}
								</Col>
							</Row>

							{course.categories && course.categories.length > 0 && (
								<Row className='pt-2 align-items-center mx-3'>
									<Col className='col-12 px-0'>
										<Row className='align-items-center mx-0'>
											<Col xl='10' md='10' xs='7' className="px-0">
												<Row className="mx-0">
													{course.categories ? course.categories.map((category, i) => {
														return (
															<Col className='col-auto py-0 pr-1 pl-0' key={i}>
																<span
																	style={{
																		backgroundColor: category.category_color,
																	}}
																	className='small-tag text-white'>
																	{category.category_title}
																</span>
															</Col>
														);
													})
														:
														""}
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
							)
							}
							<Row className='align-items-center pt-2 pb-3 mx-3'>
								<Col className='col-auto px-0'>
									<p className="fs18 font-weight-bold text-fca-dark font_2">{course.course_title}</p>
								</Col>
							</Row>
							{/* {course.has_registered && (
							<Row>
								<Col className='col-auto pt-2 text-primary'>
									<p>You already registered this course.</p>
								</Col>
							</Row>
						)} */}
						</CardBody>
					}
					
				</Card>
			</Col>
		)
	);
}

export default AvailableCourseCard;
