import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import * as yup from "yup";
import { yupHelper } from "utilities/yupHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import {
	Button,
	FormGroup,
	Col,
	Row,
	Modal,
	Form,
} from "reactstrap";
import { NATIONALITY_OPTIONS, QUALIFICATOIN_LEVEL_OPTION } from "../../../../utilities/constant";
import { useDispatch, useSelector } from "react-redux";
import { saveRegisterForm } from "reducers/courseRegisterSlice";
import ReactDatetime from "react-datetime";

function ModalRegistration() {
	const [modalChange, setModalChange] = useState(true);
	const [loading, setLoading] = useState(false);
	const [message, setmessage] = useState("");

	const dispatch = useDispatch();
	let navigate = useNavigate();

	const { user: userData } = useSelector((state) => state.auth);
	const currentUser = userData.user;

	const courseRegisterData = useSelector((state) => state.courseRegisterSlice);
	const courseData = courseRegisterData.course;

	// register form
	const schema = yup.object({
		sponsoredCompany: yup.string().required(),
		registerCompanySme: yupHelper.requiredIfSponsoredCompany(),
		registerCompanyUenNo: yupHelper.requiredIfSponsoredCompany(),
		registerCompanyName: yupHelper.requiredIfSponsoredCompany(),
		registerCompanyBillingAddress: yupHelper.requiredIfSponsoredCompany(),
		registerCompanyPostalCode: yupHelper.requiredIfSponsoredCompany(),
		registerCompanyCountry: yupHelper.requiredIfSponsoredCompany(),
		registerCompanyContactPerson: yupHelper.requiredIfSponsoredCompany(),
		registerCompanyContactNo: yupHelper.requiredIfSponsoredCompany(),
		registerCompanyEmail: yupHelper.requiredIfSponsoredCompany(),
		name: yupHelper
			.fullname()
			.default(currentUser.learnermaster_name ? currentUser.learnermaster_name : ""),
		nric: yupHelper
			.nric()
			.default(currentUser.learnermaster_nric ? currentUser.learnermaster_nric : ""),
		dob: yup
			.string()
			.required()
			.default(
				currentUser.learnermaster_dob
					? moment(currentUser.learnermaster_dob).format("YYYY-MM-DD")
					: "",
			),
		nationality: yup
			.string()
			.required()
			.default(currentUser.learnermaster_nationality ? currentUser.learnermaster_nationality : ""),
		email: yup
			.string()
			.email()
			.required()
			.default(currentUser.learnermaster_email ? currentUser.learnermaster_email : ""),
		mobileno: yupHelper
			.mobile()
			.default(currentUser.learnermaster_mobile ? currentUser.learnermaster_mobile : ""),
		education: yup
			.string()
			.required()
			.default(
				currentUser.learnermaster_qualificationlevel
					? currentUser.learnermaster_qualificationlevel
					: "",
			),
		designation: yup
			.string()
			.required()
			.default(currentUser.learnermaster_designation ? currentUser.learnermaster_designation : ""),
		laptopLoan: yup.string().required(),
	});

	const {
		control,
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const showCompany = watch("sponsoredCompany");

	const handleRegister = (data) => {
		setLoading(true);

		dispatch(saveRegisterForm(data));
		navigate("/course/payment");
	};

	return (
		<>
			<Modal
				className='modal-dialog-centered registration_modal'
				isOpen={modalChange}
			>
				<div className='modal-header'>
					<h5 className='modal-title' id='exampleModalLabel'>
						{courseData.course_title}
					</h5>
					<button
						aria-label='Close'
						className='close'
						data-dismiss='modal'
						type='button'
						// onClick={() => toggleRegistrationModal(false)}
					>
						<span aria-hidden={true}>×</span>
					</button>
				</div>
				<div className='modal-body'>
					<div>
						<Form role='form' onSubmit={handleSubmit(handleRegister)}>
							<FormGroup>
								<Row>
									<Col md='12' xl='12'>
										<FormGroup>
											<label className='form-control-label' htmlFor='sponsored_company'>
                        Sponsored by Company
											</label>
											<select
												id='sponsored_company'
												defaultValue='No'
												className={"form-control " + (errors.sponsoredCompany ? "is-invalid" : "")}
												{...register("sponsoredCompany")}
											>
												<option value='Yes'>Yes</option>
												<option value='No'>No</option>
											</select>
											<div className='invalid-feedback'>{errors.sponsoredCompany?.message}</div>
										</FormGroup>
									</Col>
									{showCompany === "Yes" && (
										<>
											<Col md='12' xl='12'>
												<h4 className='theme_text_color register_title'>Company Information</h4>
											</Col>

											<Col md='12' xl='12'>
												<FormGroup>
													<label className='form-control-label' htmlFor='register_company_sme'>
                            Is your company an SME?
													</label>
													<select
														defaultValue=''
														className={
															"form-control " + (errors.registerCompanySme ? "is-invalid" : "")
														}
														{...register("registerCompanySme")}
														id='register_company_sme'
														type='select'
													>
														<option>Yes</option>
														<option>No</option>
													</select>
													<div className='invalid-feedback'>
														{errors.registerCompanySme?.message}
													</div>
													<p>
                            To qualify as an SME, your company must have at least 30% local
                            shareholding. In addition, your company's annual turnover must be less
                            than S$100 million OR have an employment size of not more than 200
                            workers.
													</p>
												</FormGroup>
											</Col>

											<Col md='12' xl='4'>
												<FormGroup>
													<label className='form-control-label' htmlFor='register_uen_no'>
                            Company UEN No.
													</label>
													<input
														id='register_uen_no'
														placeholder='Comapny UEN No.'
														type='text'
														defaultValue=''
														className={
															"form-control " + (errors.registerCompanyUenNo ? "is-invalid" : "")
														}
														{...register("registerCompanyUenNo")}
													/>
													<div className='invalid-feedback'>
														{errors.registerCompanyUenNo?.message}
													</div>
												</FormGroup>
											</Col>
											<Col md='12' xl='8'>
												<FormGroup>
													<label className='form-control-label' htmlFor='register_company_name'>
                            Company Name
													</label>
													<input
														id='register_company_name'
														placeholder='Company Name'
														type='text'
														defaultValue=''
														className={
															"form-control " + (errors.registerCompanyName ? "is-invalid" : "")
														}
														{...register("registerCompanyName")}
													/>
													<div className='invalid-feedback'>
														{errors.registerCompanyName?.message}
													</div>
												</FormGroup>
											</Col>

											<Col md='12' xl='6'>
												<FormGroup>
													<label className='form-control-label' htmlFor='register_billing_address'>
                            Billing address
													</label>
													<input
														id='register_billing_address'
														placeholder='Billing Address'
														type='text'
														defaultValue=''
														className={
															"form-control " +
                              (errors.registerCompanyBillingAddress ? "is-invalid" : "")
														}
														{...register("registerCompanyBillingAddress")}
													/>
													<div className='invalid-feedback'>
														{errors.registerCompanyBillingAddress?.message}
													</div>
												</FormGroup>
											</Col>
											<Col md='12' xl='3'>
												<FormGroup>
													<label className='form-control-label' htmlFor='register_postcode'>
                            Postal Code
													</label>
													<input
														id='register_postcode'
														placeholder='Postal Code'
														type='text'
														defaultValue=''
														className={
															"form-control " +
                              (errors.registerCompanyPostalCode ? "is-invalid" : "")
														}
														{...register("registerCompanyPostalCode")}
													/>
													<div className='invalid-feedback'>
														{errors.registerCompanyPostalCode?.message}
													</div>
												</FormGroup>
											</Col>
											<Col md='12' xl='3'>
												<FormGroup>
													<label className='form-control-label' htmlFor='register_country'>
                            Country
													</label>
													<select
														id='register_country'
														defaultValue=''
														className={
															"form-control " + (errors.registerCompanyCountry ? "is-invalid" : "")
														}
														{...register("registerCompanyCountry")}
													>
														<option>Singapore</option>
													</select>
													<div className='invalid-feedback'>
														{errors.registerCompanyCountry?.message}
													</div>
												</FormGroup>
											</Col>

											<Col md='12' xl='4'>
												<FormGroup>
													<label
														className='form-control-label'
														htmlFor='register_company_contact_person'
													>
                            Name of Company Contact Person
													</label>
													<input
														id='register_company_contact_person'
														placeholder='Name'
														type='text'
														defaultValue=''
														className={
															"form-control " +
                              (errors.registerCompanyContactPerson ? "is-invalid" : "")
														}
														{...register("registerCompanyContactPerson")}
													/>
													<div className='invalid-feedback'>
														{errors.registerCompanyContactPerson?.message}
													</div>
												</FormGroup>
											</Col>
											<Col md='12' xl='4'>
												<FormGroup>
													<label
														className='form-control-label'
														htmlFor='register_compony_contact_no'
													>
                            Contact No
													</label>
													<input
														id='register_compony_contact_no'
														placeholder='Contact No.'
														type='text'
														defaultValue=''
														className={
															"form-control " +
                              (errors.registerCompanyContactNo ? "is-invalid" : "")
														}
														{...register("registerCompanyContactNo")}
													/>
													<div className='invalid-feedback'>
														{errors.registerCompanyContactNo?.message}
													</div>
												</FormGroup>
											</Col>
											<Col md='12' xl='4'>
												<FormGroup>
													<label className='form-control-label' htmlFor='register_compony_email'>
                            Email
													</label>
													<input
														id='register_compony_email'
														placeholder='Email'
														type='text'
														defaultValue=''
														className={
															"form-control " + (errors.registerCompanyEmail ? "is-invalid" : "")
														}
														{...register("registerCompanyEmail")}
													/>
													<div className='invalid-feedback'>
														{errors.registerCompanyEmail?.message}
													</div>
												</FormGroup>
											</Col>
										</>
									)}
									<Col md='12' xl='12'>
										<h4 className='theme_text_color register_title'>Learner Information</h4>
									</Col>

									<Col md='12' xl='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-fullname'>
                        Full Name (As Shown in NRIC)
											</label>
											<input
												className={"form-control " + (errors.name ? "is-invalid" : "")}
												defaultValue={
													currentUser.learnermaster_name ? currentUser.learnermaster_name : ""
												}
												disabled={currentUser.learnermaster_name ? true : false}
												id='input-fullname'
												placeholder='Full Name'
												type='text'
												{...register("name")}
											/>
											<div className='invalid-feedback'>{errors.name?.message}</div>
										</FormGroup>
									</Col>
									<Col md='12' xl='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-nric'>
                        NRIC or ID No.
											</label>
											<input
												className={"form-control " + (errors.nric ? "is-invalid" : "")}
												id='input-nric'
												defaultValue={
													currentUser.learnermaster_nric ? currentUser.learnermaster_nric : ""
												}
												disabled={currentUser.learnermaster_nric ? true : false}
												placeholder='NRIC or ID No.'
												type='text'
												{...register("nric")}
											/>
											<div className='invalid-feedback'>{errors.nric?.message}</div>
										</FormGroup>
									</Col>

									<Col md='12' xl='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-dob'>
                        Date of Birth
											</label>
											{currentUser.learnermaster_dob ? (
												<input
													className={"form-control " + (errors.dob ? "is-invalid" : "")}
													id='input-dob'
													defaultValue={
														currentUser.learnermaster_dob
															? moment(currentUser.learnermaster_dob).format("YYYY-MM-DD")
															: ""
													}
													disabled
													type='text'
													{...register("dob")}
												/>
											) : (
												<Controller
													name='dob'
													control={control}
													defaultValue={
														currentUser.learnermaster_dob
															? moment(currentUser.learnermaster_dob).format("YYYY-MM-DD")
															: ""
													}
													render={({ field: { onChange, value }, fieldState: { error } }) => (
														<ReactDatetime
															onChange={onChange}
															value={value}
															className={error ? "needs-validation" : ""}
															inputProps={{
																id: "input-dob",
																placeholder: "Date of Birth",
															}}
															disabled
															dateFormat='YYYY-MM-DD'
															timeFormat={false}
														/>
													)}
												/>
											)}

											<div className={"invalid-feedback " + (errors.dob ? "d-block" : "d-none")}>
												{errors.dob?.message}
											</div>
										</FormGroup>
									</Col>
									<Col md='12' xl='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-nationality'>
                        Nationality
											</label>
											<select
												defaultValue={
													currentUser.learnermaster_nationality
														? currentUser.learnermaster_nationality
														: ""
												}
												disabled={currentUser.learnermaster_nationality ? true : false}
												className={"form-control " + (errors.nationality ? "is-invalid" : "")}
												{...register("nationality")}
												id='input-nationality'
											>
												<option key='' value='' disabled>
                          Please Select Your Nationality
												</option>
												{NATIONALITY_OPTIONS.map((option) => (
													<option key={option.value} value={option.value}>
														{option.label}
													</option>
												))}
											</select>
											<div className='invalid-feedback'>{errors.nationality?.message}</div>
										</FormGroup>
									</Col>

									<Col md='12' xl='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-email'>
                        Email Address
											</label>
											<input
												className={"form-control " + (errors.email ? "is-invalid" : "")}
												id='input-email'
												placeholder='Email Address'
												type='email'
												defaultValue={
													currentUser.learnermaster_email ? currentUser.learnermaster_email : ""
												}
												disabled={currentUser.learnermaster_email ? true : false}
												{...register("email")}
											/>
											<div className='invalid-feedback'>{errors.email?.message}</div>
										</FormGroup>
									</Col>
									<Col md='12' xl='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-mobileNo'>
                        Mobile No.
											</label>
											<input
												className={"form-control " + (errors.mobileno ? "is-invalid" : "")}
												id='input-mobileNo'
												placeholder='Mobile No.'
												type='text'
												disabled={currentUser.learnermaster_mobile ? true : false}
												defaultValue={
													currentUser.learnermaster_mobile ? currentUser.learnermaster_mobile : ""
												}
												{...register("mobileno")}
											/>
											<div className='invalid-feedback'>{errors.mobileno?.message}</div>
										</FormGroup>
									</Col>
									<Col md='12' xl='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-education'>
                        Education Qualification
											</label>
											<select
												defaultValue={
													currentUser.learnermaster_qualificationlevel
														? currentUser.learnermaster_qualificationlevel
														: ""
												}
												disabled={currentUser.learnermaster_qualificationlevel ? true : false}
												className={"form-control " + (errors.education ? "is-invalid" : "")}
												{...register("education")}
												id='input-education'
											>
												<option key='' value='' disabled>
                          Please Select Your Education Qualification
												</option>
												{QUALIFICATOIN_LEVEL_OPTION.map((option) => (
													<option key={option.value} value={option.value}>
														{option.label}
													</option>
												))}
											</select>
											<div className='invalid-feedback'>{errors.education?.message}</div>
										</FormGroup>
									</Col>

									<Col md='12' xl='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-designation'>
                        Designation
											</label>
											<input
												className={"form-control " + (errors.designation ? "is-invalid" : "")}
												id='input-designation'
												defaultValue={
													currentUser.learnermaster_designation
														? currentUser.learnermaster_designation
														: ""
												}
												disabled={currentUser.learnermaster_designation ? true : false}
												placeholder='Designation'
												type='text'
												{...register("designation")}
											/>
											<div className='invalid-feedback'>{errors.designation?.message}</div>
										</FormGroup>
									</Col>

									<Col md='12' xl='6'>
										<FormGroup>
											<label className='form-control-label' htmlFor='input-laptopLoan'>
                        Laptop Loan
											</label>
											<select
												id='input-laptopLoan'
												defaultValue='No'
												className={"form-control " + (errors.laptopLoan ? "is-invalid" : "")}
												{...register("laptopLoan")}
											>
												<option value='Yes'>Yes</option>
												<option value='No'>No</option>
											</select>
											<div className='invalid-feedback'>{errors.laptopLoan?.message}</div>
										</FormGroup>
									</Col>
								</Row>
							</FormGroup>
							{message && (
								<div className='form-group'>
									<div className='alert alert-danger' role='alert'>
										{message}
									</div>
								</div>
							)}
							<div className='modal-footer justify-content-between'>
								<Link
									to='/course'
									className='btn btn-default'
								>
                  Close
								</Link>
								<Link
									to={"/course/course-info/" + courseData.course_id}
									className='btn btn-primary mr-auto'
								>
                  Back
								</Link>
								<Button className='' color='success' type='submit' disabled={loading}>
									{loading && <span className='spinner-border spinner-border-sm'></span>}
									<span>Sign Up</span>
								</Button>
							</div>
						</Form>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default ModalRegistration;
