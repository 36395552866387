import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import classnames from "classnames";
import * as yup from "yup";
import { yupHelper } from "utilities/yupHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
	Button,
	FormGroup,
	Form,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Modal,
} from "reactstrap";

import AuthService from "../../../../services/auth-api";
import { useDispatch } from "react-redux";
import { resetEmail } from "actions/auth";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { getErrorMessage } from "utilities/error";

const schema = yup.object({
	email: yupHelper.email(),
});

function ModalChangeEmail({ modalChange, otp, setModalChange }) {
	const [loading, setLoading] = useState(false);
	const [message, setmessage] = useState("");

	let navigate = useNavigate();
	const dispatch = useDispatch();
	const MySwal = withReactContent(Swal);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const handleChangeEmail = (data) => {
		setLoading(true);
		data.otp = otp;
		dispatch(resetEmail(data))
			.then(() => {
				MySwal.fire({
					title: "Congratulations!",
					html: <p>Change Email Successfully.</p>,
					icon: "success",
				}).then(() => {
					navigate("/profile");
				});
				
			})
			.catch((error) => {
				setLoading(false);
				setmessage(getErrorMessage(error));
			});
	};



	return (
		<>
			<Modal className='modal-dialog-centered' isOpen={modalChange}>
				<div>
					<button
						aria-label='Close'
						className='close p-2'
						data-dismiss='modal'
						type='button'
						onClick={() => setModalChange(false)}
					>
						<span aria-hidden={true}>×</span>
					</button>
				</div>

				<div className='modal-header pt-3'>
					<div className='modal-header-wrap text-center'>
						<h2>{"Change Email"}</h2>
					</div>
				</div>
				<div className='modal-body'>
					<Form role='form' onSubmit={handleSubmit(handleChangeEmail)}>
						<FormGroup>
							<label className='form-control-label' htmlFor='input-password'>
								{"Email"}
							</label>
							<InputGroup
								className={
									"input-group-merge " + (errors.email ? "needs-validation is-invalid" : "")
								}
							>
								<input
									className={"form-control " + (errors.email ? "is-invalid" : "")}
									id='input-email'
									placeholder='Email Address'
									type='email'
									{...register("email")}
								/>
							</InputGroup>
							<div className='invalid-feedback'>{errors.email?.message}</div>
						</FormGroup>
				
						<div className='justify-content-center mb-4'>
							<Button className='mt-4 w-100 btn-gradient' type='submit' disabled={loading}>
								{loading && <span className='spinner-border spinner-border-sm'></span>}
								<span>Save</span>
							</Button>
						</div>

						{message && (
							<div className='form-group'>
								<div
									className={classnames(
										"alert",
										message === "OTP Sent Success" ? "alert-success" : "alert-danger",
									)}
									role='alert'
								>
									{message}
								</div>
							</div>
						)}
					</Form>
				</div>
			</Modal>
		</>
	);
}

export default ModalChangeEmail;
