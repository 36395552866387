import React from "react";
import { Modal } from "reactstrap";
import styles from "../Profile.module.scss";
import { useWindow } from "hooks/UseWindow";
import EarthAnimation from "views/pages/learner/profile/components/EarthAnimation";
import QRCode from "react-qr-code";
import { ReactComponent as PlaneIcon } from "assets/img/Events/icon_plane.svg";

function ModalProfileQR({ isOpen = false, onClose = () => {}, currentUser = null }) {
	const { width } = useWindow();

	return (
		<>
			<Modal
				className={"modal-dialog-centered profile_qr_modal"}
				isOpen={isOpen}
			>
				<div className={`${styles.profile_qr_modal_close_container}`} onClick={onClose}>
					<i className={`fa fa-times ${styles.profile_qr_modal_close_icon}`} aria-hidden='true'></i>
				</div>

				<div className={`${styles.profile_qr_modal_header}`}>
					<p className={`${styles.profile_qr_modal_header_title}`}>
						{"FCA Alumni"}
					</p>
				</div>
				<div className='modal-body pt-0'>
					<div>
						<EarthAnimation />
						<div className={styles.qrContainer}>
							<p className={`${styles.frame_text}`}>
								{"Place QR inside the frame to scan"}
							</p>
							<p className={`${styles.name_text}`}>
								{currentUser.learnermaster_name}
							</p>
							<div style={{ height: "auto", margin: "0 auto", width: "40%", marginBottom: "1rem" }}>
								<QRCode
									size={256}
									style={{ height: "auto", maxWidth: "100%", width: "100%" }}
									value={currentUser.member_code}
									viewBox={"0 0 256 256"}
								/>
							</div>
							<p className={`${styles.code_text}`}>
								{currentUser.member_code}
							</p>
						</div>
						<div className={styles.planeContainer}>
							<div className="d-flex flex-row w-50 align-items-center mb-2">
								<div className={styles.horizontalLine}></div>
								<PlaneIcon height={30} width={30}  style={{ marginRight: "5px" }}/>
								<div className={styles.horizontalLine}></div>
							</div>
							<p className={`${styles.attendance_text}`}>
								{"For attendance taking,"}
							</p>
							<p className={`${styles.scan_text}`}>
								{"scan this boarding pass"}
							</p>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default ModalProfileQR;
