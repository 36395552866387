import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useAuth } from "hooks/selectors/useAuth";

// reactstrap components
import { Row, Col, Button, Card, CardBody } from "reactstrap";

import { ReactComponent as BusIcon } from "assets/img/Course/upcoming/bus.svg";
import { ReactComponent as MealIcon } from "assets/img/Course/upcoming/meal.svg";
import { ReactComponent as TimeIcon } from "assets/img/Course/upcoming/date_blue.svg";
import { ReactComponent as CourseIcon } from "assets/img/Course/upcoming/course_ID_blue.svg";
import { ReactComponent as UserIcon } from "assets/img/Course/upcoming/user_blue.svg";
import { ReactComponent as PhoneIcon } from "assets/img/Course/upcoming/phone_blue.svg";
import { ReactComponent as LocationIcon } from "assets/img/Course/upcoming/location_blue.svg";
import { ReactComponent as AlmsIcon } from "assets/img/Course/upcoming/alms_blue.svg";
import defaultCourse from "assets/img/default_course.jpg";

import { ReactComponent as CertificateIcon } from "assets/img/Course/registration/success.svg";
import ModalCertificates from "views/pages/learner/course/ModalCertificates";
import { PrintCourseDate } from "../../common_function/function.js";
import { useWindow } from "hooks/UseWindow.js";

function UpcomingCoursesCard({ course, status }) {
	const [certModalChange, setCertModalChange] = useState(false);
	const VERSION = process.env.REACT_APP_APP_VERSION;
	const MySwal = withReactContent(Swal);
	const authData = useAuth();
	const userData = authData.userData.user;
	const { width } = useWindow();
	let todayDate = moment(new Date()).format("yyyy-MM-DD");
	// let todayDate = "2024-02-04";

	const handlePopup = (e, displayMSG) => {
		e.preventDefault();
		e.stopPropagation();
		MySwal.fire({
			title: "Unable to Proceed!",
			html: displayMSG,
			icon: "error",
		});
	};

	const handleSFCClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const data = {};
		const reschedule_to = course.reschedule_to;

		data.callback_url = process.env.REACT_APP_WEB_URL + "/course";
		data.course_id = reschedule_to.course.course_ref;
		data.course_fee = course.learner_sfc;
		data.courserun_id = reschedule_to.courserun_apicourserunid;
		data.courserun_start_date = reschedule_to.courserun_course_from;
		data.nric = userData.learnermaster_nric;
		data.email = userData.learnermaster_email;
		data.mobile = userData.learnermaster_mobile;
		data.tms_regform_id = course.registrationform_id_encrypt;
		data.tms_course_id = reschedule_to.courserun_course_id;
		data.tms_courserun_id = reschedule_to.courserun_id;
		data.tms_learner_id = course.learner_id;
		data.tms_learnermaster_id = userData.learnermaster_id;
		data.purpose = "Reschedule";
		
		// console.log(data);
		const json_str = btoa(JSON.stringify(data));
		// console.log(json_str);
		// setLoading(true);
		// GTMCheckoutYesSFCButton(checkoutAnalyticsData, userData);

		window.location.href = process.env.REACT_APP_API_URL + "/api/sfc_pay?encoded=" + json_str;

	};

	const getStatusTag = (
		course_reschedule_status,
		tobe_rescheduled,
		course_withdrawal_status,
		pending_assessment_date,
	) => {
		if (course_withdrawal_status == "PENDING") {
			return (
				<span className='p-2 ml-2 course-sticker pending-withdrawal'>
					{"Withdrawal Processing"}
				</span>
			);
		}
		if (course_withdrawal_status == "REJECTED") {
			return <span className='p-2 ml-2 course-sticker rejected'>{"Withdrawal Rejected"}</span>;
		}
		if (tobe_rescheduled && course_reschedule_status !== "Submitted") {
			return (
				<span className='p-2 ml-2 course-sticker pending-reschedule'>{"Reschedule Required"}</span>
			);
		}
		if (course_reschedule_status == "Submitted") {
			return (
				<span className='p-2 ml-2 course-sticker pending-reschedule'>
					{"Reschedule Processing"}
				</span>
			);
		}
		if (course_reschedule_status == "Pending SFC Cancellation" || course_reschedule_status == "Pending SFC Resubmission" || course_reschedule_status == "Pending SFC Approval") {
			return (
				<span className='p-2 ml-2 course-sticker pending-reschedule'>
					{course_reschedule_status}
				</span>
			);
		}
		if (course_reschedule_status == "Rejected") {
			return <span className='p-2 ml-2 course-sticker rejected'>{"Reschedule Rejected"}</span>;
		}
		if (course_reschedule_status == "Assessment") {
			if (pending_assessment_date) {
				return (
					<>
						<span className='p-2 ml-2 course-sticker pending-assessment'>
							{"Pending Assessment"}
						</span>
						<span className='pt-3 d-block course-date'>{pending_assessment_date}</span>
					</>
				);
			} else {
				return (
					<>
						<span className='p-2 ml-2 course-sticker pending-assessment'>
							{"Pending Assessment"}
						</span>
					</>
				);
			}
		}
	};

	return (
		<Card style={{ boxShadow: "none", marginBottom: 0 }}>
			{/* <Link to={{pathname:"/course/course-details/", state: {course: course}}} className='text-dark'> */}
			<Link
				to='/course/course-details/'
				state={{ data: course, status: status }}
				className='text-dark'>
				<CardBody style={{ padding: 0 }}>
					<div className="upcoming_course_card_container">
						<div className="upcoming_course_card_data_container">
							<Row className='justify-content-between mx-0'>
								<Col md='auto' xl='auto' xs='auto' className="px-0">
									{course.course.logos.map((logo, i) => (
										<img
											key={i}
											className='mr-1 h-30px display_ini'
											alt={course.course.course_title}
											src={logo.course_logo_image}
										/>
									))}
								</Col>
								<Col md='auto' xl='auto' xs='auto' className='text-right pr-0'>
									{getStatusTag(
										course.reschedule_status,
										course.tobe_rescheduled,
										course.withdrawal_status,
										course.pending_courserun
											? PrintCourseDate(
												course.pending_courserun.courserun_course_from,
												course.pending_courserun.courserun_course_to,
									  )
											: null,
									)}
								</Col>
							</Row>
							<Row className='pt-2 align-items-center mx-0'>
								<Col className='col-12 px-0'>
									<Row className='align-items-center mx-0'>
										<Col xl='10' md='10' xs='7' className="px-0">
											<Row className="mx-0">
												{course.course.categories ? course.course.categories.map((category, i) => {
													return (
														<Col className='col-auto py-1 pr-1 pl-0' key={i}>
															<span
																style={{
																	backgroundColor: category.category_color,
																}}
																className='tag text-white'>
																{category.category_title}
															</span>
														</Col>
													);
											  	})
													:
													""}
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
							{course.duration &&
								<Row className='align-items-center pt-1 mx-0'>
									<Col md='12' xl='12' className="px-0">
										<Row className='align-items-center mx-0'>
											<TimeIcon width={16} height={16} />
											<span className='text-fca-dark days fs15 ml-2'>{course.duration} Days of Training</span>
										</Row>
									</Col>
								</Row>
							}
							<Row className='align-items-center pt-2'>
								<Col md='12' xl='12'>
									<p className="fs22 font-weight-bold text-fca-dark font_2">{course.course.course_title}</p>
								</Col>
							</Row>
							<Row className='course_data course_detail pt-3 mx-0'>
								<Col xl='4' md='6' xs='6' className='pr-2 pb-2 pl-0 pt-0'>
									<Row className="align-items-center mx-0">
										<CourseIcon width={13} height={16} className="" />
										<label className='m-0 pl-2 font-weight-600 text-fca-dark'>
											<span>Course ID</span>
										</label>
									</Row>
									<p className='m-0 text-fca-dark'>{course?.course?.course_ref}</p>
								</Col>
								<Col xl='4' md='6' xs='6' className='pr-2 pb-2 pl-0 pt-0'>
									<Row className="align-items-center mx-0">
										<UserIcon width={14} height={16} className="" />
										<label className='m-0 pl-2 font-weight-600 text-fca-dark'>
											<span>Student Services</span>
										</label>
									</Row>
									<p className='m-0 text-fca-dark'>{course?.training_support?.user_name}</p>
								</Col>

								<Col xl='4' md='6' xs='6' className='pr-2 pb-2 pl-0 pt-0'>
									<Row className="align-items-center mx-0">
										<PhoneIcon width={13} height={16} className="" />
										<label className='m-0 pl-2 font-weight-600 text-fca-dark'>
											<span>Student Services Contact</span>
										</label>
									</Row>
									<p className='m-0 text-fca-dark'>{course?.training_support?.user_mobile}</p>
								</Col>
								
								<Col xl='4' md='6' xs='6' className='pr-2 pb-2 pl-0 pt-0'>
									<Row className="align-items-center mx-0">
										<LocationIcon width={13} height={16} className="" />
										<label className='m-0 pl-2 font-weight-600 text-fca-dark'>
											<span>Location</span>
										</label>
									</Row>
									<p className='m-0 text-fca-dark'>{course?.venue?.venue_address}</p>
								</Col>

								<Col xl='4' md='6' xs='6' className='pr-2 pb-2 pl-0 pt-0'>
								
									<Row className="align-items-center mx-0">
										<TimeIcon width={13} height={16} className="" />
										<label className='m-0 pl-2 font-weight-600 text-fca-dark'>
											<span>Dates</span>
										</label>
									</Row>
									<p className='m-0 text-fca-dark'>
										{moment(course.courserun_course_from).format("DD MMM YYYY, ddd")} -{" "}
										{moment(course.courserun_course_to).format("DD MMM YYYY, ddd")}
									</p>
								</Col>
								<Col xl='4' md='6' xs='6' className='pr-2 pb-2 pl-0 pt-0'>
									<div className='flex h-100'>
										<Col md='auto' xl='auto' xs='auto' className='p-0'>
											<img
												className='trainer-img'
												alt={course?.trainer?.trainer_name}
												src={
													course?.trainer?.trainer_image_url
														? course.trainer.trainer_image_url
														: require("assets/img/learner/user.png")
												}
												onError={({ currentTarget }) => {
													currentTarget.onerror = null; // prevents looping
													currentTarget.src = require("assets/img/learner/user.png");
												}}
											/>
										</Col>
										<Col md='8' xl='8' xs='8' className='pr-0'>
											<label className='m-0 font-weight-600 text-fca-dark'>
												<span>Trainer</span>
											</label>
											<p className='m-0 text-fca-dark'>{course?.trainer?.trainer_name}</p>
										</Col>
									</div>
								</Col>
								{course.claim_id !== null ? (
									<Col xl='8' md='8' xs='12' className='pr-2 pb-2 pl-0 pt-0'>
										<Row className="align-items-center mx-0">
											<AlmsIcon width={16} height={16} className="" />
											<label className='m-0 pl-2 font-weight-600 text-fca-dark'>
												<span>SkillsFuture Credit Claims</span>
											</label>
										</Row>
										<p className='m-0'>
											{course.sfc_claim_id}
											{/* {JSON.stringify(course.sfc_claim_id)} */}
										</p>
										<p className='m-0 text-fca-dark'>
									Status: <span style={{ fontStyle: "italic" }}>{course.claim_status}</span>
										</p>
									</Col>
								) : null}

							</Row>
							<div className=''>
								<hr className='mt-1 mb-3'></hr>
								{course.attendance_status != "Pending Assessment" && (
									<Row className="mx-0">
										{status == "upcoming" && course.reschedule_status != "Submitted" && course.withdrawal_status != "PENDING" && (
											<div>
												{!course.cannot_select_food_reason ? (
													<Link
														to='/course/meal-selection'
														state={{
															learner_id: course.learner_id,
															course: course,
															status: status,
														}}
														className='btn btn-outline-green mb-2 ml-0'>
														<MealIcon />
														<span>Meal Selection</span>
														<i className='fa fa-angle-right' aria-hidden='true'></i>
													</Link>
												) : (
													<Button
														className='btn-outline-green opacity-6 mb-2 ml-0'
														onClick={(e) => handlePopup(e, course.cannot_select_food_reason)}>
														<MealIcon />
														<span>Meal Selection</span>
														<i className='fa fa-angle-right' aria-hidden='true'></i>
													</Button>
												)}

												{course.reschedule_status == "Pending SFC Resubmission" && (
													<Button type="button" onClick={(e) => handleSFCClick(e)} className='btn btn-outline-green ml-auto mb-2 ml-0'>
														<i className='fa fa-history' aria-hidden='true'></i>
														<span>Resubmit SFC</span>
														<i className='fa fa-angle-right' aria-hidden='true'></i>
													</Button>
												)}
												{course.courserun_course_to === todayDate &&
													<Link
														to='/assessment/download'
														state={{
															learner_id: course.learner_id,
															course: course,
															status: status,
														}}
														className='btn btn-outline-green mb-2 ml-0'>
														<span>Download Assessment</span>
														<i className='fa fa-angle-right' aria-hidden='true'></i>
													</Link>
												}
												{course.courserun_course_to === todayDate &&
													<Link
														to='/assessment/upload'
														state={{
															learner_id: course.learner_id,
															course: course,
															status: status,
														}}
														className='btn btn-outline-green mb-2 ml-0'>
														<span>Upload Assessment</span>
														<i className='fa fa-angle-right' aria-hidden='true'></i>
													</Link>
												}

												
											
												{/* <Button className='btn btn-gradient p-3 mb-2 ml-0'>
													<span>Read More</span>
												</Button> */}
											</div>
										)}
										{status == "completed" && course.cert_file_url && (
											<div>
												<ModalCertificates
													course_title={course.course_title}
													cert_file_url={course.cert_file_url}
													showModal={certModalChange}
													setShowModal={setCertModalChange}
												/>
												<Button
													className='btn btn-outline-green cert-icon mb-2 ml-0'
													onClick={(e) => {
														e.stopPropagation();
														e.preventDefault();
														setCertModalChange(true);
													}}>
													<CertificateIcon />
													<span>View Certificate</span>
													<i className='fa fa-angle-right' aria-hidden='true'></i>
												</Button>
												<Button className='btn btn-gradient p-3 mb-2 ml-0'>
													<span>Read More</span>
												</Button>
											</div>
										)}
										
									</Row>
								)}
								<Row className=''>
									<Col size="12">
										<div className='flex w-100'>
											{(status == "upcoming" || status == "to_be_reschedule") && !course.cannot_reschedule_reason ? (
												<div className='btn-wrap' style={width<=767 ? {width: "100%"} : {}}>
													<Link
														to='/course/reschedule_description'
														state={{
															data: {
																learner_id: course.learner_id,
																learner_sfc: course.learner_sfc,
																courserun_id: course.courserun_id,
																course_title: course.course.course_title,
																course: course,
																status: status,
															},
														}}
														className='btn btn-link-green h-100'>
														<i className='fa fa-history' aria-hidden='true'></i>
														<span>Reschedule</span>
													</Link>
												</div>
											
											): (
												<div className='btn-wrap' style={width<=767 ? {width: "100%"} : {}}>
													<Button
														type='link-green'
														className='btn-link-green h-100 opacity-6 shadow-none'
														onClick={() => handlePopup(course.cannot_reschedule_reason)}>
														<i className='fa fa-history' aria-hidden='true'></i>
														<span>Reschedule Request</span>
													</Button>
												</div>
											)}
											{(status == "upcoming" || status == "to_be_reschedule") && !course.cannot_withdraw_reason ? (
												<div className='btn-wrap' style={width<=767 ? {width: "100%"} : {}}>
													<Link
														to='/course/withdrawal_description'
														state={{
															data: {
																learner_id: course.learner_id,
																courserun_id: course.courserun_id,
																course_title: course.course.course_title,
																course: course,
																status: status,
															},
														}}
														className='btn btn-link-green h-100'>
														<i className='fa fa-undo' aria-hidden='true'></i>
														<span>Withdrawal</span>
													</Link>
												</div>
											) : (
												<div className='btn-wrap' style={width<=767 ? {width: "100%"} : {}}>
													<Button
														className='btn-link-green h-100 opacity-6 shadow-none'
														onClick={() => handlePopup(course.cannot_withdraw_reason)}>
														<i className='fa fa-undo' aria-hidden='true'></i>
														<span>Withdrawal Request</span>
													</Button>
												</div>
											)}
										</div>
									</Col>
								</Row>
							</div>
						</div>
						<div className="upcoming_course_card_banner_container">
							<img
								src={course.course.course_banner_image || defaultCourse}
								width='100%'
								className={"upcoming_course_banner_image"}
							/>
						</div>
					</div>
				</CardBody>
			</Link>
		</Card>
	);
}

export default UpcomingCoursesCard;
