import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate ,useLocation, Link} from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// reactstrap components
import { Button, Card, CardBody, FormGroup, Form, Container, Row, Col,Modal } from "reactstrap";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import { clearMessage } from "actions/message";

import generalApi from "services/general-api";
import { ReactComponent as TickSvg } from "assets/img/success/tick.svg";
import { ReactComponent as CourseSvg } from "assets/img/endorse/icon_course.svg";


function Coursedetail() {
	const { user: userData } = useSelector((state) => state.auth);
	const currentUser = userData.user;
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const { message } = useSelector((state) => state.message);
	const dispatch = useDispatch();
	const MySwal = withReactContent(Swal);

	
	const { state } = useLocation();
	// const { payment_id,payment_paynow_qr } = state;
	


	React.useEffect(() => {
		dispatch(clearMessage());
	}, []);
	return (
		<>
			<Row className='course-detail-div mt-4'>
				<Col className="m-2">
					<Row className='pt-4 '>
						<Col><CourseSvg style={{width:"50px"}}/></Col>
					</Row>

					<Row className='pt-2 '>
						<div className='font-weight-bolder'>
							How To Run Successful Facebook Ad Campaigns To Increase Your Sales Revenue
						</div>
					</Row>
					<Row className='pt-4 '>
						<Col>
							<Row className='font-weight-bolder fs12'>
								Course ID
							</Row>
							<Row className='fs12'>
								TGS-2020504750
							</Row>
						</Col>
						<Col>
							<Row className='font-weight-bolder fs12'>
								Location
							</Row>
							<Row className='fs12'>
								Woodlands Training Center (6 Woodlands Square, #07-01 Woods Square Tower 2, Singapore 737737)
							</Row>
						</Col>
						<Col>
							<Row className='font-weight-bolder fs12'>
								Course Dates
							</Row>
							<Row className='fs12'>
								18 August 2023, Friday - 20 August 2023, Sunday
							</Row>
						</Col>
						<Col>
							<Row className='font-weight-bolder fs12'>
								Course Timings
							</Row>
							<Row className='fs12'>
								Day 1 - 9am to 6pm, 
								Day 2 - 9am to 6pm, 
								Day 3 - 9am to 1pm
							</Row>
						</Col>
					</Row>
					<Row className='pt-4  '>
						<Col>
							<Row className='font-weight-bolder fs12'>
								Learning Mode
							</Row>
							<Row className='fs12'>
								Classroom In-Person Training
							</Row>
						</Col>
						<Col>
							<Row className='font-weight-bolder fs12'>
								Student Services Executive
							</Row>
							<Row className='fs12'>
								Malcolm Chen
							</Row>
						</Col>
						<Col>
							<Row className='font-weight-bolder fs12'>
								Contact Student Services
							</Row>
							<Row className='fs12'>
								+65 9123 1234
							</Row>
						</Col>
						<Col>
							<Row  className='font-weight-bolder fs12'>
								Trainer
							</Row>
							<Row className='flex'>
								<div className='font-weight-bolder fs12'>Daryl Kang</div>
								<div className='fs12' style={{color:"#AAAAAA"}}>ACTA/ACLP Certified</div>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>

		</>
	);
}

export default Coursedetail;
