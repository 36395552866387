import { Row, Col, Card, CardBody } from "reactstrap";
import moment from "moment";

import { ReactComponent as TimeIcon } from "assets/img/Course/upcoming/date_blue.svg";
import { ReactComponent as CourseIcon } from "assets/img/Course/upcoming/course_ID_blue.svg";
import { ReactComponent as UserIcon } from "assets/img/Course/upcoming/user_blue.svg";
import { ReactComponent as PhoneIcon } from "assets/img/Course/upcoming/phone_blue.svg";
import { ReactComponent as LocationIcon } from "assets/img/Course/upcoming/location_blue.svg";
import { ReactComponent as AlmsIcon } from "assets/img/Course/upcoming/alms_blue.svg";
import { ReactComponent as ClassIcon } from "assets/img/Course/upcoming/class.svg";


function SuccessCourseInfo({
	course_title,
	course_logos,
	trainer_name,
	trainer_image_url,
	course_duration,
	course_categories,
	course_ref,
	courserun_modeoftraining,
	venue_address,
	training_support_name,
	training_support_mobile,
	courserun_course_from,
	courserun_course_to,
	csessions,
	course_description,
	course_reschedule_status,
	course_withdrawal_status,
	pending_assessment_date,
	course_attendance_status,
	tobe_rescheduled,
	claim_id,
	sfc_claim_id,
	claim_status,
}) {

	const getStatusTag = (course_reschedule_status, tobe_rescheduled, course_withdrawal_status, pending_assessment_date) => {
		if (course_withdrawal_status == "PENDING") {
			return (
				<span className='p-2 ml-2 course-sticker pending-withdrawal'>
					{"Withdrawal Processing"}
				</span>
			);
		}
		if (course_withdrawal_status == "REJECTED") {
			return (
				<span className='p-2 ml-2 course-sticker rejected'>{"Withdrawal Rejected"}</span>
			);
		}
		if (tobe_rescheduled && course_reschedule_status !== "Submitted") {
			return (
				<span className='p-2 ml-2 course-sticker pending-reschedule'>
					{"Reschedule Required"}
				</span>
			);
		}
		if (course_reschedule_status == "Submitted" ) {
			return (
				<span className='p-2 ml-2 course-sticker pending-reschedule'>
					{"Reschedule Processing"}
				</span>
			);
		}
		if (course_reschedule_status == "Pending SFC Cancellation" || course_reschedule_status == "Pending SFC Resubmission" || course_reschedule_status == "Pending SFC Approval") {
			return (
				<span className='p-2 ml-2 course-sticker pending-reschedule'>
					{course_reschedule_status}
				</span>
			);
		}
		if (course_reschedule_status == "Rejected" ) {
			return (
				<span className='p-2 ml-2 course-sticker rejected'>{"Reschedule Rejected"}</span>
			);
		}
		if (course_reschedule_status == "Assessment" ) {
			if (pending_assessment_date) {
				return (
					<>
						<span className='p-2 ml-2 course-sticker pending-assessment'>{"Pending Assessment"}</span>
						<span className='pt-3 d-block course-date'>{pending_assessment_date}</span>
					</>
				);
			} else {
				return (
					<>
						<span className='p-2 ml-2 course-sticker pending-assessment'>{"Pending Assessment"}</span>
					</>
				);
			}
		}
	};

	return (
		<>
			<Card style={{ boxShadow: "none" }}>
				<CardBody style={{ padding: 0 }}>
					<Row className='justify-content-between'>
						<Col md='auto' xl='auto' xs='auto'>
							{course_logos?.map((logo, i) => (
								<img
									key={i}
									className='m-1 h-40px display_ini'
									alt={course_title}
									src={logo.course_logo_image}
								/>
							))}
						</Col>
						<Col md='auto' xl='auto' xs='auto' className='text-right'>
							{ getStatusTag(course_reschedule_status, tobe_rescheduled, course_withdrawal_status, pending_assessment_date) }
						</Col>
					</Row>
					<Row className='align-items-center pt-2'>
						<Col md='12' xl='12'>
							<p className="fs22 font-weight-bold text-fca-dark font_2">{course_title}</p>
						</Col>
					</Row>
					<hr className="my-3"/>
					
					<Row className="">
						<Col>
							<div className=''>
								<Row className='course_data course_detail mx-0'>
									<Col xl='3' md='4' xs='6' className='pr-2 pb-2 pl-0 py-2'>
										<Row className="align-items-center mx-0">
											<CourseIcon width={13} height={16} className="" />
											<label className='m-0 pl-2 font-weight-600 text-fca-dark'>
												<span>Course ID</span>
											</label>
										</Row>
										<p className='m-0 text-fca-dark'>{course_ref ? course_ref : "-"}</p>
									</Col>
									<Col xl='3' md='4' xs='6' className='pr-2 pb-2 pl-0 py-2'>
										<Row className="align-items-center mx-0">
											<LocationIcon width={13} height={16} className="" />
											<label className='m-0 pl-2 font-weight-600 text-fca-dark'>
												<span>Location</span>
											</label>
										</Row>
										<p className='m-0 text-fca-dark'>{venue_address ? venue_address : "-"}</p>
									</Col>
									<Col xl='3' md='4' xs='6' className='pr-2 pb-2 pl-0 py-2'>
								
										<Row className="align-items-center mx-0">
											<TimeIcon width={13} height={16} className="" />
											<label className='m-0 pl-2 font-weight-600 text-fca-dark'>
												<span>Course Dates</span>
											</label>
										</Row>
										<p className='m-0 text-fca-dark'>
											{moment(courserun_course_from).format("DD MMM YYYY, ddd")} -{" "}
											{moment(courserun_course_to).format("DD MMM YYYY, ddd")}
										</p>
									</Col>
									<Col xl='3' md='4' xs='6' className='pr-2 pb-2 pl-0 py-2'>
								
										<Row className="align-items-center mx-0">
											<ClassIcon width={13} height={16} className="" />
											<label className='m-0 pl-2 font-weight-600 text-fca-dark'>
												<span>Learning Mode</span>
											</label>
										</Row>
										<p className='m-0 text-fca-dark'>
											{courserun_modeoftraining ? courserun_modeoftraining : "-"}
										</p>
									</Col>
									<Col xl='3' md='4' xs='6' className='pr-2 pb-2 pl-0 py-2'>
										<Row className="align-items-center mx-0">
											<UserIcon width={14} height={16} className="" />
											<label className='m-0 pl-2 font-weight-600 text-fca-dark'>
												<span>Student Services</span>
											</label>
										</Row>
										<p className='m-0 text-fca-dark'>{training_support_name ? training_support_name : "-"}</p>
									</Col>
									<Col xl='3' md='4' xs='6' className='pr-2 pb-2 pl-0 py-2'>
										<Row className="align-items-center mx-0">
											<PhoneIcon width={13} height={16} className="" />
											<label className='m-0 pl-2 font-weight-600 text-fca-dark'>
												<span>Student Services Contact</span>
											</label>
										</Row>
										<p className='m-0 text-fca-dark'>{training_support_mobile ? training_support_mobile : "-"}</p>
									</Col>
										
									<Col xl='3' md='4' xs='6' className='pr-2 pb-2 pl-0 py-2'>
										<div className='flex h-100'>
											<Col md='auto' xl='auto' xs='auto' className='p-0'>
												<img
													className='trainer-img'
													alt={trainer_name}
													src={
														trainer_image_url
															? trainer_image_url
															: require("assets/img/learner/user.png")
													}
													onError={({ currentTarget }) => {
														currentTarget.onerror = null; // prevents looping
														currentTarget.src = require("assets/img/learner/user.png");
													}}
												/>
											</Col>
											<Col md='8' xl='8' xs='8' className='pr-0'>
												<label className='m-0 font-weight-600 text-fca-dark'>
													<span>Trainer</span>
												</label>
												<p className='m-0 text-fca-dark'>{trainer_name ? trainer_name : "-"}</p>
											</Col>
										</div>
									</Col>
									{claim_id ? (
										<Col xl='3' md='4' xs='5' className='pr-2 pb-2 pl-0 py-2'>
											<Row className="align-items-center mx-0">
												<AlmsIcon width={16} height={16} className="" />
												<label className='m-0 pl-2 font-weight-600 text-fca-dark'>
													<span>SkillsFuture Credit Claims</span>
												</label>
											</Row>
											<p className='m-0'>
												{sfc_claim_id}
												{/* {JSON.stringify(course.sfc_claim_id)} */}
											</p>
											<p className='m-0 text-fca-dark'>
													Status: <span style={{ fontStyle: "italic" }}>{claim_status ? claim_status : "-"}</span>
											</p>
										</Col>
									) : null}
								</Row>
							</div>
						</Col>
					</Row>
				</CardBody>
			</Card>
			
		</>
	);
}

export default SuccessCourseInfo;
