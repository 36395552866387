import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../actions/auth";
// reactstrap components
import { Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import { getNotification } from "actions/notification";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import { CONTACT_NO_CHECKOUT, NOTIFICATION_LENGTH } from "utilities/constant";
import NotificationCard from "components/LearnerPortal/NotificationCard";
import generalApi from "services/general-api";
import CollapsibleCard from "components/LearnerPortal/CollapsibleCard";
import { getErrorMessage } from "utilities/error";
import ContactUs2 from "views/pages/learner/course/ContactUs2";
import { useLocation } from "react-router-dom";

function FaqMain() {
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [faqDetails, setFaqDetails] = useState(null);
    
	const dispatch = useDispatch();
	// const { user: userData, isHyperAdminLogin } = useSelector((state) => state.auth);

	const customerService = [
		{
			id: 1,
			title: "Customer Service",
			buttons: [
				{
					id: 1,
					title: `Call +65 ${CONTACT_NO_CHECKOUT}`,
					desc: "(Mon to Sun 9am to 6pm)",
					icon: "fa fa-phone",
				},
				{
					id: 2,
					title: "learn@fca.edu.sg",
					desc: null,
					icon: "fa fa-envelope",
				},
			],
		},
	];

	React.useEffect(() => {
		getFaq();
	}, []);

	const [contactFormOpen, setContactFormOpen] = useState(false);
	const hasOpend = useRef(false);
	const {search} = useLocation();
	const searchParam = new URLSearchParams(search);
	const hasContactUs = searchParam.get("contactus");
	const enquiryModalMetadata = {
		"ts_ccc": searchParam.get("ts_ccc"),
		"contactus": searchParam.get("contactus"),
	};

	useEffect(() => { 
		if( !hasOpend.current && parseInt(hasContactUs) === 1){
			hasOpend.current = true;
			setContactFormOpen(true);
		}
	},[hasContactUs]);

	const renderFaqQuestions = (faqList, title) => {
		return (
			<div className="fadeInDiv" style={{ marginBottom: 30 }}>
				<p className="fs24" style={{ marginBottom: 10, fontWeight: "700" }}>{title}</p>
				{faqList.map((item, index) => {
					return (
						<CollapsibleCard key={index} headerText={item.article_title} body={item.article_content} />
					);
				})}
			</div>
		);
	};

	const renderCustomerServices = (item) => {
		return (
			<div style={{ marginTop: 30 }}>
				<p className="fs24" style={{ marginBottom: 10, fontWeight: "700" }}>{item.title}</p>
				<Card className='h-100 p-4'>
					{item.buttons.map((itemButton, index) => {
						return (
							<div  key={index} >
								<div style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap" }}>
									<i className={itemButton.icon} style={{ color: "rgb(55,132,196)", fontSize: 24, marginBottom: 10}}></i>
									<span style={{ marginLeft: 10, fontSize: 14, marginBottom: 10}}>{itemButton.title}</span>
									{itemButton.desc && (
										<p style={{ marginLeft: 10, marginBottom: 10, fontSize: 12, color: "#aaaaaa"}}>{itemButton.desc}</p>
									)}
								</div>
								{item.buttons.length !== index + 1 && <hr style={{ marginTop: "1.3rem" }}></hr>}
							</div>
							
						);
					})}
				</Card>
			</div>
		);
	};

	const getFaq = () => {
		setIsLoading(true);
		setIsError(false);
		generalApi.getFAQ().then(
			(response) => {
				setIsLoading(false);
				setFaqDetails(response.data);
				setIsError(false);
			},
			(error) => {
				let error_message = getErrorMessage(error);
				toast.error(error_message);
				setIsLoading(false);
				setFaqDetails(null);
				setIsError(true);
			},
		);
	};


	return (
		<div className="fadeInDiv my_course_dashboard_container">
			<ContactUs2 
				show={contactFormOpen}
				metadata={enquiryModalMetadata}
				onSuccess={() => { 
					setContactFormOpen(false);
				}}
			/>
			{/* <h2 className='mb-3'>{`Hi ${userData.user.learnermaster_name}, how can we help`}</h2> */}
			{isLoading && 
				<div className='justify-content-center w-100 flex'><span className='spinner-border spinner-border-sm'></span>
				</div>
			}
			{!isLoading && !faqDetails && (
				<div className="fadeInDiv" style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center"}} >
					<p  style={{fontWeight: "bold", marginBottom: 0}}>
						{"No FAQ"}
					</p>
					<p >
						{"FAQ list will be show here when it's available"}
					</p>
				</div>
			)}
			{!isLoading && isError && (
				<div className="fadeInDiv" onClick={() => getFaq()}>
					<Card className='mt-4'>
						<CardBody className='flex justify-content-center'>
							<div className='btn btn-gradient'>
								<span>Reload</span>
							</div>
						</CardBody>
					</Card>
				</div>
			)}
			{faqDetails && faqDetails.top_question.length > 0 && renderFaqQuestions(faqDetails.top_question, "Top Questions")}
			{faqDetails && faqDetails.quick_link.length > 0 && renderFaqQuestions(faqDetails.quick_link, "Quick Links")}
			{faqDetails && faqDetails.course_related.length > 0 && renderFaqQuestions(faqDetails.course_related, "Course Related")}
			
			{customerService.map(item => {
				return renderCustomerServices(item);
			})}
		</div>
	);
}

export default FaqMain;
