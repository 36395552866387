import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Button, Modal } from "reactstrap";

import { useDispatch } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";
import { saveAs } from "file-saver";

function ModalCertificates({ course_title, cert_file_url, showModal, setShowModal }) {
	const [loading, setLoading] = useState(false);
	const [tncContent, setTncContent] = useState("");
	const [message, setMessage] = useState("");
	const dispatch = useDispatch();

	let navigate = useNavigate();

	useEffect(() => {
		pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	}, []);

	// const [pdfData, setPdfData] = useState(null);

	// useEffect(() => {
	// 	if (cert_file_url) {
	// 		fetch(cert_file_url, {
	// 			mode: "no-cors",
	// 		})
	// 			.then((response) => response.arrayBuffer())
	// 			.then((data) => {
	// 				const blob = new Blob([data], { type: "application/pdf" });
	// 				setPdfData(URL.createObjectURL(blob));
	// 			});
	// 	}
	// }, []);

	const handleDownload = () => {
		var parts = cert_file_url.split("/");
		var fileName = parts.pop() || parts.pop(); // handle potential trailing slash

		saveAs(cert_file_url, fileName);
	};

	const options = {
		cMapUrl: "cmaps/",
		standardFontDataUrl: "standard_fonts/",
	};
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	return (
		<>
			<Modal className='modal-dialog-centered cert_modal' style={{ marginTop: "60px" }}  isOpen={showModal}>
				<div className='modal-body'>
					<div className='close-btn' onClick={() => setShowModal(false)}>
						<i className='fa fa-times' aria-hidden='true'></i>
					</div>
					<h4 className='pb-4'>{course_title}</h4>

					<div className='pdf-container'>
						{/* {pdfData && <embed src={pdfData} type='application/pdf' width='100%' height='500px' />} */}
						{/* <iframe src={cert_file_url}></iframe> */}
						<Document
							file={cert_file_url}
							// source={{
							// 	header: {
							// 		"Access-Control-Allow-Origin": "*",
							// 	},
							// }}
							// options={options}
							onLoadSuccess={onDocumentLoadSuccess}
							// workerSrc='pdf.worker.min.js'
						>
							<Page pageNumber={pageNumber} />
						</Document>
					</div>
					<div className='modal-footer justify-content-center pt-4'>
						<Button className='btn-gradient' type='button' onClick={handleDownload}>
						Download PDF
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
}

export default ModalCertificates;
