import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import generalApi from "services/general-api";
import { getErrorMessage } from "utilities/error";

let checkout = localStorage.getItem("setCheckout");
checkout = checkout ? JSON.parse(checkout) : null;

let analyticsData = localStorage.getItem("analyticsData");
analyticsData = analyticsData ? JSON.parse(analyticsData) : null;



let sfcEncryptedPayload = localStorage.getItem("sfcEncryptedPayload");

const initialState = {
	registrationform_id: checkout ? checkout.registrationform_id : null,
	courserun_id: checkout ? checkout.courserun_id : null,
	hasLoaded: false,
	isLoading: false,
	error: null,
	learners: null,
	course: null,
	courserun: null,
	registerForm: null,
	registerFormCache: null,
	checkoutResponse: null,
	sfcEncryptedPayload	:sfcEncryptedPayload,
	checkoutAnalyticsData: analyticsData ? analyticsData : {
		course_id: null,
		course_title: null,
		courserun_id: null,
		referral: null,
		campaign_type: null,
		utm_campaign: null,
		utm_content: null,
		utm_medium: null,
		utm_source: null,
		utm_term: null,
		course_price_value: null,
		url: null,
		CCC: null,
		// invite: null,
	},
};

export const fetchCheckoutData = createAsyncThunk(
	"fetchCheckout",
	async ({ courserun_id, registrationform_id = null }, thunkAPI) => {
		let checkoutData;
		try {
			console.log("params", courserun_id, registrationform_id);
			checkoutData = await generalApi.getCheckoutDetail(registrationform_id, courserun_id);
			console.log("checkoutData", checkoutData);
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrorMessage(error));
		}
		return checkoutData;
	},
);

export const fetchCourseDetailsForAnalytics = createAsyncThunk(
	"fetchCourseDetailsForAnalytics",
	async ({ course_id }, thunkAPI) => {
		let courseDetailsData;
		try {
			courseDetailsData = await generalApi.getCourseDetail(course_id);
		} catch (error) {
			return thunkAPI.rejectWithValue(getErrorMessage(error));
		}
		return courseDetailsData;
	},
);

export const courseRegisterSlice = createSlice({
	name: "courseRegister",
	initialState,
	reducers: {
		setCheckout: (state, action) => {
			if (
				action.payload.courserun_id !== state.courserun_id ||
				action.payload.registrationform_id !== state.registrationform_id
			) {
				state.hasLoaded = false;
				state.isLoading = false;
				state.courserun = null;
				state.course = null;
				state.registerForm = null;
				state.learners = null;
				state.hasLoaded = false;
				state.registerFormCache = null;
				state.error = null;
				state.checkoutResponse = null;


				state.sfcEncryptedPayload = null;
				localStorage.removeItem("sfcEncryptedPayload");
			}

			localStorage.setItem("setCheckout", JSON.stringify(action.payload));
			state.courserun_id = action.payload.courserun_id;
			state.registrationform_id = action.payload.registrationform_id;
		},
		setRegistrationId: (state, action) => {
			localStorage.setItem(
				"setCheckout",
				JSON.stringify({
					courserun_id: state.courserun_id,
					registrationform_id: action.payload.registrationform_id,
				}),
			);
			state.registrationform_id = action.payload.registrationform_id;
		},
		saveCourse: (state, action) => {
			state.course = action.payload;
		},
		saveCourseRun: (state, action) => {
			state.courserun = action.payload;
		},
		saveRegisterForm: (state, action) => {
			state.registerForm = {
				...state.registerForm,
				...action.payload,
			};
		},
		saveSFCEncryptedPayload : (state, action) => {
			state.sfcEncryptedPayload = action.payload;
			if(action.payload){
				localStorage.setItem("sfcEncryptedPayload", action.payload);
			}else{
				localStorage.removeItem("sfcEncryptedPayload");
			}
		},
		resetcourseRegister: () => initialState,
		initCheckout: (state, action) => {
			const { courserun_id } = action.payload;
			state.metadata = {
				registrationform_id: null,
				courserun_id: courserun_id,
			};
		},
		cacheRegform: (state, action) => {
			state.registerFormCache = action.payload;
			return state;
		},
		clearCheckout: (state) => {
			state.courserun_id = null;
			state.registrationform_id = null;

			state.checkoutResponse = null;
			state.sfcEncryptedPayload = null;
			state.courserun = null;
			state.course = null;
			state.registerForm = null;
			state.learners = null;
			state.hasLoaded = false;
			state.isLoading = false;
			state.error = null;

			localStorage.removeItem("sfcEncryptedPayload");
			localStorage.removeItem("setCheckout");
			return state;
		},
		saveCheckoutAnalyticsData: (state, action) => {
			state.checkoutAnalyticsData = action.payload;
			localStorage.removeItem("analyticsData");
			localStorage.setItem("analyticsData", JSON.stringify(action.payload));
			return state;
		},
		clearCheckoutAnalyticsData: (state) => {
			localStorage.removeItem("analyticsData");
			return state;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCheckoutData.pending, (state) => {
				state.isLoading = true;
				state.error = null;
				return state;
			})
			.addCase(fetchCheckoutData.fulfilled, (state, action) => {
				console.log("what is action", action);
				state.hasLoaded = true;
				state.isLoading = false;
				state.checkoutResponse = action.payload.data;
				state.learners = action.payload.data.learners;
				state.ordersubmission = action.payload.data.ordersubmission;
				state.course = action.payload.data.course;
				state.courserun = action.payload.data.courserun;
				state.registerForm = action.payload.data.registrationform;
				return state;
			})
			.addCase(fetchCheckoutData.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.payload;
				// state.checkoutResponse = null;
				// state.courserun = null;
				// state.registerForm = null;
				return state;
			})
			.addCase(fetchCourseDetailsForAnalytics.pending, (state) => {
				return state;
			})
			.addCase(fetchCourseDetailsForAnalytics.fulfilled, (state, action) => {
				console.log("what is action", action);
				state.checkoutAnalyticsData = {
					...state.checkoutAnalyticsData,
					course_title: action.payload.course_title,
				};
				return state;
			})
			.addCase(fetchCourseDetailsForAnalytics.rejected, (state, action) => {
				return state;
			});
	},
});

// Action creators are generated for each case reducer function
export const {
	saveCourse,
	saveRegisterForm,
	saveCourseRun,
	resetcourseRegister,
	cacheRegform,
	clearCheckout,
	setCheckout,
	setRegistrationId,
	saveCheckoutAnalyticsData,
	clearCheckoutAnalyticsData,
	saveSFCEncryptedPayload
} = courseRegisterSlice.actions;

// const initialState = {
// 	metadata: {
// 		registrationform_id: null,
// 		courserun_id: null,
// 	},
// 	isLoading: false,
// 	checkoutResponse: null,
// 	personalInfo: {},
// }

export default courseRegisterSlice.reducer;
