import React from "react";
import { useLocation, Outlet, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "side_menu.js";
import { Col, Container, Row } from "reactstrap";
import Profile from "components/Sidebar/Profile";
import { logGTMScreen } from "utilities/googleTagManager";
import { logAnalyticsScreen, removeLogUser } from "utilities/firebase";
import axios from "axios";
import { getErrorMessage } from "utilities/error";
import { resetUser } from "actions/auth";

function AppWithProfile() {
	const [sidenavOpen, setSidenavOpen] = React.useState(true);
	const location = useLocation();
	const mainContentRef = React.useRef(null);
	const { user: currentUser, isLoggedIn } = useSelector((state) => state.auth);
	
	React.useEffect(() => {
		logGTMScreen(location, isLoggedIn, currentUser);
		if (document.documentElement && document.scrollingElement && mainContentRef.current) {
			document.documentElement.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
			mainContentRef.current.scrollTop = 0;
			window.scrollTo(0, 0);
		}
	}, [location]);

	const NavigateFunctionComponent = () => {
		/** Intercept any unauthorized request.
		 * dispatch logout action accordingly **/
		const dispatch = useDispatch();
		let navigate = useNavigate();

		const UNAUTHORIZED = 401;
		axios.interceptors.response.use(
			(response) => response,
			(error) => {
				const { status } = error.response;
				let error_message = getErrorMessage(error);
				if (status === UNAUTHORIZED && error_message == "Unauthenticated.") {
					removeLogUser();
					dispatch(resetUser());
					localStorage.removeItem("user");
					navigate("/login");
				}
				throw error;
			},
		);

		return <></>;
	};

	if (!currentUser) {
		return <Navigate to='/login' />;
	} else {
		if (!currentUser.user.email_verified_at) {
			return <Navigate to='/login/otp-validation' />;
		}

		if (currentUser.user.reset_password) {
			return <Navigate to='/login' />;
		}
	}

	// toggles collapse between mini sidenav and normal
	const toggleSidenav = (e) => {
		if (document.body.classList.contains("g-sidenav-pinned")) {
			// document.body.classList.remove("g-sidenav-pinned");
			// document.body.classList.add("g-sidenav-hidden");
		} else {
			// document.body.classList.add("g-sidenav-pinned");
			// document.body.classList.remove("g-sidenav-hidden");
		}
		setSidenavOpen(!sidenavOpen);
	};

	return (
		<>
			<Sidebar
				routes={routes}
				toggleSidenav={toggleSidenav}
				sidenavOpen={sidenavOpen}
				logo={{
					innerLink: "/",
					imgSrc: require("assets/img/FCA.png"),
					imgAlt: "...",
				}}
			/>
			<div className='main-content' ref={mainContentRef}>
				<Container className='main-container' fluid>
					<Row>
						<Col md='9' xl='9' className="content-wrap">
							<Outlet react={React} />
						</Col>
						<Col md='3' xl='3' className="profile-wrap">
							<Profile />
						</Col>
					</Row>
				</Container>
			</div>
			{/* {sidenavOpen ? <div className='backdrop d-xl-none' onClick={toggleSidenav} /> : null} */}
			<NavigateFunctionComponent />
		</>
	);
}

export default AppWithProfile;
