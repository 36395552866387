import React from "react";
import { Link, useLocation } from "react-router-dom";

import { Card, CardBody, Row, Col, Container } from "reactstrap";

import { ReactComponent as SuccessIcon } from "assets/img/Course/registration/success.svg";

function MealSuccessPage() {
	const location = useLocation();
	const data = location.state?.data;
	const course = data.course;
	const selection = data.selection;
	const remark = data.remark;

	return (
		<>
			<Container className=''>
				{Object.keys(course).length !== 0 && (
					<div className='pt-6 pb-5'>
						<div className='title-container text-center'>
							<SuccessIcon />
							<h5 className='pt-2'>
								{"Thank you! You have successfully\nsubmitted your responses below"}
							</h5>
						</div>
						<Card className='mt-5'>
							<CardBody>
								<Row className='justify-content-between'>
									<Col md='auto' xl='auto' xs='auto'>
										{course.course.logos.map((logo, i) => (
											<img
												key={i}
												className='m-1 h-40px display_ini'
												alt={course.course.course_title}
												src={logo.course_logo_image}
											/>
										))}
									</Col>
								</Row>
								<Row className='align-items-center pt-3'>
									<Col md='12' xl='12'>
										<h5>{course.course.course_title}</h5>
									</Col>
								</Row>
								<hr className="mt-2 mb-4"></hr>
								<Row className='align-items-center'>
									<Col md='12' xl='12'>
										<h5>{"Meal Selected"}</h5>
									</Col>
								</Row>
								<div style={{borderWidth: 1, borderStyle: "solid", borderColor: "rgb(204,204,204)", marginBottom: 30, padding: 16}}>
									{selection.map((selectionItem, index) => {
										if (
											selectionItem.food_id === null &&
											selectionItem.breakfast_food_id === null
										) {
											return null;
										}
										return (
											<div key={`${selectionItem.food_id}-${index}`} style={{ marginBottom: 10 }}>
												<span className="theme_text_color" style={{ fontSize: 16, fontWeight: "500", marginBottom: 10 }}>{selectionItem.date}</span>
												<div>
													{selectionItem.isBreakfast && (
														<p style={{ fontSize: 16, fontWeight: "500", marginBottom: 5, textDecorationLine: "underline" }}>{"Breakfast"}</p>
													)}
													{selectionItem.breakfast_option_title && (
														<p style={{ fontSize: 16, fontWeight: "500", marginBottom: 5 }}>{selectionItem.breakfast_option_title}</p>
													)}
													{selectionItem.breakfastfoodmenus && selectionItem.breakfastfoodmenus.map((food, foodIndex) => (
														<div  key={`${food.food_id}-${foodIndex}`} style={{marginBottom: 5}}>
															<p style={{ fontWeight: "500", fontSize: 14, margin: 0 }}>{`- ${food.food_name_en} (${food.food_name_cn})`}</p>
															{food.food_ingredient && food.food_ingredient !== "" && (
																<div style={{ display: "flex", flexDirection: "row" }}>
																	<p style={{ fontSize: 14, marginLeft: 10, marginRight: 5,  marginBottom: 0}}>{"Ingredient:"}</p>
																	<p style={{ fontSize: 14, flexShrink: 1 }}>{`${
																		food.food_ingredient
																			? food.food_ingredient.replace(
																				/(\r\n|\n|\r)/gm,
																				"",
																			)
																			: "-"
																	}`}</p>
																</div>
															)}
															{food.food_allergern && food.food_allergern !== "" && (
																<div style={{ display: "flex", flexDirection: "row" }}>
																	<p style={{ fontSize: 14, marginLeft: 10,  marginRight: 5,  marginBottom: 0}}>{"Allergen:"}</p>
																	<p style={{ fontSize: 14, flexShrink: 1 }}>{`${
																		food.food_allergern
																			? food.food_allergern.replace(
																				/(\r\n|\n|\r)/gm,
																				"",
																			)
																			: "-"
																	}`}</p>
																</div>
															)}
														</div>
													))}
													
												</div>
												<div style={{ marginTop:  selectionItem.isBreakfast ? 10 : 0 }}>
													{selectionItem.isLunch && (
														<p style={{ fontSize: 16, fontWeight: "500", marginBottom: 5, textDecorationLine: "underline" }}>{"Lunch"}</p>
													)}
													{selectionItem.option_title && (
														<p style={{ fontSize: 16, fontWeight: "500", marginBottom: 5 }}>{selectionItem.option_title}</p>
													)}
													{selectionItem.foodmenus && selectionItem.foodmenus.map((food, foodIndex) => (
														<div  key={`${food.food_id}-${foodIndex}`} style={{marginBottom: 5}}>
															<p style={{ fontWeight: "500", fontSize: 14, margin: 0 }}>{`- ${food.food_name_en} (${food.food_name_cn})`}</p>
															{food.food_ingredient && food.food_ingredient !== "" && (
																<div style={{ display: "flex", flexDirection: "row", margin: 0 }}>
																	<p style={{ fontSize: 14, marginLeft: 10, marginRight: 5, marginBottom: 0}}>{"Ingredient:"}</p>
																	<p style={{ fontSize: 14, flexShrink: 1 }}>{`${
																		food.food_ingredient
																			? food.food_ingredient.replace(
																				/(\r\n|\n|\r)/gm,
																				"",
																			)
																			: "-"
																	}`}</p>
																</div>
															)}
															{food.food_allergern && food.food_allergern !== "" && (
																<div style={{ display: "flex", flexDirection: "row", margin: 0 }}>
																	<p style={{ fontSize: 14, marginLeft: 10,  marginRight: 5,  marginBottom: 0}}>{"Allergen:"}</p>
																	<p style={{ fontSize: 14 }}>{`${
																		food.food_allergern
																			? food.food_allergern.replace(
																				/(\r\n|\n|\r)/gm,
																				"",
																			)
																			: "-"
																	}`}</p>
																</div>
															)}
														</div>
													))}
													
												</div>
											</div>
										);
									})}
								</div>
								<Row className='align-items-center'>
									<Col md='12' xl='12'>
										<h5>{"Remarks"}</h5>
									</Col>
								</Row>
								<div style={{borderWidth: 1, borderStyle: "solid", borderColor: "rgb(204,204,204)", marginBottom: 30, padding: 16}}>
									<span style={{ fontSize: 16, fontWeight: "500", marginBottom: 5 }}>{remark || "-"}</span>
								</div>
								
								
							</CardBody>
						</Card>
						<Row className='align-items-center mb-10'>
							<Col xl='auto' md='auto' xs='auto' className='p-2 ml-auto'>
								<div className='modal-footer justify-content-center'>
									<Link to='/course' className='btn btn-gradient'>
										{" Back To Home Page"}
									</Link>
								</div>
							</Col>
						</Row>
					</div>
				)}

			</Container>
		</>
	);
}

export default MealSuccessPage;
