
import React, { useEffect, useState } from "react";

// reactstrap components

import { Link, useNavigate } from "react-router-dom";
import {
	Card,
} from "reactstrap";
import { dateFormatting } from "utilities/date";
import "../../../assets/scss/custom/notification-card.scss";
import { RWebShare } from "react-web-share";
import { toast } from "react-toastify";
import { articleShare } from "actions/news";
import { useDispatch, useSelector } from "react-redux";


function ShareButton({
	totalShare,
	url,
	title,
	desc,
	article_id,
}) {
	const [shares, setShares] = useState(totalShare);
	const dispatch = useDispatch();
	const { user: currentUser } = useSelector((state) => state.auth);
	let navigate = useNavigate();
	console.log(totalShare);
	useEffect(() => {
		if (totalShare !== shares) {
			setShares(totalShare);
		}
	}, [totalShare]);

	const shareArticle =  async (article_id) => {
		try {
			let response = await dispatch(articleShare(article_id));
		} catch (e) {
			// let error_message = getErrorMessage(e);
			// toast.error(error_message);
		}
	};

	return (
		<RWebShare
			data={{
				text: desc,
				url: url,
				title: title,
			}}
			onClick={() => {
				setShares(shares + 1);
				if (currentUser) {
					shareArticle(article_id);
				}
			}}
		>
			<button
				className={"share-button"}
				
			>
				<div className="share-button-container">
					<i className='fa fa-share-square share-button-icon' aria-hidden='true'></i>
					<p className="share-button-title">{"Share"}</p>
					{/* <p className="share-button-number">{shares}</p> */}
				</div>

			</button>
		</RWebShare>
		
	);
}

export default React.memo(ShareButton);

