
import React, { useEffect, useRef, useState } from "react";
import styles from "../AvatarPage.module.scss";

import occupation1 from "assets/img/Avatar/tava01.png";
import occupation2 from "assets/img/Avatar/tava02.png";
import occupation3 from "assets/img/Avatar/tava03.png";

const AvatarOccupationCarousel = ({ data = [], onSelectAvatar = () => null }) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const scrollRef = useRef(null);
	const [imageWidth, setImageWidth] = useState(0);

	const updateImageWidth = () => {
		const firstImage = scrollRef.current.querySelector("img");
		if (firstImage) {
		  setImageWidth(firstImage.naturalWidth);
		}
	  };

	const scrollToIndex = (index) => {
		setCurrentIndex(index);
		const scrollContainer = scrollRef.current;
		const scrollWidth = scrollContainer.scrollWidth;
		const itemWidth = imageWidth;
		const scrollPosition = index * itemWidth;
	
		scrollContainer.scrollTo({
			left: scrollPosition,
			behavior: "smooth",
		});

		// document.getElementById("avatar_scrollview").scrollTo({
		// 	left: 500,
		// 	behavior: "smooth",
		// });
	};

	const goToPrev = () => {
		const prevIndex = Math.max(0, currentIndex - 1);
    	scrollToIndex(prevIndex);
	};
	
	const goToNext = () => {
		const nextIndex = Math.min(data.length - 1, currentIndex + 1);
    	scrollToIndex(nextIndex);
	};
	

	return (
		<div className={`${styles.avatar_carousel_selection_container}`}>
		
			<div className={`${styles.avatar_carousel_arrow_left_container}` + " arrow-wrap flex completed-arrow"}>
				<div
					className={`${styles.avatar_carousel_arrow_container} ` + (currentIndex === 0 ? styles.disable : "")}
					onClick={goToPrev}
				>
					<i className='fa fa-angle-left' aria-hidden='true'></i>
				</div>
			</div>
			<div className={`${styles.avatar_carousel_arrow_right_container}` + " arrow-wrap flex completed-arrow"}>
				<div
					className={`${styles.avatar_carousel_arrow_container} ` + (currentIndex === data.length - 1 ? styles.disable : "")}
					onClick={goToNext}
				>
					<i className='fa fa-angle-right' aria-hidden='true'></i>
				</div>
			</div>

			
			<div id={"avatar_scrollview"} className={`${styles.avatar_carousel_scrollview_container}`}  ref={scrollRef}>
				<div className={`${styles.avatar_carousel_image_container}`}>
					{data.map((occupation, index) => (
						<img
							className="cursor-pointer"
							key={index}
							src={
								occupation
									? occupation.avatar_occupation_selection_image
									: null
							}
							alt={`image ${index}`}
							onLoad={updateImageWidth}
							onClick={() => onSelectAvatar(occupation)}
						/>
					))}
				</div>
			</div>
		</div>
	);
};
export default AvatarOccupationCarousel;