const NATIONALITY = [
	"Singapore Citizen",
	"Singapore Permanent Resident",
	"Long Term Visitor Pass+ (LTVP+)",
	"Non-Singapore Citizen/PR (FIN)",
	"Non-Singapore Resident (Foreign Passport)",
];

export const NATIONALITY_OPTIONS = NATIONALITY.map((x) => ({
	label: x,
	value: x,
}));

const QUALIFICATOIN_LEVEL = [
	"Primary Qualification (e.g PSLE) or Below",
	"Lower Secondary (Secondary Education without O level/N level pass)",
	"Secondary Qualification Equivalent (At least 1 pass in O level/N level or ITE Certificate)",
	"Post Secondary Qualification or Equivalent (A Level/IB Diploma/Higher NITEC)",
	"Polytechnic Diploma or Other Diploma Qualifications",
	"Bachelor's Degree and Above",
];

export const QUALIFICATOIN_LEVEL_OPTION = QUALIFICATOIN_LEVEL.map((x) => ({
	label: x,
	value: x,
}));

const GENDER = ["MALE", "FEMALE"];

export const GENDER_OPTIONS = GENDER.map((x) => ({
	label: x,
	value: x,
}));

const COUNTRY = ["SINGAPORE", "MALAYSIA"];
export const COUNTRIES_OPTIONS = COUNTRY.map((x) => ({
	label: x,
	value: x,
}));

const LOAN = ["No", "Yes"];
export const LOAN_OPTIONS = [
	{
		label: "No",
		value: "0",
	},
	{
		label: "Yes",
		value: "1",
	},
];

export const EVENT_LENGTH = 20;

export const ARTICLE_LENGTH = 20;
export const ARTICLE_COMMENT_LENGTH = 20;

export const TEASER_LENGTH = 20;
export const TEASER_COMMENT_LENGTH = 20;

export const NOTIFICATION_LENGTH = 40;

export const CONTACT_NO_CHECKOUT = "66537552";
