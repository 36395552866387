/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import moment from "moment";
import React, { useState, useEffect, useCallback, useRef } from "react";
import NewsCard from "components/LearnerPortal/NewsCard.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InfiniteScroll from "react-infinite-scroll-component";

// reactstrap components
import { Card, Col, Container, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
// core components
import { useDispatch, useSelector } from "react-redux";
import { logout } from "actions/auth";
import { EVENT_LENGTH } from "utilities/constant";
import { getDailyNews } from "actions/news";
import { getErrorMessage } from "utilities/error";
import { getEvent } from "actions/news";
import EventCard from "components/LearnerPortal/EventCard";
import styles from "./Event.module.scss";
import FeaturedEvents from "components/Event/FeaturedEvents";
import { useWindow } from "hooks/UseWindow";
import newsApi from "services/news-api";
import { debounce } from "lodash";
import { Link } from "react-router-dom";


function Dashboard() {
	const newsState = useSelector((state) => state.news);
	const dispatch = useDispatch();
	const [isLastPage, setIsLastPage] = useState(false);
	const [page, setPage] = useState(0);
	const {user: userData, isHyperAdminLogin } = useSelector((state) => state.auth);
	const [searchWord, setSearchWord] = React.useState("");
	const { width } = useWindow();
	const [isFirstPageLoading, setIsFirstPageLoading] = useState(false);

	// feature event
	const [isFeatureEventLoading, setIsFeatureEventLoading] = useState(false);
	const [featuredEvents, setFeaturedEvents] = React.useState(null);
	const [thisweekEvents, setThisweekEvents] = React.useState(null);
	
	useEffect(() => {
		fetchFeaturedEvent();
	}, []);

	useEffect(() => {
		setPage(0);
		const timer = setTimeout(() => {
			// Execute the search function after a period of inactivity
			fetchEvent(0, searchWord);
		}, 1000); // Adjust the delay as needed
	
		return () => {
		  clearTimeout(timer); // Clear the timer on each input change
		};
	}, [searchWord]); // Re-run effect whenever searchWord changes

	const fetchEvent =  async (page, searchText) => {
		if (page == 0) {
			setIsLastPage(false);
			setIsFirstPageLoading(true);
		}
		try {
			let response = await dispatch(getEvent(page, "event", searchText));
			// if last getEvent
			if (response.data.length < EVENT_LENGTH) {
				setIsLastPage(true);
			}
			setPage(response.nextPage);
			setIsFirstPageLoading(false);
		} catch (e) {
			let error_message = getErrorMessage(e);
			toast.error(error_message);
			setIsFirstPageLoading(false);
		}
	};

	const fetchFeaturedEvent =  async () => {
		setIsFeatureEventLoading(true);
		newsApi.getFeaturedEvent().then(
			(response) => {
				setIsFeatureEventLoading(false);
				setFeaturedEvents(response.data.featured_events);
				setThisweekEvents(response.data.thisweek_events);
			},
			(error) => {
				// toast.error(getErrorMessage(error));
				setIsFeatureEventLoading(false);
				setFeaturedEvents(null);
				setThisweekEvents(null);
			},
		);
	};


	// Handle input change
	const handleSearchInputChange = (e) => {
		setSearchWord(e.target.value); // Update search word state
	};

	return (
		<>
			<div className={`fadeInDiv ${styles.event_main_container}`}>
				{isFeatureEventLoading &&
					<div className='justify-content-center mt-4 w-100 flex'>
						<span className='spinner-border spinner-border-sm'></span>
					</div>
				}
				{!isFeatureEventLoading &&
					<div>
						{width <= 991 && (
							<Card className={`${styles.search_container}`}>
								<InputGroup
									className={`input-group-merge ${styles.input_container} `}>
									<InputGroupAddon addonType='prepend' className="align-item-center">
										<InputGroupText
											className={`form-control ${styles.search_input_icon}`}>
											<i className={"fa fa-search"} aria-hidden></i>
										</InputGroupText>
									</InputGroupAddon>
									<input
										placeholder='Search events here'
										type='email'
										className={`form-control ${styles.search_input}`}
										style={searchWord ? {backgroundColor: "#FFF"} : {}}
										value={searchWord}
										onChange={handleSearchInputChange}
									/>
								</InputGroup>
							</Card>
						)}
						<Row className='pt-2'>
							<Col xl={9} lg={8} md={12} sm={12} style={{ paddingLeft: "5px", paddingRight: "5px" }}>
								{searchWord == "" && !isFeatureEventLoading && 
									<p className={`m-0 mb-2 ${styles.event_title}`} style={{ paddingRight: "10px", paddingLeft: "10px" }}>{"Events"}</p>
								}
								{searchWord != "" && newsState.events.length !== 0 && 
									<p className={`m-0 mb-4 ${styles.event_search_title}`} style={{ paddingRight: "10px", paddingLeft: "10px" }}>{`Search result: '${searchWord}'`}</p>
								}
								{featuredEvents && featuredEvents.length > 0 && searchWord == "" &&
									<FeaturedEvents events={featuredEvents} />
								}
								{width <= 991 && searchWord == "" && (
									<Card className={`${styles.thisweek_container}`} style={{ marginLeft: "10px", marginRight: "10px" }}>
										<p className={`m-0 mb-2 ${styles.search_title}`}>{"Check Out What’s Happening This Week"}</p>
										{!thisweekEvents || thisweekEvents.length == 0 &&
											<>
												<hr className="mt-3 mb-3" />
												<span className="text-center">{"Ain't no event happening this week"}</span>
											</>
										}
										{thisweekEvents && thisweekEvents.length > 0 && thisweekEvents.map((event, i) => (
											<Link
												to={"/events/" + event.event_id} state={{ event: event }}
											>
												<hr className="mt-3 mb-3" />
												<Row className='align-items-center'>
													<Col className='col-auto pr-0'>
														<img alt='' src={event.event_listing_image_url ? event.event_listing_image_url : require("assets/img/FALCOMM_LOGO.png")} className={`${styles.thisweek_event_image}`}/>
													</Col>
													<div className='col'>
														<p className={`m-0 ${styles.thisweek_event_date_text}`}>
															{event.event_from &&
																`${moment(event.event_from).format("DD MMM")}`
															}
															{event.event_to && event.event_from &&
																" - "
															}
															{event.event_to &&
																`${moment(event.event_to).format("DD MMM")}`
															}
															{(event.event_time_to || event.event_time_from) &&
																" | "
															}
															{event.event_time_from &&
																`${moment(event.event_time_from, "HH:mm").format("h:mmA")}`
															}
															{event.event_time_to && event.event_time_from &&
																" - "
															}
															{event.event_time_to &&
																`${moment(event.event_time_to, "HH:mm").format("h:mmA")}`
															}
															{/* {`${moment(
																props.event.event_published,
															).format("MMMM DD YYYY, hh:ssA")}`} */}
														</p>
														<p className={`m-0 mt-1 ${styles.thisweek_event_name_text}`}>{event.event_title}</p>
													</div>
												</Row>
											</Link>
										))}
									</Card>
								)}
								{isFirstPageLoading &&
									<div className='justify-content-center mt-0 w-100 flex'>
										<span className='spinner-border spinner-border-sm' style={{ color: "#00ab84" }}></span>
										<span className="fs14 font-weight-bold ml-2" style={{ color: "#00ab84" }}>{"Loading"}</span>
									</div>
								}
								{newsState.events && newsState.events.length > 0 && (
									<>
										{searchWord == "" &&
											<p className='fs24 font_2 text-fca-darks mt-4 mb-2' style={{ paddingRight: "10px", paddingLeft: "10px" }}>
												{"Discover Our Most Popular Events and Join the Fun!"}
											</p>
										}
										<InfiniteScroll
											className=""
											style={{ paddingRight: "10px", paddingLeft: "10px", overflowY: "hidden" }}
											dataLength={newsState.events.length} //This is important field to render the next data
											next={() => {
												fetchEvent(page, searchWord);
											}}
											hasMore={!isLastPage}
											loader={
												<div className='justify-content-center mt-0 w-100 flex'>
													<span className='spinner-border spinner-border-sm' style={{ color: "#00ab84" }}></span>
													<span className="fs14 font-weight-bold ml-2" style={{ color: "#00ab84" }}>{"Loading"}</span>
												</div>
											}
											endMessage={
												<div className='justify-content-center mt-0 w-100 flex'>
													<span className="fs14 font-weight-bold ml-2" style={{ color: "#00ab84" }}>{"End"}</span>
												</div>
											}
											// below props only if you need pull down functionality
											// refreshFunction={(setItems) => null}
											pullDownToRefresh={false}
											// pullDownToRefreshThreshold={50}
											// pullDownToRefreshContent={
											// 	<h3 style={{ textAlign: "centser" }}># 8595; Pull down to refresh</h3>
											// }
											// releaseToRefreshContent={
											// 	<h3 style={{ textAlign: "center" }}># 8593; Release to refresh</h3>
											// }						
										>
											{
												newsState.events.map((single) => (
													<>
														<EventCard
															key={single.event_id}
															event={single}
															id={single.event_id}
															title={single.event_title}
															image_url={single.event_image_url}
															date={single.event_published}
															author={single.author.author_name}
															author_image_url={single.author.author_image_url}
														/>
													</>
												))
											}
										</InfiniteScroll>
									</>
								)}
								{!newsState.isGetEvent && searchWord != "" && (!newsState.events || (newsState.events && newsState.events.length === 0)) && (
									<>
										<p className={`m-0 mb-2 ${styles.event_search_title}`} style={{ paddingRight: "10px", paddingLeft: "10px" }}>{`Search result: '${searchWord}'`}</p>
										<div className='justify-content-center mt-4 w-100 flex'>
											<p className='text-center flex'>
												<b>{"Event not found"}</b>
											</p>
										</div>
									</>
								)}
							</Col>
							<Col xl={3} lg={4} md={12} sm={12} style={width > 991 ? {} : {order: "-1"}}>
								{width > 991 && (
									<div className={`${styles.sticky_right}`}>
										<Card className={`${styles.search_container}`}>
											<p className={`m-0 mb-2 ${styles.search_title}`}>{"Let’s Find What You Are Looking for"}</p>
											<InputGroup
												className={`input-group-merge ${styles.input_container} `}>
												<InputGroupAddon addonType='prepend' className="align-item-center">
													<InputGroupText
														className={`form-control ${styles.search_input_icon}`}>
														<i className={"fa fa-search"} aria-hidden></i>
													</InputGroupText>
												</InputGroupAddon>
												<input
													placeholder='Search events here'
													type='email'
													className={`form-control ${styles.search_input}`}
													style={searchWord ? {backgroundColor: "#FFF"} : {}}
													value={searchWord}
													onChange={handleSearchInputChange}
												/>
											</InputGroup>
										</Card>
										<Card className={`${styles.thisweek_container}`}>
											<p className={`m-0 mb-2 ${styles.search_title}`}>{"Check Out What’s Happening This Week"}</p>
											{!thisweekEvents || thisweekEvents.length == 0 &&
												<>
													<hr className="mt-3 mb-3" />
													<span className="text-center">{"Ain't no event happening this week"}</span>
												</>
											}
											{thisweekEvents && thisweekEvents.length > 0 && thisweekEvents.map((event, i) => (
												<Link
													to={"/events/" + event.event_id} state={{ event: event }}
												>
													<hr className="mt-3 mb-3" />
													<Row className='align-items-center'>
														<Col className='col-auto pr-0'>
															<img alt='' src={event.event_listing_image_url ? event.event_listing_image_url : require("assets/img/FALCOMM_LOGO.png")} className={`${styles.thisweek_event_image}`}/>
														</Col>
														<div className='col'>
															<p className={`m-0 ${styles.thisweek_event_date_text}`}>
																{event.event_from &&
																	`${moment(event.event_from).format("DD MMM")}`
																}
																{event.event_to && event.event_from &&
																	" - "
																}
																{event.event_to &&
																	`${moment(event.event_to).format("DD MMM")}`
																}
																{(event.event_time_to || event.event_time_from) &&
																	" | "
																}
																{event.event_time_from &&
																	`${moment(event.event_time_from, "HH:mm").format("h:mmA")}`
																}
																{event.event_time_to && event.event_time_from &&
																	" - "
																}
																{event.event_time_to &&
																	`${moment(event.event_time_to, "HH:mm").format("h:mmA")}`
																}
																{/* {`${moment(
																	props.event.event_published,
																).format("MMMM DD YYYY, hh:ssA")}`} */}
															</p>
															<p className={`m-0 mt-1 ${styles.thisweek_event_name_text}`}>{event.event_title}</p>
														</div>
													</Row>
												</Link>
											))}
										</Card>
									</div>
								)}
							</Col>
						</Row>
					</div>
				}
			
				{!newsState.isGetEvent && !isFeatureEventLoading && (featuredEvents && featuredEvents.length == 0) && (!newsState.events || (newsState.events && newsState.events.length === 0)) && (
					<div className='justify-content-center mt-4 w-100 flex'>
						<p className='text-center flex'>
							<b>{"No Event"}</b>
						</p>
					</div>
				)}
			</div>
		</>
	);
}

// export default Dashboard;
export default React.memo(Dashboard);
