import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Row, Card, CardBody } from "reactstrap";


function ModalDownloadResouces() {
	const location = useLocation();
	const course = location.state?.course;

	return (
		<>
			<div className=''>
				<div className='title-container'>
					<h2>Resources</h2>
				</div>
				{course.course.active_resources.length === 0 && (
					<div>
						<Card className='mt-4'>
							<CardBody>
								<div>
									<p style={{ fontWeight: "500" }}>
										{"There is no resources uploaded yet"}
									</p>
								</div>
							</CardBody>
						</Card>
					</div>
				)}
				{course.course.active_resources.length > 0 && course.course.active_resources.map((resource, index) => {
					return(
						<div key={index}>
							<Link
								to={"/course/resource-details/" + resource.resource_id}
								state={{ course: course }}

							>
								<Card className='mt-4'>
									<CardBody>
										<div>
											{
												resource && resource.resource_image_url &&  (
													<Row className="mb-1">
														<img
															key={"image"+index}
															className='m-1 h-40px display_ini'
															src={resource.resource_image_url}
														/>
													</Row>
												)
											}
											<p style={{ fontWeight: "600", color: "rgb(55,132,196)" }}>
												{resource.resource_title}
											</p>
											<p style={{ fontSize: 14, fontWeight: "600", color: "rgb(73,85,103)" }}>
												{resource.resource_published}
											</p>
										</div>
									</CardBody>
								</Card>
							</Link>
						</div>
					);
				})}
				
			</div>
		</>
	);
}

export default ModalDownloadResouces;
