import moment from "moment";

export const dateFormatting = (createdDate) => {
	let currentDate = moment();
	let yesterDate = moment().subtract(1, "day");

	if (createdDate) {
		if (moment(createdDate).isSame(currentDate, "day")) {
			return "Today";
		} else if (moment(createdDate).isSame(yesterDate, "day")) {
			return "Yesterday";
		}
		return `${moment(createdDate).format("MMMM DD YYYY, hh:ssA")}`;
	}
	return "";
};
