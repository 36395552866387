import React, { useState, useMemo, useEffect, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import InfiniteScroll from "react-infinite-scroll-component";
import Select from "react-select";

import { Button, Card, Col, Row, FormGroup, Label } from "reactstrap";
import videoApi from "services/video-api";
import { useMutation, useQuery } from "@tanstack/react-query";
import styles from "./VideoHome.module.scss";
import { toast } from "react-toastify";
import { useAuth } from "hooks/selectors/useAuth";
import { LoginModal } from "./components/LoginModal";
import VideoModal from "./components/VideoModal";
import classNames from "classnames";

import PresentImage from "assets/img/Video/upskilling100/present.png";
import Upsklling100Logo from "assets/img/Video/upskilling100/upsklling100-logo.png";
import Upsklling100LogoMask from "assets/img/Video/upskilling100/upskilling-logo-mask.png";
import Drawer from "@material-ui/core/Drawer";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { ReactComponent as MedalBadge } from "assets/img/Video/upskilling100/medal.svg";

const CURRENT_CAMPAIGN = 1;
const PAGE_SIZE = 5;
const YOUTUBE_CHANNEL_PLAYLIST_LINK =
	"https://www.youtube.com/playlist?list=PLJINtBkQponR8tIxOONRPlgTT12iQF_3h";

/**
 * handle incoming ads
 * track if user comes from an ad save in session
 * when they signup save and track a floag this user is resgister through an ad
 */

function VideoHome(props) {
	const isMobileMode = useMediaQuery("(max-width:768px)");
	const [page, setPage] = useState(1);
	const { userData } = useAuth();
	const [loginModalVislbe, setLoginModalVislbe] = useState(false);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const hasInitializedVideo = useRef(false);
	const [selectedCategories, setSelectedCategories] = useState([]);

	const fullVideoList = useQuery({
		queryKey: ["video", CURRENT_CAMPAIGN, selectedCategories],
		queryFn: async () => {
			const videos = await videoApi.getVideoList({
				campaign_id: CURRENT_CAMPAIGN,
				selectedCategories: selectedCategories
					.filter(Boolean)
					.map((selectedCategory) => selectedCategory.value),
			});

			const params = new URLSearchParams(window.location.search);
			const video_id = params.get("video_id");

			if (video_id && !hasInitializedVideo.current) {
				const selectedVidoe = videos.data.find((video) => video.id == video_id);
				setSelectedVideo(selectedVidoe);
				hasInitializedVideo.current = true;
			}

			return videos;
		},
	});

	const rankingQuery = useQuery({
		queryKey: ["video/ranking"],
		queryFn: videoApi.getRanking,
	});

	const currentVoteQuery = useQuery({
		queryKey: ["currentVote"],
		queryFn: videoApi.getCurrentVote,
		enabled: Boolean(userData),
	});

	const categoriesOptions = useQuery({
		queryKey: ["video/categories"],
		queryFn: videoApi.getCategories,
	});

	const currentVote = currentVoteQuery.data?.data;
	const toDisplayVideos = useMemo(() => {
		const videos = fullVideoList.data?.data;
		if (!videos) return [];
		return videos.slice(0, page * PAGE_SIZE);
	}, [fullVideoList.data, page]);

	const isLastPage = useMemo(() => {
		const videos = fullVideoList.data?.data;
		if (!videos) return false;
		return videos.length <= page * PAGE_SIZE;
	}, [page, fullVideoList.data]);

	const handleVoteSucess = () => {
		fullVideoList.refetch();
		currentVoteQuery.refetch();
		rankingQuery.refetch();
	};

	const validateVote = (video) => {
		if (!userData) {
			setLoginModalVislbe(true);
			return false;
		}
		return true;
	};

	useEffect(() => {
		if (selectedVideo) {
			const url = new URL(window.location.href);
			url.searchParams.set("video_id", selectedVideo.id);
			window.history.pushState(null, "", url.toString());
		}
	}, [selectedVideo?.id]);


	const confettRef = useRef();
	useEffect(() => {
		confettRef.current.childNodes.forEach((confetti, index) => {
			const randomLeft = Math.random() * 100;
			const randomDelay = Math.random() * 10;
			const randomDuration = Math.random() * 10 + 10;

			confetti.style.left = `${randomLeft}%`;
			confetti.style.animationDelay = `${randomDelay}s`;
			confetti.style.animationDuration = `${randomDuration}s`;
		});
	},[]);


	return (
		<div className={styles.area}>
			<ul className={styles.circles} ref={confettRef}>
				{[...Array(100)].map((_, index) => (
					<li key={index}></li>
				))}
			</ul>

			<Row
				style={{
					width: "100%",
					maxWidth: 1440,
					margin: "auto",
				}}>
				<Col xs={12} className='mb-4' style={{ backgroundColor:"black" }}>
					<div
						className={styles.hero_container}
					>
						<div className={`py-5 ${styles.hero_background}`} style={{flex:1,flexShrink:1}}>
							<div style={{ position: "relative" }}>
								<img
									src={Upsklling100Logo}
									alt='hero'
									style={{ width: "100px", height: "auto" }}
								/>
								<div
									style={{
										position: "absolute",
										maskImage: `url(${Upsklling100LogoMask})`,
										maskSize: "cover",
										width: "100px",
										height: "74px",
										top: 0,
									}}>
									<div className={styles.shining}></div>
								</div>
							</div>
							<h1 style={{ fontFamily: "GildaDisplay" }}>UPSKILLING100</h1>
							<p
								style={{
									color: "white",
									maxWidth: "400px",
								}}>
								Join our voting contest! Watch learner's videos on how our courses changed their lives and vote for your favourite!
								<span style={{
									fontWeight: "600",
									color: "#DEA651",
									fontStyle: "italic",
								}}> Winners will be picked at random, regardless of which video you vote for.</span>
							</p>
							{/* <a
								target="_blank"
								className={classNames(styles.btn_golden_outline, "btn btn-primary-info mt-2",styles.pulsing)}
								style={{
									maxWidth: "200px",
								}}
								href={"https://upskilling100.fca.edu.sg/"} rel="noreferrer">
									Go to Upskilling100
							</a> */}
						</div>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								backgroundSize: "auto 100%",
								backgroundRepeat: "no-repeat",
								flex: 1,
								flexShrink:1,
								backgroundPosition: "90% 50%",
							}}>
							<img src={PresentImage} alt="present" style={{ width:"100%" }} />
						</div>
					</div>
				</Col>

				<Col xs={12} md={9} className='mt-4'>
					<Row>
						{categoriesOptions.data?.data.map((categoryType, index) => {
							return (
								<Col xs={12} md={4} key={categoryType.id}>
									<FormGroup key={categoryType.id}>
										<Label
											for='exampleSelect'
											style={{
												color: "#C0924E",
											}}>
											{categoryType.category_type_title}
										</Label>
										<Select
											styles={{
												control: (provided, state) => ({
													...provided,
													backgroundColor: "#212121",
													color: "#C0924E",
												}),
												option: (provided, state) => ({
													...provided,
													backgroundColor: "#212121",
													color: "#C0924E",
												}),
												input: (provided, state) => ({
													...provided,
													color: "#C0924E",
												}),
												singleValue: (provided, state) => ({
													...provided,
													color: "#C0924E",
												}),
												placeholder: (provided, state) => ({
													...provided,
													color: "#C0924E",
												}),
											}}
											value={selectedCategories[index] ? selectedCategories[index] : null}
											isClearable
											onChange={(selectedOption) => {
												setSelectedCategories((selectedCategories) => {
													const newSelectedCategories = [...selectedCategories];
													if (selectedOption) {
														newSelectedCategories[index] = selectedOption;
													} else {
														newSelectedCategories[index] = null;
													}
													return newSelectedCategories;
												});
											}}
											options={categoryType.categories.map((category) => {
												return {
													value: category.id,
													label: category.category_title,
												};
											})}
										/>
									</FormGroup>
								</Col>
							);
						})}
						{/* 
							<Col xs={12} md={4}>
								<FormGroup>
									<Label for='exampleSelect'>Learning Outcomes</Label>
									<Select 
										options={[
											{
												value: "marketting",
												label: "🔈 marketting"
											},
											{
												value: "upskilling",
												label: "upskilling"
											}
										]} 
									/>
								</FormGroup>
							</Col>
						 */}
					</Row>
					<h2>Watch Now</h2>
					{isMobileMode ? ( <MobileRankingRibbon onClickVideo={(video) => { setSelectedVideo(video); }} />
					) : null}

					<InfiniteScroll
						style={{
							overflowY: "hidden",
							width: "100%",
							display: "grid",
							gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
							gap: "10px",
						}}
						dataLength={toDisplayVideos.length} //This is important field to render the next data
						next={() => {
							console.log("next", page);
							setPage((page) => page + 1);
						}}
						hasMore={!isLastPage}
						loader={
							<div className='justify-content-center mt-0 w-100 flex'>
								<span
									className='spinner-border spinner-border-sm'
									style={{ color: "#00ab84" }}></span>
								<span className='fs14 font-weight-bold ml-2' style={{ color: "#00ab84" }}>
									{"Loading"}
								</span>
							</div>
						}
						endMessage={
							<div className='justify-content-center mt-0 w-100 flex'>
								<span className='fs14 font-weight-bold ml-2' style={{ color: "#00ab84" }}>
								</span>
							</div>
						}
					// below props only if you need pull down functionality
					// refreshFunction={(setItems) => null}
					// pullDownToRefresh={false}
					// pullDownToRefreshThreshold={50}
					// pullDownToRefreshContent={
					// 	<h3 style={{ textAlign: "centser" }}># 8595; Pull down to refresh</h3>
					// }
					// releaseToRefreshContent={
					// 	<h3 style={{ textAlign: "center" }}># 8593; Release to refresh</h3>
					// }
					>
						{toDisplayVideos.map((single) => (
							<VideoCard
								key={single.id}
								video={single}
								onClick={() => {
									setSelectedVideo(single);
								}}
								currentVote={currentVote}
								setSelectedVideo={setSelectedVideo}
							/>
						))}
					</InfiniteScroll>

				</Col>

				<Col xs={0} md={3} className='d-none d-md-inline-block'>
					<RankingPanel
						animated
						onClickVideo={(video) => {
							setSelectedVideo(video);
						}}
					/>
				</Col>
			</Row>
			<LoginModal
				isOpen={loginModalVislbe}
				onSuccess={() => setLoginModalVislbe(false)}
				toggle={() => {
					setLoginModalVislbe(!loginModalVislbe);
				}}
			/>

			<VideoModal
				currentVote={currentVote}
				validateVote={validateVote}
				data={selectedVideo}
				isOpen={Boolean(selectedVideo)}
				onSuccess={() => {
					handleVoteSucess();
				}}
				onClose={() => {
					setSelectedVideo(null);
					const url = new URL(window.location.href);
					url.searchParams.delete("video_id");
					window.history.pushState(null, "", url.toString());
				}}
			/>
		</div>
	);
}

const TOP_NUMBER = 5;
const RankingPanel = (props) => {
	const rankingQuery = useQuery({
		queryKey: ["vidoe/ranking"],
		queryFn: videoApi.getRanking,
	});


	const top10list = useMemo(() => {
		return rankingQuery.data?.data?.slice(0, TOP_NUMBER);
	}, [rankingQuery.data]);

	return (
		<div style={{ position: "relative" }}>
			<div className={props.animated ? styles.conic : null}>
				<div
					style={{
						zIndex: 100,
						position: "relative",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						color: "#C0924E",
						background:
							"transparent linear-gradient(180deg, #100D0B 0%, #523C27 100%) 0% 0% no-repeat padding-box",
						padding: 10,
					}}>
					{
						props.closeButton 
							? <div className="d-flex justify-content-end" 
								onClick={() => { 
									props.onClose && props.onClose(null);
								}} 
								style={{
									height:30,
								}}>
								<i className='fa fa-times'></i>
							</div>
							: null
					}
					
					<h2
						style={{
							fontSize: "1.2em",
							color: "#C0924E",
						}}>
						Check Out Our Top {TOP_NUMBER} Learners
					</h2>
					<div style={{ gap: 10, display: "flex", flexDirection: "column" }}>
						{top10list?.map((single, index) => {
							if (index == 0) {
								return (
									<div
										key={single.id}
										onClick={() => {
											props.onClickVideo(single);
										}}>
										<div
											className={styles.video_thumbnail}
											style={{
												backgroundImage: `url(${single.video_thumbnail_url})`,
												cursor: "pointer",
												width: "100%",
												height: "auto",
												aspectRatio: "16/9",
												backgroundSize: "cover",
											}}>
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													width: "100%",
													height: "100%",
													backdropFilter: "brightness(40%)",
												}}>
												<div
													style={{
														display: "flex",
														flexGrow: 1,
														flex: 1,
														flexShrink: 0,
														padding: 10,
													}}>
													<div
														style={{
															display: "flex",
															alignItems: "center",
															justifyContent: "center",
															height: "100%",
															width: "100%",
														}}>
														<MedalBadge className={styles.vidoe_rank_1st_svg}  />
														<p className={styles.vidoe_rank_1st_svg_text} >
															{getRankText(single.rank)}
														</p>
													</div>
												</div>

												<div className='d-flex justify-content-between px-2 pb-2' style={{flexWrap:"wrap"}}>
													<p className={classNames("mb-0 color" ,styles.vidoe_rank_1st_title)}>
														{single.video_title}
													</p>
													<p className={classNames("mb-0 color" ,styles.vidoe_rank_1st_title)} >
														{single.votes_count} {single.votes_count > 1 ? "Votes" : "Vote"}
													</p>
												</div>
											</div>
										</div>
										<div className='dropdown-divider mt-3'></div>
									</div>
								);
							} else {
								return (
									<div key={single.id}>
										<div
											onClick={() => { props.onClickVideo(single); }}
											style={{
												cursor: "pointer",
												display:"flex"
											}}>
											<div
												style={{
													flex:3,
													flexShrink:0,
													backgroundImage: `url(${single.video_thumbnail_url})`,
													aspectRatio: "16/9",
													backgroundSize: "contain",
													backgroundPosition: "center",
													backgroundRepeat: "no-repeat",
												}}>
												<div
													style={{
														width: "100%",
														height: "100%",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														backdropFilter: "brightness(40%)",
													}}>
													<MedalBadge
														style={{
															width: "25px",
															height: "25px",
														}}
													/>
													<h1
														style={{
															color: "#DEA651",
															fontSize: "0.9em",
															marginBottom: 0,
															fontWeight: "600",
														}}>
														{getRankText(single.rank)}
													</h1>
												</div>
											</div>
											<div 
												className='ml-2 d-flex flex-column justify-content-between' 
												style={{ 
													flex:2,
													flexShrink:1,
												 }}
											>
												<div>
													<p className='mb-0' style={{ fontSize:"0.9em", color: "white", fontWeight: "700" }}>
														{single.video_title}
													</p>
													<p className='mb-0' style={{ fontSize:"0.8em",color: "white" }}>
														{single.video_description}
													</p>
												</div>
												<p className='mb-0' style={{ fontSize:"0.8em", color: "#AAAAAA", fontWeight: 500 }}>
													{single.votes_count} {single.votes_count > 1 ? "Votes" : "Vote"}
												</p>
											</div>
										</div>
										<div className='dropdown-divider mt-3'></div>
									</div>
								);
							}
						})}
					</div>
					<a
						target="_blank"
						href={YOUTUBE_CHANNEL_PLAYLIST_LINK}
						className={classNames(styles.btn_golden_outline, "btn btn-primary-info mt-2",styles.pulsing)}
						style={{
							fontSize: "1em",
						}} rel="noreferrer">
						Check Out Our <span style={{whiteSpace:"nowrap"}}>Youtube Channel</span> 
					</a>
				</div>
			</div>
		</div>
	);
};

const VideoIFrame = ({ src }) => {
	return (
		<iframe
			width='100%'
			height='100%'
			src={src}
			title='YouTube video player'
			allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
			referrerPolicy='strict-origin-when-cross-origin'
			allowFullScreen
			frameBorder='0'></iframe>
	);
};

const MobileRankingRibbon = (props) => {
	const [showDrawer, setShowDrawer] = useState(false);
	const rankingQuery = useRankingQuery();
	const top10list = useMemo(() => {
		return rankingQuery.data?.data?.slice(0, TOP_NUMBER);
	}, [rankingQuery.data]);

	return (
		<div>
			<Button
				style={{
					position: "fixed",
					height: 50,
					top: "calc(100vh - 50px)",
					width: "auto",
					maxWidth: "60%",
					backgroundColor: "black",
					borderWidth: 0,
					color: "#C0924E",
					zIndex: 999,
					right: 0,
				}}
				onClick={() => setShowDrawer(true)}>
				{rankingQuery.isSuccess ? (
					<SimpleRankingCarosel data={top10list}></SimpleRankingCarosel>
				) : (
					"Loading..."
				)}
			</Button>
			<Drawer
				style={{
					zIndex: 1041, // lower than the video modal
					backgroundColor: "transparent",
				}}
				anchor={"right"}
				open={showDrawer}
				onClose={() => {
					setShowDrawer(false);
				}}>
				<RankingPanel
					closeButton
					onClose={() => {
						props.onClickVideo(null);
						setShowDrawer(false);
					}}
					onClickVideo={(single) => {
						props.onClickVideo(single);
					}}
				/>
			</Drawer>
		</div>
	);
};

const useRankingQuery = () => {
	const rankingQuery = useQuery({
		queryKey: ["vidoe/ranking"],
		queryFn: videoApi.getRanking,
	});
	return rankingQuery;
};

const SimpleRankingCarosel = (props) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const childrenLength = props.data.length;
	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % childrenLength);
		}, 3000); // Change paragraph every 3 seconds

		return () => clearInterval(interval);
	}, [props.data.length]);


	if(!props.data || !props.data.length){
		return null;
	}

	return (
		<div className={styles.carousel}>
			<div className={styles["carousel-inner"]}>
				{props.data.map((video, index) => {
					return (
						<div
							key={video.id}
							className={`${styles["carousel-item"]} ${
								index === currentIndex ? styles.active : ""
							}`}>
							<div key={video.id} style={{ textAlign: "left" }}>
								<div>Top {video.rank} - {video.votes_count} { video.votes_count > 1 ? "Votes" :"Vote" }</div>
								<div className={styles.carousel_content} >
									{video.video_title}
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};


const VideoCard = React.memo(({
	video,
	onClick,
	currentVote,
	setSelectedVideo,
}) => { 
	return <Card
		className={styles.video_card}
		style={{
			height: "100%",
			backgroundColor: "#212121",
			padding: 5,
			overflow: "hidden",
			boxShadow: "none",
			justifyContent: "space-between",
		}}
		key={video.id}>
		<div style={{ aspectRatio: "16/9", position: "relative" }}>
			<div
				className={styles.video_thumbnail}
				onClick={onClick}
				style={{
					width: "100%",
					height: "100%",
					top: 0,
					position: "absolute",
					backgroundColor: "black",
					backgroundImage: `url(${video.video_thumbnail_url})`,
					backgroundSize: "cover",
					cursor:"pointer",
				}}></div>
		</div>
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "flex-start",
				justifyContent: "space-between",
				backgroundColor: "transparent",
				padding: "10px 5px",
				flexGrow:1
			}}>
			<div>
				<p className={`${styles.video_title}`}>{video.video_title}</p>
				<p className={`${styles.video_description}`}>{video.video_description}</p>
			</div>
			<div
				className='d-flex '
				style={{
					alignItems: "flex-start",
					justifyContent: "center",
				}}>
				<div
					style={{
						textAlign: "right",
					}}>
					<p className={classNames(styles.video_vote, "mb-0")}>
						{video.votes_count ? `${video.votes_count} Votes` : null}
					</p>
				</div>
			</div>
		</div>
		<Button
			disabled={currentVote?.video_id == video.id}
			className={classNames(styles.btn_golden, "m-2")}
			onClick={() => {
				setSelectedVideo(video);
			}}>
			{

				currentVote?.video_id == video.id 
					? "you have voted for this "
					: "View "
			}
		</Button>
	</Card>;
});


VideoCard.displayName = "VideoCard";

// export default Dashboard;
export default React.memo(VideoHome);

function getRankText(rank) {
	if (rank == 1) {
		return "1st";
	} else if (rank == 2) {
		return "2nd";
	} else if (rank == 3) {
		return "3rd";
	} else {
		return `${rank}th`;
	}
}
