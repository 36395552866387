import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Row, Card, CardBody, Col } from "reactstrap";
import generalApi from "services/general-api";
import { getErrorMessage } from "utilities/error";
import _ from "lodash";
import { saveAs } from "file-saver";
import { ReactComponent as DownloadIcon } from "assets/img/Course/upcoming/download.svg";

function ModalAssessmentDownload() {
	const location = useLocation();
	const learner_id = location.state?.learner_id;
	const course = location.state?.course;
	const status = location.state?.status;
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isDownloadedArray, setIsDownloadedArray] = React.useState([]);
	const [isLoadingArray, setIsLoadingArray] = React.useState([]);
	const [assessmentDetails, setAssessmentDetails] = useState(null);

	useEffect(() => {
		getCourseAssessmentDetails();
	}, [course]);

	const getCourseAssessmentDetails = () => {
		setIsLoading(true);
		setIsError(false);
		generalApi.getCourseAssessmentDetails(course.course.course_id).then(
			(response) => {
				let downloadArray = [];

				response && response.data &&
				response.data.items &&
				response.data.items.map(() => {
					downloadArray.push(false);
				});
				setIsDownloadedArray(downloadArray);
				setIsLoadingArray(downloadArray);
				setIsLoading(false);
				setAssessmentDetails( response.data);
				setIsError(false);
			},
			(error) => {
				toast.error(getErrorMessage(error));
				setIsLoading(false);
				setAssessmentDetails(null);
				setIsError(true);
			},
		);
	};

	const handlePress = async (item) => {
		window.open(item.link, "_blank");

		// if (item.resourceitem_type === "link") {
		// 	window.open(item.resourceitem_original_url, "_blank");
		// } else if (item.resourceitem_type === "file") {
		// 	const filename = _.last(item.resourceitem_original_url.split("/"))?.split("?")?.[0];
		// 	saveAs( item.resourceitem_original_url, filename);
		// }
	};

	const renderOpenURLButton = (item, index) => {
		return (
			<div style={{ cursor: "pointer" }} key={index} onClick={() => handlePress(item)}>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<p style={{fontSize: 14, fontWeight: "600", marginRight: 10}}>{`${index + 1}.`}</p>
					<div style={{flex: 1}}>
						<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
							<p  style={{fontSize: 14, fontWeight: "600"}}>{`${item.default_filename}`}</p>
							{
								!isDownloadedArray[index] &&
								!isLoadingArray[index] && (
									<DownloadIcon className="svg-fill-blue" height={20} width={20} style={{marginRight: 10}} />
								)}
						</div>
						<p  style={{fontSize: 14, textDecorationLine: "underline", color: "rgb(55,132,196)"}}>{`${item.link}`}</p>
					</div>
				</div>
				<hr className='mt-3 mb-3'></hr>
			</div>
		);
	};

	return (
		<>
			<div className=''>
				<div className='title-container'>
					<h2>{`${course && course.course ? course.course.course_title : ""}`}</h2>
					<h3 className="text-blue">Download Assessment</h3>
				</div>
				{isLoading && (
					<div className='justify-content-center mt-4 w-100 flex'>
						<span className='spinner-border spinner-border-sm'></span>
					</div>
				)}
				{!isLoading && isError && (
					<div onClick={() => getCourseAssessmentDetails()}>
						<Card className='mt-4'>
							<CardBody className='flex justify-content-center'>
								<div className='btn btn-gradient'>
									<span>Reload</span>
								</div>
							</CardBody>
						</Card>
					</div>
				)}

				{!isLoading && !isError && assessmentDetails && (
					<div>
						<Card className='mt-4'>
							<CardBody>
								{assessmentDetails.map((assessment, i) => (
									<div key={isLoading}>
										{
											renderOpenURLButton(assessment, i)
										}
									</div>
								))}
								
								{/* <Row>
									<Col md='12' xl='12' className='mt-6'>
										<div className='flex mb-4 align-items-center'>
											<Link
												to='/course/download-resources'
												state={{ course: course }}
												className='btn btn-default ml-auto'>
												{"Back"}
											</Link>
										</div>
									</Col>
								</Row> */}
							</CardBody>
						</Card>
					</div>
				)}
				
			</div>
		</>
	);
}

export default ModalAssessmentDownload;
