import {
	GET_EVENT_FIRST,
	GET_EVENT,
	GET_EVENT_SUCCESS,
	GET_EVENT_FAIL,
	GET_DAILY_NEWS,
	GET_DAILY_NEWS_SUCCESS,
	GET_DAILY_NEWS_FAIL,
	GET_TEASERS,
	GET_TEASERS_SUCCESS,
	GET_TEASERS_FAIL,
	ARTICLE_LIKE,
	ARTICLE_LIKE_SUCCESS,
	ARTICLE_LIKE_FAIL,
	ARTICLE_SHARE,
	ARTICLE_SHARE_SUCCESS,
	ARTICLE_SHARE_FAIL,
	SET_CURRENT_NEWS_INDEX,
} from "../actions/types";


const initialState = { events: [], daily_news: [], teasers: [],isGetTeasers: false, isGetEvent: false, isGetDailyNews: false, currentTabIndex: 0};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
	case GET_EVENT_FIRST:
		return {
			...state,
			isGetEvent: true,
			events: [],
		};
	case GET_EVENT:
		return {
			...state,
			isGetEvent: true,
		};
	case GET_EVENT_SUCCESS:
		return {
			...state,
			events: payload.page != 0 ?[...state.events, ...payload.events]: payload.events,
			isGetEvent: false,
		};
	case GET_EVENT_FAIL:
		return {
			...state,
			events: null,
			isGetEvent: false,
		};
			
	case GET_DAILY_NEWS:
		return {
			...state,
			isGetDailyNews: true,
		};
	case GET_DAILY_NEWS_SUCCESS:
		return {
			...state,
			daily_news: payload.page != 0 ?[...state.daily_news, ...payload.daily_news]: payload.daily_news,
			isGetDailyNews: false,
		};
	case GET_DAILY_NEWS_FAIL:
		return {
			...state,
			daily_news: null,
			isGetDailyNews: false,
		};

	case GET_TEASERS:
		return {
			...state,
			isGetTeasers: true,
		};
	case GET_TEASERS_SUCCESS:
		return {
			...state,
			teasers: payload.page != 0 ?[...state.teasers, ...payload.teasers]: payload.teasers,
			isGetTeasers: false,
		};
	case GET_TEASERS_FAIL:
		return {
			...state,
			teasers: null,
			isGetTeasers: false,
		};
	
	case ARTICLE_LIKE: {
		let currentDailyNews = JSON.parse(JSON.stringify(state.daily_news));
		if (currentDailyNews.length > 0) {
			let index = currentDailyNews.findIndex((y) => y.article_id.toString() === payload.article_id);
			if (index !== -1) {
				currentDailyNews[index].liked = payload.liked === true ? 0 : 1;
				currentDailyNews[index].likes_count = payload.liked === true ? currentDailyNews[index].likes_count - 1 : currentDailyNews[index].likes_count + 1;
				return {
					...state,
					daily_news: currentDailyNews,
				};
			} else {
				return {
					...state,
				};
			}
		} else {
			return {
				...state,
			};
		}
	}
	case ARTICLE_LIKE_SUCCESS: {
		return {
			...state,
		};
	}
	case ARTICLE_LIKE_FAIL:{
		let currentDailyNews = JSON.parse(JSON.stringify(state.daily_news));
		if (currentDailyNews.length > 0) {
			let index = currentDailyNews.findIndex((y) => y.article_id.toString() === payload.article_id);
			if (index !== -1) {
				currentDailyNews[index].liked = payload.liked === true ? 1 : 0;
				currentDailyNews[index].likes_count = payload.liked === true ? currentDailyNews[index].likes_count + 1 : currentDailyNews[index].likes_count - 1;
				return {
					...state,
					daily_news: currentDailyNews,
				};
			} else {
				return {
					...state,
				};
			}
		} else {
			return {
				...state,
			};	
		}
		
	}

	case ARTICLE_SHARE: {
		let currentDailyNews = JSON.parse(JSON.stringify(state.daily_news));
		if (currentDailyNews.length > 0) {
			let index = currentDailyNews.findIndex((y) => y.article_id.toString() === payload.article_id);
			if (index !== -1) {
				currentDailyNews[index].shares_count = currentDailyNews[index].shares_count + 1;
				return {
					...state,
					daily_news: currentDailyNews,
				};
			} else {
				return {
					...state,
				};
			}
		} else {
			return {
				...state,
			};
		}
	}
	case ARTICLE_SHARE_SUCCESS: {
		return {
			...state,
		};
	}
	case ARTICLE_SHARE_FAIL:{
		let currentDailyNews = JSON.parse(JSON.stringify(state.daily_news));
		if (currentDailyNews.length > 0) {
			let index = currentDailyNews.findIndex((y) => y.article_id.toString() === payload.article_id);
			if (index !== -1) {
				currentDailyNews[index].shares_count = currentDailyNews[index].likes_count - 1;
				return {
					...state,
					daily_news: currentDailyNews,
				};
			} else {
				return {
					...state,
				};
			}
		} else {
			return {
				...state,
			};	
		}
		
	}
	case SET_CURRENT_NEWS_INDEX:
		return {
			...state,
			currentTabIndex: payload.currentIndex,
		};
	
	default:
		return state;
	}
}
