import { Row, Col } from "reactstrap";

function CourseInfo({
	course_title,
	course_logos,
	course_duration,
	course_categories,
	courserun_modeoftraining,
	course_description,
	course_registered_learners,
}) {
	return (
		<div className="course-inner-info">
			<Row className='justify-content-between'>
				<Col md='auto' xl='auto' xs='auto'>
					{course_logos.map((logo, i) => (
						<img
							key={i}
							className='m-1 h-40px display_ini'
							alt={course_title}
							src={logo.course_logo_image}
						/>
					))}
				</Col>
			</Row>
			<Row className='align-items-center pt-3'>
				<Col md='12' xl='12'>
					<h5>{course_title}</h5>
				</Col>
			</Row>
			<Row className='align-items-center pt-3'>
				<Col className='col-12'>
					{/* {course_duration && (
						<span className='text-gray-light days'>{`${course_duration} Days`}</span>
					)} */}

					{course_categories ? (
						<>
							{course_categories.map((category, i) => {
								return (
									<span
										key={i}
										style={{
											backgroundColor: category.category_color,
										}}
										className='tag m-2 text-white'>
										{category.category_title}
									</span>
								);
							})}
						</>
					) : (
						""
					)}
				</Col>
			</Row>
			<Row className='align-items-center pt-3'>
				<Col md='12' xl='12'>
					{/* <img
						alt='Training Support'
						className='pb-1 w-20px'
						src={require("assets/img/learner/user_icon.png")}
					/> */}
					<i className='fa fa-users text-fca-blue' aria-hidden='true'></i>{" "}
					<label className='m-0 pl-2 text-dark'>
						{course_registered_learners} Learners already enrolled
					</label>
				</Col>
			</Row>

			<hr></hr>

			<Row className='course_detail'>
				<Col md='12' xl='12'>
					<b>This course includes:</b>
				</Col>
				<Col md='auto' xl='auto' className='p-2'>
					<div className='d-flex p-2 align-items-center'>
						<i className='fa fa-clock text-fca-blue' aria-hidden='true'></i>
						<label className='m-0 pl-2 text-dark'>{course_duration + " Days of Learning"}</label>
					</div>
				</Col>

				<Col md='auto' xl='auto' className='p-2'>
					<div className='d-flex p-2 align-items-center'>
						<i className='fas fa-chalkboard-teacher text-fca-blue' aria-hidden='true'></i>
						<label className='m-0 pl-2 text-dark'>{courserun_modeoftraining}</label>
					</div>
				</Col>

				<Col md='auto' xl='auto' className='p-2'>
					<div className='d-flex p-2 align-items-center'>
						<i className='fas fa-certificate text-fca-blue' aria-hidden='true'></i>
						<label className='m-0 pl-2 text-dark'>Course Accreditation</label>
					</div>
				</Col>

				{course_description && (
					<Col md='12' xl='12' className='p-2'>
						<div className='px-2'>
							<p
								dangerouslySetInnerHTML={{
									__html: course_description,
								}}></p>
						</div>
					</Col>
				)}
			</Row>
		</div>
	);
}

export default CourseInfo;
