import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
import authHeader from "./auth-header";
import { filter } from "lodash";

const getVideoList = (option) => {
	const campaign_id = option.campaign_id;
	const categories = option.selectedCategories;
	// TODO: THE COOKIE OR SESSON NOT BEING SENT 
	// SO THE SEED NEVER PERSIST
	return axios
		.get(API_URL + "/api/mobile/video", {
			withCredentials: true,
			headers: authHeader(),
			params: {
				filter_campaign_id: campaign_id,
				filter_categories: categories,
			},
		})
		.then((response) => {
			return response.data;
		});
};

const getRanking = () => {
	return axios
		.get(API_URL + "/api/mobile/video/ranking", { headers: authHeader() })
		.then((response) => {
			return response.data;
		});
};


const vote = (video_id) => {
	return axios
		.post(API_URL + "/api/mobile/video/vote", { video_id }, { headers: authHeader() })
		.then((response) => {
			return response.data;
		});
};

const getCurrentVote = (campaign_id) => {
	return axios
		.get(API_URL + "/api/mobile/video/currentVote", {
			headers: authHeader(),
			params: { campaign_id: campaign_id },
		})
		.then((response) => {
			return response.data;
		});
};


const getCategories = () => {
	return axios
		.get(API_URL + "/api/mobile/video/categories", {
			headers: authHeader(),
		})
		.then((response) => {
			return response.data;
		});
};


export default {
	getVideoList,
	getRanking,
	vote,
	getCurrentVote,
	getCategories,
};
