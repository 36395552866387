export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const OTP_SUCCESS = "OTP_SUCCESS";
export const OTP_FAIL = "OTP_FAIL";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAIL = "RESEND_OTP_FAIL";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const LOGOUT = "LOGOUT";
export const RESET_EMAIL_SUCCESS = "RESET_EMAIL_SUCCESS";
export const RESET_EMAIL_FAIL = "RESET_EMAIL_FAIL";

export const GET_PROFILE_BUNDLE_SUCCESS = "GET_PROFILE_BUNDLE_SUCCESS";
export const GET_PROFILE_BUNDLE_FAIL = "GET_PROFILE_BUNDLE_FAIL";

export const RESET_USER = "RESET_USER";

// hyper admin
export const HYPERADMIN_RESEND_OTP_SUCCESS = "HYPERADMIN_RESEND_OTP_SUCCESS";
export const HYPERADMIN_RESEND_OTP_FAIL = "HYPERADMIN_RESEND_OTP_FAIL";
export const HYPERADMIN_OTP_SUCCESS = "HYPERADMIN_OTP_SUCCESS";
export const HYPERADMIN_OTP_FAIL = "HYPERADMIN_OTP_FAIL";
export const HYPERADMIN_SEARCH_USER_SUCCESS = "HYPERADMIN_SEARCH_USER_SUCCESS";
export const HYPERADMIN_SEARCH_USER_FAIL = "HYPERADMIN_SEARCH_USER_FAIL";
export const HYPERADMIN_LOGIN_SUCCESS = "HYPERADMIN_LOGIN_SUCCESS";
export const HYPERADMIN_LOGIN_FAIL = "HYPERADMIN_LOGIN_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

// notification
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL";

// news
export const GET_EVENT_FIRST = "GET_EVENT_FIRST";
export const GET_EVENT = "GET_EVENT";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_FAIL = "GET_EVENT_FAIL";
export const GET_DAILY_NEWS = "GET_DAILY_NEWS";
export const GET_DAILY_NEWS_SUCCESS = "GET_DAILY_NEWS_SUCCESS";
export const GET_DAILY_NEWS_FAIL = "GET_DAILY_NEWS_FAIL";
export const GET_TEASERS = "GET_TEASERS";
export const GET_TEASERS_SUCCESS = "GET_TEASERS_SUCCESS";
export const GET_TEASERS_FAIL = "GET_TEASERS_FAIL";
export const ARTICLE_LIKE = "ARTICLE_LIKE";
export const ARTICLE_LIKE_SUCCESS = "ARTICLE_LIKE_SUCCESS";
export const ARTICLE_LIKE_FAIL = "ARTICLE_LIKE_FAIL";
export const ARTICLE_SHARE = "ARTICLE_SHARE";
export const ARTICLE_SHARE_SUCCESS = "ARTICLE_SHARE_SUCCESS";
export const ARTICLE_SHARE_FAIL = "ARTICLE_SHARE_FAIL";
export const SET_CURRENT_NEWS_INDEX = "SET_CURRENT_NEWS_INDEX";