/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// reactstrap components
import { Alert, Container} from "reactstrap";
import { useLocation } from "react-router-dom";
import {
	Card,
} from "reactstrap";
import { dateFormatting } from "utilities/date";
import notificationApi from "services/notification-api";
import { toast } from "react-toastify";
import AlertDialog from "components/AlertDialog";

function NotificationDetails() {
	let { state } = useLocation();
	const [notification, setNotification] = React.useState(state.notification);
	const [isButtonLoading, setIsButtonLoading] = React.useState(false);
	const [dialogDesc, setDialogDesc] = React.useState("");
	const [isDialogOpen, setIsDialogOpen] = React.useState(false);
	const [replyContent, setReplyContent] = React.useState("");

	useEffect(() => {
		if (notification && notification.pushnotificationrecord_seen_at === null) {
			notificationApi.checkNotification(notification.notification.pushnotification_id.toString()).then(
				(response) => {
				},
				(error) => {
				},
			);
		}
		
	}, []);

	const replyNotification = (pushnotification_id, reply_content) => {
		setIsButtonLoading(true);
		notificationApi.replyNotification(pushnotification_id, reply_content).then(
			(_data) => {
				setNotification(_data.data);
				setIsButtonLoading(false);
			},
			(error) => {
				toast.error(error);
				setIsButtonLoading(false);
			},
		);
	};

	const getSmartBold = (desc) => {
		if (desc) {
			// Split the text around **
			const arr = desc.split("**");

			// Here we will store an array of Text components
			const newTextArr = [];

			// Loop over split text
			arr.forEach((element, index) => {
				const arrLink = element.split("##");
				if (arrLink.length > 1) {
					arrLink.forEach((elementLink, linkIndex) => {
						if (linkIndex % 2 !== 0) {
							// Wrap with bold text style
							const newElement = (
								<span onClick={() => window.open(elementLink, "_blank")} style={{fontWeight: "bold", textDecorationLine: "underline", color: "rgb(55,132,196)"}} key={`${index}-${linkIndex}`}>
									{elementLink}
								</span>
							);
							newTextArr.push(newElement);
						} else {
							// Simple Text
							const newElement = (
								<span key={`${index}-${linkIndex}`}>
									{elementLink}
								</span>
							);
							newTextArr.push(newElement);
						}
					});
				} else {
					// If its an odd element then it is inside **...** block
					if (index % 2 !== 0) {
						// Wrap with bold text style
						const newElement = (
							<span key={index} style={{fontWeight: "bold"}}>
								{element}
							</span>
						);
						newTextArr.push(newElement);
					} else {
						// Simple Text
						const newElement = <span key={index}>
							{element}
						</span>;
						newTextArr.push(newElement);
					}
				}
			});

			return newTextArr;
		}
		return desc;
	};

	let notificationButton =
	notification && notification.button_content 
		? JSON.parse(notification.button_content)
		: null;

	const renderDiertaryRequirement = (buttons) => {
		if (buttons.length > 0) {
			return (
				<div style={{display: "flex", flexDirection: "row", marginTop: "20px"}}>
					{buttons.map((button, index) => {
						return (
							<button
								disabled={isButtonLoading}
								style={{
									flex: 1,
									flexShrink: 1,
									outline: "none",
									marginLeft: index % 2 === 0 ? "0" : "10px",
									marginRight: index % 2 === 0 ? "10px" : "0",
									padding: "10px",
									backgroundColor: "white",
									borderWidth: "1px",
									borderColor: "#adb5bd",
									borderStyle: "solid",
								}}
								key={`${button.payload}-${index}`}
								onClick={() => {
									setDialogDesc(`Confirm "${button.text} as your response"?`,);
									setReplyContent(`"${button.payload}"`);
									setIsDialogOpen(true);
								}}
							>
								{isButtonLoading && <span className='spinner-border spinner-border-sm'></span>}
								{!isButtonLoading && 
								<div style={{ alignItems: "center" }}>
									<p style={{ fontWeight: "600" }}>{button.text}</p>
								</div>
								}
							</button>
						);
					})}
				</div>
			);
		}
		return null;
	};

	const renderAcknowledge = (buttons) => {
		if (buttons.length > 0) {
			return (
				<div style={{display: "flex", flexDirection: "row", marginTop: "20px"}}>
					{buttons.map((button, index) => {
						return (
							<button
								disabled={isButtonLoading}
								style={{
									flex: 1,
									flexShrink: 1,
									outline: "none",
									marginLeft: index % 2 === 0 ? "0" : "10px",
									marginRight: index % 2 === 0 ? "10px" : "0",
									padding: "10px",
									backgroundColor: "white",
									borderWidth: "1px",
									borderColor: "#adb5bd",
									borderStyle: "solid",
								}}
								key={`${button.payload}-${index}`}
								onClick={() => {
									setDialogDesc(`Confirm "${button.text} as your response"?`,);
									setReplyContent(`"${button.payload}"`);
									setIsDialogOpen(true);
								}}
							>
								{isButtonLoading && <span className='spinner-border spinner-border-sm'></span>}
								{!isButtonLoading && 
								<div style={{ alignItems: "center" }}>
									<p style={{ fontWeight: "600" }}>{button.text}</p>
								</div>
								}
							</button>
						);
					})}
				</div>
			);
		}
		return null;
	};

	const renderButton = (buttonContent) => {
		if (buttonContent && buttonContent.buttonType === "dietary_requirement") {
			return renderDiertaryRequirement(buttonContent.button);
		} else if (buttonContent && buttonContent.buttonType === "acknowledge") {
			return renderAcknowledge(buttonContent.button);
		}
		return null;
	};

	return (
		<Container className='profile-wrap' fluid>
			<Card className='h-100 p-3 pl-4 pr-4'>
				<p className="notification-details-date">
					{`${dateFormatting(
						notification && notification.created_at,
					)}`}
				</p>
				<p className="notification-details-title mb-3">
					{notification && notification.notification.pushnotification_title}
				</p>
			
				<p className="notification-details-description m-0 mb-3"
				>
					{getSmartBold(notification && notification.notification.pushnotification_description)}
				</p>
				{notification && notification.is_reply === 0 && renderButton(notificationButton)}
				{notification && notification.is_reply === 1 && (
					<p className="notification-details-button-responded mb-3">
						{`You had responded ${notification.reply_content}`}
					</p>
				)}
			</Card>
			<AlertDialog isOpen={isDialogOpen} title={notification && notification.notification.pushnotification_title || "Notification Response"} description={dialogDesc} onCancel={() => setIsDialogOpen(false)} onAgree={() => replyNotification(notification.notification.pushnotification_id.toString(), replyContent)} />
		</Container>
	);
}

export default NotificationDetails;
