import React, { useEffect } from "react";
import styles from "./CourseDetails.module.scss";
import defaultLogo from "assets/img/FALCOMM_LOGO.png";
import { Col, Row } from "reactstrap";
import { ReactComponent as BookIcon } from "assets/img/Checkout/Success/icon_book.svg";
import { ReactComponent as CalendarIcon } from "assets/img/Checkout/Success/icon_cal.svg";
import { ReactComponent as LearnIcon } from "assets/img/Checkout/Success/icon_learn.svg";
import { ReactComponent as LocateIcon } from "assets/img/Checkout/Success/icon_locate.svg";
import { ReactComponent as PhoneIcon } from "assets/img/Checkout/Success/icon_phone.svg";
import { ReactComponent as StudentIcon } from "assets/img/Checkout/Success/icon_student.svg";
import { ReactComponent as TimeIcon } from "assets/img/Checkout/Success/icon_time.svg";
import trainerImg from "assets/img/Course/profile-dummy.jpg";

function CourseDetails({ logos, courseTitle, courseId, location, courseDate, courseTimings, learningMode, studentSupportName, studentSupportContact, trainerImage, trainerName, trainerDesc }) {
	return (
		<div>
			<div className={styles.course_logo_container}>
				{logos && logos.map((logo, i) => (
					<img
						key={i}
						className={styles.course_logo}
						alt={"test"}
						src={logo}
					/>
				))}
			</div>
			<span className={styles.course_title}>{courseTitle}</span>
			<hr className="mb-4 mt-3 w-100"></hr>
			<Row>
				<Col xl='3'>
					<div className={styles.course_info_wrap}>
						<div className={styles.course_info_title_container}>
							<BookIcon className={styles.course_info_title_icon} />
							<span className={styles.course_info_title}>Course ID</span>
						</div>
						<div className={styles.course_info_description}>{courseId}</div>
					</div>
				</Col>
				<Col xl='3'>
					<div className={styles.course_info_wrap}>
						<div className={styles.course_info_title_container}>
							<LocateIcon className={styles.course_info_title_icon} />
							<span className={styles.course_info_title}>Location</span>
						</div>
						<div className={styles.course_info_description}>{location}</div>
					</div>
				</Col>
				<Col xl='3'>
					<div className={styles.course_info_wrap}>
						<div className={styles.course_info_title_container}>
							<CalendarIcon className={styles.course_info_title_icon} />
							<span className={styles.course_info_title}>Course Dates</span>
						</div>
						<div className={styles.course_info_description}>{courseDate}</div>
					</div>
				</Col>
				<Col xl='3'>
					<div className={styles.course_info_wrap}>
						<div className={styles.course_info_title_container}>
							<TimeIcon className={styles.course_info_title_icon} />
							<span className={styles.course_info_title}>Course Timings</span>
						</div>
						<div className={styles.course_info_description}>
							{courseTimings.map((timing, index) => (
								<div key={index}>{timing}</div>
							))}
						</div>
					</div>
				</Col>
				<Col xl='3'>
					<div className={styles.course_info_wrap}>
						<div className={styles.course_info_title_container}>
							<LearnIcon className={styles.course_info_title_icon} />
							<span className={styles.course_info_title}>Learning Mode</span>
						</div>
						<div className={styles.course_info_description}>{learningMode}</div>
					</div>
				</Col>
				<Col xl='3'>
					<div className={styles.course_info_wrap}>
						<div className={styles.course_info_title_container}>
							<StudentIcon className={styles.course_info_title_icon2} />
							<span className={styles.course_info_title}>Student Support Executive</span>
						</div>
						<div className={styles.course_info_description}>{studentSupportName}</div>
					</div>
				</Col>
				<Col xl='3'>
					<div className={styles.course_info_wrap}>
						<div className={styles.course_info_title_container}>
							<PhoneIcon className={styles.course_info_title_icon} />
							<span className={styles.course_info_title}>Contact Support</span>
						</div>
						<div className={styles.course_info_description}>{studentSupportContact}</div>
					</div>
				</Col>
				<Col xl='3'>
					<div className={styles.course_info_trainer_wrap}>
						<img
							className={styles.course_info_trainer_image}
							alt={"test"}
							src={trainerImage ? trainerImage : trainerImg}
						/>
						<div className={styles.course_info_trainer_title_container}>
							<span className={styles.course_info_title}>Trainer</span>
							<div className={styles.course_info_description}>{trainerName}</div>
							<div className={styles.course_trainer_info_description}>{trainerDesc}</div>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
}

export default CourseDetails;
