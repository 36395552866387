import React, { useState } from "react";
// reactstrap components
import { Card, CardBody, Row, Col, CardHeader, Collapse } from "reactstrap";
import "../../assets/scss/custom/_inner-html.scss";

function CollapsibleCard({ headerText, body }) {
	const [accordionOpen, setAccordionOpen] = useState(false);

	const toggleAccordion = () => {
		setAccordionOpen(!accordionOpen);
	};

	let newDescHtml = body;
	newDescHtml = newDescHtml.split("<oembed").join("<iframe");
	newDescHtml = newDescHtml.split("</oembed>").join("</iframe>");
	newDescHtml = newDescHtml.split("url=").join("src=");

	return (
		<div className=''>
			<Card className="mb-0">
				<CardHeader onClick={toggleAccordion} className='cursor-pointer'>
					<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
						<b style={{ marginRight: 10 }}>{headerText}</b>
						{accordionOpen ? (
							<i className='fa fa-times'></i>
						) : (
							<i className='fa fa-plus'></i>
						)}
					</div>
				</CardHeader>
				<Collapse isOpen={accordionOpen}>
					<CardBody className="pb-0">
						<div className="inner-html" dangerouslySetInnerHTML={{ __html: newDescHtml }}></div>
					</CardBody>
					<hr className="mb-0"></hr>

				</Collapse>
			</Card>
		</div>
	);
}

export default CollapsibleCard;
