/*!

=========================================================
* Argon Dashboard PRO React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, Navigate } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
import * as yup from "yup";
// eslint-disable-next-line
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form";
// reactstrap components
import {
	Button,
	Card,
	CardBody,
	FormGroup,
	Form,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Row,
	Col,
} from "reactstrap";
// core components
import styles from "assets/scss/module/auth/login.module.scss";

import AuthService from "../../../services/auth-api";
import { clearMessage } from "actions/message";
import { hyperAdminSearchUser } from "actions/auth";
import { toast } from "react-toastify";
import { hyperAdminLogin } from "actions/auth";

const schema = yup
	.object({
		email: yup.string().email().required(),
	})
	.required();

function HyperAdminUserSelection() {
	let navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [users, setUsers] = React.useState([]);
	const [selectedId, setSelectedId] = React.useState(null);
	const dispatch = useDispatch();
	const { message } = useSelector((state) => state.message);
	const { user: currentUser, isLoggedIn, isHyperAdminOTP } = useSelector((state) => state.auth);


	useEffect(() => {
		if (!isHyperAdminOTP || isLoggedIn) {
			navigate("/");
		}
	}, []);


	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const handleSendOTP = (data) => {
		let email = data.email;
		setLoading(true);
		dispatch(hyperAdminSearchUser(email))
			.then((data) => {
				setLoading(false);
				if (data && data.length > 0) {
					setUsers(data);
				} else {
					setUsers([]);
				}
			})
			.catch(() => {
				setLoading(false);
			});
        
	};

	const handleHyperAdminLogin = () => {
		setLoading(true);
		if (selectedId === null) {
			toast.error("Please select an user");
			setLoading(false);
			return;
		}
		dispatch(hyperAdminLogin(selectedId))
			.then((data) => {
				navigate("/");
			})
			.catch(() => {
				setLoading(false);
			});
	};

	React.useEffect(() => {
		dispatch(clearMessage());
	}, []);

	return (
		<>
			<Container className=''>
				<Row className='justify-content-center'>
					<Col lg='12' md='12'>
						<Card className='bg-white border-0 mb-0'>
							<CardBody className='px-lg-4 py-lg-'>
								<div className=' text-muted mb-4'>
									<h4>Type in email to search for user.</h4>
								</div>
								<Form id='login_form' onSubmit={handleSubmit(handleSendOTP)}>
									<FormGroup>
										<InputGroup
											className={"input-group-merge " + (errors.email ? "is-invalid" : "")}
										>
											<InputGroupAddon addonType='prepend'>
												<InputGroupText
													className={"form-control " + (errors.email ? "is-invalid" : "")}
												>
													<i className='ni ni-email-83' />
												</InputGroupText>
											</InputGroupAddon>
											<input
												placeholder='Email'
												type='email'
												className={"form-control " + (errors.email ? "is-invalid" : "")}
												{...register("email")}
											/>
										</InputGroup>
										<div className='invalid-feedback'>{errors.email?.message}</div>
									</FormGroup>

									<div className='text-center'>
										<Button
											className={classnames("btn-gradient w-100", styles.submit_button)}
											type='submit'
											disabled={loading}
										>
											{loading && <span className='spinner-border spinner-border-sm'></span>}
											<span>Search</span>
										</Button>
									</div>

									{message && (
										<div className='form-group'>
											<div className='alert alert-danger' role='alert'>
												{message}
											</div>
										</div>
									)}
								</Form>
								{users.length > 0 && (
									<div className='text-muted mt-4 mb-2'>
										<h4>Select user</h4>
									</div>
								)}
								{users.length > 0 && users.map((user, index) => {
									return(
										<div onClick={() => setSelectedId(user.learnermaster_id)} key={`${user.learnermaster_id}-${index}`} style={{backgroundColor: selectedId === user.learnermaster_id ? "rgba(79,131,190, 0.1)" : "transparent",  border: selectedId === user.learnermaster_id ? "1px solid rgb(79,131,190)" : "1px solid rgb(204,204,204)", marginBottom: "10px", padding: "10px"}}>
											<p style={{ color: "rgb(79,131,190)", fontWeight: "bold" }}>{user.learnermaster_name} ({user.learnermaster_account_type}) </p>
											<p style={{ fontSize: "12px", fontWeight: "bold" }}>{user.learnermaster_email}</p>
											{
												user.corporate 
													?  <p style={{ fontSize: "12px", fontWeight: "bold" }}>{user.corporate.corporate_name}</p>
													:  null
											}
										</div>
									);
								})
								}
								{users.length > 0 && (
									<div className='text-center'>
										<Button
											className={classnames("btn-gradient w-100", styles.submit_button)}
											onClick={handleHyperAdminLogin}
											disabled={loading}
										>
											{loading && <span className='spinner-border spinner-border-sm'></span>}
											<span>Login</span>
										</Button>
									</div>

								)}
							
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default HyperAdminUserSelection;
