import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interaction from "@fullcalendar/interaction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Button, Row, Modal, Card, CardBody, Col, CardHeader, Collapse } from "reactstrap";

import { ReactComponent as BusIcon } from "assets/img/Course/upcoming/bus.svg";
import { ReactComponent as DownloadIcon } from "assets/img/Course/upcoming/download.svg";
import { ReactComponent as MealIcon } from "assets/img/Course/upcoming/meal.svg";
import { ReactComponent as TimeIcon } from "assets/img/Course/upcoming/date.svg";
// import { ReactComponent as RescheduleIcon } from "assets/img/Course/upcoming/reschedule.svg";
// import { ReactComponent as WithdrawalIcon } from "assets/img/Course/trainer.svg";
import { ReactComponent as CertificateIcon } from "assets/img/Course/registration/success.svg";
// import { ReactComponent as TrainerIcon } from "assets/img/Course/trainer.svg";

import CourseInfo from "../../../../components/LearnerPortal/CourseInfo";
import ModalCertificates from "./ModalCertificates";
import { PrintCourseDate } from "../../../../common_function/function.js";
import { useQuery } from "@tanstack/react-query";
import generalApi from "services/general-api";
import { useAuth } from "hooks/selectors/useAuth";

const VERSION = process.env.REACT_APP_APP_VERSION;

function ModalUpcomingCourseInfo() {
	let navigate = useNavigate();
	const [certModalChange, setCertModalChange] = useState(false);
	const [message, setmessage] = useState("");
	const MySwal = withReactContent(Swal);
	const [accordionOpen, setAccordionOpen] = useState(false);
	// const [course, setCourse] = useState([]);
	// const [status, setStatus] = useState("");

	// const params = useParams();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const learnerId = queryParams.get("learner_id");
	const authData = useAuth();
	const userData = authData.userData.user;

	const toggleAccordion = () => {
		setAccordionOpen(!accordionOpen);
	};

	let course = {};
	let status = "";
	let course_query;

	if (learnerId) {
		// console.log(course_query.data);
		course_query = useQuery(["upcoming-course", learnerId], () => {
			return generalApi.getUpcomingCourseById(learnerId);
		  });
		  
		if (!course_query?.data && course_query.isSuccess) {
			navigate("/course");
		}
		if (course_query?.data) {
			course = course_query.data;
			status = "upcoming";
			// setCourse(course_query.data);
			// setStatus("pending");
		}
	} else {
		const location_data = location.state?.data ?? {};
		const location_status = location.state?.status ?? "";
		if (!Object.keys(location_data).length) {
			navigate("/course");
		}
		// setCourse(location_data);
		// setStatus(location_status);

		course = location_data;
		status = location_status;
	}

	const handleSFCClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const data = {};
		const reschedule_to = course.reschedule_to;

		data.callback_url = process.env.REACT_APP_WEB_URL + "/course";
		data.course_id = reschedule_to.course.course_ref;
		data.course_fee = course.learner_sfc;
		data.courserun_id = reschedule_to.courserun_apicourserunid;
		data.courserun_start_date = reschedule_to.courserun_course_from;
		data.nric = userData.learnermaster_nric;
		data.email = userData.learnermaster_email;
		data.mobile = userData.learnermaster_mobile;
		data.tms_regform_id = course.registrationform_id_encrypt;
		data.tms_course_id = reschedule_to.courserun_course_id;
		data.tms_courserun_id = reschedule_to.courserun_id;
		data.tms_learner_id = course.learner_id;
		data.tms_learnermaster_id = userData.learnermaster_id;
		data.purpose = "Reschedule";

		// console.log(data);
		const json_str = btoa(JSON.stringify(data));
		// console.log(json_str);
		// setLoading(true);
		// GTMCheckoutYesSFCButton(checkoutAnalyticsData, userData);
		window.location.href = process.env.REACT_APP_API_URL + "/api/sfc_pay?encoded=" + json_str;
	};

	const handlePopup = (displayMSG) => {
		MySwal.fire({
			title: "Unable to Proceed!",
			html: displayMSG,
			icon: "error",
			// icon: "warning",
			// didOpen: () => {
			// 	// `MySwal` is a subclass of `Swal` with all the same instance & static methods
			// 	MySwal.showLoading();
			// },
		});
	};
	console.log(course.claim_id);
	return (
		<>
			<div className='upcoming-course-info-container fadeInDiv'>
				<div className='title-container'>
					<span className='mb-0 fs40 text-fca-dark font_2'>
						{"Courses"}
					</span>
				</div>
				{Object.keys(course).length !== 0 && (
					<>
						{course.attendance_status != "Pending Assessment" ? (
							<>
								<CourseInfo
									course_title={course.course.course_title}
									course_logos={course.course.logos}
									trainer_name={course.trainer?.trainer_name}
									trainer_image_url={course.trainer?.trainer_image_url}
									course_duration={course.duration}
									course_categories={course.course.categories}
									course_ref={course.course.course_ref}
									courserun_modeoftraining={course.courserun_modeoftraining}
									venue_address={course.venue.venue_address}
									training_support_name={course.training_support?.user_name}
									training_support_mobile={course.training_support?.user_mobile}
									courserun_course_from={course.courserun_course_from}
									courserun_course_to={course.courserun_course_to}
									csessions={course.csessions}
									course_description={course.course.course_description}
									course_reschedule_status={course.reschedule_status}
									course_withdrawal_status={course.withdrawal_status}
									course_attendance_status={course.attendance_status}
									tobe_rescheduled={course.tobe_rescheduled}
									claim_id={course.claim_id}
									sfc_claim_id={course.sfc_claim_id}
									claim_status={course.claim_status}
									reschedule_to={course.reschedule_to}
									learner_sfc={course.learner_sfc}
									registrationform_id={course.registrationform_id}
									learner_id={course.learner_id}
									course_banner_image={course.course.course_banner_image}
									pending_assessment_date={course.pending_courserun ? PrintCourseDate(
										course.pending_courserun.courserun_course_from,
										course.pending_courserun.courserun_course_to,
									) : null}
									course_short_description={course.course.course_short_description}
									registered_learners={course.registered_learners}
									message={message}
									status={status}
									course={course}
									handlePopup={handlePopup}
									handleSFCClick={handleSFCClick}
									showButtons={true}
								/>
							</>
						) : (
							<>
								<CourseInfo
									course_title={course.pending_courserun.course.course_title}
									course_logos={course.pending_courserun.course.logos}
									trainer_name={course.pending_courserun.trainer?.trainer_name}
									trainer_image_url={course.pending_courserun.trainer?.trainer_image_url}
									course_duration={course.pending_courserun.duration}
									course_categories={course.pending_courserun.course.categories}
									course_ref={course.pending_courserun.course.course_ref}
									courserun_modeoftraining={course.pending_courserun.courserun_modeoftraining}
									venue_address={course.pending_courserun.venue.venue_address}
									training_support_name={course.pending_courserun.training_support?.user_name}
									training_support_mobile={
										course.pending_courserun.training_support?.user_mobile
									}
									courserun_course_from={course.pending_courserun.courserun_course_from}
									courserun_course_to={course.pending_courserun.courserun_course_to}
									csessions={course.pending_courserun.csessions}
									course_description={course.pending_courserun.course.course_description}
									course_reschedule_status={course.pending_courserun.reschedule_status}
									course_withdrawal_status={course.pending_courserun.withdrawal_status}
									course_attendance_status={course.attendance_status}
									tobe_rescheduled={course.tobe_rescheduled}
									claim_id={course.claim_id}
									sfc_claim_id={course.sfc_claim_id}
									claim_status={course.claim_status}
									reschedule_to={course.reschedule_to}
									learner_sfc={course.learner_sfc}
									registrationform_id={course.registrationform_id}
									learner_id={course.learner_id}
									course_banner_image={course.course.course_banner_image}
									pending_assessment_date={course.pending_courserun ? PrintCourseDate(
										course.pending_courserun.courserun_course_from,
										course.pending_courserun.courserun_course_to,
									) : null}
									course_short_description={course.course.course_short_description}
									registered_learners={course.registered_learners}
									message={message}
									status={status}
									course={course}
									handlePopup={handlePopup}
									handleSFCClick={handleSFCClick}
									showButtons={false}
								/>
								<Card>
									<CardHeader onClick={toggleAccordion} className='cursor-pointer'>
										<Row>
											<Col className='col-auto'>
												<b>Original Course</b>
											</Col>
											<Col className='col-auto ml-auto'>
												{accordionOpen ? (
													<i className='fa fa-times'></i>
												) : (
													<i className='fa fa-plus'></i>
												)}
											</Col>
										</Row>
									</CardHeader>
									<Collapse isOpen={accordionOpen}>
										<CardBody>
											<CourseInfo
												course_title={course.course.course_title}
												course_logos={course.course.logos}
												trainer_name={course.trainer?.trainer_name}
												trainer_image_url={course.trainer?.trainer_image_url}
												course_duration={course.duration}
												course_categories={course.course.categories}
												course_ref={course.course.course_ref}
												courserun_modeoftraining={course.courserun_modeoftraining}
												venue_address={course.venue.venue_address}
												training_support_name={course.training_support?.user_name}
												training_support_mobile={course.training_support?.user_mobile}
												courserun_course_from={course.courserun_course_from}
												courserun_course_to={course.courserun_course_to}
												csessions={course.csessions}
												course_description={course.course.course_description}
												course_reschedule_status={course.reschedule_status}
												course_withdrawal_status={course.withdrawal_status}
												tobe_rescheduled={course.tobe_rescheduled}
												claim_id={course.claim_id}
												sfc_claim_id={course.sfc_claim_id}
												claim_status={course.claim_status}
												reschedule_to={course.reschedule_to}
												learner_sfc={course.learner_sfc}
												registrationform_id={course.registrationform_id}
												learner_id={course.learner_id}
												course_banner_image={course.course.course_banner_image}
												pending_assessment_date={course.pending_courserun ? PrintCourseDate(
													course.pending_courserun.courserun_course_from,
													course.pending_courserun.courserun_course_to,
												) : null}
												course_short_description={course.course.course_short_description}
												registered_learners={course.registered_learners}
												message={message}
												status={status}
												course={course}
												handlePopup={handlePopup}
												handleSFCClick={handleSFCClick}
												showButtons={false}

											/>
										</CardBody>
									</Collapse>
								</Card>

								<Row className='pt-4 pb-6'>
									<Col xl='auto' className='ml-auto'>
										{" "}
										<div className='btn-wrap'>
											<Link to='/course' className='btn btn-default'>
												Back
											</Link>
										</div>
									</Col>
								</Row>
							</>
						)}
					</>
				)}
			</div>
		</>
	);
}

export default ModalUpcomingCourseInfo;
