import React from "react";
// react library for routing
import { useLocation, Outlet, Navigate, useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import HomeNavbar from "components/NavBar/HomeNavBar";
import HomeFooter from "components/Footer/HomeFooter";
import { getNavRoutes } from "side_menu";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getErrorMessage } from "utilities/error";
import { resetUser } from "actions/auth";
import { logGTMScreen, removeLogGTMUser } from "utilities/googleTagManager";

function App() {
	const location = useLocation();
	const searchParam = location.search;

	const navigate = useNavigate();
	const mainContentRef = React.useRef(null);
	const { user: currentUser, isLoggedIn } = useSelector((state) => state.auth);

	React.useEffect(() => {
		logGTMScreen(location, isLoggedIn, currentUser);
		if (document.documentElement && document.scrollingElement && mainContentRef.current) {
			document.documentElement.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
			mainContentRef.current.scrollTop = 0;
			window.scrollTo(0, 0);
		}
	}, [location]);

	React.useEffect(() => {
		// Your condition to determine the initial page
		if (location.pathname === "/") {
			if (!currentUser) {
				navigate("/events" + searchParam, { replace: true });
			} else {
				if (currentUser && currentUser.user.has_registered_course) {
					navigate("/course" + searchParam, { replace: true });
				} else {
					navigate("/events" + searchParam, { replace: true });
				}
			}
		}}, [currentUser]
	);
	
	const NavigateFunctionComponent = () => {
		/** Intercept any unauthorized request.
		 * dispatch logout action accordingly **/
		const dispatch = useDispatch();
		let navigate = useNavigate();

		const UNAUTHORIZED = 401;

		axios.interceptors.response.use(
			(response) => response,
			(error) => {
				const { status } = error.response;
				let error_message = getErrorMessage(error);
				if (status === UNAUTHORIZED && error_message == "Unauthenticated.") {
					removeLogGTMUser();
					dispatch(resetUser());
					localStorage.removeItem("user");
					navigate("/login");
				}
				throw error;
			},
		);

		return <></>;
	};

	if (currentUser) {
		if (!currentUser.user.email_verified_at) {
			return <Navigate to={"/login/otp-validation"  + searchParam}/>;
		}
		if (currentUser.user.reset_password) {
			return <Navigate to={"/login" + searchParam }/>;
		}
	}

	const eventsNumbersPattern = /events\/\d+/;
	const isEventsNumbersPage = eventsNumbersPattern.test(location.pathname);

	return (
		<>
			<HomeNavbar
				routes={getNavRoutes()}
				logo={{
					innerLink: "/",
					imgSrc: require("assets/img/FCA.png"),
					imgAlt: "...",
				}}
			/>
			<div
				className={`main-content main-content-container ${
					location.pathname === "/home" ? "home-content-container" : ""
				}`}
				ref={mainContentRef}>
				<Container className={"main-container"} fluid style={location.pathname === "/avatar" ? { backgroundColor: "#F4FAFE" } : {}}>
					<Outlet react={React} />
				</Container>
			</div>
			{isEventsNumbersPage ? null : <HomeFooter />}
			<NavigateFunctionComponent />
		</>
	);
}

export default App;
