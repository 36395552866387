import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Col, Row, Modal, Card, CardBody, FormGroup, Button, Form } from "reactstrap";

import SFIcon from "assets/img/Course/Payment/SF.png";
import PSEAIcon from "assets/img/Course/Payment/PSEA.png";
import PaynowIcon from "assets/img/Course/Payment/PAYNOW.png";
import { ReactComponent as CCIcon } from "assets/img/Course/Payment/card.svg";
import { ReactComponent as MapIcon } from "assets/img/Course/bus_meal/map.svg";
import { useDispatch, useSelector } from "react-redux";
import generalApi from "services/general-api";
import { saveRegisterForm } from "reducers/courseRegisterSlice";
import * as yup from "yup";
import { yupHelper } from "utilities/yupHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import classNames from "classnames";

const schema = yup.object({
	// remarks: yup.string().required(),
	// remarks: yup.string().required(),
	// remarks: yup.string().required(),
});

function ModalShuttleBus() {
	const [loading, setLoading] = useState(false);
	const [modalChange, setModalChange] = useState(true);
	const [message, setMessage] = useState("");
	const [dateString, setDateString] = useState("");
	const [shutterBusDetails, setShutterBusDetails] = useState({});
	const [days, setDay] = React.useState(undefined);
	const [selectedDays, setSelectedDay] = React.useState([]);

	const dispatch = useDispatch();

	const courseRegisterData = useSelector((state) => state.courseRegisterSlice);

	const courseData = courseRegisterData.course;
	const courseRunData = courseRegisterData.courserun;
	const registerFormData = courseRegisterData.registerForm;
	let navigate = useNavigate();

	const MySwal = withReactContent(Swal);
	const location = useLocation();
	const learner_id = location.state?.learner_id;
	const course = location.state?.course;
	const status = location.state?.status;

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const getDate = (sessions) => {
		let sessionString = "";
		if (sessions && sessions.length > 0) {
			sessions.map((session) => {
				sessionString = sessionString.concat(
					`- ${moment(session.date, "YYYY-MM-DD").format("DD MMM YYYY")} (${moment(
						session.sessionFrom,
						"HH:mm:ss",
					).format("hh:mm a")} - ${moment(session.sessionto, "HH:mm:ss").format("hh:mm a")}) \n`,
				);
			});
		} else {
			return "-";
		}
		return sessionString;
	};

	useEffect(() => {
		generalApi.getShutterBusDetails(learner_id).then(
			(response) => {
				setShutterBusDetails(response.data);
				setDateString(getDate(response.data.sessions));
				setDay(response.data?.options || undefined);
				if (
					response.data &&
					response.data.options.length > 0
				) {
					let selectedOptions = [];
					response.data.options.map((option) => {
						selectedOptions.push({
							date: option.date || "",
							pickup_id: option.selected
								? option.selected.pickup_id.toString()
								: null,
							pickup_title: option.selected ? option.selected.pickup_addr : null,
							pickup_details: option.selected ? option.selected : null,
						});
					});
					setSelectedDay(selectedOptions);
				}
			},
			(error) => {
				navigate("/course");
			},
		);
	}, []);

	const handleSaveShutterBus = (data) => {
		setLoading(true);
		setMessage("");
		if (data.selections && data.selections.length > 0) {
			for (let i = 0; i < data.selections.length; i++) {
				if (data.selections[i] && data.selections[i].pickup_id == null) {
					setMessage("Pickup Location for Day " + (i + 1) + " is required.");
					setLoading(false);
					break;
				}
			}
		}
		generalApi
			.saveShuttleBus(learner_id, data.selections)
			.then(
				(response) => {
					// setLoading(false);
					navigate("/course/shuttle-bus-success-page", {
						state: { data: { course: course, selection: selectedDays } },
					});
					// MySwal.fire({
					// 	title: "Congratulations!",
					// 	html: <p>Shutter Bus Selection Update Successfully.</p>,
					// 	icon: "success",
					// 	// didOpen: () => {
					// 	// 	// `MySwal` is a subclass of `Swal` with all the same instance & static methods
					// 	// 	MySwal.showLoading();
					// 	// },
					// }).then(() => {
					// 	navigate("/course/course-details/", {
					// 		state: { data: course, status: status },
					// 	});
					// });
				},
				(error) => {
					setLoading(false);
					// 		let error_message =
					// (error.response && error.response.data && error.response.data.message) ||
					// (error.response && error.response.data && error.response.data.data) ||
					// error.message ||
					// error.toString();
					// 		if (typeof error_message == "object") {
					// 			let error_message_string = "";
					// 			Object.values(error_message).map((item) => {
					// 				if (typeof error_message == "object") {
					// 					error_message_string += item.join("<br>");
					// 				} else {
					// 					error_message_string += item;
					// 				}
					// 			});
					// 			setmessage(error_message_string);
					// 		} else {
					// 			setmessage(error_message);
					// 		}
				},
			)
			.catch(() => {
				setLoading(false);
			});
	};

	const selectLocation = (dayIndex, locationIndex) => {
		let newSelectedDays = [...selectedDays];
		newSelectedDays[dayIndex].pickup_id =
		(days && days[dayIndex].bus_options[locationIndex].pickup_id.toString()) || null;
		newSelectedDays[dayIndex].pickup_title =
		(days && days[dayIndex].bus_options[locationIndex].pickup_addr) || null;
		newSelectedDays[dayIndex].pickup_details =
		(days && days[dayIndex].bus_options[locationIndex]) || null;
		setSelectedDay(newSelectedDays);
	};

	const [isOpen, setIsOpen] = useState(false);
	const [modalContent, setModalContent] = useState(null);
	const API_URL = process.env.REACT_APP_API_URL;

	const openModal = (event, title, image1, image2) => {
		event.preventDefault();
		event.stopPropagation();
		image1 = API_URL + image1;
		image2 = API_URL + image2;
		setModalContent({ title, image1, image2 });
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	const handlePopup = (displayMSG) => {
		MySwal.fire({
			title: "Unable to Proceed!",
			html: displayMSG,
			icon: "error",
		});
	};

	return (
		<>
			<div className=''>
				<div className='title-container'>
					<h2>{"Free Shuttle Bus Service"}</h2>
				</div>

				<div>
					<Card className='mt-4'>
						<CardBody>
							<div>
								<p>
									{" 1. Select your date and location, at least one day before by 530 pm."}
									<br />
									<br />
									{" 2. Pickup Point: Serangoon MRT Station Exit A Bus Stop or Tampines MRT Station Exit B"}<br />
									<br />
									{"3. Reach pickup point 5 mins earlier."}
									<br />
									<br />
									{"4. Board the bus with FirstCom Academy logo (as shown in below)"}
									<br />
									<br />
									{"5. Bus service is only provided in the morning from Serangoon MRT Stn Exit A and Tampines MRT Station Exit B to 158 Kallang Way Performance Building"}
								</p>
								<p style={{ fontWeight: "600"}}>{"Serangoon MRT Stn Exit A/Tampines MRT Station Exit B to Performance Building (8.30am)"}</p>
							</div>

							<hr></hr>
							<Form role='form' onSubmit={handleSubmit(handleSaveShutterBus)}>
								<div>
									<Row>
										<Col md='12' xl='6'>
											<FormGroup>
												<label className='form-control-label' htmlFor='input-courseName'>
													{"Course Name"}
												</label>
												<input
													className={"form-control "}
													defaultValue={shutterBusDetails.course_title}
													disabled={true}
													id='input-courseName'
													type='text'
												/>
											</FormGroup>
										</Col>
										<Col md='12' xl='6'>
											<FormGroup>
												<label className='form-control-label' htmlFor='input-learnerName'>
													{"Learner Name"}
												</label>
												<input
													className={"form-control "}
													defaultValue={shutterBusDetails.learner_name}
													disabled={true}
													id='input-learnerName'
													type='text'
												/>
											</FormGroup>
										</Col>
										<Col md='12' xl='6'>
											<FormGroup>
												<label className='form-control-label' htmlFor='input-courseDate'>
													{"Date"}
												</label>
												<textarea
													className={"form-control "}
													defaultValue={dateString}
													disabled={true}
													id='input-courseDate'
													type='text'
													rows='5'
												/>
											</FormGroup>
										</Col>
										<Col md='12' xl='6'>
											<FormGroup>
												<label className='form-control-label' htmlFor='input-courseLocation'>
													{"Location"}
												</label>
												<textarea
													className={"form-control "}
													defaultValue={shutterBusDetails.addresss}
													disabled={true}
													id='input-courseLocation'
													type='text'
													rows='5'
												/>
											</FormGroup>
										</Col>
									</Row>
								</div>

								{shutterBusDetails && shutterBusDetails.options && shutterBusDetails.options.length > 0 && (
									<div>
										<label className='form-control-label pb-2' htmlFor='input-courseLocation'>
											{"Pickup Location (8.30am) *"}
										</label>
										<div className='shuttle-location-wrap'>
											{shutterBusDetails.options.map((option, i) => (
												<div key={i} className='shuttle-location-day pb-4'>
													<input
														type='hidden'
														defaultValue={option.date}
														{...register(`selections.${i}.date`)}
													></input>

													<div className='shuttle-location-content-wrap'>
														<div className='header pb-2'>
															<div className='title'>{option.date}</div>
														</div>
														<div className='content row'>
															{option.bus_options.map((bus_option, j) => (
																<label key={j} className='d-block col-xl-4 col-md-4'>
																	<input
																		type='radio'
																		defaultChecked={
																			option.selected?.pickup_id === bus_option.pickup_id
																				? true
																				: false
																		}
																		{...register(`selections.${i}.pickup_id`)}
																		onChange={() => selectLocation(i, j)}
																		value={bus_option.pickup_id}
																	/>
																	<div className='option-box flex'>
																		{/* <div className='check-tick'>
																			<div className='tick'></div>
																		</div> */}
																		<div className='option-title'>
																			<div className='title'>{bus_option.pickup_addr}</div>
																		</div>
																		{bus_option.image_1 && (
																			<div
																				className='icon-container'
																				onClick={(event) =>
																					openModal(
																						event,
																						bus_option.pickup_addr,
																						bus_option.image_1,
																						bus_option.image_2,
																					)
																				}
																			>
																				<MapIcon />
																			</div>
																		)}
																	</div>
																</label>
															))}
														</div>
													</div>
												</div>
											))}
										</div>
									</div>
								)}

								<Row>
									{message && (
										<Col md='12' xl='12' className='mt-6'>
											<div
												className={classNames(
													"alert alert-danger",
													// message === "OTP Sent Success" ? "alert-success" : "alert-danger",
												)}
												role='alert'
											>
												{message}
											</div>
										</Col>
									)}
									<Col md='12' xl='12' className='mt-6'>
										<div className='flex mb-4 align-items-center'>
											<Link
												to='/course/course-details/'
												state={{ data: course, status: status }}
												className='btn btn-default ml-auto'
											>
												{"Back"}
											</Link>
											{!course.cannot_select_bus_reason ? (
												<Button className='btn-gradient' type='submit' disabled={loading}>
													{loading && <span className='spinner-border spinner-border-sm'></span>}
													<span>{"Submit"}</span>
												</Button>
											) : (
												<Button
													className='btn-gradient opacity-6'
													onClick={() => handlePopup(course.cannot_select_bus_reason)}
												>
													<span>{"Submit"}</span>
												</Button>
											)}
										</div>
									</Col>
								</Row>
							</Form>
						</CardBody>
					</Card>
				</div>

				{modalContent && (
					<Modal className='modal-dialog-centered cert_modal shuttlebus-modal' isOpen={isOpen}>
						<div className='close-btn' onClick={closeModal}>
							<i className='fa fa-times' aria-hidden='true'></i>
						</div>
						<div className='modal-body'>
							<div className='w-100'>
								<h4 className='pb-4'>{modalContent.title}</h4>
								<Row>
									{modalContent.image1 && (
										<Col md='6' xl='6' xs='12'>
											<img src={modalContent.image1} alt='Image 1' />
										</Col>
									)}
									{modalContent.image2 && (
										<Col md='6' xl='6' xs='12'>
											<img src={modalContent.image2} alt='Image 2' />
										</Col>
									)}
								</Row>
							</div>
						</div>
					</Modal>
				)}
			</div>
		</>
	);
}

export default ModalShuttleBus;
