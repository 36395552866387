import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { Card, CardBody, Row, Col, Container, Modal } from "reactstrap";

import { ReactComponent as SuccessIcon } from "assets/img/Course/registration/success.svg";
import { ReactComponent as MapIcon } from "assets/img/Course/bus_meal/map.svg";

function ShuttleBusSuccessPage() {
	const location = useLocation();
	const data = location.state?.data;
	const course = data.course;
	const selection = data.selection;
	const API_URL = process.env.REACT_APP_API_URL;
	const [modalContent, setModalContent] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const openModal = (event, title, image1, image2) => {
		event.preventDefault();
		event.stopPropagation();
		image1 = API_URL + image1;
		image2 = API_URL + image2;
		setModalContent({ title, image1, image2 });
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
	};
    
	return (
		<>
			<Container className=''>
				{Object.keys(course).length !== 0 && (
					<div className='pt-6 pb-5'>
						<div className='title-container text-center'>
							<SuccessIcon />
							<h5 className='pt-2'>
								{"Thank you! You have successfully\nsubmitted your responses below"}
							</h5>
						</div>
						<Card className='mt-5'>
							<CardBody>
								<Row className='justify-content-between'>
									<Col md='auto' xl='auto' xs='auto'>
										{course.course.logos.map((logo, i) => (
											<img
												key={i}
												className='m-1 h-40px display_ini'
												alt={course.course.course_title}
												src={logo.course_logo_image}
											/>
										))}
									</Col>
								</Row>
								<Row className='align-items-center pt-3'>
									<Col md='12' xl='12'>
										<h5>{course.course.course_title}</h5>
									</Col>
								</Row>
								<hr className="mt-2 mb-4"></hr>
								<Row className='align-items-center'>
									<Col md='12' xl='12'>
										<h5>{"Pick Up Location"}</h5>
									</Col>
								</Row>
								<div style={{borderWidth: 1, borderStyle: "solid", borderColor: "rgb(204,204,204)", marginBottom: 30, padding: 16}}>
									{selection.map((selectionItem, index) => {
										return (
											<div key={`${selectionItem.food_id}-${index}`} style={{ marginBottom: 10 }}>
												<span className="theme_text_color" style={{ fontSize: 16, fontWeight: "500", marginBottom: 10 }}>{selectionItem.date}</span>
												<div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 5 }}>
													<p style={{ fontSize: 16, fontWeight: "500",marginRight: 10, marginBottom: 0}}>{selectionItem.pickup_title}</p>
													{selectionItem.pickup_details.image_1 && (
														<div
															className='icon-container'
															onClick={(event) =>
																openModal(
																	event,
																	selectionItem.pickup_details.pickup_addr,
																	selectionItem.pickup_details.image_1,
																	selectionItem.pickup_details.image_2,
																)
															}
														>
															<MapIcon />
														</div>
													)}
												</div>
											</div>
										);
									})}
								</div>
							</CardBody>
						</Card>
						<Row className='align-items-center mb-10'>
							<Col xl='auto' md='auto' xs='auto' className='p-2 ml-auto'>
								<div className='modal-footer justify-content-center'>
									<Link to='/course' className='btn btn-gradient'>
										{" Back To Home Page"}
									</Link>
								</div>
							</Col>
						</Row>
					</div>
				)}
				{modalContent && (
					<Modal className='modal-dialog-centered cert_modal shuttlebus-modal' isOpen={isOpen}>
						<div className='close-btn' onClick={closeModal}>
							<i className='fa fa-times' aria-hidden='true'></i>
						</div>
						<div className='modal-body'>
							<div className='w-100'>
								<h4 className='pb-4'>{modalContent.title}</h4>
								<Row>
									{modalContent.image1 && (
										<Col md='6' xl='6' xs='12'>
											<img src={modalContent.image1} alt='Image 1' />
										</Col>
									)}
									{modalContent.image2 && (
										<Col md='6' xl='6' xs='12'>
											<img src={modalContent.image2} alt='Image 2' />
										</Col>
									)}
								</Row>
							</div>
						</div>
					</Modal>
				)}
			</Container>
		</>
	);
}

export default ShuttleBusSuccessPage;
