import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Col, Row, Modal, Card } from "reactstrap";

import SFIcon from "assets/img/Course/Payment/SF.png";
import PSEAIcon from "assets/img/Course/Payment/PSEA.png";
import PaynowIcon from "assets/img/Course/Payment/PAYNOW.png";
import { ReactComponent as CCIcon } from "assets/img/Course/Payment/card.svg";
import { useDispatch, useSelector } from "react-redux";
import generalApi from "services/general-api";
import { saveRegisterForm } from "reducers/courseRegisterSlice";

const PAYMENT_METHODS = [
	{
		name: "Skill Future Credit",
		desc: "Only applicable for Self-Sponsored Individuals subjected to approval by SkillsFuture Singapore.",
		image: null,
	},
	{
		name: "PSEA",
		desc: "Only applicable for Self-Sponsored Individuals subjected to approval by Ministry of Education, Singapore.",
		image: null,
	},
	{
		name: "Bank Transfer/Paynow",
		desc: null,
		image: <img src={PaynowIcon} alt='Bank Transfer/Paynow' />,
	},
	{
		name: "Credit Card",
		desc: null,
		image: <CCIcon />,
	},
];

function ModalPayment() {
	const [loading, setLoading] = useState(false);
	const [modalChange, setModalChange] = useState(true);
	const [coursePrice, setCoursePrice] = useState("");
	const dispatch = useDispatch();

	const courseRegisterData = useSelector((state) => state.courseRegisterSlice);

	const courseData = courseRegisterData.course;
	const courseRunData = courseRegisterData.courserun;
	const registerFormData = courseRegisterData.registerForm;
	let navigate = useNavigate();

	useEffect(() => {
		let requestData = {
			course_id: courseData.course_id,
			courserun_id: courseRunData.courserun_id,
			learner_dob: registerFormData.dob,
			learner_nationality: registerFormData.nationality,
			registrationform_sme: registerFormData.registerCompanySme,
			registrationform_sponsoredbycompany: registerFormData.sponsoredCompany,
		};
		generalApi.getCoursePrice(requestData).then(
			(response) => {
				setCoursePrice(response.data.price);
				dispatch(
					saveRegisterForm({
						coursePrice: response.data.price,
					}),
				);
			},
			(error) => {
				navigate("/course");
			},
		);
	}, []);

	return (
		<>
			<Modal
				className='modal-dialog-centered registration_modal'
				isOpen={modalChange}
			>
				<div className='modal-body'>
					<div>
						<h5>Payment Options</h5>
						<p>
              Please note that the fees quoted are based on your declarations on this form. Should
              there be any discrepancy with your eligible funding, your invoice will be revised and
              you will be notified of the revision before the course.
						</p>
					</div>
					<div className='mt-4'>
						<label>
							<b>
								<u>Total Nett Course Fee Payable After SSG Subsidy (If Any)</u>
							</b>
						</label>
						<div>Price : ${coursePrice}</div>
					</div>
					<div>
						<p>
              Note: SkillsFuture Singapore (SSG) provides grants/funding on condition that trainees
              complete at least 75% of the course and pass the assessment. Firstcom Academy will
              collect net course fees with the understanding that trainee will make every effort to
              pass the assessment. In the event that trainee fails to pass the assessment or for any
              reason is unable to claim the grant/funding from SSG, Firstcom Academy reserves the
              right to collect the outstanding course fees (eligible funding amount by SSG) from
              trainee.
						</p>
						<p>
              For Bank Transfer/PayNow, kindly PayNow to UEN: 201734671R or transfer to OCBC
              712-523349-001 and notify us at registration@fcacademy.com.sg once you have completed
              the payment.
						</p>
					</div>
					<div className='mt-4'>
						<h5>Available Payment Methods</h5>
						<Row className='payment-method-row'>
							{PAYMENT_METHODS.map(({ image, name, desc }, index) => {
								return (
									<Col key={index} md='6' xl='6' className='p-2'>
										<Card className='h-100 p-2'>
											<div className='flex justify-content-between align-items-center'>
												<label>{name}</label>
												<div className='img-container'>{image}</div>
											</div>

											{desc && (
												<div className='desc pt-2'>
													<p>{desc}</p>
												</div>
											)}
										</Card>
									</Col>
								);
							})}
						</Row>
					</div>
					<div>
						<small>Our salesperson will contact you to assist you on payment.</small>
					</div>
				</div>
				<div className='modal-footer justify-content-between'>
					<Link to='/course' className='btn btn-default'>
            Close
					</Link>
					<Link
						to={"/course/register"}
						className='btn btn-primary mr-auto'
					>
            Back
					</Link>
					<Link to='/course/endorse' className='btn btn-success' color='primary' type='button b'>
            Proceed To Endorse
					</Link>
				</div>
			</Modal>
		</>
	);
}

export default ModalPayment;
