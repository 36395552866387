import React ,{useState}from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Col, Row ,Modal} from "reactstrap";
import MuiDialogContent from "@material-ui/core/DialogContent";

import videoApi from "services/video-api";
import { toast } from "react-toastify";
import { useMutation ,useQueryClient} from "@tanstack/react-query";
import { set } from "lodash";
import confetti from "canvas-confetti";
import styles from "../VideoHome.module.scss";
import { getErrorMessage } from "utilities/error";
import classNames from "classnames";

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

export default function VideoModal({ 
	isOpen,
	onClose,
	data ,
	validateVote,
	currentVote,
	onSuccess
}) {
	const [changeVoteModalVisible, setChangeVoteModalVisible] = useState(false);
	const [successModalVisible, setSuccessModalVisible] = useState(false);
	const handleClose = () => {
		onClose();
	};

	const { 
		video_title, 
		video_description,
		video_link,
		categories = [],
		video_youtube_embedded,
		votes_count } = data || {};



	const voteMutation = useMutation({
		mutationFn: videoApi.vote,
		onSuccess: (data) => {
			confetti({
				particleCount: 100,
				spread: 400,
				zIndex:2000,
				origin: { y: 0.3 },
			});
			setSuccessModalVisible(true);
			onSuccess();
		},
		onError: (error) => {
			const errorMessage= getErrorMessage(error);
			toast.error(errorMessage|| "Failed to vote", { });
		},
	});


	const handleVoteClick = () => {
		const canVote = validateVote(data);
		if(!canVote){
			return;
		}

		if(currentVote && currentVote.video_id !== data.id){
			setChangeVoteModalVisible(true);
		}else{
			voteMutation.mutate(data.id);
		}
	};

	const handleConfirmChangeVote = () => {
		const canVote = validateVote(data);
		if(!canVote){
			return;
		}

		setChangeVoteModalVisible(false);
		voteMutation.mutate(data.id);
	};


	return (
		<div>
			<Modal 
				size='xl' 
				className='modal-dialog-centered' 
				aria-labelledby='customized-dialog-title'
				isOpen={isOpen}
				toggle={handleClose}
				fullWidth
				maxWidth='md'
				style={{
					zIndex: 1000
				}}
			>

				<div
					style={{
						color: "#fff",
						backgroundColor: "#212121",
						padding: "20px",
					}}>
					<div className="d-flex justify-content-end mb-2">
						<div style={{
							borderRadius: "50%",
							backgroundColor: "black",
							width: "30px",
							height: "30px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}>
							<i className="fa fa-times" onClick={handleClose} style={{cursor:"pointer"}}></i>
						</div>
					</div>
					<div 
						style={{ 
							textAlign:"center",
							backgroundColor:"transparent",
							maxHeight: "50vh",
							display:"flex",
							justifyContent:"center",
						}}
					>
						<div 
							className={styles.video_model_iframe}
							// style={{
							// 	width:"auto",
							// 	height:"50vh",
							// 	maxHeight:"550px",
							// 	maxWidth:"100%",
							// 	aspectRatio: "16 / 9",
							// }}
						>
							<VideoIFrame src={video_youtube_embedded} />
						</div>
					</div>
					<Row className={classNames("mt-5 mb-4 align-items-strech", styles.video_modal_content)}>
						<div className="d-flex px-3" 
							style={{
								flexDirection:"column",
								flexGrow:1,
							}}>
							<span
								className="mr-4"
								style={{
									fontSize: "1.5em",
									color: "#C0924E",
								}}>
								Vote For {video_title}
							</span>
							<div style={{ fontSize: "1.5em"}} >
								{video_description}
							</div>
							<div style={{display:"flex",gap:10}}>
								{categories.map((category) => {
									return <div key={category.category_title}>
										<p className="mb-0"  style={{fontWeight:"bold"}}>{category.category_type.category_type_title}</p>
										<p >{category.category_title}</p>
									</div>;
								})}
							</div>
							<span
								style={{
									color: "#AAAAAA",
									fontSize: "1.5em",
								}}>
								{votes_count} {votes_count > 1 ? "Votes" : "Vote"}
							</span>
						</div>
						<Col className="d-flex justify-content-end align-items-center"
							style={{
								flex:1,
								flexBasis:100,
								flexGrow:0,
								flexShrink:0
							}}
						>
							{
								currentVote && currentVote.video_id === data?.id 
									? <p>you have voted for this video</p>
									: null
									// :<Button 
									// 	className={styles.pulsing}
									// 	size='sm'
									// 	disabled={voteMutation.isLoading}
									// 	onClick={()=>handleVoteClick()}
									// 	style={{
									// 		background: "transparent linear-gradient(90deg, #62480D 0%, #CCAD63 100%) 0% 0% no-repeat padding-box",
									// 		borderWidth:0,
									// 		fontWeight: "normal",
									// 		color:"white"
									// 	}}>
									// 	{voteMutation.isLoading ? "Loading..." : "Vote"}
									// </Button>
							}
							
						</Col>
					</Row>
				</div>
			</Modal>
			{/* Confirmation diagform if user has existing vote already */}
			<Modal size='md' className='modal-dialog-centered' 
				aria-labelledby='customized-dialog-title'
				isOpen={changeVoteModalVisible}
				toggle={() => { 
					setChangeVoteModalVisible(!changeVoteModalVisible);
				}}
				fullWidth
				maxWidth='md'
				style={{
					zIndex: 1000
				}}
			>
				<div
					style={{
						color: "#fff",
						backgroundColor: "#212121",
						padding: "20px",
					}}>
					<p 
						style={{
							fontSize: "1.5rem",
						}}
					>Please note that you can only vote once, kindly confirm if you would like to invalid your previous vote.  </p>
					<Row className='mt-5 mb-4 align-items-center'>
						<Col className="d-flex  justify-content-end">
							<Button 
								size='sm'
								disabled={voteMutation.isLoading}
								onClick={()=>setChangeVoteModalVisible(false)}
							>
								Cancel
							</Button>
							<Button 
								size='sm'
								color="warning"
								disabled={voteMutation.isLoading}
								onClick={()=>handleConfirmChangeVote()}
							>
                Change Vote
							</Button>
						</Col>
					</Row>
				</div>
			</Modal>

			{/* Sucess Modal */}
			<Modal size='lg' 
				className='modal-dialog-centered' 
				aria-labelledby='customized-dialog-title'
				isOpen={successModalVisible}
				toggle={() => { 
					setSuccessModalVisible(!successModalVisible);
				}}
				fullWidth
				maxWidth='md'
				style={{
					zIndex: 1000
				}}
			>
				<div style={{textAlign:"center", padding:"100px "}}>
					<h2>Thank you for your vote!</h2>
					<h2>Please wait fo us to contact you</h2>
					<Button 
						onClick={() => { 
							setSuccessModalVisible(false);
							setSuccessModalVisible(false);
						}}
						style={{
							marginTop: "20px",
							color:"white",
							padding: "20px 50px",
							background: "transparent linear-gradient(90deg, #00AB84 0%, #0085CA 100%) 0% 0% no-repeat padding-box",
						}}>
						Back to Upskilling100
					</Button>
				</div>
			</Modal>

		</div>
	);
}

const VideoIFrame = ({ src }) => {
	return (
		<iframe
			width='100%'
			height='100%'
			src={src}
			title='YouTube video player'
			allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
			referrerPolicy='strict-origin-when-cross-origin'
			allowFullScreen
			frameBorder='0'></iframe>
	);
};
