import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Col, Row, Modal, Card, Button, FormGroup, InputGroup, Form, CardBody } from "reactstrap";

import SFIcon from "assets/img/Course/Payment/SF.png";
import PSEAIcon from "assets/img/Course/Payment/PSEA.png";
import PaynowIcon from "assets/img/Course/Payment/PAYNOW.png";
import { ReactComponent as CCIcon } from "assets/img/Course/Payment/card.svg";
import { useDispatch, useSelector } from "react-redux";
import generalApi from "services/general-api";
import { saveRegisterForm } from "reducers/courseRegisterSlice";
import * as yup from "yup";
import { yupHelper } from "utilities/yupHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import moment from "moment";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import { getErrorMessage } from "utilities/error";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const schema = yup.object({
	newCourseDate: yup.string().required(),
	reasonType: yup.string().required(),
	reason: yup.string().when("reasonType", {
		is: (reasonType) => reasonType == "Others",
		then: () => yup.string(),
		otherwise: () => yup.string().required(),
	}),
	// remark: yup.string().required(""),
	remark: yup.string().when("reasonType", {
		is: (reasonType) => reasonType == "Others",
		then: () => yup.string().required("Please Enter a reason for Reschedule."),
		otherwise: () => yup.string(),
	}),
	supportingDocument: yup.mixed().required(),
	// supportingDocument: yup
	// 	.mixed()
	// 	.test("fileSize", "File Size is too large", (value) => {
	// 		if (value && value?.length > 0) {
	// 			for (let i = 0; i < value.length; i++) {
	// 				if (value[i].size > 5242880) {
	// 					return false;
	// 				}
	// 			}
	// 		}
	// 		return true;
	// 	})
	// 	.test("fileType", "Unsupported File Format", (value) => {
	// 		if (value && value.length > 0) {
	// 			for (let i = 0; i < value.length; i++) {
	// 				if (
	// 					value[i].type != "pdf" &&
	//         value[i].type != "image/png" &&
	//         value[i].type != "image/jpg" &&
	//         value[i].type != "image/jpeg"
	// 				) {
	// 					return false;
	// 				}
	// 			}
	// 		}
	// 		return true;
	// 	}),
	// supportingDocument: yup
	// 	.array("Please select at least 1 Supporting Document.")
	// 	.min(1, "Please select at least 1 Supporting Document.")
	// 	.max(3, "Please select at most 3 Supporting Document."),
});

function ModalReschedule() {
	const [loading, setLoading] = useState(false);
	const [message, setmessage] = useState("");
	const [modalChange, setModalChange] = useState(true);
	const [allCourseDate, setAllCourseDate] = useState({});
	const [selectedCourseDate, setSelectedCourseDate] = useState("");
	const dispatch = useDispatch();

	const courseRegisterData = useSelector((state) => state.courseRegisterSlice);

	const courseData = courseRegisterData.course;
	const courseRunData = courseRegisterData.courserun;
	const registerFormData = courseRegisterData.registerForm;
	let navigate = useNavigate();
	const MySwal = withReactContent(Swal);

	const location = useLocation();
	const data = location.state?.data;
	const learner_id = data?.learner_id;
	const learner_sfc = Number(data?.learner_sfc);
	const course = data?.course;
	const status = data?.status;

	const [RescheduleReason, setRescheduleReason] = useState({});
	const [selectedReasonType, setSelectedReasonType] = useState("");
	const [selectedReason, setSelectedReason] = useState("");
	const selectedReasonRef = useRef();
	selectedReasonRef.current = selectedReason;

	// const fileTypes = ["JPEG", "PNG", "PDF"];
	// const [files, setFiles] = useState([]);
	// const handleDelete = (file) => {
	// 	setFiles(files.filter((f) => f !== file));
	// };

	// const handleChange = (currentfile) => {
	// 	setFiles({
	// 		...files,
	// 		currentfile,
	// 	});
	// };

	const {
		setValue,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	if (!data) {
		navigate("/course");
	}

	useEffect(() => {
		generalApi.getRescheduleReason().then(
			(response) => {
				setRescheduleReason(response);
				// setSelectedReasonType(Object.keys(response)[0]);
			},
			(error) => {
				navigate("/course");
			},
		);
	}, []);

	useEffect(() => {
		setSelectedReason("");
		setValue("reason", "");
		// if (selectedReasonType === "Others") {
		// 	setSelectedReason("");
		// } else if (selectedReasonType) {
		// 	setSelectedReason(RescheduleReason[selectedReasonType][0]);
		// }
	}, [selectedReasonType]);

	const handleReasonTypeChange = (event) => {
		const reasonType = event.target.value;
		setSelectedReasonType(reasonType);
		// setSelectedReason("");
		// setValue("reason", "");
	};

	const handleReasonChange = (event) => {
		const reason = event.target.value;
		setSelectedReason(reason);
	};

	useEffect(() => {
		if (!data || data.length < 0) {
			navigate("/course");
		}

		generalApi.getRescheduleCourseRun(String(data?.courserun_id)).then(
			(response) => {
				let responseCourseDate = {};
				response.map(
					(option, i) =>
						(responseCourseDate[option.courserun_id.toString()] = {
							value: option.courserun_id,
							label:
								moment(option.courserun_course_from).format("DD MMM YYYY, ddd") +
								" - " +
								moment(option.courserun_course_to).format("DD MMM YYYY, ddd") +
								" (" +
								option.venue.traininglocation.traininglocation_title +
								")",
							trainer: option.trainer?.trainer_name,
							courserun_course_from: option.courserun_course_from,
						}),
				);
				setAllCourseDate(responseCourseDate);
				setSelectedCourseDate(Object.values(responseCourseDate)[0]?.value);
			},
			(error) => {
				navigate("/course");
			},
		);
	}, []);

	const handleCourseDateChange = (event) => {
		setSelectedCourseDate(event.target.value);
	};

	const handleReschedule = async (data) => {
		setLoading(true);

		const formData = new FormData();

		formData.append("learner_id", learner_id);
		formData.append("reason_type", data.reasonType);
		formData.append("reason", data.reason);
		formData.append("new_courserun_id", data.newCourseDate);
		formData.append("remark", data.remark);

		const checkfor14day = await checkCourseRunDateDifference();

		if (data.supportingDocument.length < 1) {
			// if (course.isFirstReschedule === false && data.supportingDocument.length < 1) {
			toast.error("Please Upload at least 1 Supporting Document.");
			setLoading(false);
		} else {
			for (let i = 0; i < data.supportingDocument.length; i++) {
				formData.append("attachment" + (i + 1), data.supportingDocument[i]);
			}
			if (checkfor14day) {
				generalApi
					.rescheduleCourse(formData)
					.then(
						(response) => {
							setLoading(false);
							navigate("/course/success-page", {
								state: { data: { course: course, type: "reschedule" } },
							});
						},
						(error) => {
							setLoading(false);
							setmessage(getErrorMessage(error));
						},
					)
					.catch(() => {
						setLoading(false);
					});
			}
		}

	};
	//
	const checkCourseRunDateDifference = () => {
		const currentCourseFrom = moment(course.courserun_course_from);
		const newCourseFrom = moment(allCourseDate[selectedCourseDate].courserun_course_from);

		const daysDifference = newCourseFrom.diff(currentCourseFrom, "days");
		if (learner_sfc > 0 && (daysDifference >= 14 || daysDifference <= -14)) {
			return new Promise((resolve) => {
				Swal.fire({
					// title: "",
					html: "<p>You are required to resubmit your SkillsFuture Credit claims for rescheduling to course runs that are 14 days apart.</p>",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Proceed",
					cancelButtonText: "Cancel",
				}).then((result) => {
					if (result.isConfirmed) {
						resolve(true);
					} else {
						setLoading(false);
						resolve(false);
					}
				});
			});
		} else {
			return Promise.resolve(true);
		}
	};

	return (
		<>
			{course && (
				<div className='reschedule_main_container'>
					<div className='title-container'>
						<span className="fs40 text-fca-dark font_2">Reschedule</span>
					</div>
					<div>
						<Card className='mt-2'>
							<CardBody>
								<p className='pb-2 text-fca-blue fs20 font_2'>{data.course_title}</p>

								<Form role='form' onSubmit={handleSubmit(handleReschedule)}>
									<Row>
										<Col md='6' xl='6'>
											<FormGroup>
												<label className='fs14 text-fca-dark font-weight-600' htmlFor='input-newCourseDate'>
													New Course Date
												</label>

												<select
													className={"form-control " + (errors.newCourseDate ? "is-invalid" : "")}
													{...register("newCourseDate")}
													id='input-newCourseDate'
													// defaultValue={Object.values(allCourseDate)[0]}
													onChange={handleCourseDateChange}>
													<option value=''>Please Select New Course Date</option>
													{Object.keys(allCourseDate).length !== 0
														? Object.values(allCourseDate).map((option, i) => (
															<option key={i} value={option.value}>
																{option.label}
															</option>
														  ))
														: ""}
												</select>

												<div className='invalid-feedback'>{errors.newCourseDate?.message}</div>
											</FormGroup>
										</Col>
										<Col md='6' xl='6'>
											<FormGroup>
												<label className='fs14 text-fca-dark font-weight-600' htmlFor='input-trainer'>
													Trainer
												</label>
												<input
													className={"form-control"}
													id='input-trainer'
													placeholder='Trainer'
													defaultValue={
														allCourseDate[selectedCourseDate]
															? allCourseDate[selectedCourseDate].trainer
															: "-"
													}
													disabled
												/>
											</FormGroup>
										</Col>
										<Col md='6' xl='6'>
											<FormGroup>
												<label className='fs14 text-fca-dark font-weight-600' htmlFor='input-reasonType'>
													Reason Type
												</label>
												<select
													className={"form-control " + (errors.reasonType ? "is-invalid" : "")}
													{...register("reasonType")}
													id='input-reasonType'
													onChange={handleReasonTypeChange}
													// defaultValue={Object.keys(RescheduleReason)[0]}
												>
													<option value=''>Please Select Reason Type</option>
													{Object.keys(RescheduleReason).length !== 0
														? Object.keys(RescheduleReason).map((option, i) => (
															<option key={i} value={option}>
																{option}
															</option>
														  ))
														: ""}
												</select>
												<div className='invalid-feedback'>{errors.reasonType?.message}</div>
											</FormGroup>
										</Col>
										<Col md='6' xl='6'>
											{selectedReasonType && selectedReasonType !== "Others" ? (
												<FormGroup>
													<label className='fs14 text-fca-dark font-weight-600' htmlFor='input-reason'>
														Reason
													</label>

													<select
														className={"form-control " + (errors.reason ? "is-invalid" : "")}
														{...register("reason")}
														id='input-reason'
														onChange={handleReasonChange}
														value={selectedReasonRef.current}>
														<option value=''>Please Select Reason</option>

														{RescheduleReason[selectedReasonType].map((option, i) => (
															<option key={i} value={option}>
																{option}
															</option>
														))}
													</select>

													<div className='invalid-feedback'>{errors.reason?.message}</div>
												</FormGroup>
											) : (
												""
											)}
										</Col>
										<Col md='12' xl='12'>
											<FormGroup>
												<label className='fs14 text-fca-dark font-weight-600' htmlFor='input-remark'>
													Remark
												</label>
												<textarea
													className={"form-control " + (errors.remark ? "is-invalid" : "")}
													id='input-remark'
													placeholder='Enter your Remark here...'
													{...register("remark")}
												/>
												<div className='invalid-feedback'>{errors.remark?.message}</div>
											</FormGroup>
										</Col>
										<Col md='12' xl='12'>
											<FormGroup>
												<label className='fs14 text-fca-dark font-weight-600' htmlFor='input-supporting-document'>
													Supporting Document
												</label>
												{/* <FileUploader
									multiple={true}
									handleChange={handleChange}
									name='supportingDocument'
									types={fileTypes}
									fileOrFiles='Array'
									required
								/>

								{Object.keys(files).length !== 0 ? (
									<>
										<p>Uploaded Files:</p>
										<ul>
											{files.map((file) => (
												<li key={file.name}>
													{file.name}
													<button type='button' onClick={() => handleDelete(file)}>
							Delete
													</button>
												</li>
											))}
										</ul>
									</>
								) : (
									""
								)} */}
												<input
													type='file'
													accept='image/png, image/jpeg, .pdf'
													className={
														"form-control " + (errors.supportingDocument ? "is-invalid" : "")
													}
													id='input-supportingDocument'
													{...register("supportingDocument")}
													multiple
													// required
												/>
												<small>Please upload 1 - 3 files</small>
												<div className='invalid-feedback'>{errors.supportingDocument?.message}</div>
											</FormGroup>
										</Col>
										<Col md='12' xl='12'>
											{message && (
												<div className='form-group'>
													<div
														className='alert alert-danger'
														role='alert'
														dangerouslySetInnerHTML={{ __html: message }}></div>
												</div>
											)}
										</Col>
										<Col md='12' xl='12'>
											<div className='flex justify-content-center justify-content-between mb-4 mt-6'>
												<Button
													onClick={() => navigate(-1)}
													className='btn btn-default ml-auto'>
													Back
												</Button>
												<Button className='btn-gradient' type='submit' disabled={loading}>
													{loading && <span className='spinner-border spinner-border-sm'></span>}
													<span>Reschedule Now</span>
												</Button>
											</div>
										</Col>
									</Row>
								</Form>
							</CardBody>
						</Card>
					</div>
				</div>
			)}
		</>
	);
}

export default ModalReschedule;
