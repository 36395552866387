
import React, { useEffect, useState } from "react";
import  BackgroundPNG  from "assets/img/Referral/banner_background_2.png";
import { ReactComponent as BackgroundSVG } from "assets/img/Referral/banner_background.svg";
import { ReactComponent as LeafSVG } from "assets/img/Referral/leaf.svg";
import { ReactComponent as CoinSVG } from "assets/img/Referral/coin.svg";
import { ReactComponent as GiftSVG } from "assets/img/Referral/gift.svg";
import { ReactComponent as CloudSVG } from "assets/img/Referral/cloud.svg";

import styles from "../ReferralDashboard.module.scss";
import { debounce } from "lodash";


const ReferralBannerFloating = (props) => {
	// dynamic height of image and floating image
	const [targetHeight, setTargetHeight] = useState(0);
	const [targetWidth, setTargetWidth] = useState(0);
	
	const handleResize = debounce(() => {
		// Get the container and image elements
		const container = document.querySelector(`#referral_banner_floating_container_${props.id}`);
		const image = document.querySelector(`#banner_main_image_${props.id}`);

		// Calculate the real height of the image
		const aspectRatio = image.naturalWidth / image.naturalHeight;
		const realHeight = container.offsetWidth / aspectRatio;

		const targetDiv = document.getElementById(`banner_main_image_${props.id}`);
		if (targetDiv) {
			setTargetHeight(realHeight);
			setTargetWidth(targetDiv.clientWidth);
		}
	}, 500);
	
	useEffect(() => {
		handleResize(); // Initial calculation
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div id={`referral_banner_floating_container_${props.id}`} className={`${styles.referral_banner_floating_container}`}>
			{/* <BackgroundSVG id={"banner_main_image"}  className={`${styles.banner_main_image}`} /> */}
			<img id={`banner_main_image_${props.id}`}  className={styles.banner_main_image} alt='skill' src={BackgroundPNG} />

			<div className={`${styles.vector_animate_ele} ${styles.vector_animate_wrap}`} style={{ height: `${targetHeight}px`, width: `${targetWidth}px` }}>
				<div className={`${styles.item_2} ${styles.leaf_animation}`}><LeafSVG className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_3} ${styles.coin_animation}`}><CoinSVG className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_4} ${styles.gift_animation}`}><GiftSVG className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_5} ${styles.cloud_animation1}`}><CloudSVG className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_6} ${styles.cloud_animation2}`}><CloudSVG className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_7} ${styles.cloud_animation3}`}><CloudSVG className={`${styles.banner_1_item_image}`} /></div>
				<div className={`${styles.item_8} ${styles.cloud_animation4}`}><CloudSVG className={`${styles.banner_1_item_image}`} /></div>
			</div>
		</div>
	);
};
export default ReferralBannerFloating;