import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Button, Col, Collapse, FormGroup, Input, Modal, ModalHeader, Row, Table } from "reactstrap";
import Swal from "sweetalert2";
import { ReactComponent as TncSvg } from "assets/img/endorse/icon_T&C.svg";

import { useSelector } from "react-redux";
import "./ReferralRedeemModal.css";
import IndividualForm from "./ReferralContactUsIndividual";
import CorporateForm from "./ReferralContactUsCorporate";
import { ReactComponent as CancelBtnWhite } from "assets/img/Checkout/cancel01.svg";
import { ReactComponent as CancelBtnBlack } from "assets/img/Checkout/cancel02.svg";
import ReferralBannerFloating from "views/pages/learner/referral/components/ReferralBannerFloating";
import SignatureCanvas from "react-signature-canvas";
import withReactContent from "sweetalert2-react-content";
import ReactPaginate from "react-paginate";
import referralApi from "services/referral-api";
import { getErrorMessage } from "utilities/error";
import { toast } from "react-toastify";

const pageSize = 10; // Number of items per page

function ReferralRedeemModal(props) {
	const [loading, setLoading] = useState(false);
	const [modalChange, setModalChange] = useState(true);
	const [message, setMessage] = useState("");
	const [readyRedeemList, setReadyRedeemList] = useState(props.readyRedeemList);
	const [currentPage, setCurrentPage] = useState(0);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [isSelectedAll, setIsSelectedAll] = useState(false);

	const { user: userData } = useSelector((state) => state.auth);
	const canvasRef = useRef(null);
	const checkboxTNC = useRef(null);
	const [accordionOpen, setAccordionOpen] = useState(false);
	const MySwal = withReactContent(Swal);

	const handleModalClose = () => {
		props.onClose(true);
	}; 
	
	let navigate = useNavigate();

	useEffect(() => {
		setReadyRedeemList(props.readyRedeemList);
	}, 
	[props.readyRedeemList]);

	const handleContinue = (data) => {
		setLoading(true);
		if (!checkboxTNC.current.checked) {
			setLoading(false);
			MySwal.fire({
				title: "",
				html: "Please agree to the terms & conditions",
				icon: "error",
			
			});
		} else if (selectedOptions.length <= 0) {
			setLoading(false);
			MySwal.fire({
				title: "",
				html: "Please select voucher to redeem",
				icon: "error",
			
			});
		} else {
			if (canvasRef.current.isEmpty()) {
				setLoading(false);
				// setMessage("Please Sign to Endorse");
				MySwal.fire({
					title: "",
					html: "Please sign before continue",
					icon: "error",
					// icon: "warning",
					// didOpen: () => {
					// 	// `MySwal` is a subclass of `Swal` with all the same instance & static methods
					// 	MySwal.showLoading();
					// },
				});

			} else {
				const dataUrl = canvasRef.current.toDataURL("image/png");
				referralApi.redeemVoucher(selectedOptions, dataUrl).then(
					(response) => {
						setLoading(false);
						MySwal.fire({
							title: "Redeem Voucher Requested Successfully",
							html: <p>We will contact you soon!</p>,
							icon: "success",
						}).then(() => {
							props.onSuccess(true);
						});
					},
					(error) => {
						setLoading(false);
						toast.error(getErrorMessage(error));
					},
				);
				// MySwal.fire({
				// 	title: "Thank you for your enquiry",
				// 	html: <p>We will contact you soon!</p>,
				// 	icon: "success",
				// }).then(() => {
				// 	props.onSuccess(true);
				// });
			}
		}
	};

	const handlePageChange = ({ selected }) => {
		setCurrentPage(selected);
	};

	const handleCheckboxChange = (event) => {
		const option = Number(event.target.value);

		// Check if the option is already in the selectedOptions array
		if (selectedOptions.some(obj => obj.id === option)) {
		  // If it is, remove it
		  setSelectedOptions(selectedOptions.filter((item) => item.id !== option));
		} else {
		  // If it's not, add it to the selectedOptions array
		  setSelectedOptions([...selectedOptions, readyRedeemList[option]]);
		}
	};

	const handleSelectAll = (event) => {
		if (isSelectedAll) {
			setSelectedOptions([]);
			setIsSelectedAll(false);
		} else {
			setSelectedOptions([...readyRedeemList]);
			setIsSelectedAll(true);
		}
	};

	const paginatedData = readyRedeemList ? readyRedeemList.slice(currentPage * pageSize, (currentPage + 1) * pageSize) : [];


	return (
		<>
			<Modal
				className='modal-dialog-centered registration_modal referral_redeem_modal'
				isOpen={props.show}
			>
				<ModalHeader className="contact-form-close" toggle={props.toggle}>
					<div className="close close-black">
						<CancelBtnBlack onClick={handleModalClose}></CancelBtnBlack>
					</div>
					<div className="close close-white">
						<CancelBtnWhite onClick={handleModalClose}></CancelBtnWhite>
					</div>
				</ModalHeader>
			
				<div className="p-4">
					<div className="form-section">
						<div className='desc pb-4 flex justify-content-center'>
							<p className="contact_header">
								{"Redeem Voucher"}
							</p>
						</div>
						{!readyRedeemList || (readyRedeemList && readyRedeemList.length <= 0) &&
                            <div className='justify-content-center align-items-center w-100 flex p-4'>
                            	<span className="mr-2">Nothing to Redeem</span>
                            </div>
                		}
						{readyRedeemList && readyRedeemList.length > 0 &&
                            <>
                            	<Table hover responsive className={"referral_datatable_table"}>
                            		<thead className={"datatable_table_header"}>
                            			<tr>
                            				<th className={"datatable_header_th"}>
                            					<div className="d-flex flex-row">
                            						<FormGroup className="datatable_checkbox_container mr-2">
                            							<Input
                            								type="checkbox"
                            								value={""}
                            								className={"datatable_checkbox_input"}
                            								checked={isSelectedAll}
                            								onChange={handleSelectAll}
                            							/>
                            						</FormGroup>
                            						{"Select"}
                            					</div>
                            				</th>
                            				<th className={"datatable_header_th"}>{"Type"}</th>
                            				<th className={"datatable_header_th"}>{"Name"}</th>
                            				<th className={"datatable_header_th"}>{"Contact No."}</th>
                            				<th className={"datatable_header_th"}>{"Email"}</th>
                            				<th className={"datatable_header_th"}>{"Status"}</th>
                            				<th className={"datatable_header_th"}>{"Remarks"}</th>
                            				<th className={"datatable_header_th"}>{"Redeem Status"}</th>
                            			</tr>
                            		</thead>
                            
                            		<tbody className={"datatable_table_body"}>
                            			{paginatedData.map((item, index) => (
                            				<tr key={index}>
                            					<td>
                            						<FormGroup className="datatable_checkbox_container">
                            							<Input
                            								type="checkbox"
                            								value={item.id}
                            								className={"datatable_checkbox_input"}
                            								checked={selectedOptions.some(obj => obj.id === Number(item.id))}
                            								onChange={handleCheckboxChange}
                            							/>
                            						</FormGroup>
                            					</td>
                            					<td>
                            						{item.type === "from_order" 
                            							?  "Order"
                            							: item.lead_group === "SOLAR" ?
                            								"Corporate Lead"
                            								:
                            								"Individual Lead"
                            						}
                            					</td>
                            					<td>{item.display_name}</td>
                            					<td>{item.display_phone}</td>
                            					<td>{item.display_email}</td>
                            					<td>
                            						{item.quality 
                            							?  <div className={"table_status_container"}>
                                                        	<span className={"table_status_text"}>Qualified</span>
                            							</div>
                            							:null
                            						}
                            						{item.unqualified 
                            							?  <div className={"table_status_container_orange"}>
                                                       	<span className={"table_status_text_orange"}>Not Qualified</span>
                            							</div>
                            							:null
                            						}
                            						{!item.quality && !item.unqualified 
                            							?<div className={"table_status_container_grey"}>
                                                        	<span className={"table_status_text_orange_grey"}>-</span>
                            							</div>:null
                            						}
                            					</td>
                                                
                            					<td>{item.display_remarks ? item.display_remarks : "-"}</td>
                            					<td>
                            						{item.pv_payment_status && item.pv_payment_status === "Paid" &&
                                                        <div className={"table_status_container"}>
                                                        	<span className={"table_status_text"}>Paid</span>
                                                        </div>
                            						}
                            						{item.pv_payment_status && item.pv_payment_status === "Rejected" &&
                                                        <div className={"table_status_container_red"}>
                                                        	<span className={"table_status_text_red"}>Rejected</span>
                                                        </div>
                            						}
                            						{item.pv_payment_status && item.pv_payment_status === "Submitted" &&
                                                        <div className={"table_status_container_orange"}>
                                                        	<span className={"table_status_text_orange"}>Submitted</span>
                                                        </div>
                            						}
                            						{!item.pv_payment_status &&
                                                        <div className={"table_status_container_grey"}>
                                                        	<span className={"table_status_text_orange_grey"}>-</span>
                                                        </div>
                            						}
                            					</td>
                            				</tr>
                            			))}
                            		</tbody>
                            	</Table>
                            	<ReactPaginate
                            		pageCount={Math.ceil(readyRedeemList.length / pageSize)}
                            		pageRangeDisplayed={3}
                            		marginPagesDisplayed={1}
                            		onPageChange={handlePageChange}
                            		containerClassName="pagination"
                            		activeClassName="active"
                            		previousLabel={
                            			<i className='fa fa-angle-left' aria-hidden='true'></i>
                            		}
                            		nextLabel={
                            			<i className='fa fa-angle-right' aria-hidden='true'></i>
                            		}
                            	/>
                            </>
                		}
						{readyRedeemList && readyRedeemList.length > 0 &&
							<>
								<div className='pt-4'>
									<div className='pb-2'>
										<b className='$fs15 text-fca-dark font-weight-600'>Referral Signature</b>
										<a
											href='#'
											className='ml-2'
											onClick={(e) => {
												e.preventDefault();
												canvasRef.current.clear();
											}}>
											Clear
										</a>
									</div>
									<SignatureCanvas
										ref={canvasRef}
										clearOnResize={false}
										canvasProps={{height: 200, className: "sigCanvas border", style: {width: "100%", maxWidth: "500px"}}}
									/>
								</div>
								<div className='pt-2'>
									<label htmlFor='tnc-checkbox'>
										<input type='checkbox' id='tnc-checkbox' ref={checkboxTNC}></input>
										<span>
											&nbsp; I acknowledge that I have read and agree to the above
											<a
												href='#terms-and-conditions'
												className='ml-2 text-fca-green text-underline cursor-pointer'
												onClick={(e) => {
													setAccordionOpen(!accordionOpen);
												}}>
												terms and conditions.
											</a>
										</span>
									</label>
								</div>
								<Collapse isOpen={accordionOpen}>
									<Row>
										<Col>
											<div
												className='p-3'
												style={{ borderWidth: "1px", borderStyle: "solid", borderColor: "#ddd" }}>
												<div>
											Thank you for choosing FirstCom Academy as your preferred training provider.
													<br></br>
													<br></br>
											Upon successful registration, our Training Support Executives will secure a seat
											for the class you have enrolled in. Arrangements will also be made to book our
											trainer, assessor, training room, course materials, food catering, and shuttle bus
											to prepare and welcome you for the class.
													<br></br>
													<br></br>
											Please refer to the important terms and conditions below.
													<br></br>
													<br></br>
												</div>
												<h4>Important Terms & Conditions</h4>
												<div className='font-weight-bold'>1. Course Requirements</div>
												<div className=''>
											Trainee(s) will need to fulfill at least 75% attendance and be marked competent
											during assessment to enjoy the Skillsfuture subsidized rates. In the event that
											either criteria is not met, trainee(s) are liable to pay for the full course and
											no certification and Statement of Attainment will be issued.
												</div>

												<div className='pt-4 font-weight-bold'>2. Rescheduling</div>
												<div className=''>
											Rescheduling for confirmed enrolments must be submitted via our online reschedule
											form (
													<a
														href={"https://falcomm.fca.edu.sg/"}
														target='_blank'
														rel='noreferrer'
														className={"green_hyperlink"}>
												https://falcomm.fca.edu.sg/
													</a>
											) or email us at (
													<a
														href='mailto:help@fca.edu.sg'
														target='_blank'
														rel='noreferrer'
														className={"green_hyperlink"}>
												help@fca.edu.sg
													</a>
											) at least seven (7) days prior to course date with supporting documents. Fees
											will be waived for all requests with supporting documents (ie. Medical Reasons,
											Covid, Work Commitment etc).
												</div>
												<div className='pt-3'>Reschedule Request Rescheduling Fees</div>
												<div className='pt-3'>
													<Table striped bordered hover>
														<thead>
															<tr>
																<th
																	className='font-weight-bold text-color-dark $fs15'
																	style={{ fontSize: "13px" }}>
															Reschedule Request
																</th>
																<th
																	className='font-weight-bold text-color-dark $fs15'
																	style={{ fontSize: "13px" }}>
															Rescheduling Fees
																</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>1st Reschedule</td>
																<td>No Charge</td>
															</tr>
															<tr>
																<td>2nd and Subsequent Reschedules</td>
																<td>10% of full course fees (Waivable with valid reasons)</td>
															</tr>
														</tbody>
													</Table>
												</div>
												<div className='pt-2'>
											A maximum of three (3) reschedule requests is allowed. There will be no
											rescheduling allowed after the 3rd reschedule and the enrolment will be considered
											as a no-show.
												</div>

												<div className='pt-4 font-weight-bold'>3. Withdrawal/Cancellation/No-show</div>
												<div className=''>
											Any withdrawal/cancellation/no-show for confirmed enrolments must be submitted via
											our online withdrawal form (
													<a
														href='https://falcomm.fca.edu.sg/'
														target='_blank'
														rel='noreferrer'
														className={"green_hyperlink"}>
												https://falcomm.fca.edu.sg/
													</a>
											) or email us at (
													<a
														href='mailto:help@fca.edu.sg'
														target='_blank'
														rel='noreferrer'
														className={"green_hyperlink"}>
												help@fca.edu.sg
													</a>
											) at least seven (7) days prior to course date with supporting documents. In the
											event of withdrawal/cancellation/no-show without submission of a withdrawal form
											containing supporting documents, trainee(s) will be liable to pay the full course
											fees.
												</div>
												<div className='pt-2'>
											Please note that government funding is not applicable for
											withdrawal/cancellation/no-show and the penalty fees may need to be paid via
											cash/bank transfer.
												</div>

												<div className='pt-4 font-weight-bold'>4. Other Terms</div>
												<div className=''>
											In view of the current Covid situation, FirstCom Academy reserves the right to
											cancel, postpone and change the venue or mode of the course(s) in the event of
											unforeseen circumstances. Notification will be provided to trainee(s) within a
											reasonable timeframe.
													<br></br>
													<br></br>
											For learners using your
													<a
														href='https://www.moe.gov.sg/financial-matters/psea/overview'
														target='_blank'
														rel='noreferrer'
														className={"green_hyperlink"}>
														{" Post Secondary Education Account (PSEA) "}
													</a>
											as mode of payment, kindly note that your PSEA will be closed around the middle of
											the year in which you turn 31. Any unused funds will be transferred to your CPF-OA
											and subjected to CPF-OA usage rules. Once transferred, the PSEA will be
											closed.Should this situation arise resulting in a shortfall of payment, please
											note that you will need to arrange for other forms of payment to cover the
											shortfall.
												</div>

												<div className='pt-4 font-weight-bold'>5. Privacy Policy</div>
												<div className=''>
											FirstCom Academy Pte Ltd has the right to use the data provided for the purpose of
											processing Trainee(s)’ course registration, subject to the provisions of the
											Personal Data Protection Act 2012 (PDPA). Photographs / video may be taken during
											the course for marketing purposes.
												</div>

												<div className='pt-4 font-weight-bold'>6. Refund Policy</div>
												<div className=''>
											If the Trainee(s) does not participate in the Programme / Course after having paid
											all or any portion of the Programme / Course fees, or if the Trainee(s) drop out
											before completion of the Programme / Course without valid reasons and supporting
											documents, no deposits or money paid towards the Program / Course fees shall be
											refunded. Appeals with valid reasons and supporting documents will be processed on
											a case by case basis, subjected to management approval.
												</div>
											</div>
										</Col>
									</Row>
								</Collapse>
								<div className='flex justify-content-end contact-submit mb-4 mt-4 pr-4'>
									<Button className='btn-gradient' onClick={handleContinue} disabled={loading || selectedOptions.length <= 0}>
										{loading && <span className='spinner-border spinner-border-sm'></span>}
										<span>Continue</span>
									</Button>
								</div>
							</>
						}
						
					</div>
				</div>
			</Modal>
		</>
	);
}

export default ReferralRedeemModal;
