import * as yup from "yup";

const nricRegex = new RegExp(/^[A-Za-z][0-9]{7}[A-Za-z]$/);
const mobilePhoneRegex = new RegExp(/^[8936]{1}[0-9]{7}$/);
const otpRegex = new RegExp(/^[0-9]{0,6}$/);
let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
// let emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const yupHelper = {
	password: () =>
		yup
			.string()
			.trim()
			.required()
			.min(8, "Password required at least 8 Characters")
			.matches(/^(.*[A-Z].*)*$/, "Password required at least 1 Upper case character")
			.matches(/^(.*[a-z].*)*$/, "Password required at least 1 Lower case character")
			.matches(/^(.*\d.*)*$/, "Password required at least 1 Numeric character")
			.matches(
				/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/,
				"Password required at least 1 Special character",
			),
	fullname: () =>
		yup
			.string()
			.trim()
			.required()
			.matches(/^[a-zA-Z,'/ ()-]*$/, "Your name is invalid"), // Only alphabet, comma, slash, brackets, and whitespace are allowed
	nric: () => yup.string().trim().required().matches(nricRegex, "Invalid NRIC format"),
	nricOptional: () =>
		yup
			.string()
			.trim()
			.refine((val) => {
				if (!val) {
					return true;
				}
				if (val) {
					if (nricRegex.test(val)) {
						return true;
					}
					return false;
				}
			}, "Invalid NRIC format"),

	email: () =>
		yup
			.string()
			.trim()
			.required()
			.email("Invalid Email format")
			.matches(emailRegex, "Invalid Email format"),
	emailOptional: () =>
		yup
			.string()
			.trim()
			.test("email", "Invalid Email format", function (value) {
				if (!value) return true; // Allow empty values
				// Validation logic for email format
				if (emailRegex.test(value)) {
					return true; // Valid email format
				} else {
					return this.createError({
						message: "Invalid Email format",
						path: this.path,
					});
				}
			}),
	mobile: () =>
		yup.string().trim().required("Contact no. is required").matches(mobilePhoneRegex, "Invalid Mobile No. format"),
	stringOptional: () => yup.string().trim().optional(),
	number: () => yup.number(),
	otp: () => yup.string().required().matches(otpRegex, "Invalid OTP"),
	requiredIfSponsoredCompany: () =>
		yup.string().when("sponsoredCompany", {
			is: (sponsoredCompany) => sponsoredCompany === "Yes",
			then: () => yup.string().required(),
			otherwise: () => yup.string(),
		}),
	learning_intention: () =>
		yup
			.string()
			.trim()
			.required("Learning intention is required"),
};
