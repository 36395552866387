import {
	GET_NOTIFICATION,
	GET_NOTIFICATION_SUCCESS,
	GET_NOTIFICATION_FAIL,
} from "./types";

import NotificationService from "../services/notification-api";
import { getErrorMessage } from "utilities/error";

export const getNotification = (paramPage = 0) => (dispatch) => {
	dispatch({
		type: GET_NOTIFICATION,
	});

	return NotificationService.getNotifications(paramPage).then(
		(data) => {
			dispatch({
				type: GET_NOTIFICATION_SUCCESS,
				payload: { notifications: data.data, page: paramPage },
			});

			return Promise.resolve(data);
		},
		(error) => {
			dispatch({
				type: GET_NOTIFICATION_FAIL,
			});

			return Promise.reject(getErrorMessage(error));
		},
	);
};
