import { PrintDate } from "common_function/function";
import { PrintCourseDate } from "common_function/function";
import { formatCSession } from "common_function/function";
import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Button } from "reactstrap";

import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { getAllDateArray } from "common_function/function";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { isTrainerTBA } from "common_function/function";
import { set } from "lodash";
import { setCheckout } from "../../../reducers/courseRegisterSlice";
import { useDispatch } from "react-redux";
// const bookedDays = [new Date(2021, 5, 8), new Date(2021, 5, 9)];
const modifierStyle = {
	normal_start: "clickable normal start",
	normal_middle: "clickable normal middle",
	normal_end: "clickable normal end",
	limit_start: "clickable limit start",
	limit_middle: "clickable limit middle",
	limit_end: "clickable limit end",
};
function CourseRightInfo({
	course_title,
	course_duration,
	courserun_modeoftraining,
	course_registered_learners,
	course_training_location,
	selectedLocation,
	setSelectedLocation,
	selectedCourseRunID,
	setSelectedCourseRunID,
	courseTrainer,
	setCourseTrainer,
	is980Below,
}) {
	// const [selectedLocation, setSelectedLocation] = useState("");
	const [courseRunArray, setCourseRunArray] = useState([]);
	const [calendarCourseRunArray, setCalendarCourseRunArray] = useState([]);
	const [selectedCourseRun, setSelectedCourseRun] = useState([]);

	const [selectedDay, setSelectedDay] = useState();
	const [selectedDayString, setSelectedDayString] = useState("");
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleDayClick = (day, modifiers) => {};

	const handleEnrollNow = () => {
		if (selectedCourseRun) {
			// setcheckout got changes, need redo the function - manvee
			dispatch(setCheckout({ courserun_id: selectedCourseRun.id }));
			// give some buffer time to clear redux
			setTimeout(() => {
				navigate("/checkout/account");
			}, 300);
		}
	};

	useEffect(() => {
		if (selectedDay) {
			let dateString = moment(selectedDay).format("yyyy-MM-DD");
			setSelectedDayString(dateString);
			if (calendarCourseRunArray[selectedLocation].calendar_course_run_array[dateString]) {
				setSelectedCourseRun(
					calendarCourseRunArray[selectedLocation].calendar_course_run_array[dateString][0],
				);
				setSelectedCourseRunID(
					calendarCourseRunArray[selectedLocation].calendar_course_run_array[dateString][0].id,
				);
			}
		}
	}, [selectedDay]);

	useEffect(() => {
		if (selectedCourseRunID) {
			let locationCourseRun = courseRunArray[selectedLocation];
			let courseRun = locationCourseRun.find((x) => x.id == selectedCourseRunID);
			// console.log(courseRun.start);
			// console.log(new Date());
			setSelectedDay(courseRun.start);
			setSelectedCourseRun(courseRun);
		} else {
			setSelectedCourseRun([]);
			setSelectedDay(null);
		}
	}, [selectedCourseRunID]);

	// useEffect(() => {
	// }, [selectedLocation]);

	// const footer = booked ? <div>This day is already booked!</div> : "Try to pick a booked day.";
	const CalendarFooter = (footer) => {
		return (
			<div className='mt-4 courseRunSelectWrap'>
				{selectedDayString &&
					calendarCourseRunArray[selectedLocation].calendar_course_run_array[selectedDayString] && (
					<>
						{calendarCourseRunArray[selectedLocation].calendar_course_run_array[
							selectedDayString
						].map((courserun, index) => (
							<div className={"courseRunItem"} key={index}>
								<div
									className={
										(selectedCourseRun?.id == courserun.id ? "selected" : "") + " courseRunSelect"
									}
									onClick={() => handleSelectCourseRun(courserun)}>
									<div className='title'>{courserun.title}</div>
									<div className='trainer'>
										<div className='icon'>
											<i className='fas fa-user'></i>
										</div>
										<div className='trainer-name'>
											<b>Trainer: </b>
											{courserun?.trainer?.trainer_name ?? "TBA"}
										</div>
									</div>
									{/* <div className='location'>
										<div className='icon'>
											<i className='fas fa-map-marker-alt'></i>
										</div>
										<div className='location-name'>
											<b>Location: </b>
											{course_training_location[selectedLocation].traininglocation_title}
										</div>
									</div> */}

									<div className='time'>
										{courserun.cSession &&
												courserun.cSession.map((session, i) => <div key={i}>{session}</div>)}
									</div>
								</div>
							</div>
						))}
					</>
				)}

				<Row className='legend-container mt-2'>
					<Col xl='6' md='6'>
						<div className='legend d-flex text-fca-blue fs12'>
							<div className='legend-icon'>
								<i className='fas fa-circle'></i>
							</div>
							<div className='legend-text pl-2'>Available !</div>
						</div>
					</Col>
					<Col xl='6' md='6'>
						<div className='legend d-flex text-fca-red fs12'>
							<div className='legend-icon'>
								<i className='fas fa-circle'></i>
							</div>
							<div className='legend-text pl-2'>Limited Seat !</div>
						</div>
					</Col>
				</Row>

				{selectedCourseRun && selectedCourseRun?.id && (
					<>
						{is980Below ? (
							<div className='mobile-sticky pd20'>
								<Button target='_blank' rel='noreferrer' href='https://www.fca.edu.sg/courses/' className='btn-gradient w-100 mt-4'>
									<span>Enroll Now</span>
								</Button>
								{/* <Button className='btn-gradient w-100 mt-4' onClick={handleEnrollNow}>
									<span>Enroll Now</span>
								</Button> */}
							</div>
						) : (
							<Button target='_blank' rel='noreferrer' href='https://www.fca.edu.sg/courses/' className='btn-gradient w-100 mt-4'>
								<span>Enroll Now</span>
							</Button>
							// <Button className='btn-gradient w-100 mt-4' onClick={handleEnrollNow}>
							// 	<span>Enroll Now</span>
							// </Button>
						)}
					</>
				)}
			</div>
		);
	};

	useEffect(() => {
		if (course_training_location && course_training_location.length > 0) {
			let calendar_array = [];
			let course_run_array = [];
			let all_trainer = [];
			course_training_location.map((location, i) => {
				let allCourseRun = [];
				let trainer = [];

				let calendar_course_run_array = [];
				let calendar_overlay_array = [];
				let calendar_modifier_array = {
					normal_start: [],
					normal_middle: [],
					normal_end: [],
					limit_start: [],
					limit_middle: [],
					limit_end: [],
				};
				if (location.courserun.length > 0) {
					// location.courserun.map((courserun, j) => {
					Object.keys(location.courserun).map((j) => {
						const singleCourserun = location.courserun[j];
						all_trainer.push(singleCourserun.trainer);
						if (singleCourserun && !singleCourserun.is_full) {
							// console.log(singleCourserun);
							let courserun_data = {
								id: singleCourserun.courserun_id,
								title: PrintCourseDate(
									singleCourserun.courserun_course_from,
									singleCourserun.courserun_course_to,
								),
								start: new Date(singleCourserun.courserun_course_from),
								end: new Date(singleCourserun.courserun_course_to),
								className: singleCourserun.is_limited ? "bg-red" : "bg-blue",
								isFull: singleCourserun.is_full,
								isLimited: singleCourserun.is_limited,
								cSession: formatCSession(singleCourserun.csessions),
								trainer: singleCourserun.trainer,
							};
							allCourseRun[j] = courserun_data;
							let all_dates = getAllDateArray(
								singleCourserun.courserun_course_from,
								singleCourserun.courserun_course_to,
							);

							all_dates.forEach((date, index) => {
								if (!calendar_course_run_array[date]) {
									calendar_course_run_array[date] = [];
								} else {
									calendar_overlay_array.push(date);
								}
								calendar_course_run_array[date].push(courserun_data);

								if (singleCourserun.is_limited) {
									if (index === 0) {
										calendar_modifier_array["limit_start"].push(new Date(date));
									} else if (index === all_dates.length - 1) {
										calendar_modifier_array["limit_end"].push(new Date(date));
									} else {
										calendar_modifier_array["limit_middle"].push(new Date(date));
									}
								} else {
									if (index === 0) {
										calendar_modifier_array["normal_start"].push(new Date(date));
									} else if (index === all_dates.length - 1) {
										calendar_modifier_array["normal_end"].push(new Date(date));
									} else {
										calendar_modifier_array["normal_middle"].push(new Date(date));
									}
								}
							});
						}
					});

					// all_trainer.push(trainer);
				}
				course_run_array[i] = allCourseRun;
				calendar_array[i] = {
					calendar_course_run_array: calendar_course_run_array,
					calendar_modifier_array: calendar_modifier_array,
				};
			});
			// console.log(calendar_course_run_array);
			setCourseRunArray(course_run_array);
			setCalendarCourseRunArray(calendar_array);
			// console.log(all_trainer);
			const uniqueTrainers = Object.values(all_trainer)
				.map((x) => x)
				.filter((x) => Boolean(x))
				.filter(function onlyUnique(value, index, self) {
					if (!value) {
						return false;
					}
					return self.findIndex((y) => y.trainer_id === value.trainer_id) === index;
				})
				.filter((x) => {
					return !isTrainerTBA(x);
				});
			setCourseTrainer(uniqueTrainers);
		}
	}, [course_training_location]);

	const handleLocationChange = (event) => {
		// console.log(courseRunArray);
		setSelectedLocation(event.target.value);
		setSelectedCourseRun([]);
		setSelectedDay(null);
		setSelectedDayString("");
		setSelectedCourseRunID(null);

		// createCalendar(courseRunArray[event.target.value]);
	};

	const handleSelectCourseRun = (courseRun) => {
		// console.log(courseRun);
		setSelectedCourseRun(courseRun);
		setSelectedCourseRunID(courseRun.id);
	};

	return (
		<div className='course-right-info-container'>
			<div className='align-items-center pt-3'>
				<div>
					<b className='course-title'>{course_title}</b>
				</div>
			</div>
			<div className={`align-items-center pt-3 ${is980Below && "d-none"}`}>
				<div>
					<i className='fa fa-users text-fca-blue' aria-hidden='true'></i>{" "}
					<label className='m-0 pl-2 text-dark'>
						{course_registered_learners} Learners already enrolled
					</label>
				</div>
			</div>

			<hr className={`my-2 ${is980Below && "d-none"}`}></hr>

			<div className={`course_detail ${is980Below && "d-none"}`}>
				<div>
					<b>This course includes:</b>
				</div>
				<div>
					<div className='d-flex p-2 align-items-center'>
						<i className='fa fa-clock text-fca-blue' aria-hidden='true'></i>
						<label className='m-0 pl-2 text-dark'>{course_duration + " Days of Learning"}</label>
					</div>
				</div>

				<div>
					<div className='d-flex p-2 align-items-center'>
						<i className='fas fa-chalkboard-teacher text-fca-blue' aria-hidden='true'></i>
						<label className='m-0 pl-2 text-dark'>{courserun_modeoftraining}</label>
					</div>
				</div>

				<div>
					<div className='d-flex p-2 align-items-center'>
						<i className='fas fa-certificate text-fca-blue' aria-hidden='true'></i>
						<label className='m-0 pl-2 text-dark'>Course Accreditation</label>
					</div>
				</div>
			</div>

			<hr className='my-2'></hr>

			<div className='course_detail'>
				<div className='select-courserun-wrap'>
					<FormGroup>
						<label className='form-control-label' htmlFor='input-location'>
							Select Location
						</label>
						<select
							className={"form-control"}
							id='input-location'
							value={selectedLocation}
							onChange={handleLocationChange}>
							<option value=''>Please Select Location</option>
							{course_training_location &&
								course_training_location.map((option, index) => (
									<option key={index} value={index}>
										{option.traininglocation_title}
									</option>
								))}
						</select>
					</FormGroup>

					<FormGroup>
						<label className='form-control-label' htmlFor='input-courseRun'>
							Select Dates
						</label>
						<input
							className={"form-control courseRun-input"}
							id='input-courseRun'
							placeholder='Select Dates'
							readOnly
							value={selectedCourseRun?.title ?? ""}
							// onChange={handleCourseRunChange}
						></input>
					</FormGroup>

					{courseRunArray &&
						courseRunArray[selectedLocation] &&
						calendarCourseRunArray &&
						calendarCourseRunArray[selectedLocation] && (
						<div className='course-calendar-wrap'>
							<DayPicker
								format='yyyy-MM-dd'
								fromMonth={new Date()}
								month={selectedDay ?? new Date()}
								onMonthChange={setSelectedDay}
								defaultMonth={selectedDay ?? new Date()}
								mode='single'
								modifiers={{
									...calendarCourseRunArray[selectedLocation].calendar_modifier_array,
								}}
								modifiersClassNames={{ ...modifierStyle }}
								onDayClick={handleDayClick}
								footer={<CalendarFooter />}
								selected={selectedDay}
								onSelect={setSelectedDay}
							/>
							{/* <div className='calendar' data-toggle='calendar' id='calendar' ref={calendarRef} /> */}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default CourseRightInfo;
