export const getBundlePercentages = (
	totalCompleted,
	totalCourses
) => {
	// console.log(totalCompleted);
	// console.log(totalCourses);
	if (totalCompleted === null || totalCompleted === undefined) {
		return "0% Completed";
	}
	if (totalCourses === null || totalCourses === undefined) {
		return "";
	}
	let percentages = 100 / totalCourses;
	let completedPercentages = totalCompleted * percentages;
  
	return `${parseInt(completedPercentages.toString())}% Completed`;
};

export const intToString = num => {
	num = num.toString().replace(/[^0-9.]/g, "");
	if (num < 1000) {
		return num;
	}
	let si = [
		{ v: 1e3, s: "K" },
		{ v: 1e6, s: "M" },
		{ v: 1e9, s: "B" },
		{ v: 1e12, s: "T" },
		{ v: 1e15, s: "P" },
		{ v: 1e18, s: "E" },
	];
	let index;
	for (index = si.length - 1; index > 0; index--) {
		if (num >= si[index].v) {
			break;
		}
	}
	return (
		(num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
		si[index].s
	);
};
  