import styled from "styled-components";
import { UncontrolledTooltip } from "reactstrap";

// Default values
const backgroundColor = "rgba(229, 243, 250, 1) !important";
const borderColor = "#28a745";

// pointer-events to fix the tooltip flickering when mouseover
const TooltipRight = styled(UncontrolledTooltip)`
  opacity: 1 !important;
  pointer-events: none;

  .tooltip {
    z-index: 999999 !important;
    top: 15px !important;
  }
  .tooltip.show {
    opacity: 1 !important;
  }
  .tooltip-inner {
    display: inline-block;
    position: relative;
    text-align: left;
    color: rgb(0, 133, 202);
    font-size: 11px;
    background-color: ${backgroundColor};
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    padding: 4px 6px;
    margin: 0 0 0 10px;
    opacity: 1;
    z-index: 99;
    border-radius: 4px;
    width: 100%;
    max-width: 300px;
  }

  .arrow {
    display: none !important;
  }
`;
export default TooltipRight;
