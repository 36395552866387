import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";

import generalApi from "services/general-api";
import { sleep } from "utilities/common";

/**
 * refactor a bit to use react hooks
 * @returns
 */
export function EventOmiseloading() {
	let navigate = useNavigate();

	/**
	 * Count Down Display Function
	 */
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		let cancel = false;
		let remaningSeconds = 60 * 10; // 10 mins

		countDown();
		async function countDown() {
			for (remaningSeconds; remaningSeconds >= 0; remaningSeconds--) {
				// if cancel , exit the count down functino
				if (cancel) {
					return;
				}
				// more than 60 sec: 1 minute 30 seconds 
				// less than than 60 sec:  59 seconds
				// const timeFormat = remaningSeconds > 60 
				// 	? `${Math.floor(remaningSeconds / 60)} minutes ${remaningSeconds % 60} seconds` 
				// 	: `${remaningSeconds} seconds`;
				// // setCountDown(timeFormat);
				await sleep(1000);
			}

			// count down is over redirect to /event/checkout
			navigate("/event/checkout", {
				state: {
					payment_id: searchParams.get("payment_id"),
					payment_status: false,
				},
			});
		}

		return () => {
			cancel = true;
		};
	}, []);

	/**
	 * Check Payment Status Every X seconds (8 seconds for now)
	 */
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const payment_id = searchParams.get("payment_id");

		const interval = setInterval(async () => {
			// Dispatch the async action to check payment status
			// Assuming 'dispatch' and 'generalApi' are defined
			let jsonObj = await generalApi.checkpaymentStatus({
				payment_id: payment_id,
			});

			if(jsonObj.status !== 1) {
				console.warn("Payment Status is not 1");
				// TODO: Display error message to user ?
				// maybe redirect them to 
				alert("Something is wrong");
				return; 
			}
			if (jsonObj.payment_status === "SUCCESSFUL") {
				navigate("/event/checkout/success");
				// TODO: Redirect to /event/success_page
			} else if (jsonObj.payment_status === "FAILED") {
				// TODO: Redirect to /event/success_page
				clearInterval(interval);
				navigate("/event/checkout/course", {
					state: {
						payment_id: payment_id,
						regform_id: 1,
						payment_status: false,
						countdown: false,
					},
				});
			} 
		}, 8000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<>
			<Container className='mt-5 loading' fluid>
				<div className='message'>
					<h3>Please Wait...</h3>
					<div>We are currently verifying your payment.</div>
					<div>Please contact us if you need further assistance.</div>
				</div>
				<div className='mt-5 note'>
					<div>
						<span>Note:</span> Do not refresh, close or click the back button.
					</div>
				</div>
			</Container>
		</>
	);
}

