import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Col, Card, Button, CardBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function ModalReschedule() {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const courseRegisterData = useSelector((state) => state.courseRegisterSlice);

	const courseData = courseRegisterData.course;
	const courseRunData = courseRegisterData.courserun;
	const registerFormData = courseRegisterData.registerForm;
	let navigate = useNavigate();
	const MySwal = withReactContent(Swal);

	const location = useLocation();
	const data = location.state?.data;
	const learner_id = data?.learner_id;
	const learner_sfc = Number(data?.learner_sfc);
	const course = data?.course;
	const status = data?.status;

	if (!data) {
		navigate("/course");
	}

	useEffect(() => {
		if (!data || data.length < 0) {
			navigate("/course");
		}
	}, []);

	return (
		<>
			{course && (
				<div className='reschedule_main_container'>
					<div className='title-container'>
						<span className="fs40 text-fca-dark font_2">Reschedule</span>
					</div>
					<div>
						<Card className='mt-2' >
							<CardBody>
								<p className="fs18 text-fca-dark">
									According to our Terms & Conditions during your enrolment, kindly note that the
									administrative fee will be waived for the first reschedule request. Subsequent
									reschedule requests are chargeable at 10% of full course fees. To appeal for a waiver
									on administrative fees, please attach supporting documents for our review.
								</p>
								<p className="fs18 mt-2 mb-2 font-weight-bold text-fca-dark">{"Reschedule Request & Fees"}</p>
								<div className="p-4 mb-4" style={{ backgroundColor: "#DEECF3" }}>
									<p className="fs18 font-weight-bold text-fca-dark">{"• 1st Reschedule"}<span className="fs18 font-weight-300 text-fca-dark">{" - No charge"}</span></p>
									<p className="fs18 font-weight-bold text-fca-dark">{"• 2nd and Subsequent Reschedules"}<span className="fs18 font-weight-300 text-fca-dark">{" - 10% of full course fees (Waivable with valid reasons)"}</span></p>
								</div>
								<p className="fs18 text-fca-dark">
									A maximum of three (3) reschedule requests is allowed. There will be no rescheduling allowed after the 3rd reschedule and the enrolment will be considered as a no-show.
								</p>
								<Col md='12' xl='12'>
									<div className='flex justify-content-center justify-content-between mb-4 mt-6'>
										<Button
											onClick={() => navigate(-1)}
											className='btn btn-default ml-auto'>
											Back
										</Button>
										<Link
											to='/course/reschedule'
											state={{
												data: {
													learner_id: course.learner_id,
													learner_sfc: course.learner_sfc,
													courserun_id: course.courserun_id,
													course_title: course.course.course_title,
													course: course,
													status: status,
												},
											}}
										>
											<Button className='btn-gradient'>
												<span>Proceed to Reschedule</span>
											</Button>
										</Link>
										
									</div>
								</Col>
							</CardBody>
						</Card>
					</div>
				</div>
			)}
		</>
	);
}

export default ModalReschedule;
