import { PrintDate } from "common_function/function";
import { useWindow } from "hooks/UseWindow";
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import { getBundlePercentages } from "utilities/number";
import defaultCourse from "assets/img/default_course.jpg";

function BundleCoursesCard({ bundle }) {
	const { width } = useWindow();
	// bundle_banner_image
	return (
		<div className='h-100 p-2'>
			<Card className={`m-0 ${width <= 575 ? "" : "h-100"} cursor-pointer bundle-card pb-5`}>
				<Link className='text-dark' to={"/course/bundle/" + bundle.bundle_id}>
					<CardBody style={{ padding: 0, position:"relative" }}>
						<Row className="mb-3 mx-0" style={{ overflow: "hidden" }}>
							<img
								src={bundle.bundle_thumbnail || defaultCourse}
								width='100%'
								className={"bundle_list_banner_image"}
							/>
						</Row>
						<Row className='justify-content-between mx-3'>
							<Col md='auto' xl='auto' xs='auto' className="px-0">
								{bundle?.logos.length > 0
									? bundle.logos.map((logo, i) => (
										<img
											key={i}
											className='mr-1 h-20px display_ini'
											alt={bundle.bundle_name}
											src={logo.bundle_logo_image}
										/>
									))
									: ""}
							</Col>
							<Col md='auto' xl='auto' xs='auto' className='text-right'>
								<span className='p-1 ml-2 fs12 font-weight-bold course-sticker green-sticker'>
									{getBundlePercentages(bundle.total_completed, bundle.total_courses)}
								</span>
							</Col>
						</Row>

						<Row className='align-items-center pt-2 pb-2 mx-3'>
							<Col className='col-auto px-0'>
								<p className='w-100 fs18 font-weight-bold mb-0' style={{ fontFamily: "Coolvetica, sans-serif" }}>{bundle.bundle_name}</p>
								<div className='bundle-course-description pt-2'>
									{bundle?.courses.length > 0 ? bundle.courses.map((course, i) => (
										<Row
											key={i}
											className={(course.has_registered ? "registered " : "") + "bundle-course-title m-0 pb-2"
											}
										>
											{course.has_registered && course.is_completed && (
												<Col className='icon-completed-container p-0'>
													<div className={"circle"}>
														<img alt='medal' src={require("assets/img/Course/medal.png")} />
													</div>
												</Col>
											)}
											{course.has_registered && !course.is_completed && (
												<Col className='icon-selected-container p-0'>
													<div className={"selected-circle"}>
													</div>
												</Col>
											)}
											{!course.has_registered && (
												<Col className='icon-container p-0'>
													<div className={"circle"}>
													</div>
												</Col>
											)}


											<Col className='title-container'>
												{course.course_title}
											</Col>
										</Row>
									))
										: ""}
									{/* {bundle.professional_cert && bundle.professional_cert.length > 0 && (
										<>
											<Row className={"bundle-course-title m-0 pt-3 cert_date_completed"}>
												<Col className='icon-container p-0 border-0'>
													<div className={"circle"}>
														<img alt='medal' src={require("assets/img/Attendance/check.png")} />
													</div>
												</Col>
												<Col className='title-container text-body'>
													{PrintDate(bundle.professional_cert[0].cert_date_completed)}
												</Col>
											</Row>
										</>
									)} */}
								
								</div>
								
							</Col>
						</Row>
						
					
					</CardBody>
				</Link>
				<Link to={"/course/bundle/" + bundle.bundle_id}>
					<div className={`pb-2 px-2 ${width <= 576 ? "w-100" : ""}`} style={{ position:"absolute", bottom: 0, right: 0 }}>
						<Button className='btn-gradient' type='submit'>
							<span className="fs14">Read More</span>
						</Button>
					</div>
				</Link>
			</Card>
		</div>
	);
}

export default BundleCoursesCard;
