import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import * as yup from "yup";
import { yupHelper } from "utilities/yupHelpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Button, FormGroup, Form, Modal } from "reactstrap";

import { verifyOtp, resendOtp } from "../../actions/auth";
import { clearMessage } from "actions/message";

const schema = yup.object({
	otp: yupHelper.otp(),
});

// copy ths to make new otp
function OTPModalSignup({ onSuccess = () => {} }) {
	const { user: currentUser, isLoggedIn } = useSelector((state) => state.auth);
	const [modalChange, setModalChange] = React.useState(true);
	const [loading, setLoading] = useState(false);
	const [resendLoading, setResendLoading] = useState(false);
	const [seconds, setSeconds] = useState(30);
	const { message } = useSelector((state) => state.message);
	const dispatch = useDispatch();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		if (seconds !== 0) {
			const interval = setInterval(() => {
				setSeconds(seconds - 1);
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [seconds]);

	const handleOTP = (data) => {
		setLoading(true);

		console.log("super man");
		dispatch(verifyOtp(data.otp))
			.then(() => {
				console.log("hihi sucesss");
				setModalChange(false);
				onSuccess();
				// navigate("/");
				// window.location.reload();
			})
			.catch((error) => {
				console.log("hihi error", error);
				setLoading(false);
			});
	};

	const handleResendOTP = () => {
		if (!loading && seconds === 0) {
			setResendLoading(true);
			setSeconds(30);
			dispatch(resendOtp())
				.then(() => {
					setResendLoading(false);
				})
				.catch(() => {
					setResendLoading(false);
				});
		}
	};

	useEffect(() => {
		dispatch(clearMessage());
	}, []);

	useEffect(() => {
		if (!isLoggedIn) {
			setModalChange(false);
		} else {
			if (currentUser.user.email_verified_at) {
				setModalChange(false);
			}
		}
	}, [isLoggedIn]);

	return (
		<>
			<Modal
				className='modal-dialog-centered'
				isOpen={modalChange}
				// toggle={() => setModalChange(false)}
			>
				<button
					aria-label='Close'
					className='close d-none'
					data-dismiss='modal'
					type='button'
					onClick={() => setModalChange(false)}>
					<span aria-hidden={true}>×</span>
				</button>

				<div className='modal-header pt-5'>
					<div className='modal-header-wrap text-center'>
						<h3>OTP Verification</h3>
						<p>Enter the OTP verification code that sent to</p>
						<p>
							<u className='text-lowercase'>{currentUser.user.learnermaster_email}</u>
						</p>
					</div>
				</div>
				<div className='modal-body'>
					<Form role='form' onSubmit={handleSubmit(handleOTP)}>
						<FormGroup>
							<input
								className={"form-control " + (errors.otp ? "is-invalid" : "")}
								defaultValue=''
								placeholder='OTP Verification Code'
								type='text'
								{...register("otp")}
							/>
							<div className='mt-2 invalid-feedback text-center'>{errors.otp?.message}</div>
							<div className='mt-2 text-center'>
								Do not receive OTP Code?{" "}
								{seconds !== 0 && <span className='text-blue bold'>{seconds}</span>}
								{seconds === 0 && (
									<span disabled={loading} onClick={handleResendOTP} role='button' className='text-blue bold'>
										Resend
									</span>
								)}
							</div>
						</FormGroup>
						<div className='modal-footer justify-content-center mb-4'>
							<Button className='mt-4' color='success' type='submit' disabled={loading}>
								{loading && <span className='spinner-border spinner-border-sm'></span>}
								<span>Verify</span>
							</Button>
						</div>

						{message && (
							<div className='form-group'>
								<div
									className={classnames(
										"alert",
										message === "OTP Sent Success" ? "alert-success" : "alert-danger",
									)}
									role='alert'>
									{message}
								</div>
							</div>
						)}
					</Form>
				</div>
			</Modal>
		</>
	);
}

export default OTPModalSignup;
