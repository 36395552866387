const { useSelector } = require("react-redux");
export const useCheckout = () => {
	const checkokutState = useSelector((state) => state.courseRegisterSlice);
	const {
		courserun_id,
		registrationform_id,
		isLoading,
		hasLoaded,
		erorr,
		course,
		courserun,
		checkoutResponse,
		registerFormCache,
		registerForm,
		learners,
		checkoutAnalyticsData,
	} = checkokutState;

	return {
		registrationform_id: registrationform_id,
		courserun_id: courserun_id,
		hasLoaded: hasLoaded,
		isLoading: isLoading,
		erorr: erorr,
		course: course,
		courserun: courserun,
		registerForm: registerForm,
		learners: learners,
		checkoutResponse: checkoutResponse,
		registerFormCache: registerFormCache,
		checkoutAnalyticsData: checkoutAnalyticsData,
	};
};
