import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Col, Row, Card, CardBody } from "reactstrap";
import generalApi from "services/general-api";
import { toast } from "react-toastify";
import { ReactComponent as DownloadIcon } from "assets/img/Course/upcoming/download.svg";
import _ from "lodash";
import { saveAs } from "file-saver";
import "../../../../assets/scss/custom/_inner-html.scss";
import { getErrorMessage } from "utilities/error";


function ModalResourceDetails() {
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [resourceDetails, setResourceDetails] = useState(null);
	const [isDownloadedArray, setIsDownloadedArray] = React.useState([]);
	const [isLoadingArray, setIsLoadingArray] = React.useState([]);
	const params = useParams();
	// params.id
	const location = useLocation();
	const course = location.state?.course;

	useEffect(() => {
		getResourceDetails();
	}, []);

	const getResourceDetails = () => {
		setIsLoading(true);
		setIsError(false);
		generalApi.getResourcesDetails(params.id).then(
			(response) => {
				let downloadArray = [];

				response.data &&
				response.data.items &&
				response.data.items.map(() => {
					downloadArray.push(false);
				});
				setIsDownloadedArray(downloadArray);
				setIsLoadingArray(downloadArray);
		
				setIsLoading(false);
				let newresourcedetails = response.data;
				if (newresourcedetails.resource_content) {
					let newDescHtml = newresourcedetails.resource_content;
					newDescHtml = newDescHtml.split("<oembed").join("<iframe");
					newDescHtml = newDescHtml.split("</oembed>").join("</iframe>");
					newDescHtml = newDescHtml.split("url=").join("src=");
					newresourcedetails.resource_content = newDescHtml;
					setResourceDetails(newresourcedetails);
				} else {
					setResourceDetails(newresourcedetails);
				}
			
				setIsError(false);
			},
			(error) => {
				toast.error(getErrorMessage(error));
				setIsLoading(false);
				setResourceDetails(null);
				setIsError(true);
			},
		);
	};

	const handlePress = async (item) => {
		if (item.resourceitem_type === "link") {
			window.open(item.resourceitem_original_url, "_blank");
		} else if (item.resourceitem_type === "file") {
			const filename = _.last(item.resourceitem_original_url.split("/"))?.split("?")?.[0];
			saveAs( item.resourceitem_original_url, filename);
		}
	};

	const renderOpenURLButton = (item, index) => {
		return (
			<div key={index} onClick={() => handlePress(item)}>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<p style={{fontSize: 14, fontWeight: "600", marginRight: 10}}>{`${index + 1}.`}</p>
					<div style={{flex: 1}}>
						<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
							<p  style={{fontSize: 14, fontWeight: "600"}}>{`${item.resourceitem_title}`}</p>
							{item.resourceitem_type !== "link" &&
								!isDownloadedArray[index] &&
								!isLoadingArray[index] && (
								<DownloadIcon className="svg-fill-blue" height={20} width={20} style={{marginRight: 10}} />
							)}
						</div>
						<p  style={{fontSize: 14, textDecorationLine: "underline", color: "rgb(55,132,196)"}}>{`${item.resourceitem_original_url}`}</p>
					</div>
				</div>
				<hr className='mt-3 mb-3'></hr>
			</div>
		);
	};

	return (
		<>
			<div className=''>
				<div className='title-container'>
					<h2>Resource Details</h2>
				</div>
				{isLoading && (
					<div className='justify-content-center mt-4 w-100 flex'>
						<span className='spinner-border spinner-border-sm'></span>
					</div>
				)}
				{!isLoading && isError && (
					<div onClick={() => getResourceDetails()}>
						<Card className='mt-4'>
							<CardBody className='flex justify-content-center'>
								<div className='btn btn-gradient'>
									<span>Reload</span>
								</div>
							</CardBody>
						</Card>
					</div>
				)}
				{!isLoading && !isError && resourceDetails && (
					<div>
						<Card className='mt-4'>
							<CardBody>
								<div>
									{resourceDetails && resourceDetails.resource_image_url && (
										<Row className='mb-1'>
											<img
												className='m-1 h-40px display_ini'
												src={resourceDetails.resource_image_url}
											/>
										</Row>
									)}
									<p style={{ fontSize: 16, fontWeight: "600", color: "rgb(55,132,196)" }}>
										{resourceDetails.resource_title}
									</p>
									<hr className='mt-1 mb-3'></hr>
									<div className="inner-html" dangerouslySetInnerHTML={{ __html: resourceDetails.resource_content }}></div>
									{resourceDetails.resource_content !== null &&
										<hr className='mt-1 mb-3'></hr>
									}
									<p style={{ fontSize: 14, fontWeight: "600" }}>{"Attachments: "}</p>
									{resourceDetails.items.length > 0 &&
										resourceDetails.items.map((item, index) => {
											return renderOpenURLButton(item, index);
										})}
									{resourceDetails.items.length === 0 &&
										<p>{"-"}</p>
									}
								</div>

								<Row>
									<Col md='12' xl='12' className='mt-6'>
										<div className='flex mb-4 align-items-center'>
											<Link
												to='/course/download-resources'
												state={{ course: course }}
												className='btn btn-default ml-auto'>
												{"Back"}
											</Link>
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</div>
				)}
			</div>
		</>
	);
}

export default ModalResourceDetails;
